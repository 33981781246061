
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'AppCheckbox',
  props: {
    modelValue: {
      type: [String, Boolean, Array],
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    isChecked: {
      type: Boolean,
      required: false,
    },
    hiddenCheckBox: {
      type: Boolean,
      default: false,
      required: false,
    },
    checkBoxName: {
      type: String,
      required: false,
    },
  },
  setup (props, context) {
    const checkedItems = computed({
      get: () => props.modelValue,
      set: (val) => {
        context.emit('update:modelValue', val)
      },
    })
    return {
      checkedItems,
    }
  },
})
