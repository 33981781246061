import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'

const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

/**
 * アイテムの転載時の表記例を返却します。
 * @param item
 * @returns 転載時の表記例を返す
 */
export const getItemCopyright = (item: any) => {
  if (!item) return ''

  const meta = item.meta
  const level = item.level
  // ＜表記例＞
  // 階層コードが'記事レベル'のアイテム ※ A || B の形式は前の値が無い場合は、後ろの値を表示
  // (例)著者 ほか「タイトル」『刊行物名 || タイトル』刊行物巻 || 巻次又は部編番号(刊行物号 || 刊行物通号),出版者,出版日(dcterms:W3CDTF) || 出版日. 国立国会図書館デジタルコレクション https://stg.dl.ndl.go.jp/info:ndljp/pid/01273 (参照 アクセス日)

  // 階層コードが'記事レベル'以外のアイテム
  // (例)著者 ほか『タイトル』刊行物巻 || 巻次又は部編番号(刊行物号 || 刊行物通号),出版者,出版日(dcterms:W3CDTF) || 出版日. 国立国会図書館デジタルコレクション https://stg.dl.ndl.go.jp/info:ndljp/pid/01273 (参照 アクセス日)

  // タイトル以外のメタデータが空のアイテム
  // (例)『タイトル』. 国立国会図書館デジタルコレクション https://stg.dl.ndl.go.jp/info:ndljp/pid/01271 (参照 2022-01-27)

  let copyRight = ''
  copyRight += getCreatorText(meta)
  if (level === '2') {
    // 階層コードが記事レベルの場合
    copyRight += addBrackets(metaArrayProcessing(meta['0001Dtct']), '「」')
    copyRight += addBrackets(metaArrayProcessing(meta['0271Dt']) || metaArrayProcessing(meta['0001Dtct']), '『』')
  } else {
    copyRight += addBrackets(metaArrayProcessing(meta['0001Dtct']), '『』')
  }
  copyRight += metaArrayProcessing(meta['0272Dt']) || getKenjiText(meta)
  copyRight += addBrackets(metaArrayProcessing(meta['0270Dt']) || metaArrayProcessing(meta['0269Dod']), '()')
  copyRight += addSparator(metaArrayProcessing(meta['0020Dtct']))
  copyRight += addSparator(metaArrayProcessing(meta['0058Dod']))
  copyRight += '. ' + '国立国会図書館デジタルコレクション ' + BASE_URL + makeUpdatedPidString(item.pid)
  copyRight += ' ' + addBrackets('参照 ' + getNowYMD(), '()')
  return copyRight
}

// メタ配列の連結
const metaArrayProcessing = (meta: Array<string>): string => {
  if (!hasMeta(meta)) return ''
  return meta.join()
}

// メタ配列のnullチェック
const hasMeta = (meta: Array<string>): boolean => {
  if (!meta || meta[0] === '') return false // null or undefind or '' ならばfalse
  if (meta.length === 0) return false // 空配列ならfalse
  return true
}

// 著者(0010Dtct)の値を取得 ※複数ある場合は1つ目のみで後ろに"ほか"を付ける
const getCreatorText = (meta: any): string => {
  if (!hasMeta(meta['0010Dtct'])) return ''
  if (meta['0010Dtct'].length === 1) return meta['0010Dtct'].join()
  return meta['0010Dtct'][0] + ' ほか'
}

// 巻次又は部編番号(0007Dtct)のセミコロン ; があればその前までの値を取得
const getKenjiText = (meta: any): string => {
  if (!hasMeta(meta['0007Dtct'])) return ''
  return metaArrayProcessing(meta['0007Dtct']).split(';')[0]
}

// アクセス日(YYYY-MM-DD)を取得
const getNowYMD = () => {
  const dt = new Date()
  const y = dt.getFullYear()
  const m = ('00' + (dt.getMonth() + 1)).slice(-2)
  const d = ('00' + dt.getDate()).slice(-2)
  return (y + '-' + m + '-' + d)
}

// 括弧を付与する
const addBrackets = (text: string, brackets: string): string => {
  if (text === '') return text
  const bracket = brackets.split('')
  return bracket[0] + text + bracket[1]
}

// ','を付与する
const addSparator = (text: string): string => {
  if (text === '') return text
  return ',' + text
}
