
import { defineComponent } from 'vue'
import DssIcon from '../atoms/DssIcon.vue'

export default defineComponent({
  components: {
    DssIcon,
  },
  props: {
    role: {
      type: String,
      default: 'alert',
    },
  },
})
