import { PhysicalPosition, Vec2 } from './ImageViewerCommon'

/**
 * ピンアイコンのDL状態クラス
 */
class PinIconDLState {
  /**
   * 画像要素
   */
  public img?: HTMLImageElement

  /**
   * アイコンの状態
   * @description 1~エラー, 0=未DL, 1=DL済み
   */
  public state = 0

  /**
   *
   * @param image アイコンとして使用する画像要素
   */
  public constructor (image: HTMLImageElement) {
    this.img = image
  }
}

/**
 * 検索ヒットピンクラス
 */
class SearchHitPin {
  /**
   * ダウンロード済みアイコン画像
   */
  private static iconImages: Map<string, PinIconDLState> = new Map()

  /**
   * 描画用のピンアイコン画像を取得する
   * @param iconFileName
   * @returns DL済みの場合は Image オブジェクト、DL未完了の場合は undefined を返す
   */
  public static getIconImage (iconFileName: string): PinIconDLState | undefined {
    const res = SearchHitPin.iconImages.get(iconFileName)
    if (res) {
      return res
    }

    const img = new Image()
    if (iconFileName.startsWith('/')) {
      img.src = iconFileName
    } else {
      img.src = '/' + iconFileName
    }
    const pds = new PinIconDLState(img)
    SearchHitPin.iconImages.set(iconFileName, pds)
    img.decode()
      .then(() => {
        pds.state = 1
      })
      .catch((mes) => {
        pds.state = -1
      })

    return undefined
  }

  /**
   * ピンのID
   * コンテンツ（コマ）内で一意か、現在の検索ヒット情報内（pid内）で一意かは未決定
   */
  public pinID = -1

  /**
   * ピン座標
   * @description オリジナル解像度上の割合座標 0.0-1.0
   */
  public position = new Vec2()

  public clone () {
    const res = new SearchHitPin()

    res.pinID = this.pinID
    res.position.x = this.position.x
    res.position.y = this.position.y

    return res
  }
}

/**
 * 検索ヒット語句クラス
 */
class SearchHitWord {
  /**
   * 検索文字列
   */
  public text = ''

  /**
   * ピンアイコン画像名
   */
  public icon = ''

  /**
   * この検索ヒット語句のピンの配置座標配列
   */
  public pins: Array<SearchHitPin> = []
}
/**
 * 検索ヒットコンテンツクラス
 */
class SearchHitContent {
  /**
   * このコンテンツのID
   */
  public contentID = -1

  /**
   * このコンテンツ上でヒットした検索語句
   */
  public hitWords: Array<SearchHitWord> = []
}

/**
 * 検索ヒットコンテンツの配列を生成する
 * @param info 検索ヒット情報を生成元JSON配列もしくはJSON文字列
 * @returns 検索ヒットコンテンツの配列。バリデーションに失敗した場合は要素数0の配列を返す
 */
function makeHitInfoArray (info: string | Array<any>): Array<SearchHitContent> {
  const res: Array<SearchHitContent> = []

  try {
    if (typeof info === 'string') {
      info = JSON.parse(info)
    }

    if (!Array.isArray(info)) {
      return res
    }

    for (let i = 0; i < info.length; ++i) {
      const c = new SearchHitContent()
      if (typeof info[i] !== 'object') {
        res.length = 0
        break
      }

      const root = info[i] as any

      // content_id

      if (!Object.prototype.hasOwnProperty.call(root, 'content_id')) {
        res.length = 0
        break
      }

      c.contentID = (root as any).content_id

      if (!Object.prototype.hasOwnProperty.call(root, 'hit_words') || !Array.isArray((root as any).hit_words)) {
        res.length = 0
        break
      }

      const hws = (root as any).hit_words as Array<any>

      let hasError = false
      for (let j = 0; j < hws.length; ++j) {
        const w = hws[j] as any
        const hitWord = new SearchHitWord()

        // text

        if (!Object.prototype.hasOwnProperty.call(w, 'text') || !(typeof (w as any).text === 'string')) {
          hasError = true
          break
        }
        hitWord.text = (w as any).text as string

        // icon

        if (!Object.prototype.hasOwnProperty.call(w, 'icon') || !(typeof (w as any).icon === 'string')) {
          hasError = true
          break
        }
        hitWord.icon = (w as any).icon as string

        // position

        if (!Object.prototype.hasOwnProperty.call(w, 'pins') || !Array.isArray((w as any).pins)) {
          hasError = true
          break
        }

        const p = (w as any).pins as Array<any>
        for (let k = 0; k < p.length; ++k) {
          const srcPin = p[k] as any
          const pin = new SearchHitPin()

          if (!Object.prototype.hasOwnProperty.call(srcPin, 'pin_id') || !Number.isFinite((srcPin as any).pin_id) || Number.isFinite((srcPin as any).pin_id < 0)) {
            hasError = true
            break
          }

          pin.pinID = (srcPin as any).pin_id as number

          // 座標はコマ画像に対する比(0.0 - 1.0)でもらう
          if (!Object.prototype.hasOwnProperty.call(srcPin, 'x') || !Number.isFinite((srcPin as any).x)) {
            hasError = true
            break
          }
          if (!Object.prototype.hasOwnProperty.call(srcPin, 'y') || !Number.isFinite((srcPin as any).y)) {
            hasError = true
            break
          }

          pin.position.x = (srcPin as any).x as number
          pin.position.y = (srcPin as any).y as number

          hitWord.pins.push(pin)
        }
        if (hasError) {
          break
        }

        c.hitWords.push(hitWord)
      }
      if (hasError) {
        res.length = 0
        break
      }

      res.push(c)
    }
  } catch {
    res.length = 0
  }

  return res
}

export { SearchHitContent, SearchHitWord, SearchHitPin, makeHitInfoArray }
