import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5cf8884"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-global-header-inner" }
const _hoisted_2 = {
  id: "the-global-header-body",
  class: "level is-mobile"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "is-sr-only" }
const _hoisted_5 = { class: "is-sr-only" }
const _hoisted_6 = {
  key: 0,
  class: "focus-move-button-group is-hidden-mobile"
}
const _hoisted_7 = { class: "the-global-header-nav__inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_TheGlobalNav = _resolveComponent("TheGlobalNav")!
  const _component_TheMyCollectionHeader = _resolveComponent("TheMyCollectionHeader")!
  const _component_TheRekionAdminMenuHeader = _resolveComponent("TheRekionAdminMenuHeader")!

  return (_openBlock(), _createElementBlock("header", {
    ref: "header",
    class: _normalizeClass(["the-global-header has-text-modest shadow", _ctx.headerStyle]),
    style: _normalizeStyle(_ctx.headerStyleDuringAnimation)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isRekion ? 'LogoRekion' : _ctx.isKn ? 'LogoKn' : 'LogoNdldc'), {
          type: _ctx.logoType,
          tag: "h1",
          class: "the-global-header-logo"
        }, {
          default: _withCtx(() => [
            (_ctx.isKn)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.$t('link.knApplication'),
                  id: "the-global-header-site-name"
                }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('label.knName')), 1)
                ], 8, _hoisted_3))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: { path: '/' },
                  id: "the-global-header-site-name"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.isRekion ? _ctx.$t('label.rekionName') : _ctx.$t('label.siteName')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["type"])), [
          [_vShow, !_ctx.show || _ctx.headerType !== 'top-page']
        ]),
        (_ctx.isShowFocusMoveButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_AppButton, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focusMove('focus-move-button-of-search-form'))),
                onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.focusMove('focus-move-button-of-search-form')), ["prevent"]), ["space","enter"])),
                "data-cy": "focus-move-button-of-search-form",
                class: "focus-move-button",
                styled: "is-accent",
                tabindex: "0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('label.shortcut.searchResult.focusMoveButtonOfSearchForm')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_AppButton, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focusMove('focus-move-button-of-search-result'))),
                onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.focusMove('focus-move-button-of-search-result')), ["prevent"]), ["space","enter"])),
                "data-cy": "focus-move-button-of-search-result",
                class: "focus-move-button",
                styled: "is-accent",
                tabindex: "0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('label.shortcut.searchResult.focusMoveButtonOfSearchResult')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_AppButton, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.focusMove('focus-move-button-of-facet'))),
                onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.focusMove('focus-move-button-of-facet')), ["prevent"]), ["space","enter"])),
                "data-cy": "focus-move-button-of-facet",
                class: "focus-move-button",
                styled: "is-accent",
                tabindex: "0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('label.shortcut.searchResult.focusMoveButtonOfFacet')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
          onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent"]), ["space","enter"])),
          class: "the-global-header-button is-hidden-desktop level-center is-mobile"
        }, [
          _createVNode(_component_DssIcon, { icon: "ellipsis-vertical" })
        ], 32),
        _createVNode(_Transition, { name: "accordion" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              ref: "nav",
              class: "the-global-header-nav",
              style: _normalizeStyle({ height: _ctx.heightDuringAnimation.nav && `${_ctx.heightDuringAnimation.nav}px` })
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_TheGlobalNav, {
                  isRekion: _ctx.isRekion,
                  isKn: _ctx.isKn,
                  onCloseAccordion: _ctx.toggle
                }, null, 8, ["isRekion", "isKn", "onCloseAccordion"]),
                (_ctx.isMyCollectionPage)
                  ? (_openBlock(), _createBlock(_component_TheMyCollectionHeader, { key: 0 }))
                  : _createCommentVNode("", true)
              ])
            ], 4), [
              [_vShow, _ctx.show || !_ctx.breakpoint]
            ])
          ]),
          _: 1
        })
      ]),
      (_ctx.isRekionAdminMenu)
        ? (_openBlock(), _createBlock(_component_TheRekionAdminMenuHeader, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 6))
}