
import { computed, defineComponent } from 'vue'
import MetadataRecord from '@/components/molecules/MetadataRecord.vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { simpleRecords } from '@/data/master/CustomMetadataLabel'
import { getItemListViewAllCollectionsForDetail } from '@/domain/item/ItemListView/collectionLabel'
import { createItemTitleLabel } from '@/domain/item/itemViewer/itemTitleLabel'

export default defineComponent({
  components: {
    MetadataRecord,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    collection: {
      type: String,
      required: false,
      default: '',
    },
  },
  inject: ['getMetadataValue', 'getMetadataLabels'],
  setup (props) {
    const i18n = useI18n()
    const lang = i18n.locale
    const store = useStore()
    const itemListViewCollection = computed(() => getItemListViewAllCollectionsForDetail({ content: props.item }, store, lang.value))
    return {
      itemListViewCollection,
      createItemTitleLabel,
      ...simpleRecords.A00084,
    }
  },
})
