export const detailRecords = {
  10: {
    A00024: { ja: '作詞・作曲・編曲・実演家', en: 'Author/Performer' },
    A00017: { ja: '作成者', en: 'Creator' },
    A00151: { ja: '作成者', en: 'Creator' },
    A00123: { ja: '脚本家・放送作家、原作者', en: 'Scriptwriter, Television & Radio Writer, Original Author' },
    A00126: { ja: 'スタッフ', en: 'Staff' },
    A00145: { ja: 'スタッフ', en: 'Staff' },
    A00124: { ja: '作曲者・編曲者', en: 'Composer, Arranger' },
  },
  324: {
    A00124: { ja: '作曲者・編曲者よみ', en: 'Composer,Arranger Transcription' },
  },
  17: {
    A00126: { ja: '監修・学術指導・協力・後援・提供', en: 'Editorial supervisor/Academic guide/Cooperator/Provider' },
    A00145: { ja: '監修・学術指導・協力・後援・提供', en: 'Editorial supervisor/Academic guide/Cooperator/Provider' },
  },
  271: {
    A00003: { ja: '収載資料名', en: 'Part Of' },
  },
  18: {
    A00124: { ja: '版表示', en: 'Version' },
  },
  20: {
    A00024: { ja: '製作者（レーベル）', en: 'Label Name' },
    A00123: { ja: '放送局・制作', en: 'Broadcast Station, Production' },
    A00126: { ja: '製作・企画', en: 'Producer/Planer' },
    A00145: { ja: '製作・企画', en: 'Producer/Planer' },
  },
  58: {
    A00017: { ja: '年月日', en: 'Date' },
    A00151: { ja: '年月日', en: 'Date' },
    A00123: { ja: '放送年月日', en: 'Air Date' },
    A00124: { ja: '作曲年', en: 'Year of composition' },
    default: { ja: '出版年月日', en: 'Publication Date' },
  },
  59: {
    A00017: { ja: '年月日(W3CDTF)', en: 'Date (W3CDTF)' },
    A00151: { ja: '年月日(W3CDTF)', en: 'Date (W3CDTF)' },
    A00016: { ja: '資料作成年月', en: 'Date of Creation' },
    A00126: { ja: '製作年月日(W3CDTF)', en: 'Production Date (W3CDTF)' },
    A00145: { ja: '製作年月日(W3CDTF)', en: 'Production Date (W3CDTF)' },
    A00124: { ja: '作曲年(W3CDTF)', en: 'Year of composition (W3CDTF)' },
    A00123: { ja: '放送年月日(W3CDTF)', en: 'Air Date (W3CDTF)' },
    default: { ja: '出版年月日(W3CDTF)', en: 'Publication Date (W3CDTF)' },
  },
  74: {
    A00017: { ja: '数量等', en: 'Quantity' },
    A00151: { ja: '数量等', en: 'Quantity' },
    A00124: { ja: '形態', en: 'Physical Description' },
  },
  3: {
    A00017: { ja: '階層', en: 'Bibliographic Level' },
    A00016: { ja: 'シリーズ名', en: 'Series Name' },
  },
  40: {
    A00016: { ja: '注記', en: 'Remark' },
    A00019: { ja: '注記', en: 'Remark' },
    A00126: { ja: '受賞歴', en: 'Wining award' },
    A00145: { ja: '受賞歴', en: 'Wining award' },
    A00124: { ja: '注記', en: 'Remark' },
    A00123: { ja: '注記', en: 'Remark' },
  },
  22: {
    A00126: { ja: 'ジャンル', en: 'Genre' },
    A00145: { ja: 'ジャンル', en: 'Genre' },
    A00124: { ja: 'ジャンル', en: 'Genre' },
  },
  274: {
    A00024: { ja: 'デジタル変換作業会社', en: 'Digitization Agent' },
  },
  41: {
    A00003: { ja: '解題', en: 'Analysis' },
    A00126: { ja: '解説', en: 'narration' },
    A00145: { ja: '解説', en: 'narration' },
  },
  373: {
    default: { ja: 'コレクション情報', en: 'Collection Information' },
  },
  371: {
    A00123: { ja: '請求記号', en: 'Call Number' },
  },
}

export const simpleRecords = {
  A00003: {
    label_58: { ja: '出版年月日', en: 'Publication Date' },
    label_271: { ja: '収載資料名', en: 'Part Of' },
    label_371: { ja: '請求記号', en: 'Call Number' },
    label_352: { ja: '書誌ID', en: 'Bibliographic ID' },
  },
  A00016: {
    label_59: { ja: '資料作成年月', en: 'Date of Creation' },
    label_3: { ja: 'シリーズ名', en: 'Series Name' },
    label_40: { ja: '注記', en: 'Remark' },
    label_352: { ja: '書誌ID', en: 'Bibliographic ID' },
  },
  A00017: {
    label_10: { ja: '作成者', en: 'Creator' },
    label_40: { ja: '内容記述', en: 'Note' },
    label_58: { ja: '年月日', en: 'Date' },
    label_74: { ja: '数量等', en: 'Quantity' },
    label_371: { ja: '請求記号', en: 'Call Number' },
    label_352: { ja: '書誌ID', en: 'Bibliographic ID' },
    label_3: { ja: '階層', en: 'Bibliographic Level' },
  },
  A00019: {
    label_3: { ja: 'シリーズ名', en: 'Series Name' },
    label_40: { ja: '注記', en: 'Remark' },
    label_58: { ja: '出版年月日', en: 'Publication Date' },
    label_371: { ja: '請求記号', en: 'Call Number' },
    label_352: { ja: '書誌ID', en: 'Bibliographic ID' },
  },
  A00024: {
    label_10: { ja: '作詞・作曲・編曲・実演家', en: 'Author/Performer' },
    label_20: { ja: '製作者(レーベル)', en: 'Label Name' },
    label_58: { ja: '発売年月日', en: 'Release Date' },
    label_281: { ja: '実演家権利情報', en: 'Right Status  (Performer)' },
  },
  A00084: {
    label_3: { ja: 'シリーズ名', en: 'Series Name' },
    label_58: { ja: '出版年月日', en: 'Publication Date' },
    label_371: { ja: '請求記号', en: 'Call Number' },
    label_373: { ja: 'コレクション情報', en: 'Collection Information' },
    label_352: { ja: '書誌ID', en: 'Bibliographic ID' },
  },
  A00123: {
    label_10: { ja: '脚本家・放送作家、原作者', en: 'Scriptwriter, Television & Radio Writer, Original Author' },
    label_20: { ja: '放送局・制作', en: 'Broadcast Station, Production' },
    label_58: { ja: '放送年月日', en: 'Air Date' },
    label_40: { ja: '注記', en: 'Remark' },
    label_371: { ja: '請求記号', en: 'Call Number' },
  },
  A00124: {
    label_10: { ja: '作曲者・編曲者', en: 'Composer, Arranger' },
    label_59: { ja: '作曲年', en: 'Year of composition' },
    label_22: { ja: 'ジャンル', en: 'Genre' },
    label_74: { ja: '形態', en: 'Physical Description' },
    label_40: { ja: '注記', en: 'Remark' },
    label_371: { ja: '請求記号', en: 'Call Number' },
  },
  A00126: {
    label_10: { ja: 'スタッフ', en: 'Staff' },
    label_20: { ja: '製作・企画', en: 'Producer/Planer' },
    label_58: { ja: '製作年月日', en: 'Production Date' },
  },
  A00145: {
    label_10: { ja: 'スタッフ', en: 'Staff' },
    label_20: { ja: '製作・企画', en: 'Producer/Planer' },
    label_58: { ja: '製作年月日', en: 'Production Date' },
  },
  A00151: {
    label_10: { ja: '作成者', en: 'Creator' },
    label_40: { ja: '内容記述', en: 'Note' },
    label_58: { ja: '年月日', en: 'Date' },
    label_74: { ja: '数量等', en: 'Quantity' },
    label_371: { ja: '請求記号', en: 'Call Number' },
  },
  B00000: {
    label_58: { ja: '出版年月日', en: 'Publication Date' },
  },
  default: {
    label_3: { ja: 'シリーズ名', en: 'Series Name' },
    label_58: { ja: '出版年月日', en: 'Publication Date' },
    label_371: { ja: '請求記号', en: 'Call Number' },
    label_352: { ja: '書誌ID', en: 'Bibliographic ID' },
  },
}
