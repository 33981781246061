
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    meta: {
      type: Object,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
  },
  setup () {
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join() : ''
    }

    const getMetadataLabels = (key: string) => {
      const metaData = store.getters.MetadataObjectByField(key)
      return { en: metaData.label_en, ja: metaData.label }
    }

    return {
      lang,
      metaArrayProcessing,
      getMetadataLabels,
    }
  },
})
