import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a26937a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "panel-abstracts has-background-contents"
}
const _hoisted_2 = {
  class: "title",
  id: "abstracts-title"
}
const _hoisted_3 = {
  id: "panel-abstracts-metadata-field-list",
  class: "panel-metadata-field-list"
}
const _hoisted_4 = {
  key: 0,
  class: "panel-metadata-field"
}
const _hoisted_5 = { class: "panel-metadata-field-name has-text-modest" }
const _hoisted_6 = {
  key: 1,
  class: "panel-metadata-field"
}
const _hoisted_7 = { class: "panel-metadata-field-name has-text-modest" }
const _hoisted_8 = {
  key: 2,
  class: "panel-metadata-field"
}
const _hoisted_9 = { class: "panel-metadata-field-name has-text-modest" }
const _hoisted_10 = {
  key: 3,
  class: "panel-metadata-field"
}
const _hoisted_11 = { class: "panel-metadata-field-name has-text-modest" }
const _hoisted_12 = { class: "abstracts-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isDisplayMetadata)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('label.abstract')), 1),
        _createElementVNode("ul", _hoisted_3, [
          (_ctx.existDetail)
            ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getMetadataLabels(702)[_ctx.lang]), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.currentContentMetadata['0702Dtct']?.join('\r\n')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.existIntroduction)
            ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getMetadataLabels(703)[_ctx.lang]), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.currentContentMetadata['0703Dtct']?.join('\r\n')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.existReprint)
            ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getMetadataLabels(704)[_ctx.lang]), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.currentContentMetadata['0704Dtct']?.join('\r\n')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.existAbstract)
            ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('label.summary', { name: _ctx.meta['0001Dtct'][0] })), 1),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.meta['0041Dtct']?.join('\r\n')), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}