import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6528dc80"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppRadioButton = _resolveComponent("AppRadioButton")!

  return (!_ctx.mobileFlag)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: _ctx.id,
        class: _normalizeClass(["radio-button-list", _ctx.styled])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_AppRadioButton, {
            id: `${_ctx.id}-${item.value}`,
            name: _ctx.name,
            key: item.value,
            value: item.value,
            modelValue: _ctx.pcPicked,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pcPicked) = $event)),
            disabled: item.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["id", "name", "value", "modelValue", "disabled"]))
        }), 128))
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        id: _ctx.id,
        class: _normalizeClass(["radio-button-list", _ctx.styled])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_AppRadioButton, {
            id: `${_ctx.id}-${item.value}`,
            name: _ctx.name,
            key: item.value,
            value: item.value,
            modelValue: _ctx.mobilePicked,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mobilePicked) = $event)),
            onMousedown: ($event: any) => (_ctx.selected(item.value)),
            onTouchstart: ($event: any) => (_ctx.selected(item.value)),
            onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.selected(item.value)), ["prevent"]), ["enter"]),
            disabled: item.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["id", "name", "value", "modelValue", "onMousedown", "onTouchstart", "onKeydown", "disabled"]))
        }), 128))
      ], 10, _hoisted_2))
}