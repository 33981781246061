
import { defineComponent } from 'vue'
import NotificationToast from '../molecules/NotificationToast.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    NotificationToast,
  },
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale

    return {
      lang,
    }
  },
})
