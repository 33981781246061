const SORT_SCORE = 'SCORE' // 適合度順
const SORT_TITLE_ASC = 'TITLE_ASC' // タイトル：昇順
const SORT_TITLE_DESC = 'TITLE_DESC' // タイトル：降順
const SORT_CREATOR_ASC = 'CREATOR_ASC' // 著者：昇順
const SORT_CREATOR_DESC = 'CREATOR_DESC' // 著者：降順
const SORT_ISSUED_ASC = 'ISSUED_DESC' // 出版日：新しい順
const SORT_ISSUED_DESC = 'ISSUED_ASC' // 出版日：古い順
const SORT_CALL_NUMBER_ASC = 'CALL_NUMBER_ASC' // 請求記号：昇順
const SORT_CALL_NUMBER_DESC = 'CALL_NUMBER_DESC' // 請求記号：降順

export const mycollectionSortList = [
  { value: SORT_SCORE },
  { value: SORT_TITLE_ASC },
  { value: SORT_TITLE_DESC },
  { value: SORT_CREATOR_ASC },
  { value: SORT_CREATOR_DESC },
  { value: SORT_ISSUED_DESC },
  { value: SORT_ISSUED_ASC },
  { value: SORT_CALL_NUMBER_ASC },
  { value: SORT_CALL_NUMBER_DESC },
]
