const isOutputConsolelog = process.env.VUE_APP_ENV !== 'production' || (window && window.location.hostname === 'localhost')

const noConsole = (): Console => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _console: any = {}
  Object.keys(console).forEach(e => {
    if (e === 'error') {
      _console[e] = console.error
      return
    }
    _console[e] = () => { /** */ }
  })
  return _console
}

export default (): Console => isOutputConsolelog ? console : noConsole()
