import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e442ba7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal-sheet",
  role: "dialog",
  "aria-modal": "true",
  "aria-labelledby": "modal-sheet-heading"
}
const _hoisted_2 = {
  id: "modal-sheet-heading",
  class: "modal-sheet-header has-background-contents level is-mobile"
}
const _hoisted_3 = { class: "modal-sheet-nav" }
const _hoisted_4 = { class: "modal-sheet-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true),
      _createElementVNode("nav", _hoisted_3, [
        _createVNode(_component_ButtonIcon, {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"])),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent","stop"]), ["space","enter"])),
          styled: "is-none",
          icon: "xmark-circle",
          "aria-label": _ctx.$t('parts.close')
        }, null, 8, ["aria-label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}