import { v4 as uuidv4 } from 'uuid'
import { Picture } from '@/data/@types/picture'
import { SearchHits } from '@/data/@types/SearchHits'
import { ImageSearchRequest } from '@/data/@types/ImageSearchRequest'
import { getData, postData } from '@/helpers/util/webApiUtil'

const querystring = require('querystring') // eslint-disable-line

const BASE_URL = process.env.VUE_APP_API_BASE_URL

type S3Object = {
  key: string,
  file: File
}

type ImageSearch = {
  request: ImageSearchRequest,
  errorMessage: string
}

/**
 * ステート
 */
const state = {
  ids: [],
  file: {},
  ImageHits: {
    searchHits: [],
  },
  isProcessing: false,
  removeItems: [],
  pictureMeta: {},
}

/**
 * ゲッター
 */

const getters = {
  PictureIds (state: any): string[] {
    return state.ids
  },
  PictureFile (state: any) {
    return state.file
  },
  ImageList (state: any) {
    return state.ImageHits
  },
  PictureIsProcessing (state: any) {
    return state.isProcessing
  },
  RemoveItems (state: any) {
    return state.removeItems
  },
  pictureMeta: (state: any) => {
    if (!state.pictureMeta) return
    return state.pictureMeta
  },
}

/**
 * ミューテーション
 */
const mutations = {
  SET_IDS (state: any, ids: string[]) {
    state.ids = [...ids]
  },
  SET_FILE (state: any, object: S3Object) {
    state.file = { ...object }
  },
  SET_RESULTS (state: any, payload: SearchHits<Picture>) {
    state.ImageHits = payload
    state.hasError = false
  },
  PICTURE_PROCESSING (state: any) {
    state.isProcessing = true
  },
  PICTURE_PROCESSED (state: any) {
    state.isProcessing = false
  },
  ADD_REMOVEITEM_LIST (state: any, { pid, title }: any) {
    if (state.removeItems.some((v: any) => v.pid === pid)) return
    state.removeItems.push({ pid: pid, title: title })
  },
  DELETE_REMOVEITEM_LIST (state: any, pid: string) {
    const newItemList = state.removeItems.filter((v: any) => v.pid !== pid)
    state.removeItems = newItemList
  },
  DELETE_ALL_REMOVEITEM_LIST (state: any) {
    state.removeItems = []
  },
  SET_PICTURE_META (state: any, picture: Picture): void {
    state.pictureMeta = picture
  },
}

/**
 * アクション
 */
const actions = {
  async uploadFile (context: any, file: File) {
    context.commit('SET_RESULTS', {
      searchHits: [],
    })
    const result = await s3upload(context, file)
    console.log('Upload Success', result.Location)
  },
  async imageSearch (context: any, { request, errorMessage }: ImageSearch) {
    await imageSearch(context, request, errorMessage)
  },
  async clear (context: any) {
    context.commit('META_SEARCH', {
      searchHits: [],
    }, { root: true })
    context.commit('SET_RESULTS', {
      searchHits: [],
    })
  },
}

/*
 * AWS SDK Settings
 */
// eslint-disable-next-line
const AWS = require("aws-sdk");
AWS.config.region = 'ap-northeast-1'
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.VUE_APP_IDENTITY,
})
const s3 = new AWS.S3({ apiVersion: '2006-03-01' })
const s3upload = async (context: any, file: File) => {
  const key = file.name ? `${uuidv4()}.${file.name.split('.')[1]}` : `${uuidv4()}.jpg`
  const params = {
    Bucket: process.env.VUE_APP_PICTURE_UPLOAD_BUCKET,
    Key: `public/${key}`,
    Body: file,
  }
  const result = await s3.upload(params).promise()
  context.commit('SET_FILE', {
    key,
    file: file,
  })
  return result
}

const imageSearch = async (context: any, request: ImageSearchRequest, errorMessage: string) => {
  const valdUrl = process.env.VUE_APP_PICTURE_SEARCH_API_URL
  const pictureUrl = `${BASE_URL}/picture/search`
  // TODO エラーハンドリング
  try {
    context.commit('PICTURE_PROCESSING')
    const valdQuery = {
      filename: request.key,
      size: 1000,
      epsilon: 0.01,
    }
    // valdにはページングがないため、一括取得
    getData(`${valdUrl}?${querystring.stringify(valdQuery)}`, errorMessage).then(async ({ results }) => {
      const allIds = results.map((result: any) => result.id)
      context.commit('SET_IDS', allIds)

      context.commit('NARROW_SIMPLE_SEARCH_QUERY', request, { root: true })
      const response = await postData(pictureUrl, { ...request, id: allIds }, errorMessage)
      context.commit('META_SEARCH', response, { root: true })
      context.commit('SET_RESULTS', response)

      if (request.excludes_pid_list && request.excludes_pid_list.length) {
        // 除外資料ファセットに表示するアイテム情報の取得
        request.excludes_pid_list.forEach(async pid => {
          const url = `${BASE_URL}/item/search/info:ndljp/pid/${pid}`
          try {
            const response = await getData(url)
            context.commit('ADD_REMOVEITEM_LIST', { pid: pid, title: response.item.meta['0001Dtct'][0] })
          } catch (error: any) {
            console.error('error: ', error.message)
          }
        })
      } else {
        context.commit('DELETE_ALL_REMOVEITEM_LIST')
      }
    }).finally(() => context.commit('PICTURE_PROCESSED'))
  } catch (error: any) {
    console.log(error)
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
}
