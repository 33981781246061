import { BlockInfo, DLProgress, TileDrawer } from './TileDrawer'
import {
  BoundingBox,
  decryptTextAsync,
  KeyPair,
  mm2px,
  PhysicalRect,
  PhysicalSize,
  RectF,
  RectI,
  RectMM,
  Rotation,
} from './ImageViewerCommon'
import { DrawResult, VisualElement } from './VisualElement'
import { PageElement } from './PageElement'
import { AccessToken } from '@/store/modules/AccessToken'
import { ImageViewer } from './ImageViewer'
import { SearchHitContent } from './SearchHitInfo'

type DammyKoma = {
  Src: string,
  Token: string,
  Clipping: any
}

class KomaLevel {
  /**
   * タイル描画インスタンス配列
   */
  public tileDrawers_ = new Array<TileDrawer>();

  /**
   * タイル余白を除いた画像の有効解像度 幅
   */
  public originWidth = 0;
  /**
   * タイル余白を除いた画像の有効解像度 高さ
   */
  public originHeight = 0;

  /**
   * タイル余白を含んだ 幅 tileSize * tileX
   */
  public fullWidth = 0;
  /**
   * タイル余白を含んだ 高さ tileSize * tileY
   */
  public fullHeight = 0;

  /**
   * 余剰ピクセルを除いたタイルサイズ
   */
  public tileSize = 0;

  /**
   * 横方向タイル数
   */
  public tileX = 0;
  /**
   * 縦方向タイル数
   */
  public tileY = 0;

  /**
   * この情報がダミーであるか否か
   */
  public isDummy = false

  public constructor (isReal = true) {
    this.tileDrawers_.length = 0
    this.isDummy = !isReal
  }
}

const DEOBFUSCATE_URL = process.env.VUE_APP_DEOBFUSCATE_API_URL

class KomaElement extends VisualElement {
  private static async getTileInfo (keyPair: KeyPair, objectKey: string): Promise<string> {
    const ops: RequestInit = {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        objectKey: objectKey,
        pubKeyPem: keyPair.publicKey,
      }),
    }

    return fetch(DEOBFUSCATE_URL, ops)
      .then((response) => response.text())
      .then((decText: string) => decryptTextAsync(keyPair.privateKey, decText))
  }

  /**
   * komainfoのない、空のKomaElementを生成する
   * 非同期である必要は無いが、既存のフローにそのまま置き換える目的で async にしている
   * @param url
   * @param komaID
   * @param token
   */
  public static async prepareKoma (
    url: string,
    komaID: number,
    token: AccessToken
  ): Promise<KomaElement> {
    return new Promise<KomaElement>((resolve, reject) => {
      const res = new KomaElement()
      res.komaID_ = komaID
      res.currentToken_ = token
      res.komainfoURL_ = url
      res.getObjectKey()
      resolve(res)
    })
  }

  /**
   * このコマのObjectKey
   */
  private objectKey_ = ''
  private blockInfo_?: BlockInfo = undefined
  public get blockInfo () {
    return this.blockInfo_
  }

  /**
   * このコマのObjectKeyを取得する
   */
  private getObjectKey (): string | undefined {
    if (this.komainfoURL_.length <= 0) {
      return undefined
    }

    const part = this.komainfoURL_.split('/')
    if (part.length < 5) {
      return undefined
    }

    // ObjectKeyの厳密なバリデーションはせずに、APIではじいてもらいエラー扱いにする
    this.objectKey_ = `${part[part.length - 4]}_${part[part.length - 3]}_${part[part.length - 2]}`
    return this.objectKey_
  }

  /**
   * コマ画像の情報を取得する
   * @param url ページ画像(タイル状)情報取得先URL
   * @returns
   */
  public async getKoma (
    dummyKomaResolutionUpdate: () => PhysicalSize
  ): Promise<KomaElement> {
    const ops: RequestInit = {
      mode: 'cors',
      credentials: 'same-origin',
    }

    await this.fetchTileInfo()

    return new Promise<KomaElement>((resolve, reject) => {
      // komainfo 取得済みの場合
      if (this.fetchedKomainfo_) {
        resolve(this)
        return
      }

      this.fetchedKomainfo_ = true

      fetch(this.komainfoURL_, ops)
        .then((response) => response.json())
        .then((json) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const levels = json.Levels as Array<any>
          for (let i = 0; i < levels.length; ++i) {
            const level = new KomaLevel()
            const jLevel = levels[i]
            const tileURLs = jLevel.Tiles as Array<string>

            level.tileX = jLevel.TileX as number
            level.tileY = jLevel.TileY as number
            level.originWidth = jLevel.OriginWidth as number
            level.originHeight = jLevel.OriginHeight as number
            level.tileSize = jLevel.TileSize as number
            level.fullWidth = level.tileX * level.tileSize
            level.fullHeight = level.tileY * level.tileSize

            this.komaLevels_.push(level)

            for (let i = 0; i < tileURLs.length; ++i) {
              const drawer = new TileDrawer(this.komainfoURL_ + '/../' + tileURLs[i], this.currentToken_)
              drawer.komaID = this.komaID_

              let avWidth = 1.0
              let avHeight = 1.0

              if (i % level.tileX === level.tileX - 1) {
                if (level.originWidth % level.tileSize === 0) {
                  avWidth = 1.0
                } else {
                  avWidth =
                    (level.originWidth % level.tileSize) / level.tileSize
                }
              }
              if (~~Math.floor(i / level.tileX) === level.tileY - 1) {
                if (level.originHeight % level.tileSize === 0) {
                  avHeight = 1.0
                } else {
                  avHeight =
                    (level.originHeight % level.tileSize) / level.tileSize
                }
              }

              drawer.setTileSize(level.tileSize)
              drawer.setAvailableSize(avWidth, avHeight)

              level.tileDrawers_.push(drawer)
            }
          }

          this.setClippingArea(this.clippingAreaInfo_)

          dummyKomaResolutionUpdate()

          resolve(this)
        })
        .catch((reason) => {
          // ダミーコマへ差し替える
          if (!ImageViewer.DUMMY_KOMA) {
            reject(reason)
            return
          }

          const dummyKoma = ImageViewer.DUMMY_KOMA as DammyKoma

          fetch(dummyKoma.Src, ops)
            .then((response) => response.json())
            .then((json) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const levels = json.Levels as Array<any>
              for (let i = 0; i < levels.length; ++i) {
                const level = new KomaLevel()
                const jLevel = levels[i]
                const tileURLs = jLevel.Tiles as Array<string>

                level.tileX = jLevel.TileX as number
                level.tileY = jLevel.TileY as number
                level.originWidth = jLevel.OriginWidth as number
                level.originHeight = jLevel.OriginHeight as number
                level.tileSize = jLevel.TileSize as number
                level.fullWidth = level.tileX * level.tileSize
                level.fullHeight = level.tileY * level.tileSize

                this.komaLevels_.push(level)

                for (let i = 0; i < tileURLs.length; ++i) {
                  // ダミーコマ画像に適切なトークンを設定する必要があるのなら要改善
                  const at = {
                    id: undefined,
                    pid: 'dummy',
                    cid: 'dummy',
                    token: dummyKoma.Token,
                    timestamp: 0,
                  }
                  const drawer = new TileDrawer(dummyKoma.Src + '/../' + tileURLs[i], at)
                  drawer.komaID = this.komaID_

                  let avWidth = 1.0
                  let avHeight = 1.0

                  if (i % level.tileX === level.tileX - 1) {
                    if (level.originWidth % level.tileSize === 0) {
                      avWidth = 1.0
                    } else {
                      avWidth =
                        (level.originWidth % level.tileSize) / level.tileSize
                    }
                  }
                  if (~~Math.floor(i / level.tileX) === level.tileY - 1) {
                    if (level.originHeight % level.tileSize === 0) {
                      avHeight = 1.0
                    } else {
                      avHeight =
                        (level.originHeight % level.tileSize) / level.tileSize
                    }
                  }

                  drawer.setTileSize(level.tileSize)
                  drawer.setAvailableSize(avWidth, avHeight)

                  level.tileDrawers_.push(drawer)
                }
              }

              resolve(this)
            })

          // reject(reason)
        })
    })
  }

  private komaID_ = -1

  private dpi_ = 400

  public get dpi (): number {
    return this.dpi_
  }

  private currentToken_: AccessToken = { pid: '', cid: '', token: '', timestamp: 0 } // ダミートークン

  private komainfoURL_ = ''

  /**
   * komainfo.json 取得前のダミー解像度
   */
  private dummyKomaLevel_ = new KomaLevel(false)
  public setDummyResolution (reso: PhysicalSize): void {
    this.dummyKomaLevel_.originWidth = reso.width
    this.dummyKomaLevel_.originHeight = reso.height
  }

  /**
   * komainfo.json をすでに fetch 済みか否か
   * DLが完了しているかいないかは問わない
   */
  private fetchedKomainfo_ = false

  public get originWidth (): number {
    const origin = this.getKomaLevel(0)
    if (origin) {
      return origin.originWidth
    }

    return -1
  }

  public get originHeight (): number {
    const origin = this.getKomaLevel(0)
    if (origin) {
      return origin.originHeight
    }

    return -1
  }

  /**
   * 指定した解像度レベルの4辺の余白ピクセル数を返す
   * @param level 取得する解像度レベル デフォルト値は 0（最大解像度）
   * @returns
   */
  public getCroppingPixels (croppingArea: RectF, level = 0): RectI {
    const res = new RectI()

    if (level < 0 || level >= this.komaLevels_.length) {
      return res
    }
    res.left = this.komaLevels_[level].originWidth * croppingArea.left
    res.top = this.komaLevels_[level].originHeight * croppingArea.top
    res.right =
      this.komaLevels_[level].originWidth * (1.0 - croppingArea.right)
    res.bottom =
      this.komaLevels_[level].originHeight * (1.0 - croppingArea.bottom)

    return res
  }

  /**
   * 解像度レベル配列
   */
  private komaLevels_ = new Array<KomaLevel>();

  public getKomaLevel (level: number, acceptDummy = true): KomaLevel | undefined {
    if (acceptDummy && this.komaLevels_.length <= 0 && level === 0) {
      return this.dummyKomaLevel_
    }
    if (level < 0 || (this.komaLevels_.length - 1) < level) {
      return undefined
    }

    return this.komaLevels_[level]
  }

  /**
   * 解像度レベル数
   */
  public get levelCount (): number {
    return this.komaLevels_.length
  }

  private constructor () {
    super()
    this.komaLevels_.length = 0
  }

  /**
   * タイル情報を取得する
   * @returns
   */
  public async fetchTileInfo (): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const kp = ImageViewer.getKeyPair()
      if (kp) {
        KomaElement.getTileInfo(kp, this.objectKey_)
          .then(ti => {
            this.blockInfo_ = new BlockInfo(ti)

            if (!this.blockInfo_.blockIDs || this.blockInfo_.blockIDs.length <= 0 || this.blockInfo_.divisions < 0) {
              reject(new Error('Invalid Image Error'))
              return
            }

            resolve(true)
          })
      } else {
        reject(new Error('Invalid Image Request'))
      }
    })
  }

  /**
   * 指定したレベルの全タイル画像のDLを開始する
   * DL完了を待たない
   * @param level 解像度レベル 0=最高解像度、-1=最低解像度
   * @param onLoadedPerEachTiles 各タイルのDL完了毎に実行するコールバック関数 success: ダウンロードにエラーが無かったか否か ダウンロード中でも true となる
   * @returns 処理の実行ができたか否か
   */
  public loadTiles (
    level: number,
    onLoadedPerEachTiles?: (success: boolean, tileDrawer: TileDrawer) => void
  ): boolean {
    let l = level
    if (level < 0) {
      l = this.komaLevels_.length - 1
    }
    const lev = this.komaLevels_[l]
    if (lev) {
      let allDone = true
      lev.tileDrawers_.some((drawer) => {
        if (drawer.dlProgress !== DLProgress.Done) {
          allDone = false
          return true
        }
      })

      if (allDone) {
        lev.tileDrawers_[0].loadTile(l !== (this.komaLevels_.length - 1), this.blockInfo_)
          .then(tile => { if (onLoadedPerEachTiles) onLoadedPerEachTiles(true, tile) })
          .catch(() => { if (onLoadedPerEachTiles) onLoadedPerEachTiles(false, lev.tileDrawers_[0]) })
      } else {
        lev.tileDrawers_.forEach((drawer) => {
          drawer.loadTile(l !== (this.komaLevels_.length - 1), this.blockInfo_)
            .then(tile => { if (onLoadedPerEachTiles) onLoadedPerEachTiles(true, tile) })
            .catch(() => { if (onLoadedPerEachTiles) onLoadedPerEachTiles(false, drawer) })
        })
      }

      return true
    }

    return false
  }

  /**
   * 指定したタイル画像のDLを開始する
   * @param level 取得するタイルの解像度レベル
   * @param tileIndex タイルID 0～ tileX*tileY-1 まで
   */
  public async loadTIle (
    level: number,
    tileIndex: number
  ): Promise<TileDrawer> {
    return new Promise<TileDrawer>((resolve, reject) => {
      const lev = this.komaLevels_[level]
      if (lev) {
        const drawer = lev.tileDrawers_[tileIndex]
        if (drawer) {
          return drawer.loadTile(level !== (this.komaLevels_.length - 1), this.blockInfo_)
        }
        reject(new Error('FAILED_TO_LOAD_RESOURCE'))
      }
    })
  }

  /**
   * 指定した解像度レベル内のTileDrawerを取得する
   * @param level 取得する解像度レベル
   * @param index 取得する解像度レベル内のタイルインデックス
   * @returns 指定したタイルが存在した場合はそのTileDrawer, 存在しない場合は undefined
   */
  public getTileDrawer (level: number, index: number): TileDrawer | undefined {
    if (!this.komaLevels_[level]) return undefined

    return this.komaLevels_[level].tileDrawers_[index]
  }

  /**
   * 指定した解像度レベル内のタイル数を返す
   * @param level 取得する解像度レベル
   * @returns 有効な解像度レベルであればその中のタイル数、無効な解像度レベルであれば -1
   */
  public getTileCount (level: number): number {
    if (!this.komaLevels_[level]) return -1

    return this.komaLevels_[level].tileDrawers_.length
  }

  public get komaID (): number {
    return this.komaID_
  }

  /**
   * 指定した拡大率でこのコマを描画した場合のピクセルサイズを返す
   * @param scale
   * @param cropping 切り抜き範囲
   * @returns
   */
  public getElementSize (scale = 1.0, cropping?: RectMM): BoundingBox {
    const res = new BoundingBox()

    // 左右に配置されるコマによって cropping を調整しなければならない

    const levelInfo = this.getKomaLevel(0)
    if (levelInfo) {
      const originWidth = res.width =
        levelInfo.originWidth * scale
      const originHeight = res.height =
        levelInfo.originHeight * scale

      if (cropping && cropping.width > 0 && cropping.height > 0) {
        const cropRate = new RectF()
        cropRate.left = mm2px(cropping.left, this.dpi) / levelInfo.originWidth
        cropRate.top = mm2px(cropping.top, this.dpi) / levelInfo.originHeight
        cropRate.right = mm2px(cropping.right, this.dpi) / levelInfo.originWidth// + cropRate.left
        cropRate.bottom = mm2px(cropping.bottom, this.dpi) / levelInfo.originHeight// + cropRate.top

        const croppingRect = new PhysicalRect()
        croppingRect.left = (res.width * cropRate.left)
        croppingRect.top = (res.height * cropRate.top)
        croppingRect.right = (res.width * cropRate.right)
        croppingRect.bottom = (res.height * cropRate.bottom)

        res.width = croppingRect.width
        res.height = croppingRect.height

        res.offset.left = croppingRect.left
        res.offset.top = croppingRect.top
        res.offset.right = originWidth * -(1.0 - cropRate.right)
        res.offset.bottom = originHeight * -(1.0 - cropRate.bottom)
      }
    }

    return res
  }

  /**
   * KomaElementを返す
   */
  public get koma (): KomaElement | undefined {
    return this
  }

  /**
   * 現在のページ描画画像解像度から描画に最適な解像度レベルを算出する
   * @param targetPageSize 描画される予定のページ画像のサイズ
   * @returns
   */
  public calcDecentLevel (targetPageSize: PhysicalSize): number {
    let level = this.levelCount - 1

    for (let i = 0; i < this.levelCount; ++i) {
      const komaLevel = this.getKomaLevel(i)
      if (!komaLevel) { continue }

      if (komaLevel.originWidth <= targetPageSize.width || komaLevel.originHeight <= targetPageSize.height) {
        level = i - 1
        break
      }
    }

    if (level < 0) { level = 0 }

    return level
  }

  /**
   * このページを指定したコンテキストに描画する
   * @param currentPageIndex 現在のページインデックス（テンポラリ）
   * @param targetLevel 描画する解像度レベル 0未満で適切な解像度を自動判定
   * @param context
   * @param bbContext バックバッファキャンバス
   * @param left canvas描画座標 左
   * @param top canvas描画座標 上
   * @param scale 画像のオリジナルサイズに対する描画スケール
   * @param drawBG 下地を描画するか否か
   * @param delayDrawFunc 未ダウンロードタイルがダウンロード完了したときに実行する関数
   * @param rotation 回転情報
   * @param clipping 自動余白削除を使用するか否か
   * @param cropping 印刷切り抜き範囲
   * @param blitPerfect タイルがすべて描画されない限りメインキャンバスへ転送しない
   * @param searchHitInfo 検索ヒット情報配列
   */
  public draw (
    pageIndex: number,
    targetLevel: number,
    context: CanvasRenderingContext2D,
    bbCanvas: HTMLCanvasElement,
    originalCanvasSize: PhysicalSize,
    left: number,
    top: number,
    offsetX: number,
    offsetY: number,
    scale: number,
    drawBG: boolean,
    delayDrawFunc: (() => void) | undefined,
    rotation: Rotation,
    clipping: boolean,
    cropping: RectMM | undefined,
    clippingOffsetLeft: number,
    is2In1: boolean,
    transformedOffsetY: number,
    blitPerfect = true,
    searchHitInfo?: Array<SearchHitContent>
  ): DrawResult {
    // 循環参照が懸念されるので、自動変数を毎回生成する
    const dammyPageElement_ = new PageElement()
    if (!dammyPageElement_.initDammy(this)) {
      return DrawResult.Failed
    }

    const res = dammyPageElement_.draw(
      pageIndex,
      targetLevel,
      context,
      bbCanvas,
      originalCanvasSize,
      left,
      top,
      offsetX,
      offsetY,
      scale,
      drawBG,
      delayDrawFunc,
      rotation,
      clipping,
      cropping,
      clippingOffsetLeft,
      is2In1,
      transformedOffsetY,
      blitPerfect,
      searchHitInfo
    )
    this.currentFinalClippingPath_ = dammyPageElement_.currentFinalClippingPaths
    this.currentMetaSearchHitPins_ = dammyPageElement_.currentMetaSearchHitPins

    return res
  }

  /**
   * ページを描画可能な状態にする
   * 最小解像度画像をDLする
   * @param onLoadedPerEachTiles 各タイルのDL完了毎に実行するコールバック関数
   * @returns
   */
  public activate (
    dummyKomaResolutionUpdate: () => PhysicalSize,
    onLoadedPerEachTiles?: (success: boolean, tileDrawer: TileDrawer) => void
  ): boolean {
    // まだkomainfoをfetchしていない場合
    if (!this.fetchedKomainfo_) {
      this.getKoma(dummyKomaResolutionUpdate).then(koma => {
        this.loadTiles(-1, onLoadedPerEachTiles)
      })

      return true
    }

    this.loadTiles(-1, onLoadedPerEachTiles)
    return true
  }

  /**
   * このコマが管理するタイルのトークンを更新する
   * @param newToken
   */
  public updateToken (newToken: AccessToken): void {
    this.currentToken_ = newToken
    for (let i = 0; i < this.komaLevels_.length; ++i) {
      const kl = this.komaLevels_[i]
      if (kl) {
        kl.tileDrawers_.forEach(t => {
          t.token = newToken
        })
      }
    }
  }
}

export { KomaElement, KomaLevel }
