import { createI18n } from 'vue-i18n'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const messages = require('./messages.json')

const isServer = typeof window === 'undefined'

const defaultLanguage = isServer ? 'ja' : (localStorage.language || 'ja')

export default createI18n({
  legacy: false,
  locale: defaultLanguage === 'en' ? 'en' : 'ja',
  allowComposition: true,
  messages,
  modifiers: {
  },
})
