
import { defineComponent, reactive, computed, ref, watch, watchEffect, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import DssIcon from '@/components/atoms/DssIcon.vue'
import TheImageViewer from '@/components/TheImageViewer.vue'
import ImageViewerHeader from './ImageViewerHeader.vue'
import ImageViewerFooter from './ImageViewerFooter.vue'
import ModalSheet from '@/components/organisms/ModalSheet.vue'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import HelpImageViewerMobile from '@/components/static/HelpImageViewerMobile.vue'
import AddonView from '../AddonView.vue'
import ImageViewerSettings from './ImageViewerSettings.vue'
import { useI18n } from 'vue-i18n'
import { LocalStorageProcessingData } from '@/data/@types/LocalStorageProcessingData'
import fixBackground from '@/helpers/util/fixBackground'
import { focusInsideDialog } from '@/helpers/util/focusInsideDialog'
import { Content } from '@/data/@types/Content'
import { ViewMode } from '@/helpers/imageviewer/ImageViewer'

export default defineComponent({
  components: {
    DssIcon,
    TheImageViewer,
    ImageViewerHeader,
    ImageViewerFooter,
    ModalSheet,
    ModalWindow,
    HelpImageViewerMobile,
    AddonView,
    ImageViewerSettings,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    bundleIndex: {
      type: String,
      required: false,
    },
    tabViewFlag: {
      type: Boolean,
      required: true,
    },
  },
  setup (props, context) {
    const store = useStore()
    const stateModule = store.state[props.item.id]
    const currentProcessingData = computed(() => store.getters.processingData)
    const currentBundleNumber = computed(() => store.getters.bundleNumber)
    const currentBundleType = store.getters.contentType
    const currentBundleId = computed(() => props.item.contentsBundles[currentBundleNumber.value].id)

    const i18n = useI18n()
    const lang = i18n.locale

    const state = reactive({
      modal: '',
      showNav: true,
      showWalkthrough: true,
      timeoutId: 0,
    })

    const hasDivideData = computed(() => {
      return store.getters.contents.some((content: Content) => {
        const devideData = content?.extra?.layout?.divide
        return devideData && devideData > 0 && devideData < 1
      })
    })

    if (hasDivideData.value && Number(localStorage.viewMode) === ViewMode.SINGLE) {
      store.commit('changeViewMode', ViewMode.SINGLE)
    }

    setTimeout(() => {
      state.showWalkthrough = false
    }, 2000)

    fixBackground.fix()
    onUnmounted(() => {
      fixBackground.cancel()
    })

    focusInsideDialog('.image-viewer-mobile')

    const images = computed(() => {
      const index = stateModule.imageViewer.currentContentsBundleIndex
      return props.item.content.contentsBundles[index].contents.map((item: { publicPath: string }) => {
        return {
          alt: '',
          src: item.publicPath,
        }
      })
    })

    const image = computed(() => {
      return images.value[stateModule.imageViewer.currentImageIndex]
    })

    const abstract = ref(props.item.meta['0041Dtct']?.join('\r\n'))

    // ローカルストレージで各バンドルごとの調整タブの値を管理する
    const resetProcessing = () => {
      store.commit('resetImageViewerSizeProcessingData')
      store.commit('resetImageViewerColorProcessingData')
    }
    const getProcessingDataFromLocalStorage = () => {
      try {
        return JSON.parse(localStorage.getItem('imageViewerProcessingData')!)
      } catch {
        console.error('ローカルストレージからのデータの取得に失敗しました')
        localStorage.removeItem('imageViewerProcessingData')
        return {}
      }
    }
    const setProcessingDataToLocalStorage = (processingData: string) => {
      localStorage.setItem('imageViewerProcessingData', processingData)
    }

    let localStorageProcessingData: LocalStorageProcessingData
    const processingDataSync = () => {
      localStorageProcessingData = getProcessingDataFromLocalStorage()
      if (localStorageProcessingData[currentBundleId.value]) {
        store.commit('setImageViewerProcessingData', localStorageProcessingData[currentBundleId.value])
      } else {
        resetProcessing()
        localStorageProcessingData[currentBundleId.value] = currentProcessingData.value
        setProcessingDataToLocalStorage(JSON.stringify(localStorageProcessingData))
      }
    }

    if (currentBundleType !== 'image') return
    if (!localStorage.getItem('imageViewerProcessingData')) {
      setProcessingDataToLocalStorage('{}')
    }
    processingDataSync()

    watch(currentBundleId, () => {
      processingDataSync()
    })

    watchEffect(() => {
      localStorageProcessingData[currentBundleId.value] = currentProcessingData.value
      setProcessingDataToLocalStorage(JSON.stringify(localStorageProcessingData))
    })

    // 全画面表示のときブラウザバックでポスター表示に変えるため
    window.addEventListener('popstate', () => {
      context.emit('close')
    })

    return {
      state,
      lang,
      image,
      abstract,
      HelpImageViewerMobile,
    }
  },
})
