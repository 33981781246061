
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    hasContentError: {
      type: Boolean,
      required: true,
    },
  },
  setup (props) {
    const isHeadstone: boolean = props.item.meta?.['0501Dk']?.[0] === '1'
    if (!isHeadstone) return
    const transformedItem = ref(makeUpdatedPidString(props.item.meta['0500Dod']?.[0]))
    const headstoneMessage = ref(props.item.meta['0499Dod']?.[0])
    const isTransferedItem: boolean = transformedItem.value && transformedItem.value.match('pid/\\d+')

    return {
      isHeadstone,
      isTransferedItem,
      transformedItem,
      headstoneMessage,

    }
  },
})
