import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8e9c17e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "disabled", "readonly", "cols", "rows", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    class: _normalizeClass(["app-textarea", _ctx.styled]),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    placeholder: _ctx.placeholder,
    disabled: _ctx.disabled,
    readonly: _ctx.readonly,
    cols: _ctx.cols,
    rows: _ctx.rows,
    "aria-label": _ctx.label
  }, null, 10, _hoisted_1)), [
    [_vModelText, _ctx.value]
  ])
}