import { MutationTree, ActionTree } from 'vuex'
import { LocationQuery } from 'vue-router'
// import createPersistedState from 'vuex-persistedstate'
import { uploadFileData, Contents, LoginUser, AutoCollectForm, CollectAuthor } from '@/data/dms/dmsData'

export interface State {
  loginUser: Partial<LoginUser>
  s3UploadFileList: Partial<uploadFileData[]>
  contentsData: Partial<Contents>
  reacquireFlag: Partial<boolean>
  thumbnail: Partial<FileList>
  reuseFlag: Partial<boolean>
  reusePid: Partial<string>
  autoCollectData: Partial<AutoCollectForm>
  collectAuthorData: Partial<CollectAuthor>
  bannerMessage: { id: string, type: string, text: { en: string, ja: string} }[]
  loading: Partial<boolean>
  fileUploadData: Partial<any>
  openinqSearch: Partial<LocationQuery>
}

const state: State = {
  loginUser: {},
  s3UploadFileList: [],
  contentsData: {},
  reacquireFlag: true,
  thumbnail: [],
  reuseFlag: false,
  reusePid: '',
  autoCollectData: {},
  collectAuthorData: {},
  bannerMessage: [],
  loading: false,
  fileUploadData: {},
  openinqSearch: {},
}

const getters = {
  loginUser: (state: State) => {
    return state.loginUser
  },
  s3UploadFileList: (state: State) => {
    return state.s3UploadFileList
  },
  contentsData: (state: State) => {
    return state.contentsData
  },
  reacquireFlag: (state: State) => {
    return state.reacquireFlag
  },
  thumbnail: (state: State) => {
    return state.thumbnail
  },
  reuseFlag: (state: State) => {
    return state.reuseFlag
  },
  reusePid: (state: State) => {
    return state.reusePid
  },
  autoCollectData: (state: State) => {
    return state.autoCollectData
  },
  collectAuthorData: (state: State) => {
    return state.collectAuthorData
  },
  bannerMessage: (state: State) => {
    return state.bannerMessage
  },
  loading: (state: State) => {
    return state.loading
  },
  fileNameList: (state: State) => {
    return state.fileUploadData
  },
  openinqSearch: (state: State) => {
    return state.openinqSearch
  },
}

const mutations: MutationTree<State> = {
  SET_LOGINUSER (state: State, item) {
    state.loginUser = item
  },
  SET_S3_UPLOAD_FILE_LIST (state: State, item) {
    state.s3UploadFileList = item
  },
  SET_CONTENTSDATA (state: State, item) {
    state.contentsData = item
  },
  SET_REACQUIRE_FLAG (state: State, item) {
    state.reacquireFlag = item
  },
  SET_THUMBNAIL (state: State, item) {
    state.thumbnail = item
  },
  SET_REUSE_FLAG (state: State, item) {
    state.reuseFlag = item
  },
  SET_REUSE_PID (state: State, item) {
    state.reusePid = item
  },
  SET_AUTOCOLLECTDATA (state: State, item) {
    state.autoCollectData = item
  },
  SET_COLLECTAUTHORDATA (state: State, item) {
    state.collectAuthorData = item
  },
  SET_BANNER_MESSAGE (state: State, item) {
    state.bannerMessage = item
  },
  DELETE_BANNER_MESSAGE (state: State, id) {
    const ids = state.bannerMessage.map(a => a.id)
    const index = ids.indexOf(id)
    state.bannerMessage.splice(index, 1)
  },
  SET_LOADING (state: State, item) {
    state.loading = item
  },
  SET_FILE_UPLOAD_DATA (state: State, item) {
    state.fileUploadData = item
  },
  SET_OPENINQ_SEARCH (state: State, item) {
    state.openinqSearch = item
  },
}

const actions: ActionTree<State, any> = {
  setLoginUser (context: any, item: any) {
    context.commit('SET_LOGINUSER', item)
  },
  setS3UploadFileList (context: any, item: any) {
    context.commit('SET_S3_UPLOAD_FILE_LIST', item)
  },
  setContentsData (context: any, item: any) {
    context.commit('SET_CONTENTSDATA', item)
  },
  setReacquireFlag (context: any, item: any) {
    context.commit('SET_REACQUIRE_FLAG', item)
  },
  setThumbnail (context: any, item: any) {
    context.commit('SET_THUMBNAIL', item)
  },
  setReuseFlag (context: any, item: any) {
    context.commit('SET_REUSE_FLAG', item)
  },
  setReusePid (context: any, item: any) {
    context.commit('SET_REUSE_PID', item)
  },
  setAutoCollectData (context: any, item: any) {
    context.commit('SET_AUTOCOLLECTDATA', item)
  },
  setCollectAuthorData (context: any, item: any) {
    context.commit('SET_COLLECTAUTHORDATA', item)
  },
  setBannerMessage (context: any, item: any) {
    context.commit('SET_BANNER_MESSAGE', item)
  },
  deleteBannerMessage (context: any, id: any) {
    context.commit('DELETE_BANNER_MESSAGE', id)
  },
  setLoading (context: any, flag: any) {
    context.commit('SET_LOADING', flag)
  },
  setFileUploadData (context: any, fileUploadData: any) {
    context.commit('SET_FILE_UPLOAD_DATA', fileUploadData)
  },
  setOpeninqSearch (context: any, item: any) {
    context.commit('SET_OPENINQ_SEARCH', item)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
