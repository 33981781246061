import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title is-hidden-mobile" }
const _hoisted_2 = {
  id: "panel-metadata-field-list",
  class: "panel-metadata-field-list"
}
const _hoisted_3 = { class: "panel-metadata-nav level-right is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetadataSimpleRecords = _resolveComponent("MetadataSimpleRecords")!
  const _component_MetadataDetailRecords = _resolveComponent("MetadataDetailRecords")!
  const _component_CloserController = _resolveComponent("CloserController")!

  return (_openBlock(), _createElementBlock("section", {
    class: "panel-metadata has-background-contents",
    key: _ctx.item.pid
  }, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('label.metadata')), 1),
    _createElementVNode("ul", _hoisted_2, [
      (!_ctx.isDetail)
        ? (_openBlock(), _createBlock(_component_MetadataSimpleRecords, {
            key: 0,
            id: "simple-record",
            item: _ctx.item
          }, null, 8, ["item"]))
        : (_openBlock(), _createBlock(_component_MetadataDetailRecords, {
            key: 1,
            id: "detail-record",
            item: _ctx.item
          }, null, 8, ["item"]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CloserController, {
        modelValue: _ctx.isDetail,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDetail) = $event)),
        text: [_ctx.$t('informationPanel.openDetailRecord'), _ctx.$t('informationPanel.closeDetailRecord')],
        class: "has-text-modest"
      }, null, 8, ["modelValue", "text"])
    ])
  ]))
}