
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import PlaceholderImage from '@/components/atoms/PlaceholderImage.vue'
import TheImageViewer from '@/components/TheImageViewer.vue'

export default defineComponent({
  components: {
    PlaceholderImage,
    TheImageViewer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    bundleIndex: {
      type: String,
      required: false,
    },
    ischangedToViewerPoster: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const store = useStore()
    const currentBundleType = computed(() => store.getters.contentType)
    const placeholder = computed(() => {
      if (currentBundleType.value === 'movie') {
        return 'video'
      } else if (currentBundleType.value === 'audio') {
        return 'music'
      } else if (currentBundleType.value === 'file' || currentBundleType.value === 'content') {
        return 'file'
      } else {
        return 'book-open'
      }
    })

    return {
      placeholder,
      currentBundleType,
    }
  },
})
