import {
  filter,
  reduce,
  map,
} from 'lodash'
import { getData, postData } from '@/helpers/util/webApiUtil'
import { MyCollectionItem } from '@/data/@types/MyCollectionItem'
import { MyCollectionToastMessage } from '@/data/@types/MyCollectionToastMessage'
import { MyCollectionRecord } from '@/data/@types/MyCollectionRecord'
import { queryConvertToSortKeyAndOrder } from '@/data/SortList'
import { ActionContext } from 'vuex'
import { MyCollectionSearchMode, initialMyCollectionSearchMode } from '@/data/@types/MyCollectionSearchMode'
import { displayContentsList } from '@/data/selector/displayContentsList'
import { mycollectionSortList } from '@/data/selector/mycollectionSortList'
import { displayList } from '@/data/selector/displayList'
import { displayModeList } from '@/data/selector/displayModeList'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

type MycollectionTag = {
  id: number,
  label: string,
  hit: number,
}

/**
 * ステート
 */
type State = {
  itemList: MyCollectionItem[],
  tagList: MycollectionTag[],
  allItemList: MyCollectionRecord[],
  totalHits: number,
  isProcessing: boolean,
  keyword: string,
  selectedTagId: number,
  notificationToasts: MyCollectionToastMessage[]
  thumbnailTokenList: any,
  notificationNotLoggedIn: boolean,
  pageNum: number,
  showCollectionDropdown: boolean,
}

type Context = ActionContext<State, any>

const state: State = {
  itemList: [],
  tagList: [],
  allItemList: [],
  totalHits: 0,
  isProcessing: false,
  keyword: '',
  selectedTagId: 0,
  notificationToasts: [],
  thumbnailTokenList: [],
  notificationNotLoggedIn: false,
  pageNum: 0,
  showCollectionDropdown: false,
}

/**
 * ゲッター
 */

const getters = {
  myCollectionList (state: State) {
    return modifyItemList(state.itemList, state.thumbnailTokenList)
  },
  myCollectionThumbnail (state: State, getters: any) {
    const itemList: MyCollectionItem[] = getters.myCollectionList
    const thumbnailList: any = []

    itemList.forEach(item => {
      thumbnailList.push({
        pid: item.pid,
        tagIds: item.tagIds,
        contents: [],
        updateAt: item.updateAt,
        title: item.title,
        contentTitle: false,
        contentLink: false,
        permission: item.permission,
        contentsNumber: false,
        thumbnail: item.thumbnail,
        meta: item.meta,
        isItem: true,
      })

      if (item.contents) {
        item.contents.forEach(content => {
          const contentsName: any = content.name || ''
          const contentsNameJa: string = contentsName.ja || ''
          const contentsThumbnail: any = content.publicPath || item.thumbnail

          thumbnailList.push({
            pid: item.pid, // リンクパスとして利用する
            tagIds: item.tagIds,
            contents: [],
            updateAt: item.updateAt,
            title: item.title,
            contentTitle: contentsNameJa,
            contentLink: content.bundleNumber && content.contentsNumber ? `${item.pid}/${content.bundleNumber}/${content.contentsNumber}` : item.pid, // リンクパスとして利用する
            permission: item.permission,
            contentsNumber: content.contentsNumber,
            thumbnail: contentsThumbnail,
            meta: item.meta,
            isItem: false,
          })
        })
      }
    })

    return thumbnailList
  },
  myCollectionTagList (state: State): MycollectionTag[] {
    return state.tagList
  },
  myCollectionAllItemList (state: State): any[] {
    return state.allItemList
  },
  myCollectionItemCount (state: State): number {
    return state.totalHits
  },
  myCollectionKeyword (state: State): string {
    return state.keyword
  },
  notificationToasts (state: State): MyCollectionToastMessage[] {
    return state.notificationToasts
  },
  myCollectionPageNumber (state: State): number {
    return state.pageNum
  },
  myCollectionSelectedTag (state: State): MycollectionTag {
    return state.tagList.find(tag => Number(tag.id) === Number(state.selectedTagId)) || state.tagList[0]
  },
  notificationNotLoggedIn (state: State): boolean {
    return state.notificationNotLoggedIn
  },
  myCollectionIsProcessing (state: State): boolean {
    return state.isProcessing
  },
  myCollectionIsShowCollectionDropdown (state: State): boolean {
    return state.showCollectionDropdown
  },
  myCollectionSearchMode () {
    const cookieSearchMode = <any>[]
    const isServer = typeof document === 'undefined'
    if (!isServer) {
      document.cookie.split(';').map(cookie => {
        if (cookie.split('=')[0].includes('myCollectionSearchMode')) {
          cookieSearchMode.push(JSON.parse(cookie.split('=')[1]))
        }
      })
      return cookieSearchMode[0] || initialMyCollectionSearchMode
    }
  },
}

/**
 * ミューテーション
 */

const mutations = {
  MY_COLLECTION_SET (state: State, items: MyCollectionItem[]): void {
    if (items) state.itemList = items || []
  },
  MY_COLLECTION_SET_TAG_LIST (state: State, tags: MycollectionTag[]): void {
    if (tags) state.tagList = tags || []
  },
  MY_COLLECTION_ALL_ITEMS (state: State, items: MyCollectionRecord[]): void {
    state.allItemList = items
  },
  SET_MY_COLLECTION_TOTAL_HITS (state: State, totalHits: number): void {
    state.totalHits = totalHits
  },
  MY_COLLECTION_SELECTED_TAG (state: State, tagId: number): void {
    state.selectedTagId = tagId > 0 ? tagId : 0
  },
  MY_COLLECTION_PROCESSING (state: State): void {
    state.isProcessing = true
  },
  MY_COLLECTION_PROCESSED (state: State): void {
    state.isProcessing = false
  },
  ADD_MY_COLLECTION_NOTIFICATION_MESSAGE (state: State, message: MyCollectionToastMessage): void {
    state.notificationToasts.push(message)
  },
  MY_COLLECTION_SELECT_PAGE_NUMBER (state: State, pageNum: number): void {
    state.pageNum = pageNum > 0 ? pageNum : 0
  },
  DELETE_MY_COLLECTION_NOTIFICATION_MESSAGE (state: State, deleteId: string): void {
    state.notificationToasts = state.notificationToasts.filter(toast => toast.id !== deleteId)
  },
  MY_COLLECTION_KEY_WORD (state: State, word: string): void {
    if (!word) state.keyword = ''
    state.keyword = word
  },
  SET_ITEM_CONTENTS_THUMBNAIL_TOKEN (state: State, updateItem: any): void {
    state.itemList = map(state.itemList, (item) => {
      const contents = item.contents || false
      if (!contents) return item
      if (item.pid !== updateItem.pid) return item
      return updateItem
    })
  },
  // 非同期でコンテンツサムネイルのアクセストークンを取得する
  SET_CONTENTS_THUMBNAIL_TOKEN (state: State, thumbnailTokenList: any): void {
    state.thumbnailTokenList = thumbnailTokenList
  },
  // ログアウトしたらマイコレクションをクリアする
  MY_COLLECTION_CLEAR (state: State): void {
    state.itemList = []
    state.tagList = []
  },
  NotLoggedIn (state: State, is: boolean): void {
    state.notificationNotLoggedIn = is
  },
  MY_COLLECTION_SHOW_COLLECTION_DROPDOWN (state: State, showCollectionDropdown: boolean): void {
    state.showCollectionDropdown = showCollectionDropdown
  },
}

/**
 * アクション
 */

const actions = {
  // 画面表示の初期処理
  async myCollectionInit (context: Context): Promise<void> {
    await context.commit('MY_COLLECTION_PROCESSING')
    await context.dispatch('myCollectionCollectionList')
    await context.commit('MY_COLLECTION_PROCESSED')
  },
  // マイコレクションアイテムの再読み込み。ローダーは表示しない
  async myCollectionCollectionList (context: Context): Promise<void> {
    context.commit('MY_COLLECTION_SET', [])
    context.commit('MY_COLLECTION_SET_TAG_LIST', [])
    context.commit('SET_MY_COLLECTION_TOTAL_HITS', 0)
    context.dispatch('myCollectionGetAllItems')
    const url = `${BASE_URL}/mycollection/collection/list`
    try {
      const myCollectionSearchMode: MyCollectionSearchMode = context.getters.myCollectionSearchMode
      const response = await postData(url, {
        keywords: state.keyword, // 絞り込み検索用
        tagId: state.selectedTagId, // タグID絞り込み用（数値。「すべて」の場合は「0」、「未分類」の場合は「99999」）
        pageNum: state.pageNum,
        pageSize: myCollectionSearchMode.myColletionPageSize,
        sortKey: queryConvertToSortKeyAndOrder(myCollectionSearchMode.myColletionSortKey).sortKey, // 追加した順は「SCORE」、その他は詳細検索のキーと同じ
        order: queryConvertToSortKeyAndOrder(myCollectionSearchMode.myColletionSortKey).order, // 追加した順は「DESC」のみ
      })
      context.commit('MY_COLLECTION_SET', response.items)
      context.commit('MY_COLLECTION_SET_TAG_LIST',
        reduce(response.tags, (result: MycollectionTag[], value: string, key: string) => {
          result.push({ id: Number(key), label: value, hit: response.tagsCount[key] })
          return result
        }, [
          // label には多言語内容をvueファイル側で設定するため、初期値で空を設定
          { id: 0, label: '', hit: response.tagsCount['0'] }, // 「すべて」タグ
          { id: 99999, label: '', hit: response.tagsCount['99999'] }, // 「未分類」タグ
        ]
        ).sort((a, b) => { return a.id - b.id }) || []
      )
      context.dispatch('getContentsThumbnailToken', response.items)
      context.commit('SET_MY_COLLECTION_TOTAL_HITS', response.totalHits)
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    }
  },
  async myCollectionGetAllItems (context: Context): Promise<void> {
    context.commit('MY_COLLECTION_ALL_ITEMS', [])
    const url = `${BASE_URL}/mycollection/collection/all`
    try {
      const response = await getData(url)
      context.commit('MY_COLLECTION_ALL_ITEMS', response.items)
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    }
  },
  myCollectionSelectSortKey (context: Context, sortKey: string): void {
    const myCollectionSearchMode: MyCollectionSearchMode = context.getters.myCollectionSearchMode
    if (sortKey && mycollectionSortList.filter(
      checkSortKey => checkSortKey.value.toString() === sortKey.toString()
    ).length > 0) {
      myCollectionSearchMode.myColletionSortKey = sortKey
    }
    context.dispatch('setMyCollectionSearchMode', myCollectionSearchMode)
  },
  myCollectionSelectDisplayCount (context: Context, displayCount: number): void {
    const myCollectionSearchMode: MyCollectionSearchMode = context.getters.myCollectionSearchMode
    if (displayCount && displayList.filter(
      checkDisplayCount => checkDisplayCount.value.toString() === displayCount.toString()
    ).length > 0) {
      myCollectionSearchMode.myColletionPageSize = displayCount.toString()
    }
    context.dispatch('setMyCollectionSearchMode', myCollectionSearchMode)
  },
  myCollectionSelectDisplayMode (context: Context, mode: string): void {
    const myCollectionSearchMode: MyCollectionSearchMode = context.getters.myCollectionSearchMode
    if (mode && displayModeList.filter(
      checkDisplayMode => checkDisplayMode.value.toString() === mode.toString()
    ).length > 0) {
      myCollectionSearchMode.myColletionDisplayMode = mode
    }
    context.dispatch('setMyCollectionSearchMode', myCollectionSearchMode)
  },
  myCollectionSelectPageNumber (context: Context, pageNum: number): void {
    context.commit('MY_COLLECTION_SELECT_PAGE_NUMBER', pageNum)
  },
  myCollectionSelectDisplayedItemViewPattern (context: Context, displayedItemViewPattern: string): void {
    const myCollectionSearchMode: MyCollectionSearchMode = context.getters.myCollectionSearchMode
    if (displayedItemViewPattern && displayContentsList.filter(
      checkDisplayedItemViewPattern => checkDisplayedItemViewPattern.value.toString() === displayedItemViewPattern.toString()
    ).length > 0) {
      myCollectionSearchMode.myColletionDisplayItemViewPattern = displayedItemViewPattern
    }
    context.dispatch('setMyCollectionSearchMode', myCollectionSearchMode)
  },
  async myCollectionDelete (context: Context, query: MyCollectionToastMessage): Promise<void> {
    context.commit('ADD_MY_COLLECTION_NOTIFICATION_MESSAGE', { ...query, type: 'notice', text: 'bookmarkRemove', id: new Date().getTime().toString() })
    const url = `${BASE_URL}/mycollection/collection/delete`
    try {
      await postData(url, query)
      context.dispatch('myCollectionInit')
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    }
  },
  myCollectionDeleteMessage (context: Context, id: string): void {
    context.commit('DELETE_MY_COLLECTION_NOTIFICATION_MESSAGE', id)
  },
  async mycollectionCollectionEntry (context: Context, query: any): Promise<void> {
    const url = `${BASE_URL}/mycollection/collection/entry`
    try {
      await postData(url, query)
      context.dispatch('myCollectionInit')
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error)
      context.commit('ADD_MY_COLLECTION_NOTIFICATION_MESSAGE', { ...query, type: 'error', text: 'upperLimit', id: new Date().getTime().toString() })
    }
  },
  async myCollectionTagLink (context: Context, query: MyCollectionToastMessage): Promise<void> {
    const url = `${BASE_URL}/mycollection/tag/link`
    try {
      await postData(url, query)
      await context.dispatch('myCollectionInit')
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
      context.commit('ADD_MY_COLLECTION_NOTIFICATION_MESSAGE', { ...query, type: 'error', text: 'upperLimit', id: new Date().getTime().toString() })
    }
  },
  async myCollectionItemLink (context: Context, query: MyCollectionToastMessage): Promise<void> {
    const url = `${BASE_URL}/mycollection/item/link`
    try {
      await postData(url, query)
      await context.dispatch('myCollectionInit')
      // 成功時のみ、マイコレクション追加のメッセージを表示
      context.commit('ADD_MY_COLLECTION_NOTIFICATION_MESSAGE', { ...query, type: 'success', text: 'bookmarkAdded', id: new Date().getTime().toString() })
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
      context.commit('ADD_MY_COLLECTION_NOTIFICATION_MESSAGE', { ...query, type: 'error', text: 'upperLimit', id: new Date().getTime().toString() })
    }
  },
  async myCollectionItemUnlink (context: Context, query: any): Promise<void> {
    const url = `${BASE_URL}/mycollection/item/unlink`

    await context.commit('MY_COLLECTION_PROCESSING')
    try {
      await postData(url, query)
      await context.dispatch('myCollectionCollectionList')
      if (state.itemList.length === 0) {
        await context.commit('MY_COLLECTION_SELECTED_TAG', 0)
        await context.dispatch('myCollectionCollectionList')
      }
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    } finally {
      await context.commit('MY_COLLECTION_PROCESSED')
    }
  },
  async getContentsThumbnailToken (context: Context, itemList: MyCollectionItem[]): Promise<void> {
    if (!itemList) {
      context.commit('SET_CONTENTS_THUMBNAIL_TOKEN', [])
      return
    }
    const getPid = (pid: any) => {
      return pid ? `info:ndljp/pid/${pid}` : ''
    }
    const result = await Promise.all(itemList.map(async (item) => {
      const contents = item.contents || false
      if (!contents) return false

      const pid: string = getPid(item.pid)

      return await getData(`${BASE_URL}/restriction/issue/token/${pid}`)
        .then(async (response: any) => {
          const tokens = await response.tokens
          const timestamp = await response.timestamp

          return {
            pid: item.pid,
            tokens,
            timestamp,
          }
        })
    }))
    context.commit('SET_CONTENTS_THUMBNAIL_TOKEN', filter(result, v => v !== false))
  },
  myCollectionShowCollectionDropdown (context: Context, showCollectionDropdown: boolean): void {
    context.commit('MY_COLLECTION_SHOW_COLLECTION_DROPDOWN', showCollectionDropdown)
  },
  async setMyCollectionSearchMode (context: Context, myCollectionSearchMode: MyCollectionSearchMode): Promise<void> {
    const isServer = typeof document === 'undefined'
    if (!isServer) {
      const now = new Date()
      now.setFullYear(now.getFullYear() + 1)
      document.cookie = `myCollectionSearchMode=${JSON.stringify(myCollectionSearchMode)};expires=${now.toUTCString()};`
    }
  },
}

const mergeContentsThumbnailToken = (list: MyCollectionItem[], tokenList: any) => {
  if (tokenList.length === 0) return list
  const res = map(list, (item) => {
    const hasContents = item.contents || false
    if (!hasContents) return item
    const targetToken = filter(tokenList, (v) => v.pid === item.pid)[0]
    const contents = map(item.contents, content => {
      const encode = reduce(targetToken.tokens, (r: any, v, k) => {
        if (k === content.cid) {
          r = encodeURIComponent(
            JSON.stringify({
              token: targetToken.tokens[k],
              timestamp: targetToken.timestamp,
            })
          )
        }

        return r
      }, false)

      const path = `contents/${item.pid}/${content.bid}/${content.cid}/thumbnail.jpg`
      const token = encode ? `?token=${encode}` : ''
      const publicPath = `${path}${token}`
      return { ...content, ...{ publicPath } }
    })

    return { ...item, ...{ contents } }
  })

  return res
}

const modifyItemList = (li: MyCollectionItem[], tokens: any) => {
  if (li.length === 0) return []
  const list: MyCollectionItem[] = mergeContentsThumbnailToken(li, tokens)
  return list
}

export default {
  state,
  getters,
  mutations,
  actions,
}
