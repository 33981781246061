import { postDataCredential } from '@/helpers/util/webApiUtil'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { ActionContext } from 'vuex'

const STREAMING_API_URL = process.env.VUE_APP_STREAMING_API_URL
const retryClient = axios.create()
axiosRetry(retryClient, { retries: 3 })

type PackageStreamingRequest = {
  userId: string,
  pid: string,
  bundleId: string,
  contents: any,
  packageEnv: string,
}

type PublishingUrlStatus = '' | 'publishing' | 'successful' | 'failed' | 'session-limit-exceeded' | 'os-limit-exceeded' | 'interval-period' | 'not-available-instance' | 'system-error' | 'sim-execution-limit-exceeded'

/**
 * ステート
 */
type State = {
  packageStreamingUrl: string,
  publishingUrlStatus: PublishingUrlStatus,
  showPackageGuideModal: boolean,
  publishingSequence: number,
}

type Context = ActionContext<State, any>

const state: State = {
  packageStreamingUrl: '',
  publishingUrlStatus: '',
  showPackageGuideModal: false,
  publishingSequence: 0, // キャンセル後に前回発行したURLが使用されるのを防ぐ
}

/**
 * ゲッター
 */
const getters = {
  packageStreamingUrl (state: State): string {
    return state.packageStreamingUrl
  },
  publishingUrlStatus (state: State): string {
    return state.publishingUrlStatus
  },
  showPackageGuideModal (state: State): boolean {
    return state.showPackageGuideModal
  },
  publishingSequence (state: State): number {
    return state.publishingSequence
  },
}

/**
 * ミューテーション
 */
const mutations = {
  SET_PACKAGE_STREAMING_URL (state: State, packageStreamingUrl: string): void {
    state.packageStreamingUrl = packageStreamingUrl
  },
  UPDATE_PUBLISHING_URL_STATUS (state: State, status: PublishingUrlStatus): void {
    state.publishingUrlStatus = status
  },
  SET_SHOW_PACKAGE_GUIDE_MODAL (state: State, showPackageGuideModal: boolean): void {
    state.showPackageGuideModal = showPackageGuideModal
  },
  INCREMENT_PUBLISHING_SEQUENCE (state: State): void {
    ++state.publishingSequence
  },
}

/**
 * アクション
 */
const actions = {
  async publishPackageStreamingUrl (context: Context, data: PackageStreamingRequest): Promise<void> {
    try {
      const currentSequence = state.publishingSequence
      context.commit('UPDATE_PUBLISHING_URL_STATUS', 'publishing')
      const body = JSON.stringify(data)
      const response = await postDataCredential(`${STREAMING_API_URL}`, body, false)

      if (!response) throw new Error('Empty Response')

      if (currentSequence !== state.publishingSequence || context.getters.publishingUrlStatus === '') return
      const packageStreamingUrl = response.data?.streamingURL
      if (response.status === 200 && packageStreamingUrl) {
        context.commit('UPDATE_PUBLISHING_URL_STATUS', 'successful')
        context.commit('SET_PACKAGE_STREAMING_URL', packageStreamingUrl)
      } else {
        const errorCode = response.data?.errorCode
        if (response.status === 502) {
          context.commit('UPDATE_PUBLISHING_URL_STATUS', 'sim-execution-limit-exceeded')
        } else if (errorCode) {
          context.commit('UPDATE_PUBLISHING_URL_STATUS', errorCode)
        } else {
          throw new Error('Failed to publish streaming url')
        }
      }
    } catch (error) {
      console.error(error)
      context.commit('UPDATE_PUBLISHING_URL_STATUS', 'failed')
    }
  },
  closePackageGuideModal (context: Context): void {
    context.commit('SET_SHOW_PACKAGE_GUIDE_MODAL', false)
    context.commit('UPDATE_PUBLISHING_URL_STATUS', '')
    context.commit('INCREMENT_PUBLISHING_SEQUENCE')
  },
  setShowPackageGuideModal (context: Context): void {
    context.commit('SET_SHOW_PACKAGE_GUIDE_MODAL', true)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
