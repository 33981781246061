import { withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-534ba3d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "placeholder", "disabled", "list", "type", "aria-label", "id", "min", "max", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keydownDelete && _ctx.keydownDelete(...args)), ["delete"])),
    placeholder: _ctx.placeholder,
    class: _normalizeClass([_ctx.styled, "app-input app-input-firefox"]),
    disabled: _ctx.disabled,
    list: _ctx.list,
    type: _ctx.type,
    "aria-label": _ctx.label,
    id: _ctx.inputId,
    min: _ctx.min,
    max: _ctx.max,
    readonly: _ctx.readonly,
    ref: "input",
    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
  }, null, 42, _hoisted_1))
}