import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4393b014"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading_icon_holder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLoading = _resolveComponent("IconLoading")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_IconLoading, { style: {
        width: '4rem',
        height: '4rem',
      } })
      ]))
    : _createCommentVNode("", true)
}