
import { defineComponent, computed } from 'vue'
import AppInput from '../atoms/AppInput.vue'
import { StyleName, styleNames } from '../../data/def'

export default defineComponent({
  name: 'KeywordSearch',
  components: {
    AppInput,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['update:modelValue', 'search'],
  setup (props, context) {
    const keyword = computed({
      get: () => props.modelValue,
      set: (val) => {
        context.emit('update:modelValue', val)
      },
    })
    return {
      keyword,
    }
  },
})
