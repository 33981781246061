
import { defineComponent } from 'vue'
import { StyleName, styleNames } from '../../data/def'
import DssIcon from '../atoms/DssIcon.vue'
import AppButton from '../atoms/AppButton.vue'

export default defineComponent({
  components: {
    DssIcon,
    AppButton,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconAfter: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      required: false,
    },
    iconAfterSize: {
      type: String,
      required: false,
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  setup (props) {
    const iconStyle = (() => {
      if (props.icon && props.iconAfter) {
        return 'text-icon-double'
      } else if (props.icon && props.text) {
        return 'text-icon'
      } else if (props.iconAfter && props.text) {
        return 'text-after-icon'
      }
      return 'icon'
    })()

    const classes = {
      [iconStyle]: true,
    }

    return {
      classes,
    }
  },
})
