
export type StyleName = 'is-normal' |
  'is-accent' |
  'is-accent-sub' |
  'is-alert' |
  'is-none'

export const styleNames: StyleName[] = [
  'is-normal',
  'is-accent',
  'is-accent-sub',
  'is-alert',
  'is-none',
]

export type IconSize = 's' | 'm' | 'l' | 'xl'
export const iconSizes: IconSize[] = ['s', 'm', 'l', 'xl']
export const iconSizeList: { [key: string]: number } = {
  s: 16,
  m: 24,
  l: 32,
  xl: 52,
}
