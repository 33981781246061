import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationMessage = _resolveComponent("NotificationMessage")!

  return (_ctx.messages.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["the-notification-area", { sticky: _ctx.sticky }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
          return (_openBlock(), _createBlock(_component_NotificationMessage, {
            key: message.id,
            id: message.id,
            type: message.type,
            text: message.fixedText || _ctx.$t(`header.toaster.${message.text}`),
            style: _normalizeStyle(message.style),
            onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove', $event))),
            onResetDownload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('resetDownload'))),
            onRetryDownload: ($event: any) => (_ctx.$emit('retryDownload', message.type)),
            onCancelDownload: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancelDownload')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(message), 1)
            ]),
            _: 2
          }, 1032, ["id", "type", "text", "style", "onRetryDownload"]))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}