import { IVError } from './IVError'
import { KomaElement, KomaLevel } from './KomaElement'
import { PageElement } from './PageElement'
import { ViewMode } from './ImageViewer'
import { VisualElement } from './VisualElement'
import { AccessToken } from '@/store/modules/AccessToken'
import { PhysicalSize } from './ImageViewerCommon'

/**
 * 綴じ方向
 */
const Binding = {
  none: 0,
  rtl: 1,
  ltr: 2,
} as const
type Binding = typeof Binding[keyof typeof Binding];
type BindingStr = 'rtl' | 'ltr' | 'none' | '';

class JCSArea {
  public Left = 0.0;
  public Top = 0.0;
  public Right = 0.0;
  public Bottom = 0.0;
}

class JCSKoma {
  public KomaID = -1;
  public Src = '';
  public Token?: AccessToken;
  public Komainfo: any;
  public AntiTilt = 0;
  public Clipping?: JCSArea;
}

class JCSPage {
  public PageID = -1;
  public Koma = -1;
  public Area = new JCSArea();
  public AntiTilt = 0;
  public Clipping?: JCSArea;
}

/*
class JCSTwoInOePair {
  public KomaPairs = new Array<number>()
}
*/

class JContentStruct {
  public Version = '0.0'
  public DRM = true
  public BindingDirection: BindingStr = 'rtl'
  public Komas = new Array<JCSKoma>()
  public PagesLTR = new Array<JCSPage>()
  public PagesRTL = new Array<JCSPage>()
  // public TwoInOnePairs = new Array<JCSTwoInOePair>()
}

/**
 * 2in1時の表紙ページの扱い方法
 */
const CoverPageMode = {
  /**
   * 独立したページとして表示する
   */
  Standalone: 0,
  /**
   * ２コマ一組で表示する
   */
  Combined: 1,
}

type CoverPageMode = typeof CoverPageMode[keyof typeof CoverPageMode]

/**
 * 2in1ペアクラス
 */
class TwoInOePair {
  public twoInOneIndex = -1
  public komaPairs = new Array<KomaElement>()
}

class ContentStructure {
  private static validateContentJSON (jsonData: any): JContentStruct { // eslint-disable-line @typescript-eslint/no-explicit-any
    const res = new JContentStruct()
    const errMsg = 'Invalid Content JSON'

    if (jsonData.Version) {
      res.Version = jsonData.Version
    }

    if (jsonData.DRM) {
      res.DRM = true
    } else {
      res.DRM = false
    }

    if (jsonData.BindingDirection === 'ltr') {
      res.BindingDirection = 'ltr'
    } else {
      // none は rtl 扱い
      res.BindingDirection = 'rtl'
    }

    if (jsonData.Komas) {
      if (!Array.isArray(jsonData.Komas)) {
        throw new Error(errMsg)
      }
      if (jsonData.Komas.length <= 0) {
        throw new Error(errMsg)
      }

      for (let i = 0; i < jsonData.Komas.length; ++i) {
        const koma = jsonData.Komas[i]
        const jcsKoma = new JCSKoma()
        if (isFinite(koma.KomaID) && koma.KomaID >= 0) {
          jcsKoma.KomaID = koma.KomaID
        } else {
          throw new Error(errMsg)
        }
        if (typeof koma.Src === 'string' && koma.Src.length > 0) {
          jcsKoma.Src = koma.Src
        } else {
          throw new Error(errMsg)
        }
        if (koma.Token) {
          jcsKoma.Token = koma.Token
        } else {
          throw new Error(errMsg)
        }
        if (koma.AntiTilt && isFinite(koma.AntiTilt) && koma.AntiTilt >= -360 && koma.AntiTilt <= 360) {
          jcsKoma.AntiTilt = koma.AntiTilt
        }

        if (koma.Clipping) {
          jcsKoma.Clipping = new JCSArea()
          if (isFinite(koma.Clipping.Left) && koma.Clipping.Left >= 0 && koma.Clipping.Left < 1.0) {
            jcsKoma.Clipping.Left = koma.Clipping.Left
          }
          if (isFinite(koma.Clipping.Top) && koma.Clipping.Top >= 0 && koma.Clipping.Top < 1.0) {
            jcsKoma.Clipping.Top = koma.Clipping.Top
          }
          if (isFinite(koma.Clipping.Right) && koma.Clipping.Right > 0 && koma.Clipping.Right <= 1.0) {
            jcsKoma.Clipping.Right = koma.Clipping.Right
          }
          if (isFinite(koma.Clipping.Bottom) && koma.Clipping.Bottom > 0 && koma.Clipping.Bottom <= 1.0) {
            jcsKoma.Clipping.Bottom = koma.Clipping.Bottom
          }
        }

        res.Komas.push(jcsKoma)
      }
    } else {
      throw new Error(errMsg)
    }

    // 左綴じ時のPages配列
    if (jsonData.PagesLTR) {
      for (let i = 0; i < jsonData.PagesLTR.length; ++i) {
        const p = jsonData.PagesLTR[i]
        if (!p) {
          throw new Error(errMsg)
        }

        const page = new JCSPage()
        if (isFinite(p.PageID) && p.PageID >= 0) {
          page.PageID = p.PageID
        } else {
          throw new Error(errMsg)
        }
        if (isFinite(p.Koma) && p.Koma >= 0) {
          page.Koma = p.Koma
        } else {
          throw new Error(errMsg)
        }
        if (p.Area) {
          if (isFinite(p.Area.Left) && p.Area.Left >= 0 && p.Area.Left < 1.0) {
            page.Area.Left = p.Area.Left
          } else {
            throw new Error(errMsg)
          }
          if (isFinite(p.Area.Top) && p.Area.Top >= 0 && p.Area.Top < 1.0) {
            page.Area.Top = p.Area.Top
          } else {
            throw new Error(errMsg)
          }
          if (isFinite(p.Area.Right) && p.Area.Right > 0 && p.Area.Right <= 1.0) {
            page.Area.Right = p.Area.Right
          } else {
            throw new Error(errMsg)
          }
          if (isFinite(p.Area.Bottom) && p.Area.Bottom > 0 && p.Area.Bottom <= 1.0) {
            page.Area.Bottom = p.Area.Bottom
          } else {
            throw new Error(errMsg)
          }
        }

        if (p.AntiTilt && isFinite(p.AntiTilt) && p.AntiTilt >= -360 && p.AntiTilt <= 360) {
          page.AntiTilt = p.AntiTilt
        }

        if (p.Clipping) {
          page.Clipping = new JCSArea()
          if (isFinite(p.Clipping.Left) && p.Clipping.Left >= 0 && p.Clipping.Left < 1.0) {
            page.Clipping.Left = p.Clipping.Left
          }
          if (isFinite(p.Clipping.Top) && p.Clipping.Top >= 0 && p.Clipping.Top < 1.0) {
            page.Clipping.Top = p.Clipping.Top
          }
          if (isFinite(p.Clipping.Right) && p.Clipping.Right > 0 && p.Clipping.Right <= 1.0) {
            page.Clipping.Right = p.Clipping.Right
          }
          if (isFinite(p.Clipping.Bottom) && p.Clipping.Bottom > 0 && p.Clipping.Bottom <= 1.0) {
            page.Clipping.Bottom = p.Clipping.Bottom
          }
        }

        res.PagesLTR.push(page)
      }
    } else {
      throw new Error(errMsg)
    }

    // 右綴じ時のPages配列
    if (jsonData.PagesRTL) {
      for (let i = 0; i < jsonData.PagesRTL.length; ++i) {
        const p = jsonData.PagesRTL[i]
        if (!p) {
          throw new Error(errMsg)
        }

        const page = new JCSPage()
        if (isFinite(p.PageID) && p.PageID >= 0) {
          page.PageID = p.PageID
        } else {
          throw new Error(errMsg)
        }
        if (isFinite(p.Koma) && p.Koma >= 0) {
          page.Koma = p.Koma
        } else {
          throw new Error(errMsg)
        }
        if (p.Area) {
          if (isFinite(p.Area.Left) && p.Area.Left >= 0 && p.Area.Left < 1.0) {
            page.Area.Left = p.Area.Left
          } else {
            throw new Error(errMsg)
          }
          if (isFinite(p.Area.Top) && p.Area.Top >= 0 && p.Area.Top < 1.0) {
            page.Area.Top = p.Area.Top
          } else {
            throw new Error(errMsg)
          }
          if (isFinite(p.Area.Right) && p.Area.Right > 0 && p.Area.Right <= 1.0) {
            page.Area.Right = p.Area.Right
          } else {
            throw new Error(errMsg)
          }
          if (isFinite(p.Area.Bottom) && p.Area.Bottom > 0 && p.Area.Bottom <= 1.0) {
            page.Area.Bottom = p.Area.Bottom
          } else {
            throw new Error(errMsg)
          }
        }

        if (p.AntiTilt && isFinite(p.AntiTilt) && p.AntiTilt >= -360 && p.AntiTilt <= 360) {
          page.AntiTilt = p.AntiTilt
        }

        if (p.Clipping) {
          page.Clipping = new JCSArea()
          if (isFinite(p.Clipping.Left) && p.Clipping.Left >= 0 && p.Clipping.Left < 1.0) {
            page.Clipping.Left = p.Clipping.Left
          }
          if (isFinite(p.Clipping.Top) && p.Clipping.Top >= 0 && p.Clipping.Top < 1.0) {
            page.Clipping.Top = p.Clipping.Top
          }
          if (isFinite(p.Clipping.Right) && p.Clipping.Right > 0 && p.Clipping.Right <= 1.0) {
            page.Clipping.Right = p.Clipping.Right
          }
          if (isFinite(p.Clipping.Bottom) && p.Clipping.Bottom > 0 && p.Clipping.Bottom <= 1.0) {
            page.Clipping.Bottom = p.Clipping.Bottom
          }
        }

        res.PagesRTL.push(page)
      }
    } else {
      throw new Error(errMsg)
    }

    /*
    if (jsonData.TwoInOnePairs) {
      for (let i = 0; i < jsonData.TwoInOnePairs.length; ++i) {
        const m = jsonData.TwoInOnePairs[i]
        if (!m) {
          throw new Error(errMsg)
        }
        const twoInOePair = new JCSTwoInOePair()

        if (m.KomaPair && Array.isArray(m.KomaPair) && m.KomaPair.length > 0) {
          for (let j = 0; j < m.KomaPair.length; ++j) {
            const kp = m.KomaPair[j]
            if (isFinite(kp) && kp >= 0) {
              twoInOePair.KomaPairs.push(kp)
            } else {
              throw new Error(errMsg)
            }
          }
        } else {
          throw new Error(errMsg)
        }

        res.TwoInOnePairs.push(twoInOePair)
      }
    } else {
      throw new Error(errMsg)
    }
    */

    return res
  }

  /**
   * content.jsonオブジェクトからContentStructureを生成する
   * @param contentObj
   * @returns
   */
  public static fromJSONObject (contentObj: unknown): ContentStructure | undefined {
    const res = new ContentStructure()
    res.csURL_ = ''

    try {
      res.csJsonData_ = ContentStructure.validateContentJSON(contentObj)
    } catch (error) {
      return undefined
    }

    if (res.csJsonData_) {
      return res
    }

    return undefined
  }

  public static async loadJSON (url: string): Promise<ContentStructure> {
    const errorMsg = 'Failed to Load JSON'

    const ops: RequestInit = {
      mode: 'cors',
      credentials: 'same-origin',
    }

    return new Promise<ContentStructure>((resolve, reject) => {
      fetch(url, ops)
        .then((response) => response.text())
        .then((text) => {
          const jsonData = JSON.parse(text)
          if (!jsonData) {
            reject(errorMsg)
          }
          try {
            const res = new ContentStructure()
            res.csURL_ = url.substring(0, url.lastIndexOf('/') + 1) + '/'
            res.csJsonData_ = ContentStructure.validateContentJSON(jsonData)

            if (res.csJsonData_) {
              resolve(res)
            } else {
              reject(errorMsg)
            }
          } catch (error) {
            reject(error)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  private csJsonData_?: JContentStruct = undefined;

  /**
   * このコンテンツの初期綴じ方向
   */
  private binding_: Binding = Binding.none;

  /**
   * このコンテンツで使用するすべての画像要素
   */
  private komaElements_ = new Array<KomaElement>();
  private komaElementsMap_ = new Map<number, KomaElement>();

  /**
   * コンテンツで使用するすべてのページ要素
   */
  private get pageElements_ (): Array<PageElement> {
    if (this.binding_ === Binding.ltr) {
      return this.pageLTRElements_
    }

    return this.pageRTLElements_
  }

  /**
   * コンテンツで使用するすべての左綴じ時のページ要素
   */
   private pageLTRElements_ = new Array<PageElement>();

   /**
   * コンテンツで使用するすべての右綴じ時のページ要素
   */
    private pageRTLElements_ = new Array<PageElement>();

    /*
   * コンテンツで使用するすべての見開き組み合わせ配列
   *
  private mihirakis_ = new Array<Array<PageElement>>();
  */

  /**
   * 2in1時の表紙の扱い
   */
  private currentCoverPageMode_: CoverPageMode = CoverPageMode.Standalone
  public set currentCoverPageMode (value: CoverPageMode) {
    this.currentCoverPageMode_ = value
  }

  /*
   * 2in1ペア配列
   *
  private twoInOePairs_ = new Array<TwoInOePair>()
  */

  /**
   * コンテンツ構造データの取得先URL
   */
  private csURL_ = '';

  private dummyKomaResolution_ = new PhysicalSize()
  public get dummyKomaResolution (): PhysicalSize {
    return this.dummyKomaResolution_
  }

  private enoughDummySampling_ = false
  /**
   * コンテンツを初期化する
   */
  public async init (): Promise<boolean> {
    if (!this.csJsonData_) {
      return new Promise((resolve, reject) => {
        reject(new Error('There is no Content Data'))
      })
    }

    this.komaElements_.length = 0

    // 表示対象の画像のみ取得する

    const ps = new Array<Promise<boolean | void>>()
    for (let i = 0; i < this.csJsonData_.Komas.length; ++i) {
      ps.push(
        /*
        KomaElement.getKoma(
          this.csURL_ + this.csJsonData_.Komas[i].Src,
          this.csJsonData_.Komas[i].KomaID,
          this.csJsonData_.Komas[i].Token as AccessToken
        ).then((result) => {
          this.komaElements_.push(result)
        })
        */
        KomaElement.prepareKoma(
          this.csURL_ + this.csJsonData_.Komas[i].Src,
          this.csJsonData_.Komas[i].KomaID,
          this.csJsonData_.Komas[i].Token as AccessToken
        ).then((result) => {
          this.komaElements_.push(result)
          this.komaElementsMap_.set(result.komaID, result)
        })
      )
    }

    return new Promise<boolean>((resolve, reject) => {
      Promise.all(ps)
        .then(() => {
          if (!this.csJsonData_) {
            return reject(new Error('Invalid Page structure'))
          } else {
            if (this.csJsonData_.BindingDirection === 'ltr') {
              this.binding_ = Binding.ltr
            } else {
              this.binding_ = Binding.rtl
            }
            // Komasのソート
            this.komaElements_.sort((a: KomaElement, b: KomaElement) => {
              if (a.komaID < b.komaID) {
                return -1
              } else if (a.komaID > b.komaID) {
                return 1
              }
              return 0
            })

            // コマの傾き補正と自動余白削除情報を挿入する
            for (let i = 0; i < this.csJsonData_.Komas.length; ++i) {
              const jk = this.csJsonData_.Komas[i]
              const k = this.komaElements_[i]
              k.antiTiltAngle = jk.AntiTilt
              if (jk.Clipping) {
                k.setClippingArea(jk.Clipping)
              }
            }

            // ページの格納
            // 左綴じ
            for (let i = 0; i < this.csJsonData_.PagesLTR.length; ++i) {
              const jp = this.csJsonData_.PagesLTR[i]

              const page = new PageElement()
              if (!page.init(jp, this.komaElementsMap_)) {
                return reject(new Error('Invalid Page structure'))
              }

              page.antiTiltAngle = jp.AntiTilt

              this.pageLTRElements_.push(page)
            }
            // 右綴じ
            for (let i = 0; i < this.csJsonData_.PagesRTL.length; ++i) {
              const jp = this.csJsonData_.PagesRTL[i]

              const page = new PageElement()
              if (!page.init(jp, this.komaElementsMap_)) {
                return reject(new Error('Invalid Page structure'))
              }

              page.antiTiltAngle = jp.AntiTilt

              this.pageRTLElements_.push(page)
            }

            const error: IVError = IVError.NONE

            /*
            // 2in1ペアの格納
            for (let i = 0; i < this.csJsonData_.TwoInOnePairs.length; ++i) {
              const jtio = this.csJsonData_.TwoInOnePairs[i]
              const twoInOePair = new TwoInOePair()
              twoInOePair.twoInOneID = i
              jtio.KomaPairs.some((komaID) => {
                const k = this.getKomaElement(komaID)
                if (!k) {
                  error = IVError.INVALID_CONTENT
                  return true
                }
                twoInOePair.komaPairs.push(k)
                return false
              })

              if (error !== IVError.NONE) {
                return reject(new Error('Invalid Page structure'))
              }

              this.twoInOePairs_.push(twoInOePair)
            }
            */
          }
          resolve(true)// (result)
        })
        .catch((reason) => reject(reason))
    })
  }

  /**
   * 指定したページIDのPageElementを返す
   * @param pageID
   * @returns
   */
  public _getPage (pageID: number): PageElement | undefined {
    if (!this.pageElements_) return undefined

    let res: PageElement | undefined

    this.pageElements_.some((page) => {
      if (page.pageID === pageID) {
        res = page
        return true
      }
      return false
    })

    return res
  }

  /**
   * 指定したページインデックスのPageElementを返す
   * @param pageIndex
   * @returns
   */
  public getPageByPageIndex (pageIndex: number): PageElement | undefined {
    if (!this.pageElements_) return undefined

    return this.pageElements_[pageIndex]
  }

  /**
   * ページインデックスからPageElementを返す
   * @param pageIndex 検索するページインデックス
   * @returns
   */
  public getPageElementByIndex (pageIndex: number): PageElement | undefined {
    return this.pageElements_[pageIndex]
  }

  /*
  public getTwoInOe (twoInOeIndex: number): Array<VisualElement> {
    if (this.twoInOePairs_.length <= 0) { return new Array<VisualElement>(2) }

    return this.twoInOePairs_[twoInOeIndex].komaPairs
  }
  */

  /**
   * ページ総数を返す
   * @param viewMode 見開き時のページ数を取得する場合は true
   */
  public getPageCount (viewMode: ViewMode): number {
    let res = 0

    switch (viewMode) {
      case ViewMode.SINGLE:
      case ViewMode.SCROLL_SINGLE_V:
      case ViewMode.SCROLL_SINGLE_H:
        res = this.pageElements_.length
        break

      case ViewMode.KOMA:
      case ViewMode.SCROLL_KOMA_V:
      case ViewMode.SCROLL_KOMA_H:
        res = this.komaElements_.length
        break

      case ViewMode.TOW_IN_ONE:
      case ViewMode.SCROLL_TOW_IN_ONE_V:
      case ViewMode.SCROLL_TOW_IN_ONE_H:
        if (this.currentCoverPageMode_ === CoverPageMode.Standalone) {
          res = Math.floor(this.komaElements_.length / 2) + 1
        } else {
          res = Math.ceil(this.komaElements_.length / 2)
        }
        break
    }

    return res
  }

  /**
   * 綴じ方向を返す
   * @returns "rtl", "ltr", "none"
   */
  public get bindingDirection (): Binding {
    return this.binding_
  }

  /**
  * 綴じ方向を変更する
  * @param direction
  * @returns 処理の実行ができたか否か
  */
  public changeBindingDirection (direction: Binding): boolean {
    if (this.csJsonData_) {
      if (direction === Binding.rtl || direction === Binding.ltr) {
        this.binding_ = direction
        return true
      } else if (direction === Binding.none) {
        this.binding_ = Binding.rtl
        if (this.csJsonData_.BindingDirection === 'ltr') {
          this.binding_ = Binding.ltr
        }
        return true
      }
    }
    return false
  }

  /*
  public getCurretnBindingDirection (): Binding {
    return this.binding_
  }
  */

  public getKomaElement (komaID: number): KomaElement | undefined {
    let res: KomaElement | undefined

    this.komaElements_.some((img) => {
      if (img.komaID === komaID) {
        res = img
        return true
      }
      return false
    })

    return res
  }

  /**
   * コマインデックスを指定してKomaElementを返す
   * @param komaIndex 検索するコマインデックス
   */
  public getKomaElementByIndex (komaIndex: number): KomaElement | undefined {
    return this.komaElements_[komaIndex]
  }

  /*
   * ページIDから2in1ペアインデックスを検索する
   *
   * @param pageID 検索するページID 変換不能な場合は -1 を返す
   *
  public findTwoInOnePairIndexFromPageID (pageID: number): number {
    let res = -1

    // ページIDをコマIDに変換する
    const page = this.getPageByPageID(pageID)
    if (!page) {
      return -1
    }

    for (let i = 0; i < this.twoInOePairs_.length; ++i) {
      if (this.twoInOePairs_[i].komaPairs[0].komaID === page.koma?.komaID) {
        res = i
        break
      }
      if (this.twoInOePairs_[i].komaPairs[1].komaID === page.koma?.komaID) {
        res = i
        break
      }
    }

    return res
  }
  */

  /**
   * 指定した2in1ペアインデックスのTwoInOnePairを返す
   * @param pairIndex
   */
  public getTwoInOnePair (pairIndex: number): TwoInOePair | undefined {
    if (pairIndex < 0) {
      return undefined
    }

    const res = new TwoInOePair()
    if (this.currentCoverPageMode_ === CoverPageMode.Standalone) {
      if (pairIndex === 0) {
        res.komaPairs[0] = this.komaElements_[0]
        res.twoInOneIndex = 0
      } else {
        const kIndex = pairIndex * 2 - 1
        let koma = this.komaElements_[kIndex]
        if (koma) {
          res.komaPairs[0] = koma
        }
        koma = this.komaElements_[kIndex + 1]
        if (koma) {
          res.komaPairs[1] = koma
        }
      }

      res.twoInOneIndex = pairIndex
    } else {
      const kIndex = pairIndex * 2
      let koma = this.komaElements_[kIndex]
      if (koma) {
        res.komaPairs[0] = koma
      }
      koma = this.komaElements_[kIndex + 1]
      if (koma) {
        res.komaPairs[1] = koma
      }

      res.twoInOneIndex = pairIndex
    }

    return res
  }

  /**
   * 指定したElementが属している 2in1ペアを探す
   * @param koma
   */
  public findTwoInOnePairFromElement (element: VisualElement): TwoInOePair | undefined {
    let res

    const koma = element.koma
    if (!koma) {
      return undefined
    }

    if (this.currentCoverPageMode_ === CoverPageMode.Standalone) {
      // 表紙を独立させる場合
      res = new TwoInOePair()

      if (koma.komaID === 0) {
        // 先頭のコマ=表紙を独立させる
        res.komaPairs[0] = koma
        res.twoInOneIndex = 0
      } else {
        // 奇数ページ を [0] に
        if ((koma.komaID % 2) > 0) {
          res.komaPairs[0] = koma

          // 隣のコマが存在するか
          const nextKoma = this.getKomaElementByIndex(koma.komaID + 1)
          if (nextKoma) {
            res.komaPairs[1] = nextKoma
          }

          res.twoInOneIndex = Math.ceil(koma.komaID / 2)
        } else {
          // 偶数ページは [1] に
          const prevKoma = this.getKomaElement(koma.komaID - 1)
          if (prevKoma) {
            res.komaPairs[1] = koma
            res.komaPairs[0] = prevKoma
          } else {
            // 運用上この状況にはならないと思われる
            res.komaPairs[0] = koma
          }

          res.twoInOneIndex = Math.ceil(koma.komaID / 2)
        }
      }
    } else {
      // 表紙を独立させない場合
      res = new TwoInOePair()

      if ((koma.komaID % 2) === 0) {
        // 偶数ページは [0] に
        res.komaPairs[0] = koma

        // 隣のコマが存在するか
        const nextKoma = this.getKomaElementByIndex(koma.komaID + 1)
        if (nextKoma) {
          res.komaPairs[1] = nextKoma
        }

        res.twoInOneIndex = Math.floor(koma.komaID / 2)
      } else {
        // 奇数ページ を [1] に
        res.komaPairs[1] = koma

        // 隣のコマが存在するか
        const prevKoma = this.getKomaElementByIndex(koma.komaID - 1)
        if (prevKoma) {
          res.komaPairs[0] = prevKoma
        }

        res.twoInOneIndex = Math.floor(koma.komaID / 2)
      }
    }

    /*
    this.twoInOePairs_.some(pair => {
      if (pair.komaPairs[0].komaID === element.koma?.komaID) {
        res = pair
        return true
      }
      if (pair.komaPairs[1].komaID === element.koma?.komaID) {
        res = pair
        return true
      }

      return false
    })
    */

    return res
  }

  /**
   * コマをPageIDに変換する
   * @param koma
   * @returns 0>=PageID, 0<変換不能
   */
  public convertKomaToPageID (koma: KomaElement): number {
    let res = -1

    for (let i = 0; i < this.pageElements_.length; ++i) {
      if (this.pageElements_[i].koma?.komaID === koma.komaID) {
        res = this.pageElements_[i].pageID
        break
      }
    }

    return res
  }

  /**
   * PageIDをPageIndexに変換する
   * @param pageID
   */
  public convertPageIDToPageIndex (pageID: number): number {
    let res = -1

    for (let i = 0; i < this.pageElements_.length; ++i) {
      if (this.pageElements_[i].pageID === pageID) {
        res = i
        break
      }
    }

    return res
  }

  /**
   * コマIDをコマインデックスに変換する
   * @param komaID 変換するコマID
   */
  public convertKomaIDtoKomaIndex (komaID: number | undefined): number {
    if (komaID === undefined) {
      return -1
    }

    let res = -1
    for (let i = 0; i < this.komaElements_.length; ++i) {
      if (this.komaElements_[i].komaID === komaID) {
        res = i
        break
      }
    }

    return res
  }

  public getPageByPageID (pageID: number): PageElement | undefined {
    let res: PageElement | undefined

    this.pageElements_.some(p => {
      if (p.pageID === pageID) {
        res = p
        return true
      }
    })

    return res
  }

  /**
   * コマをコマインデックスに変換する
   * @param koma 変換するコマID
   */
  public convertKomaToKomaIndex (koma: KomaElement | undefined): number {
    if (koma === undefined) {
      return -1
    }

    let res = -1
    for (let i = 0; i < this.komaElements_.length; ++i) {
      if (this.komaElements_[i].komaID === koma.komaID) {
        res = i
        break
      }
    }

    return res
  }

  /**
   * 指定したElementを2in1に変換したTwoInOePairを返す
   * @param koma
   * @returns
   */
  public convertElementToTwoInOne (element: VisualElement | undefined): TwoInOePair | undefined {
    if (element === undefined) {
      return undefined
    }

    const pair = this.findTwoInOnePairFromElement(element)
    if (!pair) {
      return undefined
    }

    return pair
  }

  /*
   * 指定した2in1ペアの2in1ペアインデックスを返す
   * @param pair
   *
  public getTwoInOnePairIndex (pair: TwoInOePair): number {
    let res = -1

    for (let i = 0; i < this.twoInOePairs_.length; ++i) {
      if (this.twoInOePairs_[i].twoInOneID === pair.twoInOneID) {
        res = i
        break
      }
    }

    return res
  }
  */

  /**
   * 指定したコマを参照しているすべてのPageElementの配列を返す
   * @param koma
   */
  public getAllPagesThatReferToTheKoma (koma: KomaElement | undefined): Array<PageElement | undefined> | undefined {
    if (!koma) {
      return undefined
    }

    const res = new Array<PageElement | undefined>()

    this.pageElements_.forEach(page => {
      if (page.koma?.komaID === koma.komaID) {
        res.push(page)
      }
    })

    return res
  }

  /**
   * 指定したPageElementのページインデックスを返す
   * @param page
   */
  public getPageIndex (page: PageElement | undefined): number {
    if (!page) {
      return -1
    }

    let res = -1

    for (let i = 0; i < this.pageElements_.length; ++i) {
      if (this.pageElements_[i].pageID === page.pageID) {
        res = i
        break
      }
    }

    return res
  }

  /**
   * トークンを更新する
   * @param newToken
   */
  public updateToken (newToken: Array<AccessToken>): void {
    for (let i = 0; i < this.komaElements_.length; ++i) {
      const k = this.komaElements_[i]
      if (k) {
        k.updateToken(newToken[i])
      }
    }
  }

  /**
   * komainfo.json 取得が間に合わない場合に使用するダミーコマ画像の解像度情報を返す
   * @returns
   */
  public updateDammyKomaResolution (): PhysicalSize {
    if (this.enoughDummySampling_) {
      return this.dummyKomaResolution_
    }

    // 最大サンプル数
    const maxSamples = 100
    const res = new PhysicalSize()
    const allKomaCount = this.komaElements_.length

    if (allKomaCount <= 0) {
      return this.dummyKomaResolution_
    }

    // 取得済みの全コマからふさわしい解像度を決定する

    // komainfo取得済みコマ情報配列
    const availableKomaInfo = new Array<KomaLevel>()

    for (let i = 0; i < allKomaCount; ++i) {
      const levelInfo = this.komaElements_[i].getKomaLevel(0, false)

      if (levelInfo && !levelInfo.isDummy) {
        availableKomaInfo.push(levelInfo)
      }
    }

    if (availableKomaInfo.length <= 0) {
      return this.dummyKomaResolution_
    }

    // 有効なコマが8未満なら平均だけ出す
    if (availableKomaInfo.length < 8) {
      for (let i = 0; i < availableKomaInfo.length; ++i) {
        res.width += availableKomaInfo[i].originWidth
        res.height += availableKomaInfo[i].originHeight
      }

      res.width = Math.floor(res.width / availableKomaInfo.length)
      res.height = Math.floor(res.height / availableKomaInfo.length)

      // 全コマに適用する
      for (let i = 0; i < allKomaCount; ++i) {
        this.komaElements_[i].setDummyResolution(res)
      }

      this.dummyKomaResolution_ = res
      return res
    }

    // 外れ値の除去
    const q = Math.floor(availableKomaInfo.length / 4)
    const q1 = new PhysicalSize()
    const q2 = new PhysicalSize()
    const q3 = new PhysicalSize()

    q1.width = availableKomaInfo[q].originWidth
    q1.height = availableKomaInfo[q].originHeight
    q2.width = availableKomaInfo[q * 2].originWidth
    q2.height = availableKomaInfo[q * 2].originHeight
    q3.width = availableKomaInfo[q * 3].originWidth
    q3.height = availableKomaInfo[q * 3].originHeight

    const iqr = new PhysicalSize()
    const highFilter = new PhysicalSize()
    const lowFilter = new PhysicalSize()

    iqr.width = q3.width - q1.width
    iqr.height = q3.height - q1.height

    highFilter.width = q3.width + iqr.width * 1.5
    highFilter.height = q3.height + iqr.height * 1.5
    lowFilter.width = q1.width - iqr.width * 1.5
    lowFilter.height = q1.height - iqr.height * 1.5

    let accurateKomainfo = new Array<KomaLevel>()
    const availableKomaCount = availableKomaInfo.length

    if (iqr.width === 0 && iqr.height === 0) {
      accurateKomainfo = availableKomaInfo
    } else {
      for (let i = 0; i < availableKomaCount; ++i) {
        const k = availableKomaInfo[i]
        if (iqr.width === 0) {
          if (lowFilter.height < k.originHeight && k.originHeight < highFilter.height) {
            accurateKomainfo.push(k)
          }
        } else if (iqr.height === 0) {
          if (lowFilter.width < k.originWidth && k.originWidth < highFilter.width) {
            accurateKomainfo.push(k)
          }
        } else if (lowFilter.width < k.originWidth && k.originWidth < highFilter.width && lowFilter.height < k.originHeight && k.originHeight < highFilter.height) {
          accurateKomainfo.push(k)
        }
      }
    }

    // 平均

    if (accurateKomainfo.length <= 0) {
      // 有効な値が無い場合は取得済みkomainfoの平均を使用する
      accurateKomainfo = availableKomaInfo
    }

    const accurateKomaCount = accurateKomainfo.length

    for (let i = 0; i < accurateKomaCount; ++i) {
      res.width += accurateKomainfo[i].originWidth
      res.height += accurateKomainfo[i].originHeight
    }

    res.width = Math.floor(res.width / accurateKomaCount)
    res.height = Math.floor(res.height / accurateKomaCount)

    // 標準偏差

    // 最終の値

    // 全コマに適用する
    for (let i = 0; i < allKomaCount; ++i) {
      this.komaElements_[i].setDummyResolution(res)
    }

    if (availableKomaInfo.length >= maxSamples) {
      this.enoughDummySampling_ = true
    }

    this.dummyKomaResolution_ = res
    return res
  }
}

export {
  ContentStructure,
  JCSArea,
  JCSKoma,
  JCSPage,
  Binding,
  CoverPageMode,
}
