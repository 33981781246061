import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-683e3c88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "keyword-search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('search', $event.target[0].value)), ["prevent"])),
      role: "search"
    }, [
      _createVNode(_component_AppInput, {
        class: "keyword-search-input-form",
        modelValue: _ctx.keyword,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyword) = $event)),
        placeholder: _ctx.placeholder,
        styled: _ctx.styled,
        label: _ctx.label
      }, null, 8, ["modelValue", "placeholder", "styled", "label"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 32)
  ]))
}