import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetadataRecord = _resolveComponent("MetadataRecord")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["default-detail-record-component", `collection-${_ctx.collection}`])
  }, [
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-78",
      labels: _ctx.getMetadataLabels(78),
      value: [_ctx.getMetadataValue(78)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-1",
      labels: _ctx.getMetadataLabels(1),
      value: [_ctx.getMetadataValue(1)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-311",
      labels: _ctx.getMetadataLabels(311),
      value: [_ctx.getMetadataValue(311)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-7",
      labels: _ctx.getMetadataLabels(7),
      value: [_ctx.getMetadataValue(7)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-321",
      labels: _ctx.getMetadataLabels(321),
      value: [_ctx.getMetadataValue(321)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-2",
      labels: _ctx.getMetadataLabels(2),
      value: [_ctx.getMetadataValue(2)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-312",
      labels: _ctx.getMetadataLabels(312),
      value: [_ctx.getMetadataValue(312)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-8",
      labels: _ctx.getMetadataLabels(8),
      value: [_ctx.getMetadataValue(8)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-322",
      labels: _ctx.getMetadataLabels(322),
      value: [_ctx.getMetadataValue(322)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-10",
      labels: _ctx.getMetadataLabels(10),
      value: [_ctx.getMetadataValue(10)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-420",
      labels: _ctx.getMetadataLabels(420),
      value: [_ctx.getMetadataValue(420)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-324",
      labels: _ctx.getMetadataLabels(324),
      value: [_ctx.getMetadataValue(324)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-11",
      labels: _ctx.getMetadataLabels(11),
      value: [_ctx.getMetadataValue(11)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-388",
      labels: _ctx.getMetadataLabels(388),
      value: [_ctx.getMetadataValue(388)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-12",
      labels: _ctx.getMetadataLabels(12),
      value: [_ctx.getMetadataValue(12)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-325",
      labels: _ctx.getMetadataLabels(325),
      value: [_ctx.getMetadataValue(325)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-13",
      labels: _ctx.getMetadataLabels(13),
      value: [_ctx.getMetadataValue(13)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-17",
      labels: _ctx.getMetadataLabels(17),
      value: [_ctx.getMetadataValue(17)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-330",
      labels: _ctx.getMetadataLabels(330),
      value: [_ctx.getMetadataValue(330)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-117",
      labels: _ctx.getMetadataLabels(117),
      value: [_ctx.getMetadataValue(117)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-271",
      labels: _ctx.getMetadataLabels(271),
      value: [_ctx.getMetadataValue(271)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-272",
      labels: _ctx.getMetadataLabels(272),
      value: [_ctx.getMetadataValue(272)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-269",
      labels: _ctx.getMetadataLabels(269),
      value: [_ctx.getMetadataValue(269)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-270",
      labels: _ctx.getMetadataLabels(270),
      value: [_ctx.getMetadataValue(270)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-273",
      labels: _ctx.getMetadataLabels(273),
      value: [_ctx.getMetadataValue(273)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-18",
      labels: _ctx.getMetadataLabels(18),
      value: [_ctx.getMetadataValue(18)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-394",
      labels: _ctx.getMetadataLabels(394),
      value: [_ctx.getMetadataValue(394)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-19",
      labels: _ctx.getMetadataLabels(19),
      value: [_ctx.getMetadataValue(19)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-329",
      labels: _ctx.getMetadataLabels(329),
      value: [_ctx.getMetadataValue(329)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-266",
      labels: _ctx.getMetadataLabels(266),
      value: [_ctx.getMetadataValue(266)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-21",
      labels: _ctx.getMetadataLabels(21),
      value: [_ctx.getMetadataValue(21)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-20",
      labels: _ctx.getMetadataLabels(20),
      value: [_ctx.getMetadataValue(20)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-331",
      labels: _ctx.getMetadataLabels(331),
      value: [_ctx.getMetadataValue(331)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-58",
      labels: _ctx.getMetadataLabels(58),
      value: [_ctx.getMetadataValue(58)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-59",
      labels: _ctx.getMetadataLabels(59),
      value: [_ctx.getMetadataValue(59)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-413",
      labels: _ctx.getMetadataLabels(413),
      value: [_ctx.getMetadataValue(413)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-414",
      labels: _ctx.getMetadataLabels(414),
      value: [_ctx.getMetadataValue(414)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-522",
      labels: _ctx.getMetadataLabels(522),
      value: [_ctx.getMetadataValue(522)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-72",
      labels: _ctx.getMetadataLabels(72),
      value: [_ctx.getMetadataValue(72)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-73",
      labels: _ctx.getMetadataLabels(73),
      value: [_ctx.getMetadataValue(73)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-74",
      labels: _ctx.getMetadataLabels(74),
      value: [_ctx.getMetadataValue(74)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-3",
      labels: _ctx.getMetadataLabels(3),
      value: [_ctx.getMetadataValue(3)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-313",
      labels: _ctx.getMetadataLabels(313),
      value: [_ctx.getMetadataValue(313)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-315",
      labels: _ctx.getMetadataLabels(315),
      value: [_ctx.getMetadataValue(315)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-316",
      labels: _ctx.getMetadataLabels(316),
      value: [_ctx.getMetadataValue(316)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-317",
      labels: _ctx.getMetadataLabels(317),
      value: [_ctx.getMetadataValue(317)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-318",
      labels: _ctx.getMetadataLabels(318),
      value: [_ctx.getMetadataValue(318)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-4",
      labels: _ctx.getMetadataLabels(4),
      value: [_ctx.getMetadataValue(4)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-314",
      labels: _ctx.getMetadataLabels(314),
      value: [_ctx.getMetadataValue(314)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-14",
      labels: _ctx.getMetadataLabels(14),
      value: [_ctx.getMetadataValue(14)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-326",
      labels: _ctx.getMetadataLabels(326),
      value: [_ctx.getMetadataValue(326)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-6",
      labels: _ctx.getMetadataLabels(6),
      value: [_ctx.getMetadataValue(6)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-320",
      labels: _ctx.getMetadataLabels(320),
      value: [_ctx.getMetadataValue(320)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-391",
      labels: _ctx.getMetadataLabels(391),
      value: [_ctx.getMetadataValue(391)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-9",
      labels: _ctx.getMetadataLabels(9),
      value: [_ctx.getMetadataValue(9)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-323",
      labels: _ctx.getMetadataLabels(323),
      value: [_ctx.getMetadataValue(323)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-16",
      labels: _ctx.getMetadataLabels(16),
      value: [_ctx.getMetadataValue(16)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-328",
      labels: _ctx.getMetadataLabels(328),
      value: [_ctx.getMetadataValue(328)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-40",
      labels: _ctx.getMetadataLabels(40),
      value: [_ctx.getMetadataValue(40)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-372",
      labels: _ctx.getMetadataLabels(372),
      value: [_ctx.getMetadataValue(372)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-302",
      labels: _ctx.getMetadataLabels(302),
      value: [_ctx.getMetadataValue(302)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-337",
      labels: _ctx.getMetadataLabels(337),
      value: [_ctx.getMetadataValue(337)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-268",
      labels: _ctx.getMetadataLabels(268),
      value: [_ctx.getMetadataValue(268)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-300",
      labels: _ctx.getMetadataLabels(300),
      value: [_ctx.getMetadataValue(300)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-301",
      labels: _ctx.getMetadataLabels(301),
      value: [_ctx.getMetadataValue(301)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-267",
      labels: _ctx.getMetadataLabels(267),
      value: [_ctx.getMetadataValue(267)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-264",
      labels: _ctx.getMetadataLabels(264),
      value: [_ctx.getMetadataValue(264)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-150",
      labels: _ctx.getMetadataLabels(150),
      value: [_ctx.getMetadataValue(150)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-374",
      labels: _ctx.getMetadataLabels(374),
      value: [_ctx.getMetadataValue(374)],
      type: "ITEM_LINK_WITH_LABEL"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-401",
      labels: _ctx.getMetadataLabels(401),
      value: [_ctx.getMetadataValue(401)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-151",
      labels: _ctx.getMetadataLabels(151),
      value: [_ctx.getMetadataValue(151)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-152",
      labels: _ctx.getMetadataLabels(152),
      value: [_ctx.getMetadataValue(152)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-153",
      labels: _ctx.getMetadataLabels(153),
      value: [_ctx.getMetadataValue(153)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-154",
      labels: _ctx.getMetadataLabels(154),
      value: [_ctx.getMetadataValue(154)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-155",
      labels: _ctx.getMetadataLabels(155),
      value: [_ctx.getMetadataValue(155)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-156",
      labels: _ctx.getMetadataLabels(156),
      value: [_ctx.getMetadataValue(156)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-157",
      labels: _ctx.getMetadataLabels(157),
      value: [_ctx.getMetadataValue(157)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-142",
      labels: _ctx.getMetadataLabels(142),
      value: [_ctx.getMetadataValue(142)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-375",
      labels: _ctx.getMetadataLabels(375),
      value: [_ctx.getMetadataValue(375)],
      type: "ITEM_LINK_WITH_LABEL"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-402",
      labels: _ctx.getMetadataLabels(402),
      value: [_ctx.getMetadataValue(402)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-143",
      labels: _ctx.getMetadataLabels(143),
      value: [_ctx.getMetadataValue(143)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-144",
      labels: _ctx.getMetadataLabels(144),
      value: [_ctx.getMetadataValue(144)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-145",
      labels: _ctx.getMetadataLabels(145),
      value: [_ctx.getMetadataValue(145)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-146",
      labels: _ctx.getMetadataLabels(146),
      value: [_ctx.getMetadataValue(146)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-147",
      labels: _ctx.getMetadataLabels(147),
      value: [_ctx.getMetadataValue(147)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-148",
      labels: _ctx.getMetadataLabels(148),
      value: [_ctx.getMetadataValue(148)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-149",
      labels: _ctx.getMetadataLabels(149),
      value: [_ctx.getMetadataValue(149)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-377",
      labels: _ctx.getMetadataLabels(377),
      value: [_ctx.getMetadataValue(377)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-378",
      labels: _ctx.getMetadataLabels(378),
      value: [_ctx.getMetadataValue(378)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-118",
      labels: _ctx.getMetadataLabels(118),
      value: [_ctx.getMetadataValue(118)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-376",
      labels: _ctx.getMetadataLabels(376),
      value: [_ctx.getMetadataValue(376)],
      type: "ITEM_LINK_WITH_LABEL"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-403",
      labels: _ctx.getMetadataLabels(403),
      value: [_ctx.getMetadataValue(403)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-119",
      labels: _ctx.getMetadataLabels(119),
      value: [_ctx.getMetadataValue(119)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-120",
      labels: _ctx.getMetadataLabels(120),
      value: [_ctx.getMetadataValue(120)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-121",
      labels: _ctx.getMetadataLabels(121),
      value: [_ctx.getMetadataValue(121)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-122",
      labels: _ctx.getMetadataLabels(122),
      value: [_ctx.getMetadataValue(122)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-123",
      labels: _ctx.getMetadataLabels(123),
      value: [_ctx.getMetadataValue(123)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-124",
      labels: _ctx.getMetadataLabels(124),
      value: [_ctx.getMetadataValue(124)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-125",
      labels: _ctx.getMetadataLabels(125),
      value: [_ctx.getMetadataValue(125)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-174",
      labels: _ctx.getMetadataLabels(174),
      value: [_ctx.getMetadataValue(174)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-369",
      labels: _ctx.getMetadataLabels(369),
      value: [_ctx.getMetadataValue(369)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-175",
      labels: _ctx.getMetadataLabels(175),
      value: [_ctx.getMetadataValue(175)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-176",
      labels: _ctx.getMetadataLabels(176),
      value: [_ctx.getMetadataValue(176)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-177",
      labels: _ctx.getMetadataLabels(177),
      value: [_ctx.getMetadataValue(177)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-178",
      labels: _ctx.getMetadataLabels(178),
      value: [_ctx.getMetadataValue(178)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-179",
      labels: _ctx.getMetadataLabels(179),
      value: [_ctx.getMetadataValue(179)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-180",
      labels: _ctx.getMetadataLabels(180),
      value: [_ctx.getMetadataValue(180)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-181",
      labels: _ctx.getMetadataLabels(181),
      value: [_ctx.getMetadataValue(181)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-126",
      labels: _ctx.getMetadataLabels(126),
      value: [_ctx.getMetadataValue(126)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-127",
      labels: _ctx.getMetadataLabels(127),
      value: [_ctx.getMetadataValue(127)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-128",
      labels: _ctx.getMetadataLabels(128),
      value: [_ctx.getMetadataValue(128)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-129",
      labels: _ctx.getMetadataLabels(129),
      value: [_ctx.getMetadataValue(129)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-130",
      labels: _ctx.getMetadataLabels(130),
      value: [_ctx.getMetadataValue(130)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-131",
      labels: _ctx.getMetadataLabels(131),
      value: [_ctx.getMetadataValue(131)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-132",
      labels: _ctx.getMetadataLabels(132),
      value: [_ctx.getMetadataValue(132)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-133",
      labels: _ctx.getMetadataLabels(133),
      value: [_ctx.getMetadataValue(133)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-134",
      labels: _ctx.getMetadataLabels(134),
      value: [_ctx.getMetadataValue(134)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-135",
      labels: _ctx.getMetadataLabels(135),
      value: [_ctx.getMetadataValue(135)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-136",
      labels: _ctx.getMetadataLabels(136),
      value: [_ctx.getMetadataValue(136)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-137",
      labels: _ctx.getMetadataLabels(137),
      value: [_ctx.getMetadataValue(137)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-138",
      labels: _ctx.getMetadataLabels(138),
      value: [_ctx.getMetadataValue(138)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-139",
      labels: _ctx.getMetadataLabels(139),
      value: [_ctx.getMetadataValue(139)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-140",
      labels: _ctx.getMetadataLabels(140),
      value: [_ctx.getMetadataValue(140)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-141",
      labels: _ctx.getMetadataLabels(141),
      value: [_ctx.getMetadataValue(141)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-158",
      labels: _ctx.getMetadataLabels(158),
      value: [_ctx.getMetadataValue(158)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-159",
      labels: _ctx.getMetadataLabels(159),
      value: [_ctx.getMetadataValue(159)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-160",
      labels: _ctx.getMetadataLabels(160),
      value: [_ctx.getMetadataValue(160)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-161",
      labels: _ctx.getMetadataLabels(161),
      value: [_ctx.getMetadataValue(161)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-162",
      labels: _ctx.getMetadataLabels(162),
      value: [_ctx.getMetadataValue(162)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-163",
      labels: _ctx.getMetadataLabels(163),
      value: [_ctx.getMetadataValue(163)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-164",
      labels: _ctx.getMetadataLabels(164),
      value: [_ctx.getMetadataValue(164)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-165",
      labels: _ctx.getMetadataLabels(165),
      value: [_ctx.getMetadataValue(165)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-166",
      labels: _ctx.getMetadataLabels(166),
      value: [_ctx.getMetadataValue(166)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-167",
      labels: _ctx.getMetadataLabels(167),
      value: [_ctx.getMetadataValue(167)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-168",
      labels: _ctx.getMetadataLabels(168),
      value: [_ctx.getMetadataValue(168)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-169",
      labels: _ctx.getMetadataLabels(169),
      value: [_ctx.getMetadataValue(169)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-170",
      labels: _ctx.getMetadataLabels(170),
      value: [_ctx.getMetadataValue(170)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-171",
      labels: _ctx.getMetadataLabels(171),
      value: [_ctx.getMetadataValue(171)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-172",
      labels: _ctx.getMetadataLabels(172),
      value: [_ctx.getMetadataValue(172)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-173",
      labels: _ctx.getMetadataLabels(173),
      value: [_ctx.getMetadataValue(173)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-182",
      labels: _ctx.getMetadataLabels(182),
      value: [_ctx.getMetadataValue(182)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-183",
      labels: _ctx.getMetadataLabels(183),
      value: [_ctx.getMetadataValue(183)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-184",
      labels: _ctx.getMetadataLabels(184),
      value: [_ctx.getMetadataValue(184)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-185",
      labels: _ctx.getMetadataLabels(185),
      value: [_ctx.getMetadataValue(185)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-186",
      labels: _ctx.getMetadataLabels(186),
      value: [_ctx.getMetadataValue(186)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-187",
      labels: _ctx.getMetadataLabels(187),
      value: [_ctx.getMetadataValue(187)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-188",
      labels: _ctx.getMetadataLabels(188),
      value: [_ctx.getMetadataValue(188)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-189",
      labels: _ctx.getMetadataLabels(189),
      value: [_ctx.getMetadataValue(189)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-190",
      labels: _ctx.getMetadataLabels(190),
      value: [_ctx.getMetadataValue(190)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-191",
      labels: _ctx.getMetadataLabels(191),
      value: [_ctx.getMetadataValue(191)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-192",
      labels: _ctx.getMetadataLabels(192),
      value: [_ctx.getMetadataValue(192)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-193",
      labels: _ctx.getMetadataLabels(193),
      value: [_ctx.getMetadataValue(193)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-194",
      labels: _ctx.getMetadataLabels(194),
      value: [_ctx.getMetadataValue(194)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-195",
      labels: _ctx.getMetadataLabels(195),
      value: [_ctx.getMetadataValue(195)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-196",
      labels: _ctx.getMetadataLabels(196),
      value: [_ctx.getMetadataValue(196)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-197",
      labels: _ctx.getMetadataLabels(197),
      value: [_ctx.getMetadataValue(197)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-198",
      labels: _ctx.getMetadataLabels(198),
      value: [_ctx.getMetadataValue(198)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-199",
      labels: _ctx.getMetadataLabels(199),
      value: [_ctx.getMetadataValue(199)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-200",
      labels: _ctx.getMetadataLabels(200),
      value: [_ctx.getMetadataValue(200)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-201",
      labels: _ctx.getMetadataLabels(201),
      value: [_ctx.getMetadataValue(201)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-202",
      labels: _ctx.getMetadataLabels(202),
      value: [_ctx.getMetadataValue(202)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-203",
      labels: _ctx.getMetadataLabels(203),
      value: [_ctx.getMetadataValue(203)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-204",
      labels: _ctx.getMetadataLabels(204),
      value: [_ctx.getMetadataValue(204)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-205",
      labels: _ctx.getMetadataLabels(205),
      value: [_ctx.getMetadataValue(205)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-206",
      labels: _ctx.getMetadataLabels(206),
      value: [_ctx.getMetadataValue(206)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-207",
      labels: _ctx.getMetadataLabels(207),
      value: [_ctx.getMetadataValue(207)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-208",
      labels: _ctx.getMetadataLabels(208),
      value: [_ctx.getMetadataValue(208)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-209",
      labels: _ctx.getMetadataLabels(209),
      value: [_ctx.getMetadataValue(209)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-210",
      labels: _ctx.getMetadataLabels(210),
      value: [_ctx.getMetadataValue(210)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-211",
      labels: _ctx.getMetadataLabels(211),
      value: [_ctx.getMetadataValue(211)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-212",
      labels: _ctx.getMetadataLabels(212),
      value: [_ctx.getMetadataValue(212)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-213",
      labels: _ctx.getMetadataLabels(213),
      value: [_ctx.getMetadataValue(213)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-214",
      labels: _ctx.getMetadataLabels(214),
      value: [_ctx.getMetadataValue(214)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-215",
      labels: _ctx.getMetadataLabels(215),
      value: [_ctx.getMetadataValue(215)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-216",
      labels: _ctx.getMetadataLabels(216),
      value: [_ctx.getMetadataValue(216)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-217",
      labels: _ctx.getMetadataLabels(217),
      value: [_ctx.getMetadataValue(217)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-218",
      labels: _ctx.getMetadataLabels(218),
      value: [_ctx.getMetadataValue(218)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-219",
      labels: _ctx.getMetadataLabels(219),
      value: [_ctx.getMetadataValue(219)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-220",
      labels: _ctx.getMetadataLabels(220),
      value: [_ctx.getMetadataValue(220)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-221",
      labels: _ctx.getMetadataLabels(221),
      value: [_ctx.getMetadataValue(221)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-222",
      labels: _ctx.getMetadataLabels(222),
      value: [_ctx.getMetadataValue(222)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-223",
      labels: _ctx.getMetadataLabels(223),
      value: [_ctx.getMetadataValue(223)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-224",
      labels: _ctx.getMetadataLabels(224),
      value: [_ctx.getMetadataValue(224)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-225",
      labels: _ctx.getMetadataLabels(225),
      value: [_ctx.getMetadataValue(225)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-226",
      labels: _ctx.getMetadataLabels(226),
      value: [_ctx.getMetadataValue(226)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-227",
      labels: _ctx.getMetadataLabels(227),
      value: [_ctx.getMetadataValue(227)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-228",
      labels: _ctx.getMetadataLabels(228),
      value: [_ctx.getMetadataValue(228)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-229",
      labels: _ctx.getMetadataLabels(229),
      value: [_ctx.getMetadataValue(229)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-230",
      labels: _ctx.getMetadataLabels(230),
      value: [_ctx.getMetadataValue(230)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-231",
      labels: _ctx.getMetadataLabels(231),
      value: [_ctx.getMetadataValue(231)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-232",
      labels: _ctx.getMetadataLabels(232),
      value: [_ctx.getMetadataValue(232)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-233",
      labels: _ctx.getMetadataLabels(233),
      value: [_ctx.getMetadataValue(233)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-234",
      labels: _ctx.getMetadataLabels(234),
      value: [_ctx.getMetadataValue(234)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-235",
      labels: _ctx.getMetadataLabels(235),
      value: [_ctx.getMetadataValue(235)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-236",
      labels: _ctx.getMetadataLabels(236),
      value: [_ctx.getMetadataValue(236)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-237",
      labels: _ctx.getMetadataLabels(237),
      value: [_ctx.getMetadataValue(237)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-5",
      labels: _ctx.getMetadataLabels(5),
      value: [_ctx.getMetadataValue(5)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-319",
      labels: _ctx.getMetadataLabels(319),
      value: [_ctx.getMetadataValue(319)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-15",
      labels: _ctx.getMetadataLabels(15),
      value: [_ctx.getMetadataValue(15)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-327",
      labels: _ctx.getMetadataLabels(327),
      value: [_ctx.getMetadataValue(327)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-10001",
      labels: _ctx.getMetadataLabels(10001),
      value: [_ctx.getMetadataValue(10001)],
      canCopyClipboard: "true"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-89",
      labels: _ctx.getMetadataLabels(89),
      value: [_ctx.getMetadataValue(89)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-421",
      labels: _ctx.getMetadataLabels(421),
      value: [_ctx.getMetadataValue(421)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-425",
      labels: _ctx.getMetadataLabels(425),
      value: [_ctx.getMetadataValue(425)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-447",
      labels: _ctx.getMetadataLabels(447),
      value: [_ctx.getMetadataValue(447)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-92",
      labels: _ctx.getMetadataLabels(92),
      value: [_ctx.getMetadataValue(92)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-422",
      labels: _ctx.getMetadataLabels(422),
      value: [_ctx.getMetadataValue(422)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-423",
      labels: _ctx.getMetadataLabels(423),
      value: [_ctx.getMetadataValue(423)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-81",
      labels: _ctx.getMetadataLabels(81),
      value: [_ctx.getMetadataValue(81)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-82",
      labels: _ctx.getMetadataLabels(82),
      value: [_ctx.getMetadataValue(82)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-83",
      labels: _ctx.getMetadataLabels(83),
      value: [_ctx.getMetadataValue(83)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-84",
      labels: _ctx.getMetadataLabels(84),
      value: [_ctx.getMetadataValue(84)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-85",
      labels: _ctx.getMetadataLabels(85),
      value: [_ctx.getMetadataValue(85)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-86",
      labels: _ctx.getMetadataLabels(86),
      value: [_ctx.getMetadataValue(86)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-87",
      labels: _ctx.getMetadataLabels(87),
      value: [_ctx.getMetadataValue(87)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-88",
      labels: _ctx.getMetadataLabels(88),
      value: [_ctx.getMetadataValue(88)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-90",
      labels: _ctx.getMetadataLabels(90),
      value: [_ctx.getMetadataValue(90)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-91",
      labels: _ctx.getMetadataLabels(91),
      value: [_ctx.getMetadataValue(91)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-93",
      labels: _ctx.getMetadataLabels(93),
      value: [_ctx.getMetadataValue(93)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-116",
      labels: _ctx.getMetadataLabels(116),
      value: [_ctx.getMetadataValue(116)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-80",
      labels: _ctx.getMetadataLabels(80),
      value: [_ctx.getMetadataValue(80)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-98",
      labels: _ctx.getMetadataLabels(98),
      value: [_ctx.getMetadataValue(98)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-94",
      labels: _ctx.getMetadataLabels(94),
      value: [_ctx.getMetadataValue(94)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-348",
      labels: _ctx.getMetadataLabels(348),
      value: [_ctx.getMetadataValue(348)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-95",
      labels: _ctx.getMetadataLabels(95),
      value: [_ctx.getMetadataValue(95)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-96",
      labels: _ctx.getMetadataLabels(96),
      value: [_ctx.getMetadataValue(96)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-97",
      labels: _ctx.getMetadataLabels(97),
      value: [_ctx.getMetadataValue(97)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-100",
      labels: _ctx.getMetadataLabels(100),
      value: [_ctx.getMetadataValue(100)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-101",
      labels: _ctx.getMetadataLabels(101),
      value: [_ctx.getMetadataValue(101)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-102",
      labels: _ctx.getMetadataLabels(102),
      value: [_ctx.getMetadataValue(102)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-103",
      labels: _ctx.getMetadataLabels(103),
      value: [_ctx.getMetadataValue(103)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-104",
      labels: _ctx.getMetadataLabels(104),
      value: [_ctx.getMetadataValue(104)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-105",
      labels: _ctx.getMetadataLabels(105),
      value: [_ctx.getMetadataValue(105)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-106",
      labels: _ctx.getMetadataLabels(106),
      value: [_ctx.getMetadataValue(106)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-107",
      labels: _ctx.getMetadataLabels(107),
      value: [_ctx.getMetadataValue(107)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-108",
      labels: _ctx.getMetadataLabels(108),
      value: [_ctx.getMetadataValue(108)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-109",
      labels: _ctx.getMetadataLabels(109),
      value: [_ctx.getMetadataValue(109)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-110",
      labels: _ctx.getMetadataLabels(110),
      value: [_ctx.getMetadataValue(110)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-111",
      labels: _ctx.getMetadataLabels(111),
      value: [_ctx.getMetadataValue(111)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-112",
      labels: _ctx.getMetadataLabels(112),
      value: [_ctx.getMetadataValue(112)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-113",
      labels: _ctx.getMetadataLabels(113),
      value: [_ctx.getMetadataValue(113)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-114",
      labels: _ctx.getMetadataLabels(114),
      value: [_ctx.getMetadataValue(114)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-438",
      labels: _ctx.getMetadataLabels(438),
      value: [_ctx.getMetadataValue(438)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-115",
      labels: _ctx.getMetadataLabels(115),
      value: [_ctx.getMetadataValue(115)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-424",
      labels: _ctx.getMetadataLabels(424),
      value: [_ctx.getMetadataValue(424)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-371",
      labels: _ctx.getMetadataLabels(371),
      value: [_ctx.getMetadataValue(371)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-350",
      labels: _ctx.getMetadataLabels(350),
      value: [_ctx.getMetadataValue(350)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-351",
      labels: _ctx.getMetadataLabels(351),
      value: [_ctx.getMetadataValue(351)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-352",
      labels: _ctx.getMetadataLabels(352),
      value: [_ctx.getMetadataValue(352)],
      type: "URI_WITH_LABEL"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-409",
      labels: _ctx.getMetadataLabels(409),
      value: [_ctx.getMetadataValue(409)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-489",
      labels: _ctx.getMetadataLabels(489),
      value: [_ctx.getMetadataValue(489)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-30",
      labels: _ctx.getMetadataLabels(30),
      value: [_ctx.getMetadataValue(30)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-334",
      labels: _ctx.getMetadataLabels(334),
      value: [_ctx.getMetadataValue(334)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-31",
      labels: _ctx.getMetadataLabels(31),
      value: [_ctx.getMetadataValue(31)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-335",
      labels: _ctx.getMetadataLabels(335),
      value: [_ctx.getMetadataValue(335)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-405",
      labels: _ctx.getMetadataLabels(405),
      value: [_ctx.getMetadataValue(405)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-406",
      labels: _ctx.getMetadataLabels(406),
      value: [_ctx.getMetadataValue(406)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-22",
      labels: _ctx.getMetadataLabels(22),
      value: [_ctx.getMetadataValue(22)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-518",
      labels: _ctx.getMetadataLabels(518),
      value: [_ctx.getMetadataValue(518)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-498",
      labels: _ctx.getMetadataLabels(498),
      value: [_ctx.getMetadataValue(498)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-332",
      labels: _ctx.getMetadataLabels(332),
      value: [_ctx.getMetadataValue(332)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-23",
      labels: _ctx.getMetadataLabels(23),
      value: [_ctx.getMetadataValue(23)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-333",
      labels: _ctx.getMetadataLabels(333),
      value: [_ctx.getMetadataValue(333)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-32",
      labels: _ctx.getMetadataLabels(32),
      value: [_ctx.getMetadataValue(32)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-336",
      labels: _ctx.getMetadataLabels(336),
      value: [_ctx.getMetadataValue(336)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-26",
      labels: _ctx.getMetadataLabels(26),
      value: [_ctx.getMetadataValue(26)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-27",
      labels: _ctx.getMetadataLabels(27),
      value: [_ctx.getMetadataValue(27)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-28",
      labels: _ctx.getMetadataLabels(28),
      value: [_ctx.getMetadataValue(28)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-487",
      labels: _ctx.getMetadataLabels(487),
      value: [_ctx.getMetadataValue(487)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-29",
      labels: _ctx.getMetadataLabels(29),
      value: [_ctx.getMetadataValue(29)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-33",
      labels: _ctx.getMetadataLabels(33),
      value: [_ctx.getMetadataValue(33)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-34",
      labels: _ctx.getMetadataLabels(34),
      value: [_ctx.getMetadataValue(34)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-35",
      labels: _ctx.getMetadataLabels(35),
      value: [_ctx.getMetadataValue(35)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-36",
      labels: _ctx.getMetadataLabels(36),
      value: [_ctx.getMetadataValue(36)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-37",
      labels: _ctx.getMetadataLabels(37),
      value: [_ctx.getMetadataValue(37)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-38",
      labels: _ctx.getMetadataLabels(38),
      value: [_ctx.getMetadataValue(38)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-39",
      labels: _ctx.getMetadataLabels(39),
      value: [_ctx.getMetadataValue(39)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-24",
      labels: _ctx.getMetadataLabels(24),
      value: [_ctx.getMetadataValue(24)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-25",
      labels: _ctx.getMetadataLabels(25),
      value: [_ctx.getMetadataValue(25)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-64",
      labels: _ctx.getMetadataLabels(64),
      value: [_ctx.getMetadataValue(64)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-65",
      labels: _ctx.getMetadataLabels(65),
      value: [_ctx.getMetadataValue(65)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-66",
      labels: _ctx.getMetadataLabels(66),
      value: [_ctx.getMetadataValue(66)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-67",
      labels: _ctx.getMetadataLabels(67),
      value: [_ctx.getMetadataValue(67)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-68",
      labels: _ctx.getMetadataLabels(68),
      value: [_ctx.getMetadataValue(68)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-69",
      labels: _ctx.getMetadataLabels(69),
      value: [_ctx.getMetadataValue(69)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-70",
      labels: _ctx.getMetadataLabels(70),
      value: [_ctx.getMetadataValue(70)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-71",
      labels: _ctx.getMetadataLabels(71),
      value: [_ctx.getMetadataValue(71)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-265",
      labels: _ctx.getMetadataLabels(265),
      value: [_ctx.getMetadataValue(265)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-277",
      labels: _ctx.getMetadataLabels(277),
      value: [_ctx.getMetadataValue(277)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-75",
      labels: _ctx.getMetadataLabels(75),
      value: [_ctx.getMetadataValue(75)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-76",
      labels: _ctx.getMetadataLabels(76),
      value: [_ctx.getMetadataValue(76)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-77",
      labels: _ctx.getMetadataLabels(77),
      value: [_ctx.getMetadataValue(77)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-79",
      labels: _ctx.getMetadataLabels(79),
      value: [_ctx.getMetadataValue(79)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-379",
      labels: _ctx.getMetadataLabels(379),
      value: [_ctx.getMetadataValue(379)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-380",
      labels: _ctx.getMetadataLabels(380),
      value: [_ctx.getMetadataValue(380)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-381",
      labels: _ctx.getMetadataLabels(381),
      value: [_ctx.getMetadataValue(381)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-382",
      labels: _ctx.getMetadataLabels(382),
      value: [_ctx.getMetadataValue(382)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-383",
      labels: _ctx.getMetadataLabels(383),
      value: [_ctx.getMetadataValue(383)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-384",
      labels: _ctx.getMetadataLabels(384),
      value: [_ctx.getMetadataValue(384)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-387",
      labels: _ctx.getMetadataLabels(387),
      value: [_ctx.getMetadataValue(387)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-370",
      labels: _ctx.getMetadataLabels(370),
      value: [_ctx.getMetadataValue(370)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-collections",
      labels: _ctx.getMetadataLabels(373),
      value: [_ctx.itemListViewCollection]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-43",
      labels: _ctx.getMetadataLabels(43),
      value: [_ctx.getMetadataValue(43)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-44",
      labels: _ctx.getMetadataLabels(44),
      value: [_ctx.getMetadataValue(44)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-45",
      labels: _ctx.getMetadataLabels(45),
      value: [_ctx.getMetadataValue(45)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-46",
      labels: _ctx.getMetadataLabels(46),
      value: [_ctx.getMetadataValue(46)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-47",
      labels: _ctx.getMetadataLabels(47),
      value: [_ctx.getMetadataValue(47)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-488",
      labels: _ctx.getMetadataLabels(488),
      value: [_ctx.getMetadataValue(488)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-520",
      labels: _ctx.getMetadataLabels(520),
      value: [_ctx.getMetadataValue(520)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-48",
      labels: _ctx.getMetadataLabels(48),
      value: [_ctx.getMetadataValue(48)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-49",
      labels: _ctx.getMetadataLabels(49),
      value: [_ctx.getMetadataValue(49)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-50",
      labels: _ctx.getMetadataLabels(50),
      value: [_ctx.getMetadataValue(50)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-51",
      labels: _ctx.getMetadataLabels(51),
      value: [_ctx.getMetadataValue(51)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-52",
      labels: _ctx.getMetadataLabels(52),
      value: [_ctx.getMetadataValue(52)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-53",
      labels: _ctx.getMetadataLabels(53),
      value: [_ctx.getMetadataValue(53)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-54",
      labels: _ctx.getMetadataLabels(54),
      value: [_ctx.getMetadataValue(54)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-55",
      labels: _ctx.getMetadataLabels(55),
      value: [_ctx.getMetadataValue(55)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-56",
      labels: _ctx.getMetadataLabels(56),
      value: [_ctx.getMetadataValue(56)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-57",
      labels: _ctx.getMetadataLabels(57),
      value: [_ctx.getMetadataValue(57)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-60",
      labels: _ctx.getMetadataLabels(60),
      value: [_ctx.getMetadataValue(60)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-61",
      labels: _ctx.getMetadataLabels(61),
      value: [_ctx.getMetadataValue(61)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-62",
      labels: _ctx.getMetadataLabels(62),
      value: [_ctx.getMetadataValue(62)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-63",
      labels: _ctx.getMetadataLabels(63),
      value: [_ctx.getMetadataValue(63)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-240",
      labels: _ctx.getMetadataLabels(240),
      value: [_ctx.getMetadataValue(240)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-241",
      labels: _ctx.getMetadataLabels(241),
      value: [_ctx.getMetadataValue(241)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-242",
      labels: _ctx.getMetadataLabels(242),
      value: [_ctx.getMetadataValue(242)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-243",
      labels: _ctx.getMetadataLabels(243),
      value: [_ctx.getMetadataValue(243)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-244",
      labels: _ctx.getMetadataLabels(244),
      value: [_ctx.getMetadataValue(244)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-245",
      labels: _ctx.getMetadataLabels(245),
      value: [_ctx.getMetadataValue(245)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-246",
      labels: _ctx.getMetadataLabels(246),
      value: [_ctx.getMetadataValue(246)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-247",
      labels: _ctx.getMetadataLabels(247),
      value: [_ctx.getMetadataValue(247)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-248",
      labels: _ctx.getMetadataLabels(248),
      value: [_ctx.getMetadataValue(248)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-249",
      labels: _ctx.getMetadataLabels(249),
      value: [_ctx.getMetadataValue(249)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-250",
      labels: _ctx.getMetadataLabels(250),
      value: [_ctx.getMetadataValue(250)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-251",
      labels: _ctx.getMetadataLabels(251),
      value: [_ctx.getMetadataValue(251)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-252",
      labels: _ctx.getMetadataLabels(252),
      value: [_ctx.getMetadataValue(252)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-253",
      labels: _ctx.getMetadataLabels(253),
      value: [_ctx.getMetadataValue(253)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-254",
      labels: _ctx.getMetadataLabels(254),
      value: [_ctx.getMetadataValue(254)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-255",
      labels: _ctx.getMetadataLabels(255),
      value: [_ctx.getMetadataValue(255)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-256",
      labels: _ctx.getMetadataLabels(256),
      value: [_ctx.getMetadataValue(256)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-257",
      labels: _ctx.getMetadataLabels(257),
      value: [_ctx.getMetadataValue(257)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-258",
      labels: _ctx.getMetadataLabels(258),
      value: [_ctx.getMetadataValue(258)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-259",
      labels: _ctx.getMetadataLabels(259),
      value: [_ctx.getMetadataValue(259)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-260",
      labels: _ctx.getMetadataLabels(260),
      value: [_ctx.getMetadataValue(260)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-261",
      labels: _ctx.getMetadataLabels(261),
      value: [_ctx.getMetadataValue(261)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-262",
      labels: _ctx.getMetadataLabels(262),
      value: [_ctx.getMetadataValue(262)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-263",
      labels: _ctx.getMetadataLabels(263),
      value: [_ctx.getMetadataValue(263)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-282",
      labels: _ctx.getMetadataLabels(282),
      value: [_ctx.getMetadataValue(282)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-283",
      labels: _ctx.getMetadataLabels(283),
      value: [_ctx.getMetadataValue(283)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-284",
      labels: _ctx.getMetadataLabels(284),
      value: [_ctx.getMetadataValue(284)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-285",
      labels: _ctx.getMetadataLabels(285),
      value: [_ctx.getMetadataValue(285)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-286",
      labels: _ctx.getMetadataLabels(286),
      value: [_ctx.getMetadataValue(286)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-287",
      labels: _ctx.getMetadataLabels(287),
      value: [_ctx.getMetadataValue(287)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-288",
      labels: _ctx.getMetadataLabels(288),
      value: [_ctx.getMetadataValue(288)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-289",
      labels: _ctx.getMetadataLabels(289),
      value: [_ctx.getMetadataValue(289)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-290",
      labels: _ctx.getMetadataLabels(290),
      value: [_ctx.getMetadataValue(290)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-291",
      labels: _ctx.getMetadataLabels(291),
      value: [_ctx.getMetadataValue(291)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-292",
      labels: _ctx.getMetadataLabels(292),
      value: [_ctx.getMetadataValue(292)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-293",
      labels: _ctx.getMetadataLabels(293),
      value: [_ctx.getMetadataValue(293)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-294",
      labels: _ctx.getMetadataLabels(294),
      value: [_ctx.getMetadataValue(294)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-295",
      labels: _ctx.getMetadataLabels(295),
      value: [_ctx.getMetadataValue(295)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-296",
      labels: _ctx.getMetadataLabels(296),
      value: [_ctx.getMetadataValue(296)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-297",
      labels: _ctx.getMetadataLabels(297),
      value: [_ctx.getMetadataValue(297)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-298",
      labels: _ctx.getMetadataLabels(298),
      value: [_ctx.getMetadataValue(298)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-299",
      labels: _ctx.getMetadataLabels(299),
      value: [_ctx.getMetadataValue(299)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-304",
      labels: _ctx.getMetadataLabels(304),
      value: [_ctx.getMetadataValue(304)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-306",
      labels: _ctx.getMetadataLabels(306),
      value: [_ctx.getMetadataValue(306)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-307",
      labels: _ctx.getMetadataLabels(307),
      value: [_ctx.getMetadataValue(307)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-308",
      labels: _ctx.getMetadataLabels(308),
      value: [_ctx.getMetadataValue(308)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-309",
      labels: _ctx.getMetadataLabels(309),
      value: [_ctx.getMetadataValue(309)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-310",
      labels: _ctx.getMetadataLabels(310),
      value: [_ctx.getMetadataValue(310)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-338",
      labels: _ctx.getMetadataLabels(338),
      value: [_ctx.getMetadataValue(338)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-340",
      labels: _ctx.getMetadataLabels(340),
      value: [_ctx.getMetadataValue(340)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-274",
      labels: _ctx.getMetadataLabels(274),
      value: [_ctx.getMetadataValue(274)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-275",
      labels: _ctx.getMetadataLabels(275),
      value: [_ctx.getMetadataValue(275)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-276",
      labels: _ctx.getMetadataLabels(276),
      value: [_ctx.getMetadataValue(276)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-360",
      labels: _ctx.getMetadataLabels(360),
      value: [_ctx.getMetadataValue(360)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-278",
      labels: _ctx.getMetadataLabels(278),
      value: [_ctx.getMetadataValue(278)],
      type: "ACCESS_RIGHTS"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-410",
      labels: _ctx.getMetadataLabels(410),
      value: [_ctx.getMetadataValue(410)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-281",
      labels: _ctx.getMetadataLabels(281),
      value: [_ctx.getMetadataValue(281)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-279",
      labels: _ctx.getMetadataLabels(279),
      value: [_ctx.getMetadataValue(279)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-280",
      labels: _ctx.getMetadataLabels(280),
      value: [_ctx.getMetadataValue(280)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-361",
      labels: _ctx.getMetadataLabels(361),
      value: [_ctx.getMetadataValue(361)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-353",
      labels: _ctx.getMetadataLabels(353),
      value: [_ctx.getMetadataValue(353)],
      type: "URI"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-354",
      labels: _ctx.getMetadataLabels(354),
      value: [_ctx.getMetadataValue(354)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-355",
      labels: _ctx.getMetadataLabels(355),
      value: [_ctx.getMetadataValue(355)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-356",
      labels: _ctx.getMetadataLabels(356),
      value: [_ctx.getMetadataValue(356)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-392",
      labels: _ctx.getMetadataLabels(392),
      value: [_ctx.getMetadataValue(392)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-393",
      labels: _ctx.getMetadataLabels(393),
      value: [_ctx.getMetadataValue(393)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-395",
      labels: _ctx.getMetadataLabels(395),
      value: [_ctx.getMetadataValue(395)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-396",
      labels: _ctx.getMetadataLabels(396),
      value: [_ctx.getMetadataValue(396)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-397",
      labels: _ctx.getMetadataLabels(397),
      value: [_ctx.getMetadataValue(397)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-398",
      labels: _ctx.getMetadataLabels(398),
      value: [_ctx.getMetadataValue(398)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-411",
      labels: _ctx.getMetadataLabels(411),
      value: [_ctx.getMetadataValue(411)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-399",
      labels: _ctx.getMetadataLabels(399),
      value: [_ctx.getMetadataValue(399)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-400",
      labels: _ctx.getMetadataLabels(400),
      value: [_ctx.getMetadataValue(400)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-415",
      labels: _ctx.getMetadataLabels(415),
      value: [_ctx.getMetadataValue(415)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-416",
      labels: _ctx.getMetadataLabels(416),
      value: [_ctx.getMetadataValue(416)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-417",
      labels: _ctx.getMetadataLabels(417),
      value: [_ctx.getMetadataValue(417)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-42",
      labels: _ctx.getMetadataLabels(42),
      value: [_ctx.getMetadataValue(42)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-41",
      labels: _ctx.getMetadataLabels(41),
      value: [_ctx.getMetadataValue(41)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-491",
      labels: _ctx.getMetadataLabels(491),
      value: [_ctx.getMetadataValue(491)],
      type: "IIIF"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-provider",
      labels: _ctx.getMetadataLabels(360),
      value: [_ctx.provider]
    }, null, 8, ["labels", "value"])
  ], 2))
}