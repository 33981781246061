import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2337d501"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationToast = _resolveComponent("NotificationToast")!

  return (_ctx.messages.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["the-notification-area toast", { sticky: _ctx.sticky }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
            return (_openBlock(), _createBlock(_component_NotificationToast, {
              key: message.pid,
              pid: message.pid,
              bid: message.bid,
              cid: message.cid,
              type: message.type,
              id: message.id,
              text: _ctx.$t(`mycollection.${message.text}`),
              autoRemove: 2000,
              onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove', $event)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(message), 1)
              ]),
              _: 2
            }, 1032, ["pid", "bid", "cid", "type", "id", "text"]))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}