
import { defineComponent, defineAsyncComponent, computed, onMounted, watchEffect, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useHead } from '@vueuse/head'
import { MUTATIONS } from '@/store/mutations-types'
import TheGlobalHeader from '@/components/organisms/TheGlobalHeader.vue'
import TheGlobalFooter from '@/components/organisms/TheGlobalFooter.vue'
import TheKnFooter from '@/components/organisms/kn/TheKnFooter.vue'
import TheNotificationArea from '@/components/organisms/TheNotificationArea.vue'
import { useI18n } from 'vue-i18n'
import TheNotificationBottomArea from '@/components/organisms/TheNotificationBottomArea.vue'
import TheGlobalPrivacyNotification from '@/components/ios_depend/TheGlobalPrivacyNotification.vue'
import DmsLoading from '@/components/organisms/dms/common/DmsLoading.vue'
import { downloadContents } from '@/domain/item/itemViewer/contentsDownload'
import AppButton from '@/components/atoms/AppButton.vue'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import TheLoginForm from '@/components/organisms/TheLoginForm.vue'
import { DownloadType } from './data/@types/DownloadType'
import SessionExpiredModalWindow from '@/components/organisms/SessionExpiredModalWindow.vue'

const AsyncTheDmsHeader = defineAsyncComponent({
  loader: () => {
    return new Promise<any>(resolve => {
      setTimeout(() => {
        resolve(import('@/components/organisms/dms/common/TheDmsHeader.vue' /* webpackChunkName: "dms-header" */))
      }, 50)
    })
  },
})

export default defineComponent({
  components: {
    TheGlobalHeader,
    TheGlobalFooter,
    TheKnFooter,
    TheNotificationArea,
    TheNotificationBottomArea,
    TheGlobalPrivacyNotification,
    DmsLoading,
    AsyncTheDmsHeader,
    TheLoginForm,
    AppButton,
    ModalWindow,
    SessionExpiredModalWindow,
  },
  setup () {
    const store = useStore()

    onMounted(async () => {
      store.dispatch('setLocation')
      handleIsLoggedIn()
    })

    // ログイン状態に応じて、マイコレクションのコレクションリストとパーソナライズデータを取得
    const isLoginInitialized = computed(() => store.getters.isLoginInitialized)
    const handleIsLoggedIn = () => {
      if (isLoginInitialized.value) {
        store.dispatch('myCollectionCollectionList')
        store.dispatch('fetchPersonalizeData')
      }
    }
    // 監視して値が変わった時に実行
    watch(isLoginInitialized, handleIsLoggedIn)

    const route = useRoute()
    const path = computed(() => route.path)
    const isServer = typeof window === 'undefined'
    const i18n = useI18n()
    const isDms = computed(() => path.value.includes('/dms/'))
    watch(() => route.meta.isIframe, () => {
      if (route.meta.isIframe) {
        store.commit('ENABLE_IFRAME_VIEWER')
      }
    })
    const isIframePage = computed(() => store.getters.isIframe)

    watchEffect(() => {
      if (route.meta.lang) {
        i18n.locale.value = (route.meta.lang as string)
      }
      store.commit('SET_LANG', i18n.locale.value)
      if (!isServer && !document.getElementsByTagName('html')[0].lang) {
        // 初期画面表示時にlangを設定
        document.getElementsByTagName('html')[0].lang = i18n.locale.value === 'ja' ? 'ja-JP' : 'en-US'
      } else {
        document.documentElement.lang = i18n.locale.value === 'ja' ? 'ja-JP' : 'en-US'
      }
    })

    // ページタイトルの設定
    const pageTitle = ref<string>('')
    watchEffect(() => {
      const lang = i18n.locale.value as 'ja' | 'en'
      const title = (() => {
        if (route.meta.pageTitle) {
          return (route.meta.pageTitle as { ja: string; en: string })[lang]
        } else {
          return ''
        }
      })()
      const titleTag = document.querySelector('head title')
      if (titleTag) {
        pageTitle.value = title
        titleTag.innerHTML = pageTitle.value
      }
    })

    const isRekion = computed(() => route.meta.isRekion)
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const isTopPage = computed(() => {
      return route.name === 'TopPage' || route.name === 'RekionTopPage'
    })
    const headerType = computed(() =>
      isTopPage.value ? i18n.t('header.logoType') : i18n.t('header.underLayerLogo')
    )

    const notificationMessages = computed(
      () => store.getters.notificationMessages
    )
    const informationMessages = computed(
      () => store.state.notificationMessages
    )
    const notificationToasts = computed(() => store.getters.notificationToasts)

    const downloadNotificationToasts = computed(() => store.getters.downloadNotificationToasts)

    const removeNotificationMessage = (id: string) => {
      store.commit(MUTATIONS.DELETE_NOTIFICATION, id)
    }
    const deleteNotificationToast = (id: string) => {
      store.dispatch('myCollectionDeleteMessage', id)
    }
    const removeDownloadNotificationToast = () => {
      store.commit('UPDATE_DOWNLOAD_STATUS', { status: '' })
    }
    const retryDownload = (type: string) => {
      const previousRequest = store.getters.previousRequest
      const requestItemTitle = store.getters.requestItemTitle
      const downloadType = type === 'image_download_error' ? DownloadType.IMAGE : DownloadType.PICTURE
      store.commit('UPDATE_DOWNLOAD_STATUS', { status: '' })
      downloadContents(store, previousRequest, requestItemTitle, i18n, downloadType)
    }
    const cancelDownload = () => {
      store.dispatch('cancelDownload')
    }
    document.addEventListener('fullscreenchange', () => {
      const itemViewerRef = document.getElementById('full-screen-div')
      if (!itemViewerRef) {
        return
      }
      store.commit('toggleFullScreen')
      if (document.fullscreenElement) {
        itemViewerRef.setAttribute('full-screen', 'full-screen')
      } else {
        itemViewerRef.removeAttribute('full-screen')
      }
    })

    if (!isServer) {
      const setViewPort = () => {
        const viewPortMeta = document.querySelector('meta[name="viewport"]') as Element
        if (window.outerWidth >= 768) {
          viewPortMeta.setAttribute('content', 'width=1120,viewport-fit=cover')
        } else {
          viewPortMeta.setAttribute('content', 'width=device-width,viewport-fit=cover')
        }
      }
      setViewPort()

      window.addEventListener('orientationchange', () => {
        setViewPort()
        // iOS用のディレイ処理。300は体感値。
        setTimeout(() => {
          setViewPort()
        }, 300)
      })
    }

    const masterDispatchs = [
      store.dispatch('fetchMetadataMaster'),
      store.dispatch('fetchCollectionMasters'),
    ]
    Promise.all(masterDispatchs).then(() => {
      store.commit('COMPLETE_FETCH_MASTER')
    })

    //  アイテム詳細画面で取得していないマスター情報を取得する
    watchEffect(() => {
      const pageName = route.name
      if (!pageName) return

      const meta = route.meta || false
      const params = route.params || false

      store.commit('SET_OGP_INITIALIZE', {
        routeName: pageName,
        meta,
        params,
      })
    })

    const isMasterFetched = computed(() => store.getters.IsMasterFetched)

    const notificationNotLoggedIn = computed(() => store.getters.notificationNotLoggedIn)
    const onCloseNotificationNotLoggedIn = () => {
      store.commit('NotLoggedIn', false)
    }

    useHead({
      title: computed(() => {
        // コレクションマスタの取得を検知させるため
        if (isMasterFetched.value) {
          return store.getters.OgpTitle
        } else return store.getters.OgpTitle
      }),
      link: computed(() => {
        // ファビコンの表示を切り替えるため
        if (isKn) {
          return store.getters.linkListKn
        } else {
          return store.getters.linkListDc
        }
      }),
      meta: computed(() => {
        // コレクションマスタの取得を検知させるため
        if (isMasterFetched.value) {
          return store.getters.metaList
        } else return store.getters.metaList
      }),
    })

    return {
      isRekion,
      isKn,
      isIframePage,
      isTopPage,
      headerType,
      pageTitle,
      informationMessages,
      isMasterFetched,
      notificationMessages,
      notificationToasts,
      isDms,
      deleteNotificationToast,
      removeNotificationMessage,
      downloadNotificationToasts,
      removeDownloadNotificationToast,
      retryDownload,
      cancelDownload,
      notificationNotLoggedIn,
      onCloseNotificationNotLoggedIn,
    }
  },
})
