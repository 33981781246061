import { PhysicalPosition } from './ImageViewerCommon'

/**
 * 慣性コントロールクラス
 */
class InertiaControll {
    /**
     * 摩擦の強さ 慣性の弱さ
     */
    private friction_ = 1.5
    public get friction (): number {
      return this.friction_
    }

    public set friction (value: number) {
      this.friction_ = value
    }

    private currentPower_ = 1.0

    private adjustment_ = 1

    private movementStrength_ = new PhysicalPosition()
    public setMovementStrength (value: PhysicalPosition, adjustment: number): void {
      this.movementStrength_.x = value.x
      this.movementStrength_.y = value.y
      this.adjustment_ = adjustment
    }

    private stopThreshould_ = 1

    private moveAmount_ = new PhysicalPosition()

    private prevTime_ = 0

    private updateFunc_?: () => void = undefined

    private isRunning_ = false
    public get isRunning (): boolean {
      return this.isRunning_
    }

    /**
     * 慣性スクロール発生時に実行する関数
     *
     * @param movement: 移動量 movement.x, movement.y が共に0の場合、スクロール完了とする
     * @returns: false を返すとスクロールを止める
     **/
    private callbackFunction_?: (movement: PhysicalPosition) => boolean
    public setCallbackFuntion (func: (movement: PhysicalPosition) => boolean): void {
      this.callbackFunction_ = func
    }

    public constructor () {
      this.updateFunc_ = () => { this.update() }
    }

    private update (): void {
      if (!this.isRunning_) {
        return
      }

      const now = Date.now()
      const deltaTime = (now - this.prevTime_) / 1000
      this.prevTime_ = now

      this.currentPower_ -= this.currentPower_ * (deltaTime * this.friction_)
      const p = Math.asin(this.currentPower_)

      if (this.currentPower_ <= 0) {
        this.isRunning_ = false
      }

      if (this.isRunning_) {
        this.moveAmount_.x = this.movementStrength_.x * p / this.adjustment_
        this.moveAmount_.y = this.movementStrength_.y * p / this.adjustment_

        if (Math.abs(this.moveAmount_.x) > Math.abs(this.moveAmount_.y)) {
          if (Math.abs(this.moveAmount_.x) <= this.stopThreshould_ / this.adjustment_) {
            this.isRunning_ = false
          }
        } else {
          if (Math.abs(this.moveAmount_.y) <= this.stopThreshould_ / this.adjustment_) {
            this.isRunning_ = false
          }
        }

        if (this.callbackFunction_) {
          if (!this.callbackFunction_(this.moveAmount_)) {
            this.isRunning_ = false
          }
        }
      }

      if (!this.isRunning_) {
        this.moveAmount_.x = 0
        this.moveAmount_.y = 0
        if (this.callbackFunction_) this.callbackFunction_(this.moveAmount_)
        return
      }

      if (this.updateFunc_) {
        requestAnimationFrame(this.updateFunc_)
      }
    }

    public begin (): void {
      this.isRunning_ = true
      this.currentPower_ = 1.0

      this.prevTime_ = Date.now()

      if (this.updateFunc_) {
        requestAnimationFrame(this.updateFunc_)
      }
    }

    public stop (): void {
      this.isRunning_ = false
    }
}

export { InertiaControll }
