import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d17d9bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image-viewer-controller-auto-crop"
}
const _hoisted_2 = { class: "level-left is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToggleSwitch = _resolveComponent("AppToggleSwitch")!

  return (_ctx.hasClippingData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppToggleSwitch, {
            modelValue: _ctx.autoClipFlag,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autoClipFlag) = $event)),
            id: "auto-clip-toggle-switch",
            "aria-labelledby": "image-viewer-controller-auto-crop-label"
          }, null, 8, ["modelValue"]),
          _createElementVNode("span", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.autoClipFlag = !_ctx.autoClipFlag)),
            onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.autoClipFlag = !_ctx.autoClipFlag), ["prevent"]), ["space","enter"])),
            tabindex: "-1",
            role: "button",
            id: "image-viewer-controller-auto-crop-label"
          }, _toDisplayString(_ctx.$t('rightPane.autoCutWhite')), 33)
        ])
      ]))
    : _createCommentVNode("", true)
}