import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b41dcc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "viewer-poster" }
const _hoisted_2 = { class: "level-center is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheImageViewer = _resolveComponent("TheImageViewer")!
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("figure", _hoisted_2, [
      (_ctx.currentBundleType === 'image')
        ? (_openBlock(), _createBlock(_component_TheImageViewer, {
            key: _ctx.item.pid + _ctx.bundleIndex,
            item: _ctx.item,
            ischangedToViewerPoster: _ctx.ischangedToViewerPoster
          }, null, 8, ["item", "ischangedToViewerPoster"]))
        : (_openBlock(), _createBlock(_component_PlaceholderImage, {
            key: 1,
            type: _ctx.placeholder
          }, null, 8, ["type"]))
    ])
  ]))
}