import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncAppImage = _resolveComponent("AsyncAppImage")!

  return (_openBlock(), _createBlock(_component_AsyncAppImage, {
    src: _ctx.src,
    alt: _ctx.alt,
    type: _ctx.type
  }, null, 8, ["src", "alt", "type"]))
}