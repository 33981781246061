import { Content } from '@/data/@types/Content'
import { generateKomaJsonPath } from '@/domain/item/itemViewer/publicPath'
import { AccessToken } from '@/store/modules/AccessToken'
import { getClippingData } from '@/domain/item/itemViewer/imageViewerUtil'
import { Page } from '@/data/@types/Page'
import { PageObject } from '@/data/@types/PageObject'

interface Pair {
  KomaPair: Array<number>
}

const CONTENT_BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

const GenerateContentJson = (contents: Array<Content>, bindingDirection: 'ltr' | 'rtl', tokens: AccessToken[], viewMode: string, pages: PageObject, isMobile: string | null) => {
  const data: any = {}
  data.DRM = false
  // ページめくり方向の情報がメタに含まれていない場合の初期値は'rtl'
  data.BindingDirection = bindingDirection || 'rtl'

  const komas = contents.map((content, i) => {
    const ClippingData = getClippingData(content)
    const Token = tokens.find((token) => token.cid === content.id)
    if (Token) {
      return {
        KomaID: i,
        Src: CONTENT_BASE_URL + generateKomaJsonPath(content.publicPath),
        Token,
        Clipping: ClippingData,
      }
    } else {
      // TODO 要仕様調整 - no image -
      return {
        KomaID: i,
        Src: `${CONTENT_BASE_URL}contents/komainfo.json`,
        Token: 'dummy',
        Clipping: ClippingData,
      }
    }
  })
  data.Komas = komas

  data.PagesLTR = pages.pagesDataLTR
  data.PagesRTL = pages.pagesDataRTL

  return data
}

/**
 * コマ画像取得失敗時の代替コマ画像の情報を返す
 * @returns
 */
const getDummyKoma = () => {
  return {
    Src: `${CONTENT_BASE_URL}contents/komainfo.json`,
    Token: 'dummy',
    Clipping: { Left: 0, Top: 0, Right: 1.0, Bottom: 1.0 },
  }
}

export { GenerateContentJson, getDummyKoma }
