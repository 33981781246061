
import { defineComponent } from 'vue'
import DssIcon from '../atoms/DssIcon.vue'

export default defineComponent({
  components: {
    DssIcon,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    icon: {
      type: String,
      required: false,
    },
    packing: {
      type: Boolean,
      default: false,
    },
  },
})
