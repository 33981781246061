import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-367f501c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "package-modal-header",
  "data-cy": "package-modal"
}
const _hoisted_2 = { class: "package-modal-breadcrumbs" }
const _hoisted_3 = { class: "package-modal-section" }
const _hoisted_4 = { class: "package-modal-section-part" }
const _hoisted_5 = { class: "package-modal-radio-package-env-section" }
const _hoisted_6 = { class: "title has-text-weight-normal package-modal-section-part" }
const _hoisted_7 = {
  key: 0,
  class: "package-modal-section-part"
}
const _hoisted_8 = { class: "package-bundle-message" }
const _hoisted_9 = { class: "package-modal-footer level-right is-mobile" }
const _hoisted_10 = { class: "open-package-streaming-url" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_RadioButtonList = _resolveComponent("RadioButtonList")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_IconLoading = _resolveComponent("IconLoading")!
  const _component_TextAlert = _resolveComponent("TextAlert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_ctx.packageTitle), 1),
        (_ctx.currentBundle?.name.ja)
          ? (_openBlock(), _createBlock(_component_DssIcon, {
              key: 0,
              icon: "arrow-right",
              size: "is-small"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.currentBundle?.name.ja), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('packageModal.description')), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('packageModal.radioButtonSelectionTitle')), 1),
        _createVNode(_component_RadioButtonList, {
          modelValue: _ctx.selectedPackageEnv,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPackageEnv) = $event)),
          items: _ctx.packageStreamingEnvList,
          id: "package-modal-env",
          name: "package-modal-env",
          styled: "line-up",
          class: "package-modal-env package-modal-section-part"
        }, null, 8, ["modelValue", "items"])
      ]),
      (_ctx.packageBundleMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.packageBundleMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_AppButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
        onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]), ["space","enter"])),
        "data-cy": "package-cancel"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.cancel')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_AppButton, {
          onClick: _ctx.openPackageStreamingUrl,
          onKeydown: _withKeys(_withModifiers(_ctx.openPackageStreamingUrl, ["prevent"]), ["space","enter"]),
          disabled: _ctx.isPublishingStreamingUrl,
          styled: "is-accent",
          "data-cy": "package-open-package-streaming"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('packageModal.openContentEnvironment')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "onKeydown", "disabled"]),
        (_ctx.isPublishingStreamingUrl)
          ? (_openBlock(), _createBlock(_component_IconLoading, {
              key: 0,
              class: "publishing-streaming-url-icon"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.publishingErrorMessage)
      ? (_openBlock(), _createBlock(_component_TextAlert, {
          key: 0,
          class: "publishing-error-message"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.publishingErrorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}