/**
 * 公開用バケットにあるコンテンツのオブジェクトキーを生成する
 * 公開用バケットにはコンテンツID毎のディレクトリの下にファイルが格納されている
 * @param publicPath ESに保存されているパス
 * @returns 公開用バケットのオブジェクトキー
 */
export const generetePublicPath = (publicPath: string): string => {
  const contentDirectoryWithoutContentDirectory = publicPath.split('/').slice(0, -1).join('/')
  const contentFileName = publicPath.split('/').slice(-1)[0]
  const contentDirectory = contentFileName.split('.').slice(0, -1).join('.') // 拡張子のみ取り除く
  return [contentDirectoryWithoutContentDirectory, contentDirectory, contentFileName].join('/')
}

/**
 * 公開用バケットにあるサムネイルのオブジェクトキーを生成する
 * 公開用バケットにはコンテンツID毎のディレクトリの下に固定ファイル名でサムネイルが格納されている
 * @param publicPath ESに保存されているパス
 * @returns 公開用バケットのオブジェクトキー
 */
export const generateThumbnailPath = (publicPath: string): string | null => {
  if (!publicPath) return null
  return publicPath.split('.').slice(0, -1).join('.') + '/thumbnail.jpg'
}

/**
 * 公開用バケットにある画像情報ファイルのオブジェクトキーを生成する
 * 公開用バケットにはコンテンツID毎のディレクトリの下に固定ファイル名で画像情報ファイルが格納されている
 * @param publicPath ESに保存されているパス
 * @returns 公開用バケットのオブジェクトキー
 */
export const generateKomaJsonPath = (publicPath: string): string => {
  return publicPath.split('.').slice(0, -1).join('.') + '/komainfo.json'
}
