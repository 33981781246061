
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import AppButton from '../atoms/AppButton.vue'
import { MyCollectionRecord } from '@/data/@types/MyCollectionRecord'
import TheLoginForm from '@/components/organisms/TheLoginForm.vue'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import { sessionCheck } from '@/domain/session/sessionCheck'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    ButtonIcon,
    TheLoginForm,
    ModalWindow,
    AppButton,
  },
  props: {
    pid: String,
    bid: {
      type: [String, Boolean],
      default: false,
    },
    cid: {
      type: [String, Boolean],
      default: false,
    },
    isContentDetailPageHeader: {
      type: Boolean,
      default: false,
    },
    isContentDetailPageToolbar: {
      type: Boolean,
      default: false,
    },
    showFullScreenHeader: {
      type: Function,
      required: false,
    },
    hideFullScreenHeader: {
      type: Function,
      required: false,
    },
  },
  setup (props) {
    const ICON_STYLE_BOOKMARK_FILL = 'bookmark-fill'
    const ICON_STYLE_BOOKMARK = 'bookmark'

    const store = useStore()
    const route = useRoute()

    const isAnnotationModalShow = ref<boolean>(false)
    const isLogoutModalShow = ref<boolean>(false)

    const addRemoveList = async () => {
      if (!await sessionCheck(route.fullPath)) return
      store.dispatch('myCollectionDelete', {
        pid: props.pid,
        bid: props.bid || null,
        cid: props.cid || null,
      })
    }

    const itemLink = async () => {
      if (!await sessionCheck(route.fullPath)) return
      store.dispatch('myCollectionItemLink', {
        tagId: 0,
        pid: props.pid,
        bid: props.bid || null,
        cid: props.cid || null,
      })
    }

    const matchContentId = (content: any) => {
      return props.bid === content.bid && props.cid === content.cid
    }

    const matchPid = (item: MyCollectionRecord) => {
      if (props.bid && props.cid) {
        const contents = item.contents || []
        if (!contents.length) return false

        if (Number(props.pid) === item.pid) {
          return contents.filter(matchContentId).length > 0
        }
      }

      return Number(props.pid) === item.pid
    }

    const isLoggedIn = computed(() => {
      return store.getters.isLoggedIn || false
    })

    const bookmarkButtonStyle = computed(() => {
      if (isLoggedIn.value && props.isContentDetailPageHeader) return 'logged-in display-header-on-focus'
      if (isLoggedIn.value) return 'logged-in'
      if (props.isContentDetailPageToolbar) return 'has-form-label'
      if (props.isContentDetailPageHeader) return 'title display-header-on-focus'
      else return 'has-text-thin'
    })

    const isBookmark = computed(() => {
      if (!isLoggedIn.value) return false
      const list = store.getters.myCollectionAllItemList || []
      const match = list.filter(matchPid)
      return match.length > 0
    })

    const onLogin = () => {
      if (!isBookmark.value) itemLink()
      isLogoutModalShow.value = false
    }

    const onBookmarkItem = () => {
      if (!isLoggedIn.value) {
        isAnnotationModalShow.value = !isAnnotationModalShow.value
        return
      }

      if (isBookmark.value) { // 削除
        addRemoveList()
      } else { // 追加
        itemLink()
      }
    }

    const bookmarked = ref(isBookmark)

    return {
      ICON_STYLE_BOOKMARK_FILL,
      ICON_STYLE_BOOKMARK,
      onBookmarkItem,
      onLogin,
      bookmarked,
      isBookmark,
      isLoggedIn,
      isAnnotationModalShow,
      isLogoutModalShow,
      bookmarkButtonStyle,
    }
  },
})
