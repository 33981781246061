/**
 * ログイン状態に応じた公開範囲のテキストを返すメソッド
 * @param permissionRule internet/ooc/ndl/rekion/forbiddenのいずれか
 * @param location ndl/kss/rekion/unknownのいずれか
 * @param isLoggedIn ログイン状態
 * @param isMiddlePersend 個人送信の手続き途中か否か
 * @param i18n i18n
 * @returns 公開範囲のテキスト
 */
export const getAvailableStateText = (permissionRule: string, location: string, isLoggedIn: boolean, isMiddlePersend: boolean, newLineCode: string, i18n: any) => {
  if (permissionRule === 'internet') {
    return i18n.t('permissionText.internet', { newLine: newLineCode })
  } else if (permissionRule === 'ooc') {
    return i18n.t('permissionText.ooc', { newLine: newLineCode })
  } else if (permissionRule === 'rekion') {
    return i18n.t('permissionText.rekion', { newLine: newLineCode })
  } else if (permissionRule === 'inlibrary') {
    return i18n.t('permissionText.inlibrary', { newLine: newLineCode })
  } else {
    return ''
  }
}
