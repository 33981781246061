import { JCSArea } from './ContentStructure'
import { BoundingBox, deg2rad, PhysicalRect, PhysicalSize, PolygonRect2D, px2mm, RectF, RectMM, Rotation, transform2D, transform2DArray, Vec2 } from './ImageViewerCommon'
import { KomaElement } from './KomaElement'
import { MetaSearchHitPin } from './Layouter'
import { SearchHitContent, SearchHitPin } from './SearchHitInfo'
import { TileDrawer } from './TileDrawer'
// const polygonBoolean = require('2d-polygon-boolean') // eslint-disable-line

/* eslint-disable */

/**
 * このエレメントのページの立ち位置
 * 
 */
const PagePositioning = {
  /**
   * １コマ表示
   */
  FullPage: 0,
  /**
   * 左のページ
   */
  LeftPage: 1,
  /**
   * 右のページ
   */
  RightPage: 2
} as const
type PagePositioning = typeof PagePositioning[keyof typeof PagePositioning];

/**
 * 描画ステータス
 */
 const DrawResult = {
  /**
   * 描画完了
   */
  Successed: 0,

  /**
   * 描画失敗
   */
  Failed: 1,

  /**
   * 再描画を推奨
   */
  Retry: 2

} as const

type DrawResult = typeof DrawResult[keyof typeof DrawResult]


/**
 * 描画エレメント仮想クラス
 */
class VisualElement {

  /*
   * このエレメントのページ立ち位置
   */
  private pagePositioning_: PagePositioning = PagePositioning.FullPage
  /**
   * このエレメントのページ立ち位置
   */
  public get pagePositioning(): PagePositioning {
    return this.pagePositioning_
  }
  /**
   * このエレメントのページ立ち位置
   */
  public set pagePositioning(value: PagePositioning) {
    this.pagePositioning_ = value
  }

  /**
   * 参照先画像要素内で、このページとして使用するの有効な領域
   *
   */
  protected usingKomaArea_ = new RectF(0, 0, 1.0, 1.0);

  public get usingKomaWidth (): number {
    return this.usingKomaArea_.right - this.usingKomaArea_.left
  }

  public get usingKomaHeight (): number {
    return this.usingKomaArea_.bottom - this.usingKomaArea_.top
  }

  public get usingKomaLeft (): number {
    return this.usingKomaArea_.left
  }

  public get usingKomaTop (): number {
    return this.usingKomaArea_.top
  }

  public get usingKomaRight (): number {
    return this.usingKomaArea_.right
  }

  public get usingKomaBottom (): number {
    return this.usingKomaArea_.bottom
  }

  /**
   * 傾き補正回転角度 deg
   */
  protected antiTiltAngle_ = 0; 

  public set antiTiltAngle(value: number){
    this.antiTiltAngle_ = value
  }
  public get antiTiltAngle(): number {
    return this.antiTiltAngle_
  }


  /**
   * 余白自動削除の値
   */
  protected clippingArea_?: PolygonRect2D// = new PolygonRect2D();
  protected clippingAreaBuf_ = new PolygonRect2D();

  protected clippingAreaInfo_?: JCSArea = undefined

  /**
   * 直近描画された状態の最終的なクリッピングマスクのパス
   */
  protected currentFinalClippingPath_: Array<PolygonRect2D> = []

  /**
   * 直近描画された状態の最終的な検索ヒットピンの配列
   */
  protected currentMetaSearchHitPins_: Array<MetaSearchHitPin> = []
  /**
   * 直近描画された状態の最終的なクリッピングマスクのパスを取得する
   */
  public get currentFinalClippingPaths(): Array<PolygonRect2D>{
    const res: Array<PolygonRect2D> = []
    for(let i=0; i<this.currentFinalClippingPath_.length; ++i){
      res.push(this.currentFinalClippingPath_[i].clone())
    }
    return res
  }

  /**
   * 直近描画された状態の最終的な検索ヒットピンの配列を返す
   */
  public get currentMetaSearchHitPins(): Array<MetaSearchHitPin>{
    const res: Array<MetaSearchHitPin> = []
    for(let i=0; i<this.currentMetaSearchHitPins_.length; ++i){
      res.push(this.currentMetaSearchHitPins_[i].clone())
    }
    return res
  }

  public setClippingArea(area?: JCSArea): void {

    if(!area || !this.koma){
      return
    }

    this.clippingAreaInfo_ = area

    // clipping範囲の設定がすべて0の場合無効扱いとする
    if(area.Left === 0 && area.Top === 0 && area.Right === 0 && area.Bottom === 0){
      return
    }


    this.clippingArea_ = new PolygonRect2D()

    // 割合をミリメートルに変換する

    const originKomaInfo =  this.koma.getKomaLevel(0)
    if(!originKomaInfo){
      return
    }
    
    // komainfo.json 未取得時は何もしない
    if(originKomaInfo.originWidth <= 0 || originKomaInfo.originHeight <= 0){
      return
    }
    
    this.clippingArea_.vertices[0].x = originKomaInfo.originWidth * area.Left
    this.clippingArea_.vertices[0].y = originKomaInfo.originHeight * area.Top
    this.clippingArea_.vertices[1].x = originKomaInfo.originWidth * area.Right
    this.clippingArea_.vertices[1].y = originKomaInfo.originHeight * area.Top
    this.clippingArea_.vertices[2].x = originKomaInfo.originWidth * area.Right
    this.clippingArea_.vertices[2].y = originKomaInfo.originHeight * area.Bottom
    this.clippingArea_.vertices[3].x = originKomaInfo.originWidth * area.Left
    this.clippingArea_.vertices[3].y = originKomaInfo.originHeight * area.Bottom
  
    /*
    if(this.pagePositioning !== PagePostioning.FullPage) {
      const originSize = new PhysicalSize(originKomaInfo.originWidth, originKomaInfo.originHeight)
      // 片ページ表示の場合、usingKomaArea_ を考慮した Cropping 領域に変換する必要がある
      const pageSize = new PhysicalSize()
      pageSize.width = originSize.width * (this.usingKomaArea_.right - this.usingKomaArea_.left)
      pageSize.height = originSize.height * (this.usingKomaArea_.bottom - this.usingKomaArea_.top)

      const finalRate = new RectF()

      if(this.pagePositioning_ === PagePostioning.LeftPage){
        const ol = originSize.width * area.Left
        finalRate.left = pageSize.width / ol
        finalRate.right = 1.0
      }
      else if(this.pagePositioning_ === PagePostioning.RightPage){
        finalRate.left = 0
        const w = originSize.width * (area.Right - this.usingKomaArea_.left)
        finalRate.right = pageSize.width / w
      }
      finalRate.top = area.Top
      finalRate.bottom = area.Bottom
      

      this.clippingArea_.vertices[0].x = originKomaInfo.originWidth * finalRate.left
      //this.clippingArea_.vertices[0].y *= area.Top
      this.clippingArea_.vertices[1].x = originKomaInfo.originWidth * finalRate.right
      //this.clippingArea_.vertices[1].y = originKomaInfo.originHeight * area.Top
      this.clippingArea_.vertices[2].x = originKomaInfo.originWidth * finalRate.right
      //this.clippingArea_.vertices[2].y = originKomaInfo.originHeight * area.Bottom
      this.clippingArea_.vertices[3].x = originKomaInfo.originWidth * finalRate.left
      //this.clippingArea_.vertices[3].y
    }
    */

  }
  public get clippingArea(): PolygonRect2D | undefined {
    if(!this.clippingArea_){
      return undefined
    }
    this.clippingAreaBuf_.copy(this.clippingArea_)
    return this.clippingAreaBuf_
  }

  /**
   * 余白自動削除を適用した場合のバウンディングボックスを返す
   * @param matrix 
   * @param scale 
   * @returns 
   */
  public getClippedBoundingBox(matrix?: DOMMatrix, scale=1.0): BoundingBox | undefined {

    if(!this.clippingArea || !this.koma){
      return undefined
    }

    const res = new BoundingBox()

    let rotated: Array<Array<number>>
    const komaPolygon = new Array<Array<number>>()

    const scaledSize = this.getElementSize(scale)//1.0)
    const unclippedSize = new PhysicalSize(this.koma.originWidth * scale, this.koma.originHeight * scale)
    /*
    komaPolygon.push([0, 0])
    komaPolygon.push([originSize.width-1, 0])
    komaPolygon.push([originSize.width-1, originSize.height-1])
    komaPolygon.push([0, originSize.height-1])
    */

    const clippingPolygon = new Array<Array<number>>()
    const clipA = this.clippingArea
    if(!clipA){
      return undefined
    }
    for (let i = 0; i < 4; ++i) {
      clippingPolygon.push([
        clipA.vertices[i].x * scale,
        clipA.vertices[i].y * scale
      ])
    }

    if(this.pagePositioning_ === PagePositioning.RightPage){
      clippingPolygon[0][0] = 0
      clippingPolygon[1][0] -= (unclippedSize.width- scaledSize.width)
      clippingPolygon[2][0] -= (unclippedSize.width - scaledSize.width)
      clippingPolygon[3][0] = 0
    }
    else if(this.pagePositioning_ === PagePositioning.LeftPage){
      // clippingPolygon[0][0] = 0
      clippingPolygon[1][0] = scaledSize.width
      clippingPolygon[2][0] = scaledSize.width
      //clippingPolygon[3][0] = 0
    }

    /*
    let union = polygonBoolean(komaPolygon, clippingPolygon, 'and')
    
    // 交差していない場合はエラー
    if(!union || union.length <= 0){
      return res
    }
    
    union = union[0]

    // 交差していない場合はエラー
    if(!union || union.length <= 0){
      return res
    }
    
   
    for(let i=0; i<union.length; ++i){
      union[i][0] = union[i][0] * scale
      union[i][1] = union[i][1] * scale
    }
    */

    if(matrix && !matrix.isIdentity){
      rotated = []
      for(let i=0; i<clippingPolygon.length; ++i){
        const p = transform2DArray(clippingPolygon[i], matrix)
        rotated.push(p)
      }
    }
    else {
      rotated = clippingPolygon
    }

    res.left = rotated[0][0]
    res.right = rotated[0][0]
    res.top = rotated[0][1]
    res.bottom = rotated[0][1]

    for (let i = 1; i < rotated.length; ++i) {
      const c = rotated[i]
      if (res.left > c[0]) {
        res.left = c[0]
      }
      if (res.right < c[0]) {
        res.right = c[0]
      }
      if (res.top > c[1]) {
        res.top = c[1]
      }
      if (res.bottom < c[1]) {
        res.bottom = c[1]
      }
    }


    res.offset.left = res.left
    res.offset.top = res.top
    //res.offset.right = res.right - originSize.width * scale
    //res.offset.bottom = res.bottom - originSize.height * scale


    return res
  }
  

  /**
   * 指定した拡大率でこのページを描画した場合のピクセルサイズを返す
   * Layouter の getElementBoundingBox() 内部で使用する。ImageViewer から直接呼ばないこと。
   * @param scale
   * @returns
   **/
  getElementSize (scale: number, cropping?: RectMM): BoundingBox {
    return new BoundingBox()
  }
  

  /**
   * KomaElementを返す
   */
  get koma (): KomaElement | undefined {
    return undefined
  }
  

  /**
   * 現在のページ描画画像解像度から描画に最適な解像度レベルを算出する
   * @param targetPageSize 描画される予定のページ画像のサイズ
   * @returns
   */
  calcDecentLevel (targetPageSize: PhysicalSize): number {
    return -1
  }

  /**
   * このページを指定したコンテキストに描画する
   * @param currentPageIndex 現在のページインデックス（テンポラリ）
   * @param targetLevel 描画する解像度レベル 0未満で適切な解像度を自動判定
   * @param context
   * @param bbContext バックバッファキャンバス
   * @param left canvas描画座標 左
   * @param top canvas描画座標 上
   * @param scale 画像のオリジナルサイズに対する描画スケール
   * @param drawBG 下地を描画するか否か
   * @param delayDrawFunc 未ダウンロードタイルがダウンロード完了したときに実行する関数
   * @param rotation 回転情報
   * @param clipping 自動余白削除を使用するか否か
   * @param cropping 印刷切り抜き範囲
   * @param clippingOffsetLeft 切り抜きと余白削除用左オフセット（2in1右コマ用）
   * @param is2In1 2in1表示か否か
   * @param transformedOffsetY 2in1高さ揃え用 回転が適用される垂直方向オフセット
   * @param blitPerfect タイルがすべて描画されない限りメインキャンバスへ転送しない
   * @param searchHitInfo 検索ヒット情報配列
   */
  draw (
    currentPageIndex: number,
    targetLevel: number,
    context: CanvasRenderingContext2D,
    bbCanvas: HTMLCanvasElement,
    originalCanvasSize: PhysicalSize,
    left: number,
    top: number,
    offsetX: number,
    offsetY: number,
    scale: number,
    drawBG:boolean,
    delayDrawFunc: (()=>void) | undefined,
    rotation: Rotation,
    clipping: boolean,
    cropping: RectMM | undefined,
    clippingOffsetLeft: number,
    is2In1: boolean,
    transformedOffsetY: number,
    blitPerfect: boolean,
    searchHitInfo?: Array<SearchHitContent>
  ): DrawResult {
    return DrawResult.Successed
  }
  
  /**
   * ページを描画可能な状態にする
   * 最小解像度画像をDLする
   * @param onLoadedPerEachTiles 各タイルのDL完了毎に実行するコールバック関数
   * @returns
   */
  public activate (
    dummyKomaResolutionUpdate: ()=>PhysicalSize,
    onLoadedPerEachTiles?: (success: boolean, tileDrawer: TileDrawer) => void
  ): boolean {
    return false
  }

}
/* eslint-disable */

export { VisualElement, PagePositioning as PagePostioning, DrawResult }
