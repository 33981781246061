/**
 * モーダルウィンドウを表示したときにfix()を実行し、閉じるときにcancel()を実行する。
 *
 * fix時にsafetyを指定した場合、iOSでは背景が固定されない。
 *
 * iOSで背景を固定するには以下の2つの対応が必要
 * - fix時にunsafetyを指定する。副作用としてタッチ操作が制限される場合があるので注意。
 * - モーダルウィンドウ内にスクロール可能な領域がある場合は、overflow-y: scrollの指定をしたDOMのCSSセレクタ文字列を渡す。
 *
 * モバイルのビューアーなどでモーダルウィンドウが重なる場合があるのでシングルトンパターンで作成（stackを共有）
 */

class FixBackground {
  stack: ('safety' | 'unsafety')[]

  constructor () {
    this.stack = []
  }

  fix (
    safety: ('safety' | 'unsafety') = 'safety',
    targetSelector?: string
  ): void {
    this.stack.push(safety)
    const body = document.querySelector('body') as HTMLBodyElement
    body.style.overflowY = 'hidden'

    if (safety === 'unsafety') {
      body.style.touchAction = 'pan-x pinch-zoom'
    }

    if (!targetSelector) {
      return
    }

    setTimeout(() => { // DOMのレンダリング待ち
      const target = document.querySelector(targetSelector) as HTMLBodyElement
      target.scrollTop = 1
      if (!target.scrollTop) {
        target.style.overflowY = 'visible'
      }
      target.addEventListener('scroll', () => {
        if (target.scrollTop === 0) {
          target.scrollTop = 1
        } else if (target.scrollTop + target.clientHeight === target.scrollHeight) {
          target.scrollTop = target.scrollTop - 1
        }
      })
    }, 200)
  }

  cancel (): void {
    this.stack.pop()
    const body = document.querySelector('body') as HTMLBodyElement

    if (!this.stack.includes('unsafety')) {
      body.style.touchAction = 'auto'
    }

    if (!this.stack.length) {
      body.style.overflowY = 'visible'
    }
  }
}

export default new FixBackground()
