
import { computed, defineComponent, ref } from 'vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import ViewerPoster from './ViewerPoster.vue'
import TheFileViewer from './FileViewerMobile.vue'
import TheImageViewer from './ImageViewerMobile.vue'
import TheVideoViewer from './VideoViewerMobile.vue'
import NoContentViewer from '@/components/NoContentViewer.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ImageViewer } from '@/helpers/imageviewer/ImageViewer'

const iv = ImageViewer.getInstance()

export default defineComponent({
  components: {
    ButtonIcon,
    ViewerPoster,
    TheFileViewer,
    TheImageViewer,
    TheVideoViewer,
    NoContentViewer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
    bundleIndex: {
      type: String,
      required: false,
    },
    tabViewFlag: {
      type: Boolean,
      required: true,
    },
  },
  setup (props) {
    const route = useRoute()
    const store = useStore()
    const isIframePage = computed(() => store.getters.isIframe)
    const mobileViewerFullscreenFlag = ref(false)
    const startIcon = computed(() => {
      if (props.viewerType === 'NoContentViewer') {
        return
      }
      if (props.viewerType === 'TheVideoViewer') {
        return 'play'
      } else {
        return 'arrow-to-outside'
      }
    })

    const fullTextPinIcon = computed(() => {
      if (props.viewerType !== 'TheImageViewer') return
      const pins = store.getters.getItemViewFulltextSnippetPins
      if (pins.length === 0) return
      if (store.getters.showFulltextPin) return 'viewer-pin-disabled'
      return 'viewer-pin'
    })

    const toggleFulltextPin = (event: Event) => {
      event.stopPropagation()
      if (store.getters.showFulltextPin) {
        iv.changeCurrentSearchHitPin(-1)
        iv.deleteSearchHitInfo()
      } else {
        iv.setSearchHitInfo(store.getters.getItemViewFulltextSnippetPins)
      }
      store.commit('toggleFulltextPin')
    }

    // SP版のビューア表示からポスター表示に切り替えたときに指定されたコンテンツを表示させるため
    const currentContentIndex = ref()
    const ischangedToViewerPoster = ref()

    const start = () => {
      if (props.viewerType === 'NoContentViewer') {
        return
      }
      // 全画面表示のときブラウザバックでポスター表示に変えるため
      // router.pushではブラウザバックの挙動の検知が出来なかったため
      window.history.pushState(null, '', route.fullPath)
      ischangedToViewerPoster.value = false
      mobileViewerFullscreenFlag.value = true
      isIframePage.value && document.documentElement.requestFullscreen()
    }
    const close = () => {
      currentContentIndex.value = route.params.contentIndex
      ischangedToViewerPoster.value = true
      mobileViewerFullscreenFlag.value = false
      isIframePage.value && document.exitFullscreen()
    }

    return {
      startIcon,
      fullTextPinIcon,
      start,
      close,
      toggleFulltextPin,
      mobileViewerFullscreenFlag,
      currentContentIndex,
      ischangedToViewerPoster,
    }
  },
})
