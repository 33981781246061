
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    level: {},
    visualLevel: {},
    tag: {},
  },
  setup (props) {
    const tag = props.tag || (props.level ? `h${props.level}` : 'p')

    const headingLevel = (() => {
      const level = props.visualLevel || props.level
      return 'is-' + (isNaN(level as number) ? level : `h${level}`)
    })()
    return {
      tag_: tag,
      headingLevel,
    }
  },
})
