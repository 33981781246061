
import { defineComponent } from 'vue'
import ButtonIcon from './ButtonIcon.vue'

export default defineComponent({
  components: {
    ButtonIcon,
  },
  props: {
    pid: {
      type: Number,
      required: true,
    },
    bid: {
      type: [String, Boolean],
      required: true,
    },
    cid: {
      type: [String, Boolean],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    autoRemove: {
      type: Number,
    },
  },
  setup (props, context) {
    let timeObj: any = null

    const onRemove = () => {
      context.emit('remove', props.id)
      clearTimeout(timeObj)
    }

    if (props.autoRemove) {
      timeObj = setTimeout(onRemove, props.autoRemove)
    }

    return {
      onRemove,
    }
  },
})
