
import { computed, defineComponent } from 'vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { useStore } from 'vuex'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import { Content } from '@/data/@types/Content'
import { getPermissionColor, ruleToType } from '@/data/Permission'
import { useRoute } from 'vue-router'
import AppButton from '@/components/atoms/AppButton.vue'
import AppLabelPlaces from '@/components/atoms/AppLabelPlaces.vue'
import AppIconPlaces from '@/components/atoms/AppIconPlaces.vue'
import IconNdlOnline from '@/components/atoms/IconNdlOnline.vue'
import TextLinkWithArrow from '@/components/molecules/TextLinkWithArrow.vue'

export default defineComponent({
  components: {
    DssIcon,
    AppButton,
    AppLabelPlaces,
    AppIconPlaces,
    IconNdlOnline,
    TextLinkWithArrow,
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const isRekion = route.meta.isRekion
    const isKn = process.env.VUE_APP_IS_KN ?? false

    const location = computed(() => store.getters.location)
    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const isRegistered = computed(() => store.getters.isRegistered)
    const isSimple = computed(() => store.getters.isSimple)
    const isApplying = computed(() => store.getters.isApplying)
    const isPersendUserType = computed(() => store.getters.isPersendUserType)
    const isOrganizationUserType = computed(() => store.getters.isOrganizationUserType)
    const isLiveInJapan = computed(() => store.getters.isLiveInJapan)
    const isPersendAgreed = computed(() => store.getters.isPersendAgreed)
    const isPersendForbidden = computed(() => store.getters.isPersendForbidden)
    const isFileBundle = computed(() => (currentBundle.value?.type === 'file') || (currentBundle.value?.type === 'content'))

    const currentBundle = computed<ContentsBundle>(
      () => store.getters.currentContentsBundle
    )
    const currentContent = computed<Content>(
      () => store.getters.currentContent
    )

    const permissionRule = computed(() => store.getters.item.permission?.rule ? store.getters.item.permission.rule : 'internet')
    const bundlePermissionRule = computed(() => currentBundle.value?.permission?.rule ? currentBundle.value.permission.rule : permissionRule.value)
    const contentPermissionRule = computed(() => currentContent.value?.permission?.rule ? currentContent.value.permission.rule : bundlePermissionRule.value)

    const permissionType = computed(() => ruleToType(permissionRule.value))
    const contentPermissionType = computed(() => ruleToType(contentPermissionRule.value))

    const contentRestrictionMessage = computed(() => store.getters.currentContent?.meta?.[store.getters.MetadataObjectById(742)?.field])
    const bundleRestrictionMessage = computed(() => store.getters.currentContentsBundle?.meta?.[store.getters.MetadataObjectById(601)?.field])
    const hasRestrictionMessage = computed(() => (contentRestrictionMessage.value || bundleRestrictionMessage.value) ?? false)
    const permissionColor = computed(() => {
      return getPermissionColor(permissionType.value, isRekion)
    })

    return {
      permissionColor,
      location,
      isKn,
      isRegistered,
      isSimple,
      isApplying,
      isLoggedIn,
      isPersendUserType,
      isOrganizationUserType,
      isLiveInJapan,
      isPersendAgreed,
      isPersendForbidden,
      isFileBundle,
      permissionRule,
      bundlePermissionRule,
      contentPermissionRule,
      permissionType,
      contentPermissionType,
      contentRestrictionMessage,
      bundleRestrictionMessage,
      hasRestrictionMessage,
    }
  },
})
