import { createItemTitleLabel } from '@/domain/item/itemViewer/itemTitleLabel'
import { getMetadataLabel } from '@/domain/snippet/metadataLabel'
import { map } from 'lodash'
import { computed } from 'vue'

const VUE_APP_CONTENTS_BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
const COLLECTION_DETAILED_SEARCH_RESULT = 'CollectionDetailedSearchResult'
const COLLECTION_DETAILED_SEARCH = 'CollectionDetailedSearch'
const DETAILED_SEARCH_RESULT = 'DetailedSearchResult'
const CONTENT_DETAIL = 'ContentDetail'
const COLLECTIONDETAILEDSEARCHRESULT = 'CollectionDetailedSearchResult'
const DETAILEDSEARCHRESULT = 'DetailedSearchResult'
const REKIONSEARCHRESULT = 'RekionSearchResult'
const SEARCHIMAGERESULT = 'SearchImageResult'
const PUBLICSEARCHRESULT = 'PublicSearchResultPage'
const MYCOLLECTION = 'MyCollection'
const LOGIN = 'Login'
const ADMIN = 'AdminMenu'

const defaultSiteName = {
  ja: '国立国会図書館デジタルコレクション',
  en: 'NDL Digital Collections',
}
const defaultDescription = {
  ja: '国立国会図書館デジタルコレクションは、国立国会図書館で収集・保存しているデジタル資料を検索・閲覧できるサービスです。',
  en: 'The NDL Digital Collections enables a user to search and view a variety of resources, collected and stored by the National Diet Library of Japan.',
}

const defaultImage = `${VUE_APP_CONTENTS_BASE_URL}img/twitter-card/ogp-ndldc-twitter-summary.png`

const internetPublishingPermissionRules = [
  'internet', 'internet_no_download', 'internet_no_print', 'internet_print_ndl_only',
]

// アイコン is = isRekion
const twitterCardImage = (is: boolean) => {
  const dl = 'img/twitter-card/ogp-ndldc-twitter-summary.png'
  const rekionDl = 'img/twitter-card/ogp-ndlrekion-twitter-summary.png'
  return (is) ? `${VUE_APP_CONTENTS_BASE_URL}${rekionDl}` : `${VUE_APP_CONTENTS_BASE_URL}${dl}`
}

const isPermissionRuleInternet = (permissionRule: any) => {
  if (!permissionRule) return false
  return internetPublishingPermissionRules.includes(permissionRule)
}

const linksDc = [
  {
    rel: 'icon',
    href: '/img/icons/ndldc/favicon.svg',
    type: 'image/svg+xml',
  },
  {
    rel: 'icon alternate',
    href: '/img/icons/ndldc/favicon.png',
    type: 'image/png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: 'img/icons/ndldc/apple-touch-icon.png',
  },
  {
    rel: 'mask-icon',
    href: '/img/icon/apple-touch-icon.png',
    color: '#FFFFFF',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '180x180',
    href: '/img/icon/android-chrome-192×192.png',
  },
]

const linksKn = [
  {
    rel: 'icon',
    href: '/img/icons/kn/angular-pilot_app_favicon.ico',
    type: 'image',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: 'img/icons/ndldc/apple-touch-icon.png',
  },
  {
    rel: 'mask-icon',
    href: '/img/icon/apple-touch-icon.png',
    color: '#FFFFFF',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '180x180',
    href: '/img/icon/android-chrome-192×192.png',
  },
]

type State = {
  description: { 'ja': string, 'en': string } | false,
  lang: 'ja' | 'en',
  image: string,
  ogType: string,
  routeName: string,
  title: { 'ja': string, 'en': string } | false,
  twitterCard: string,
  twitterTypeParams: any[],
  siteName: string,
  route: any,
  params: any | false,
  collectionId: string,
  isRekion: boolean,
  is404: boolean,
  isHeadstoneItem: boolean,
}

/**
 * ステート
 */
const state: State = {
  description: defaultDescription,
  lang: 'ja',
  siteName: '',
  title: defaultSiteName,
  image: defaultImage,
  ogType: 'website',
  routeName: '',
  twitterCard: 'summary',
  twitterTypeParams: [],
  route: {},
  params: false,
  collectionId: '',
  isRekion: false,
  is404: false,
  isHeadstoneItem: false,
}

/**
 * ゲッター
 */
const getters = {
  OgpSiteName: (state: any) => {
    return {
      property: 'og:site_name',
      content: defaultSiteName[state.lang as 'ja' | 'en'],
    }
  },
  getCollectionDetailTitle: (state: any, getters: any) => {
    if (!state.params.collectionId) return false

    const collectionFamily: any[] = getters.CollectionFamily(state.params.collectionId)
    if (!collectionFamily.length) return false

    const collectionName = collectionFamily[0].collectionName[state.lang]
    if (collectionFamily.length === 1) return collectionName

    const title = collectionFamily[collectionFamily.length - 1].title[state.lang]
    return `${collectionName} (${title})`
  },
  getContentDetailContentTitle: (state: any, getters: any) => {
    // 詳細ページ
    const item = getters.item

    if (!item.meta) return false

    const bundleIndex = state.params.bundleIndex || false
    const contentIndex = state.params.contentIndex || false

    // コンテンツ名を取得
    const contentName = bundleIndex &&
    contentIndex &&
    item?.contentsBundles?.[bundleIndex - 1]?.contents?.[contentIndex - 1]?.name?.[state.lang as 'ja' | 'en']

    return contentName || false
  },
  OgpTitle: (state: any, getters: any) => {
    const routeName = state.routeName
    const siteName = defaultSiteName[state.lang as 'ja' | 'en']

    const detailTitle = (): string => {
      const title = getters.getCollectionDetailTitle
      return title ? `${title} - ${siteName}` : siteName
    }

    const contentListTitle = (): string => {
      // コンテンツ名 - アイテムタイトル - 国立国会図書館デジタルコレクション
      const contentTitle = getters.getContentDetailContentTitle

      const item = getters.item
      if (!item.meta) return siteName
      const itemTitle = createItemTitleLabel(item)

      if (contentTitle) {
        if (itemTitle) {
          return `${contentTitle} - ${itemTitle} - ${siteName}`
        } else {
          return `${contentTitle} - ${siteName}`
        }
      } else if (itemTitle) {
        return `${itemTitle} - ${siteName}`
      } else return siteName
    }

    switch (routeName) {
      case COLLECTION_DETAILED_SEARCH_RESULT:
        return detailTitle()
        break
      case COLLECTION_DETAILED_SEARCH:
        return detailTitle()
        break
      case CONTENT_DETAIL:
        return contentListTitle()
        break
      default:
        return state.title[state.lang as 'ja' | 'en']
    }
  },
  getCollectionDetailDescription: (state: any, getters: any) => {
    if (!state.params.collectionId) return false

    const collectionFamily: any = getters.CollectionFamily(state.params.collectionId)
    const collectionFamilyLength = collectionFamily.length || false
    if (!collectionFamilyLength) return false
    // length=0には「collectionName」に値が入っていて、それ以外の場合は「title」に値が入っている
    const collectionName = collectionFamilyLength === 1 ? (
      collectionFamily[0].collectionName
    ) : (
      collectionFamily[collectionFamilyLength - 1].title
    )
    const requestQuery = getters.requestQuery.keyword || false

    return requestQuery ? `${collectionName[state.lang as 'ja' | 'en']} - ${requestQuery}` : collectionName[state.lang as 'ja' | 'en']
  },
  getContentDetailDescription: (state: any, getters: any) => {
    const item = getters.item || false

    if (!item || !item.meta) return false

    const getMetadataLabelName = (metadataFieldName: string) => {
      return getMetadataLabel(getters, item.collections[0], metadataFieldName)[state.lang as 'en' | 'ja']
    }

    // 書誌情報を「ラベル名1：バリュー, ラベル名2：バリュー」の形で連結させる
    const descriptionTextList = []
    if (item.meta['0010Dtct']?.length) {
      descriptionTextList.push(`${getMetadataLabelName('0010Dtct')}：${item.meta['0010Dtct']}`)
    }
    if (item.meta['0020Dtct']?.length) {
      descriptionTextList.push(`${getMetadataLabelName('0020Dtct')}：${item.meta['0020Dtct']}`)
    }
    if (item.meta['0058Dod']?.length) {
      descriptionTextList.push(`${getMetadataLabelName('0058Dod')}：${item.meta['0058Dod']}`)
    }

    return descriptionTextList.length ? descriptionTextList.join(', ') : false
  },
  getDetailedSearchResultDescription: (state: any, getters: any) => {
    const requestQuery = getters.requestQuery.keyword || false
    return requestQuery || defaultSiteName[state.lang as 'ja' | 'en']
  },
  OgpDescription: (state: any, getters: any) => {
    const routeName = state.routeName
    const initDescription = defaultDescription[state.lang as 'ja' | 'en']

    switch (routeName) {
      case COLLECTION_DETAILED_SEARCH_RESULT:
        return getters.getCollectionDetailDescription || initDescription
        break
      case COLLECTION_DETAILED_SEARCH:
        return getters.getCollectionDetailDescription || initDescription
        break
      case CONTENT_DETAIL:
        return getters.getContentDetailDescription || initDescription
        break
      case DETAILED_SEARCH_RESULT:
        return getters.getDetailedSearchResultDescription || initDescription
        break
      default:
        return state.description[state.lang as 'ja' | 'en']
    }
  },
  OgpOgType: (state: any) => {
    return {
      property: 'og:type',
      content: state.ogType,
    }
  },
  // 各コレクションのサムネイルを取得
  getCollectionThumbnail: (state: any, getters: any) => {
    const defaultImg: string = twitterCardImage(state.isRekion)

    const defaultState = {
      property: 'og:image',
      content: defaultImg,
    }

    return (collectionId: string) => {
      if (!collectionId) return defaultState

      const collectionMaster = getters.CollectionMaster
      const thumbnail = collectionMaster[collectionId]?.content.thumbnail || false

      return thumbnail ? {
        property: 'og:image',
        content: `${VUE_APP_CONTENTS_BASE_URL}${thumbnail}`,
      } : defaultState
    }
  },
  OgpImage: (state: any, getters: any) => {
    const defaultState = {
      property: 'og:image',
      content: twitterCardImage(state.isRekion),
    }

    const routeName = state.routeName

    const getHitItemContent = (item: any) => {
      if (!item || !item.content) return false
      return item.content
    }

    // コレクション
    if (routeName === COLLECTION_DETAILED_SEARCH_RESULT) {
      return getters.getCollectionThumbnail(state.collectionId)
    }

    // コレクション検索
    if (routeName === DETAILED_SEARCH_RESULT) {
      const itemContent = getHitItemContent(getters.ItemList[0])
      if (!itemContent) return defaultState
      if (!isPermissionRuleInternet(itemContent.permission?.rule)) return defaultState
      const hasThumbnail = itemContent.thumbnail || false

      return hasThumbnail ? {
        property: 'og:image',
        content: hasThumbnail,
      } : defaultState
    }

    return defaultState
  },
  getTwitterCard: (state: any, getters: any) => {
    const typeNormal = 'summary'
    const typeLarge = 'summary_large_image'

    return (imagePath: string) => {
      return {
        name: 'twitter:card',
        content: imagePath === twitterCardImage(state.isRekion) ? typeNormal : typeLarge,
      }
    }
  },
  metaList: (state: any, getters: any) => {
    const routeName = state.routeName

    const staticDefaultMeta = [
      {
        name: 'viewport',
        content: 'width=device-width',
      },
      {
        name: 'referrer',
        content: 'origin-when-crossorigin',
      },
      {
        name: 'format-detection',
        content: 'email=no,telephone=no,address=no',
      },
      {
        property: 'og:url',
        content: location.href,
      },
      {
        name: 'twitter:site',
        content: '@NDLJP',
      },
    ]

    const nofollowPages = [
      MYCOLLECTION,
      LOGIN,
      ADMIN,
    ]

    const noindexPages = [
      PUBLICSEARCHRESULT,
      COLLECTIONDETAILEDSEARCHRESULT,
      DETAILEDSEARCHRESULT,
      SEARCHIMAGERESULT,
      REKIONSEARCHRESULT,
    ]

    const robotsMeta = () => {
      if (nofollowPages.includes(routeName) || state.is404) {
        return {
          name: 'robots',
          content: 'noindex, nofollow',
        }
      } else if (noindexPages.includes(routeName) || state.isHeadstoneItem) {
        return {
          name: 'robots',
          content: 'noindex, follow',
        }
      } else {
        return {
          name: 'robots',
          content: 'index, follow',
        }
      }
    }

    const list = map(staticDefaultMeta.concat(
      [robotsMeta()],
      [getters.OgpSiteName],
      [getters.OgpOgType],
      [getters.OgpImage],
      [getters.getTwitterCard(getters.OgpImage.content)],
      [{
        property: 'og:title',
        content: getters.OgpTitle,
      }],
      [
        {
          name: 'description',
          content: getters.OgpDescription,
        }, {
          property: 'og:description',
          content: getters.OgpDescription,
        },
      ],
      state.twitterTypeParams
    ), (v) => {
      return v
    })

    state.is404 = false // 初期化
    state.isHeadstoneItem = false // 初期化

    return list
  },
  linkListDc: () => linksDc,
  linkListKn: () => linksKn,
}

/**
 * ミューテーション
 */
const mutations = {
  // App.vueからの呼び出し
  SET_OGP_INITIALIZE (state: any, params: any) {
    // TOP, 検索結果一覧
    const meta = params.meta

    state.routeName = params.routeName
    state.isRekion = meta.isRekion || false
    state.title = meta.pageTitle
    state.description = meta.description || false
    state.ogType = meta.ogType
    state.params = params.params // コレクション一覧用
    state.ogType = meta.ogType
    state.collectionId = params.params?.collectionId
  },
  // 詳細画面のビューアーによってtwitterのOGPを設定します
  SET_DETEIL_VIEWER_MODE (state: any, params: any) {
    const currentItemId = params.item.itemId
    const currentBundleIndex = params.store.getters.bundleNumber
    const currentContentIndex = params.store.getters.contentNumber
    const currentContentPath = params.item.contentsBundles?.[currentBundleIndex]?.contents?.[currentContentIndex]?.publicPath
    const isContentSelected = params.isContentSelected
    const targetPermissionRule = computed(() => {
      const itemPermissionRule = params.item.permission?.rule
      if (isContentSelected) {
        // ルールが未設定の場合は、上位を参照
        const bundlePermissionRule = params.item.contentsBundles?.[currentBundleIndex]?.permission?.rule || itemPermissionRule
        const contentPermissionRule = params.item.contentsBundles?.[currentBundleIndex]?.contents?.[currentContentIndex]?.permission?.rule || bundlePermissionRule
        return contentPermissionRule
      }
      return itemPermissionRule
    })

    // Ogpサムネイル提供APIのパスを生成する
    const generateItemThumbnailUrl = (itemId: string) => {
      return `${VUE_APP_CONTENTS_BASE_URL}api/ogpThumb?naturalNumberString=${itemId}`
    }

    // Ogpサムネイル提供APIのパスを生成する
    const generateContentThumbnailUrl = (itemId: string, bundleNo: string, contentNo: string) => {
      return `${generateItemThumbnailUrl(itemId)}&bundleNo=${bundleNo}&contentNo=${contentNo}`
    }

    const getThumbnailOrIconLogo = (itemId: string) => {
      return params.item.thumbnail ? generateItemThumbnailUrl(itemId) : twitterCardImage(state.isRekion)
    }

    const defaultParams = () => {
      return [
        {
          property: 'og:image',
          content: twitterCardImage(state.isRekion),
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
      ]
    }

    const internetPublishingDefaultParams = () => {
      let ogImageContent = getThumbnailOrIconLogo(currentItemId)
      let twitterCardContent = 'summary'
      if (isContentSelected && currentContentPath) {
        // コンテンツのURLが指定され、かつコンテンツのパスが存在する場合
        ogImageContent = generateContentThumbnailUrl(currentItemId, currentBundleIndex, currentContentIndex)
        twitterCardContent = 'summary_large_image'
      }
      return [
        {
          property: 'og:image',
          content: ogImageContent,
        },
        {
          name: 'twitter:card',
          content: twitterCardContent,
        },
      ]
    }

    const internetPublishingMovieParams = () => {
      let ogImageContent = getThumbnailOrIconLogo(currentItemId)
      if (isContentSelected && currentContentPath) {
        // コンテンツのURLが指定され、かつコンテンツのパスが存在する場合
        ogImageContent = generateContentThumbnailUrl(currentItemId, currentBundleIndex, currentContentIndex)
      }
      return [
        {
          property: 'og:image',
          content: ogImageContent,
        },
        {
          property: 'og:type',
          content: 'video.other',
        },
        {
          name: 'twitter:card',
          content: 'player',
        },
        {
          name: 'twitter:player',
          content: `${VUE_APP_CONTENTS_BASE_URL}theoplayer.html?autoplay=false&muted=false&preload=none&src=${VUE_APP_CONTENTS_BASE_URL}${params.publicPath}`,
        },
        {
          name: 'twitter:player:width',
          content: '1280',
        },
        {
          name: 'twitter:player:height',
          content: '720',
        },
      ]
    }

    if (!isPermissionRuleInternet(targetPermissionRule.value)) {
      // インターネット公開でない場合
      state.twitterTypeParams = defaultParams()
      return
    }
    if (!(targetPermissionRule.value === 'internet')) {
      // 「インターネット公開」かつ「印刷不可 or 館外印刷不可 or ダウンロード不可」の場合
      if (isContentSelected) {
        state.twitterTypeParams = defaultParams()
        return
      }
    }
    if (params.viewerType === 'NoContentViewer') {
      state.twitterTypeParams = internetPublishingDefaultParams()
    } else if (params.viewerType === 'BranchViewer') {
      state.twitterTypeParams = internetPublishingDefaultParams()
    } else if (params.viewerType === 'TheFileViewer') {
      state.twitterTypeParams = internetPublishingDefaultParams()
    } else if (params.viewerType === 'TheImageViewer') {
      state.twitterTypeParams = internetPublishingDefaultParams()
    } else if (params.viewerType === 'TheVideoViewer') {
      state.twitterTypeParams = internetPublishingMovieParams()
    } else {
      state.twitterTypeParams = []
    }
  },
  SET_LANG (state: any, lang: string) {
    state.lang = lang
  },
  SET_IS404 (state: any, flag: boolean) {
    state.is404 = flag
  },
  SET_ISHEADSTONEITEM (state: any, flag: boolean) {
    state.isHeadstoneItem = flag
  },
}

/**
 * アクション
 */
const actions = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
