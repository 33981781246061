
import { defineComponent } from 'vue'
import MetadataRecord from '@/components/molecules/MetadataRecord.vue'
import { simpleRecords } from '@/data/master/CustomMetadataLabel'
import { createItemTitleLabel } from '@/domain/item/itemViewer/itemTitleLabel'

export default defineComponent({
  components: {
    MetadataRecord,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    collection: {
      type: String,
      required: false,
      default: '',
    },
  },
  inject: ['getMetadataValue', 'getMetadataLabels'],
  setup () {
    return {
      createItemTitleLabel,
      ...simpleRecords.A00016,
    }
  },
})
