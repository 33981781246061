
import { defineComponent, computed } from 'vue'
import AppInput from '../atoms/AppInput.vue'
import AppButton from '../atoms/AppButton.vue'
import ButtonIcon from './ButtonIcon.vue'
import { ImageViewer } from '@/helpers/imageviewer/ImageViewer'
import { useStore } from 'vuex'
import _ from 'lodash'

const iv = ImageViewer.getInstance()

export default defineComponent({
  components: {
    AppInput,
    AppButton,
    ButtonIcon,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabledIncrement: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    iconIncrement: {
      type: String,
      default: 'plus',
    },
    iconDecrement: {
      type: String,
      default: 'minus',
    },
    label: {
      type: String,
      required: true,
    },
    labelLeft: {
      type: String,
      required: true,
    },
    labelRight: {
      type: String,
      required: true,
    },
    styled: {
      type: String,
      default: 'is-normal',
    },
    tag: {
      type: String,
      default: 'span',
    },
  },
  setup () {
    const store = useStore()
    const scaleValue = computed(() => Math.floor(store.getters.currentScale * 100))

    const maxScaleValue = computed(() => Math.floor(store.getters.maxScale * 100))
    const minScaleValue = computed(() => Math.floor(store.getters.minScale * 100))

    const increment = () => {
      iv.scaleUp()
    }
    const decrement = () => {
      iv.scaleDown()
    }

    const setScaleValue = (val: number) => {
      // storeには整数値(floor)で保存してある
      // minScaleは小数値であるため、storeにはそれよりも小さい値が格納される
      // changeScaleはminScaleよりも小さい値では作動しないためstoreではなくgetMinScaleした値を使う
      val = Math.min(iv.getMaxScale()! * 100, val)
      val = Math.max(iv.getMinScale()! * 100, val)
      iv.changeScale(val / 100)
    }

    const debounceSetScale = _.debounce(setScaleValue, 1000)

    return {
      scaleValue,
      maxScaleValue,
      minScaleValue,
      increment,
      decrement,
      debounceSetScale,
    }
  },
})
