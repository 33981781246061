
import { useRoute, useRouter } from 'vue-router'
import {
  reactive,
  defineComponent,
  onMounted,
  watchEffect,
  ref,
  watch,
} from 'vue'
import ItemDetail from '@/components/templates/ItemDetail.vue'
import { useStore } from 'vuex'
import storeModule from '@/store/contentViewer'
import HeadstoneItem from '@/components/organisms/ItemContentViewer/HeadstoneItem.vue'
import { AccessTokenService } from '@/store/modules/AccessToken'
import { currentContentScrollTo } from '@/helpers/util/scrollUtil'
import { ImageViewer } from '@/helpers/imageviewer/ImageViewer'

export default defineComponent({
  components: {
    ItemDetail,
    HeadstoneItem,
  },
  setup () {
    const localState = reactive<{ mounted: boolean }>({
      mounted: false,
    })

    const store = useStore()
    const route = useRoute()
    const hasContentError = ref()
    const router = useRouter()
    const showViewer = ref(false)
    const iv = ImageViewer.getInstance()

    const item = ref()
    const pid = `info:ndljp/pid/${route.params.pid}`
    onMounted(async () => {
      // 初回描画時にNotPublishedを表示しないため。
      try {
        await AccessTokenService.setAccessTokenAsync(pid)
      } catch (e) {
        console.error('AccessTokenService.setAccessTokenAsync error 3')
        console.error(e)
        throw e
      }

      localState.mounted = true
    })

    const updateShowViewer = (val: boolean) => {
      showViewer.value = val
    }

    const fulltextSearch = () => {
      // アイテム詳細画面表示時にvuexが保持する以前の全文検索結果をリセット
      store.dispatch('resetSnippetSearchList')
      // アイテム詳細画面表示時に画像ビューア内のピン情報をリセット
      iv.changeCurrentSearchHitPin(-1)
      iv.deleteSearchHitInfo()
      store.commit('setSelectedSnippetId', 0)
      // クエリパラメータにキーワードがある場合は全文検索を実行
      if (route.query.keyword) {
        const value = route.query.keyword.toString()
        store.dispatch('addonViewFulltextSearch', { value, router, route })
      }
    }

    let currentPid: string | string[] = ''
    watchEffect(async () => {
      // 初回ロード時、PIDが変更された場合の処理
      if (!route.params.pid) return
      if (route.params.pid === currentPid) return
      updateShowViewer(false)

      const hasLangInfo = route.path.match('^/en|ja')
      const pidPositionNum = hasLangInfo ? 4 : 3
      const bundleIdx = route.params.bundleIndex
      const contentIdx = route.params.contentIndex
      //  現在有効なpidをクロージャに退避
      currentPid = route.params.pid

      // アイテムメタデータの取得
      await store.dispatch('fetchContentData', route.params)
      hasContentError.value = store.getters.hasContentSearchError

      // 初期表示バンドルの設定
      if (bundleIdx) {
        store.commit('updateCurrentBundleIndex', {
          currentBundleIndex: Math.max(Number(route.params.bundleIndex.toString()) - 1, 0),
        })
      } else {
        await store.dispatch('setInitialContentBundle')
      }

      // 初期表示コンテンツの設定
      store.commit('updateCurrentContentIndex', {
        currentContentIndex: Math.max(Number(route.params.contentIndex.toString()) - 1, 0),
      })

      // バンドルidx || コンテンツidxに対してアイテムがない場合にPIDのみで再度画面遷移
      const targetItem = store.getters.item
      if (bundleIdx && bundleIdx > (targetItem.contentsBundles?.length || 0)) {
        router.push({ path: route.path.split('/').slice(0, pidPositionNum).join('/') })
      } else if (bundleIdx && contentIdx && contentIdx > (targetItem.contentsBundles?.[Number(bundleIdx) - 1]?.contents?.length || 0)) {
        router.push({ path: route.path.split('/').slice(0, pidPositionNum).join('/') })
      }
      updateShowViewer(true)
      // 全文検索
      fulltextSearch()
    })

    // 同一アイテム内でバンドルが変更された場合に全文検索を実行
    watch(() => store.getters.currentContentsBundle, (before, after) => {
      const bundleIdx = route.params.bundleIndex
      if (bundleIdx && before && after && (before?.id !== after?.id)) {
        fulltextSearch()
      }
    })

    // 必要なので消さないでください
    if (!store.dispatch('fetchContentData', route.params)) {
      store.registerModule(item.value.pid, {
        namespaced: true,
        ...storeModule,
      })
    }

    watchEffect(() => {
      // コンテンツタブで選択されているコンテンツ位置までスクロールさせる
      // checkCurrentContentのvalueをwatchEffectの監視対象にするため
      const checkCurrentContent =
        store.getters.bundleNumber + store.getters.contentNumber
      setTimeout(currentContentScrollTo, 100)
    })

    watchEffect(() => {
      item.value = store.getters.item
    })

    watch(item, (newItem) => {
      if (!newItem.pid) return
      if (!store.state[newItem.pid]) {
        store.registerModule(newItem.pid, {
          namespaced: true,
          ...storeModule,
        })
      }
    })

    return {
      item,
      localState,
      hasContentError,
      showViewer,
    }
  },
})
