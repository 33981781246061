import { simpleRecordsCustomLabel } from '@/data/@types/SimpleRecordsCustomLabel'
import { simpleRecords } from '@/data/master/CustomMetadataLabel'

/**
 * パラメータで受け取ったメタデータのラベル名を取得
 * @param store.getters
 * @param collectionId
 * @param metadataFieldName
 * @returns パラメータで受け取ったメタデータのラベル名
 */
export const getMetadataLabel = (storeGetters: any, collectionId: string, metadataFieldName: string) => {
  const metadataMasterId = storeGetters.MetadataObjectByField(metadataFieldName).id
  const collectionFamily = storeGetters.CollectionFamily(collectionId)

  const customLabelMapping: simpleRecordsCustomLabel = simpleRecords

  const labelPatternList = Object.keys(customLabelMapping)

  collectionFamily.reverse() // 下位コレクションの設定のほうが先にhitするようにする
  const labelPattern: string = collectionFamily.find(
    (collection: any) => labelPatternList.includes(collection.collectionId)
  )?.collectionId || 'default'

  const customMetadataLabel = customLabelMapping[labelPattern]?.[`label_${metadataMasterId}`]

  if (customMetadataLabel) return customMetadataLabel // ラベル表記が指定されていた場合
  else {
    // ラベル表記が指定されていない場合はメタデータマスタから取得
    const metadataFromMaster = storeGetters.MetadataObjectByField(metadataFieldName)
    return { en: metadataFromMaster.label_en, ja: metadataFromMaster.label }
  }
}
