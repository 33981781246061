
import { defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'
import DssIcon from './DssIcon.vue'

export default defineComponent({
  name: 'AppIconPlaces',
  components: {
    DssIcon,
  },
  props: {
    type: {
      type: String,
      default: 'internet',
      // TODO 現状は指定されていた値以外で値が代入される
      // type: String as PropType<'internet' | 'ooc' | 'inlibrary' | 'rekion'>,
      // validator: (val: string): boolean => {
      //   return ['internet', 'ooc', 'inlibrary', 'rekion'].includes(val)
      // }
    },
    size: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const route = useRoute()

    const icon = (() => {
      if (props.type === 'internet') {
        return 'network'
      } else if (props.type === 'ooc') {
        return 'bookshelf'
      } else if (props.type === 'rekion') {
        return 'place-library-rekion'
      } else if (props.type === 'inlibrary') {
        return 'place-ndl'
      } else {
        return false
      }
    })()

    return {
      icon,
    }
  },
})
