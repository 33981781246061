import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c781965"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type", "id", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.styled, "app-button"]),
    disabled: _ctx.disabled,
    type: _ctx.type,
    id: _ctx.buttonId,
    "aria-label": _ctx.label
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}