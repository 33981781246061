/**
 * 登録利用者の登録状態
 */
export const RegistrationStatusCode = {
  SIMPLE: 'SIMPLE', // 簡易登録
  PROVISIONAL: 'PROVISIONAL', // 仮登録
  OFFICIAL: 'OFFICIAL', // 本登録
} as const

export type RegistrationStatusCode = typeof RegistrationStatusCode[keyof typeof RegistrationStatusCode]

/**
 * 利用者種別
 */
export const PatronTypeCode = {
  DIET_MEMBER: 'DIET_MEMBER', // 国会議員
  DIET_STAFF: 'DIET_STAFF', // 国会職員
  BRANCHLIB: 'BRANCHLIB', // 支部図書館
  LIBRARY: 'LIBRARY', // 各種図書館
  BRAILLE: 'BRAILLE', // 点字図書館
  REGISTERED: 'REGISTERED', // 登録利用者
  ONEDAY: 'ONEDAY', // 当日利用者
  NDL_STAFF: 'NDL_STAFF', // NDL職員
  PHOTO: 'PHOTO', // 複写代行入力
  TEMPORARY_DUMMY: 'TEMPORARY_DUMMY', // 臨時
  LIBTRANS: 'LIBTRANS', // 図書館送信
  PCUSE_DUMMY: 'PCUSE_DUMMY', // 端末利用
} as const

export type PatronTypeCode = typeof PatronTypeCode[keyof typeof PatronTypeCode]

/**
 * 利用者の追加属性のキーを表すコード
 */
export const PatronAttributeKeyCode = {
  MICRO: 'MICRO', // マイクロリーダ有無
  LIBSEND: 'LIBSEND', // デジタルコレクション使用権限
  ILCL_LOAN: 'ILCL_LOAN', // 子ども図資料貸出
  ILL: 'ILL', // 図書館間貸出
  PHOTO: 'PHOTO', // 借受館複写
  DAISY: 'DAISY', // 視覚障害者等用データ送信
  PERSEND: 'PERSEND', // 個人送信サービス
} as const

export type PatronAttributeKeyCode = typeof PatronAttributeKeyCode[keyof typeof PatronAttributeKeyCode];

/**
 * 利用者の追加属性のバリューを表すコード
  */
export const PatronAttributeValueCode = {
  MICRO_FISH: 'MICRO_FISH', // フィッシュリーダあり
  MICRO_FILM: 'MICRO_FILM', // フィルムリーダあり
  MICRO_FISHFILM: 'MICRO_FISHFILM', // フィッシュ、フィルムリーダともにあり
  MICRO_NONE: 'MICRO_NONE', // マイクロリーダなし
  MICRO_UNKNOWN: 'MICRO_UNKNOWN', // 未調査
  LIBSEND_B_PATRON: 'LIBSEND_B_PATRON', // （閲覧のみ参加館）利用者閲覧用
  LIBSEND_B_STAFF: 'LIBSEND_B_STAFF', // （閲覧のみ参加館）図書館職員の業務用
  LIBSEND_BP_PATRON: 'LIBSEND_BP_PATRON', // （閲覧・複写とも参加館）利用者閲覧用
  LIBSEND_BP_STAFF: 'LIBSEND_BP_STAFF', // （閲覧・複写とも参加館）複写用、図書館職員の業務用
  LIBSEND_B_PERSEND: 'LIBSEND_B_PERSEND', // 個人送信可能利用者
  ILCL_LOAN_OK: 'ILCL_LOAN_OK', // 子ども図資料貸出可
  ILCL_LOAN_NOTOK: 'ILCL_LOAN_NOTOK', // 子ども図資料貸出不可
  ILL_OK: 'ILL_OK', // 図書館間貸出可
  ILL_NOTOK: 'ILL_NOTOK', // 図書館間貸出不可
  PHOTO_ALL: 'PHOTO_ALL', // 借受館複写可
  PHOTO_TML_KSK: 'PHOTO_TML_KSK', // 東京本館・関西館資料のみ借受館複写可
  PHOTO_NONE: 'PHOTO_NONE', // 借受館複写不可
  DAISY_REGISTER: 'DAISY_REGISTER', // 送信登録者（審査中）
  DAISY_REEX: 'DAISY_REEX', // 送信登録者（再審査要）
  DAISY_NOREEX: 'DAISY_NOREEX', // 送信登録者（再審査不要）
  DAISY_LIB: 'DAISY_LIB', // 送信承認館
  ACCEPTED: 'ACCEPTED', // 同意済
} as const

export type PatronAttributeValueCodeType = typeof PatronAttributeValueCode[keyof typeof PatronAttributeValueCode];

/**
 * 利用者属性情報
 */
export interface Attr {
  updated?: number
  id?: number
  key: PatronAttributeKeyCode
  value: PatronAttributeValueCodeType
}

/**
 * 利用制限コード
 */
export const RestrictionTypeCode = {
  ENTER: 'ENTER', // 入館禁止
  EXIT: 'EXIT', // 退館禁止
  LOGIN: 'LOGIN', // ログイン禁止
  PHOTO: 'PHOTO', // 複写禁止
  ALL: 'ALL', // 全サービス利用禁止
  PERSEND: 'PERSEND', // 個人送信利用禁止
  ALL_EXPERSEND: 'ALL_EXPERSEND', // 全サービス利用禁止（個人送信を除く）
} as const

export type RestrictionTypeCode = typeof RestrictionTypeCode[keyof typeof RestrictionTypeCode]

/**
 * 利用制限情報
 */
export interface Restriction {
  created?: number
  updated?: number
  id?: number
  type: RestrictionTypeCode
  reason?: string
}

/**
 * 本人確認書類確認状態コード
 */
export const CertificateStatusCode = {
  INPROCESS: 'INPROCESS', // 確認中
  ACCEPTED: 'ACCEPTED', // 確認済み
  PENDING: 'PENDING', // 保留
  REJECTED: 'REJECTED', // 問題あり
  FINISHED: 'FINISHED', // 終了
} as const

export type CertificateStatusCode = typeof CertificateStatusCode[keyof typeof CertificateStatusCode]

/**
 * 本人確認書類情報
 */
export interface Certificate {
  status: CertificateStatusCode
}

/**
 * デジデポの認証で使用するPatron情報
 */
export interface Patoron {
  attrs: Attr[]
  cardId: string
  certificate: Certificate
  country: string
  id: number
  name: string
  patronId: string
  type: PatronTypeCode
  registrationStatus?: RegistrationStatusCode
  restrictions: Restriction[]
  testData: boolean
}

/**
 * SSOログインAPIのリクエスト
 */
export interface SsoLoginRequest {
  cardId: string
  password: string
  enableRm: boolean
}

/**
 * SSOログインAPIのレスポンス
 */
export interface SsoLoginResponse {
  sessionId: string
  patron: Patoron
}

/**
 * 認証状態
 */
export interface AuthState {
  authenticated: boolean
  cardId?: string
  patron?: Patoron
  authorities: string[]
}

/**
 * 組み込みユーザ権限タイプ
 */
export const AuthorityType = {
  NON_LIMIT: 'NON_LIMIT',
  ALL_RIGHT: 'ALL_RIGHT',
  ALL_PERMISSION: 'ALL_PERMISSION',
  REKION: 'REKION',
  REKION_ADMIN: 'REKION_ADMIN',
  ROLE_ANONYMOUS: 'ROLE_ANONYMOUS',
  PACKAGE: 'PACKAGE',
} as const

export type AuthorityType = typeof AuthorityType[keyof typeof AuthorityType]

/**
 * 組み込みユーザ権限ロール
 */
export const AuthorityRole = {
  NON_LIMIT: 'NON_LIMIT',
  ALL_RIGHT: 'ALL_RIGHT',
  ALL_PERMISSION: 'ALL_PERMISSION',
  REKION: 'REKION',
  REKION_ADMIN: 'REKION_ADMIN',
  ROLE_ANONYMOUS: 'ROLE_ANONYMOUS',
  PACKAGE: 'PACKAGE',
} as const

export type AuthorityRole = typeof AuthorityRole[keyof typeof AuthorityRole]

/**
 * 組み込みログインAPIのリクエスト
 */
export interface BuiltinLoginRequest {
  cardId: string
  password: string
}

/**
 * 組み込みログインAPIのレスポンス
 */
export interface BuiltinLoginResponse {
  cardId?: string
  type?: AuthorityType
  role: AuthorityRole[]
  requestId?: string
  message?: string
  success?: boolean
}
