import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a60dcede"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-window-container" }
const _hoisted_2 = {
  class: "modal-window shadow-deep",
  role: "dialog",
  "aria-modal": "true",
  "aria-labelledby": "modal-window-heading"
}
const _hoisted_3 = { class: "modal-window-header level is-mobile" }
const _hoisted_4 = {
  id: "modal-window-heading",
  class: "title has-text-weight-normal level is-mobile"
}
const _hoisted_5 = {
  key: 0,
  class: "has-text-alert warn-message has-text-weight-normal level is-mobile"
}
const _hoisted_6 = { class: "modal-window-body has-background-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.heading), 1),
        (_ctx.warnMessage)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.$t('label.loginWarnMessage')), 1))
          : _createCommentVNode("", true),
        (_ctx.hasClose)
          ? (_openBlock(), _createBlock(_component_ButtonIcon, {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]), ["space","enter"])),
              styled: "is-none",
              icon: "xmark-circle",
              "aria-label": `${_ctx.heading} ${_ctx.$t('label.dialogClose')}`
            }, null, 8, ["aria-label"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}