
import { defineComponent, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import AppButton from '@/components/atoms/AppButton.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppCheckbox from '@/components/atoms/AppCheckbox.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import { SsoLoginRequest } from '@/data/@types/Authentication'
import { MUTATIONS } from '@/store/mutations-types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    AppButton,
    AppInput,
    AppCheckbox,
    TextAlert,
  },
  emits: ['close'],
  setup (_, { emit }) {
    const store = useStore()
    const i18n = useI18n()

    const form = reactive<SsoLoginRequest>({
      cardId: '',
      password: '',
      enableRm: false,
    })
    const localState = reactive({
      userIdErrFlag: false,
      passwordErrFlag: false,
    })
    const errorMessage = computed(() => {
      const errorText = store.getters.errorTextInLoginPage
      if (errorText === '') {
        // ログイン成功時
        return ''
      } else if (errorText !== 'Internal Server Error') {
        // ID・パスワードで不正な入力による認証失敗時
        return i18n.t('message.loginError.authenticationError')
      } else {
        // サーバダウンなどでサーバエラーによるログイン失敗時
        return i18n.t('message.loginError.serverDownError')
      }
    })
    const hasError = computed(() => {
      return store.getters.hasErrorInLoginPage
    })
    const submitDisable = computed(() => !form.cardId || !form.password)

    const login = async () => {
      if (submitDisable.value) {
        return
      }
      await store.dispatch('login', form)
      if (!hasError.value) {
        store.commit(MUTATIONS.LOGIN_NOTIFICATION, i18n)
        emit('close')
      }
    }

    return {
      form,
      localState,
      errorMessage,
      submitDisable,
      login,
      blurCheck (type: string) {
        if (type === 'cardId') {
          localState.userIdErrFlag = !form.cardId
        } else if (type === 'password') {
          localState.passwordErrFlag = !form.password
        }
      },
    }
  },
})
