
import { computed, defineComponent } from 'vue'
import RadioButtonList from '@/components/molecules/RadioButtonList.vue'
import AddonViewProcessingColor from '../AddonViewProcessingColor.vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { ViewMode } from '@/helpers/imageviewer/ImageViewer'
import { Content } from '@/data/@types/Content'
import { bindingDirectionList } from '@/data/selector/imageToolbarList'
import AutoClipToggleSwitch from '@/components/molecules/AutoClipToggleSwitch.vue'
import { useRoute, useRouter } from 'vue-router'
import { getSelector } from '@/data/selector/getSelector'
import TwoInOneCoverPageToggleSwitch from '@/components/molecules/TwoInOneCoverPageToggleSwitch.vue'

export default defineComponent({
  components: {
    RadioButtonList,
    AutoClipToggleSwitch,
    AddonViewProcessingColor,
    TwoInOneCoverPageToggleSwitch,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const i18n = useI18n()
    const currentBindingDirection = computed({
      get: () => store.getters.bindingDirection,
      set: (val) => {
        store.commit('updateBindingDirection', val)
        localStorage.setItem('direction', val)
      },
    })

    // 分割パラメータを保持しているかのフラグ
    const hasDivideData = computed(() => {
      return store.getters.contents?.some((content: Content) => {
        const devideData = content?.extra?.layout?.divide
        return devideData && devideData > 0 && devideData < 1
      })
    })
    const viewMode = computed(() => store.getters.currentContentsBundle.layout || 'spread')

    if (hasDivideData.value && Number(localStorage.viewMode) === ViewMode.SINGLE) {
      store.commit('changeViewMode', ViewMode.SINGLE)
    }
    const viewModeType = computed({
      get: () => store.getters.imageviewerViewMode,
      set: (val) => {
        store.commit('changeViewMode', val)
        // 片ページ表示の時とみページ方向のパラメータを付与させるため
        if (val === ViewMode.SINGLE) {
          const isLTR = currentBindingDirection.value === 'ltr'
          localStorage.setItem('viewMode', String(ViewMode.SINGLE))
          // アイテム内の遷移のためヒストリーに残さずに遷移する
          if (route.query.keyword) {
            router.replace(route.path + (isLTR ? '?page=left' : '?page=right') + '&keyword=' + route.query.keyword)
          } else {
            router.replace(route.path + (isLTR ? '?page=left' : '?page=right'))
          }
        } else {
          localStorage.setItem('viewMode', String(ViewMode.KOMA))
          // アイテム内の遷移のためヒストリーに残さずに遷移する
          if (route.query.keyword) {
            router.replace(`${route.path}?keyword=${route.query.keyword}`)
          } else {
            router.replace(route.path)
          }
        }
      },
    })

    // 非活性のフィールドを追加する必要があるためコンポーネント内で定義
    const viewModeList = computed(() => {
      const canSingleView = viewMode.value === 'spread'
      return [
        { id: 'single', value: canSingleView ? ViewMode.SINGLE : ViewMode.KOMA, disabled: !hasDivideData.value && canSingleView },
        { id: 'open', value: canSingleView ? ViewMode.KOMA : ViewMode.TOW_IN_ONE },
      ]
    })
    const viewModeListLocal = computed(() => getSelector('mobileViewModeList', viewModeList.value, i18n.t))

    const directionList = computed(() => getSelector('mobileBindingDirectionList', bindingDirectionList, i18n.t))

    const hasClippingData = computed(() => {
      const currentClippingData = store.getters.crippingData
      if (currentClippingData) return true
      return false
    })

    return {
      currentBindingDirection,
      viewModeListLocal,
      viewModeType,
      directionList,
      hasClippingData,
    }
  },
})
