
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  defineComponent,
  onBeforeUnmount,
  ref,
  watchEffect,
  onMounted,
  computed,
  watch,
} from 'vue'
import {
  ImageViewer,
  ImageViewerOption,
  InitialCropping,
  ScaleStepNotice,
  ViewMode,
} from '../helpers/imageviewer/ImageViewer'
import { IVError } from '../helpers/imageviewer/IVError'
import { GenerateContentJson, getDummyKoma } from '../helpers/imageviewer/GenerateContentJson'
import ImageViewerToolbar from './organisms/ItemContentViewer/ImageViewerToolbar.vue'
import { Binding } from '@/helpers/imageviewer/ContentStructure'
import IconLoading from '@/components/atoms/IconLoading.vue'
import { RectMM } from '@/helpers/imageviewer/ImageViewerCommon'
import { AccessTokenService } from '@/store/modules/AccessToken'
import { getTwoInOnePageIndex, getSingleModePageIndex, getPagesData, getSingleModePageSideParam } from '@/domain/item/itemViewer/imageViewerUtil'
import { PageObject } from '@/data/@types/PageObject'
import { Content } from '@/data/@types/Content'
import md from '@/helpers/util/MobileDetect'
import useI18nFunction from '@/helpers/hooks/useI18nFunction'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { generateKeyPair } from '@/helpers/util/generateKeyPair'

ImageViewer.DUMMY_KOMA = getDummyKoma()
const iv = ImageViewer.getInstance()

export default defineComponent({
  name: 'TheImageViewer',
  components: {
    ImageViewerToolbar,
    IconLoading,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    ischangedToViewerPoster: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    // ページが変更されたときの新しいページインデックスとコマID通知
    'noticeCurrentPageIndex',
    // 描画スケールが変更されたときの新しい描画スケール通知
    'noticeCurrentDrawingScale',
    // 描画スケールステップが変更されたときに新しい描画スケールステップを通知する
    'noticeNewScaleSteps',
    // ビューア中央部のシングルタップ・クリックを通知する
    'noticeTapTheCenter',
    // 印刷範囲変更を通知する
    'noticeChangePrintCropping',
  ],
  setup (props, { emit }) {
    const isMobile = md.phone()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const i18nFunction = useI18nFunction()
    const isServer = typeof window === 'undefined'

    const currentViewMode = computed(() => store.getters.imageviewerViewMode)
    const currentContentId = computed(() => store.getters.contentId)
    const contents: Array<Content> | undefined = store.getters.contents
    const processingData = computed(() => store.getters.processingData)
    const croppingModeFlag = computed(() => store.getters.croppingModeFlag)
    const croppedRange = computed(() => store.getters.processingData.croppedRange)
    const autoClipFlag = computed(() => store.getters.processingData.autoClipFlag)
    // 表示モードの値が設定されていないときは初期値を'spread'として扱う
    const viewMode = computed(() => store.getters.currentContentsBundle.layout || 'spread')
    // 分割パラメータを保持しているかのフラグ
    const hasDivideData = computed(() => {
      return contents?.some((content: Content) => {
        const devideData = content?.extra?.layout?.divide
        return devideData && devideData > 0 && devideData < 1
      })
    })
    const ivclient = ref<HTMLDivElement>()

    const ivclientCover = ref<HTMLDivElement>()
    const showLoadingIcon = ref<boolean>(true)

    if (props.ischangedToViewerPoster) {
      // SP版のビューア表示からポスター表示に切り替えたときにpageIndexが更新されないため
      showLoadingIcon.value = false
    }

    // if(iv.disposed){
    //   iv = ImageViewer.getInstance()
    // }

    /** *** 画像ビューア制御API *****/

    const {
      /**
       * 指定したページインデックスを表示する
       * (pageIndex: number) => number
       */
      changePage,
      /**
       * 次のページを表示する
       * () => boolean
       */
      toNextPage,
      /**
       * 前のページを表示する
       * () => boolean
       */
      toPrevPage,
      /**
       * 次のページを表示する
       * () => boolean
       */
      goToNextPage,
      /**
       * 前のページを表示する
       * () => boolean
       */
      goToPrevPage,
      changeDirection,
      /**
       * ユーザーによる回転角度を変更する
       * (angle:number) => boolean
       */
      changeRotation,
      /**
       * 表示モードを変更する
       * (mode: ViewMode) => boolean
       */
      changeViewMode,

      /**
       * フィット表示させる
       * () => number
       */
      showFit,

      /**
       * 描画スケールを一段階上げる
       * () => void
       */
      scaleUp,
      /**
       * 描画スケールを一段階下げる
       * () => void
       */
      scaleDown,

      /**
       * 現在のスケールステップを返す
       * () => ScaleStepNotice
       */
      getScaleStep,
      /**
       * 現在の最大拡大倍率を返す
       * () => number | undefined
       */
      getMaxScale,
      /**
       * 現在の最小縮小倍率を返す
       * () => number | undefined
       */
      getMinScale,
      /**
       * 内部倍率を指定して画像の拡大率を変更する
       * (scale: number) => void
       */
      changeScale,
      /**
       * 表示倍率を指定して画像の拡大率を
       * (dipsScale: number) => void
       */
      changeScaleByDisplayScale,
      /**
       * 印刷時の画像の分割数を指定する
       * (horizontal: number, vertical:number) => void
       */
      setDivision,

      /**
       * 自動余白削除を有効にする
       * () => void
       */
      enableAutoClipping,

      /**
       * 自動余白削除を無効にする
       * () => void
       */
      disableAutoClipping,

      /**
       * 切り抜きモードに入る
       * () => void
       */
      execPrintCroppingMode,

      /**
       * 切り抜きモードから出る
       * () => void
       */
      exitPrintCroppingMode,

      /**
       * 特定サイズの選択範囲を設置する（切り抜きモードに入っていない場合は、自動的にモードに入る）
       * (left: number, top: number, width: number, height: number, label?: string, centering?: boolean, dpi?: number) => boolean
       */
      setPrintCroppingArea,

      /**
       * 切り抜きモードを介さず、直接範囲指定と切り抜き実行を行う
       * (left: number, top: number, width: number, height: number, label?:string, dpi?: number) => boolean
       */
      cropArea,

      /**
       * 現在の選択範囲で切り抜く
       * () => boolean
       */
      cropWithCurrentArea,

      /**
       * 切り抜き範囲のビットマップのdataURLを取得する
       * () => string
       */
      getCroppingAreaBitmap,

      /**
       * 切り抜きを無効化する
       * () => void
       */
      disableCropping,

      /*
       * ルーラーを表示する
       * () => void
       *
      showRuler,
      */

      /*
       * ルーラーを非表示にする
       * () => void
       *
      hideRuler,
      */

      /**
       * 現在表示中の画像の縮小版 ImageData を返す
       * (size=128) => ImageData | undefiend
       */
      getImageData,

      /**
       * 閲覧制限トークンを更新する
       * (newToken: Array<AccessToken>) => void
       */
      updateToken,

      /**
       * ダーク・ライトモードの変更をビューアに通知する
       * () => void
       */
      updateStyleMode,

      /**
       * ビューアキャンバスサイズの変更をビューアに通知する
       * () => void
       */
      viewerSizeChanged,

      /*
       * 2in1表示時の表紙ページの表示モードを変更する
       * (mode: CoverPageMode) => void
       */
      changeCoverPageMode,

    } = iv.getPublicMethods()

    /** *** 画像ビューア制御API *****/

    const setProcessingData = () => {
      const currentProcessingData = store.getters.processingData
      if (currentProcessingData) {
        iv.changeRotation(currentProcessingData.degree)
        iv.applyBrightnessFilter(currentProcessingData.brightness)
        iv.applyContrastFilter(currentProcessingData.contrast)
        iv.applySharpnessFilter(currentProcessingData.sharpness)
        iv.applyGammaFilter(currentProcessingData.gamma)
        iv.applyGrayscaleFilter(currentProcessingData.grayscaleFlag)
      }
    }

    watch(
      () => currentContentId.value,
      async () => {
        // console.log(`content changed. = ${cid}`)
        // TODO アクセストークンの差し替え
        // AccessTokenService.setAccessToken(`info:ndljp/pid/${router.params.pid}`)
        // const tokens = await AccessTokenService.findAll()
      }
    )

    /** *** 親コンポーネントへの通知 emit *****/

    /**
     * ページが変更されたときに新しいページインデックスを通知する
     * @param pageIndex: 新しいページインデックス 0オリジン
     * @param komaID: 新しいページが参照しているKomaID = Komaインデックス
     */
    const noticeCurrentPageIndex = (pageIndex: number, komaID: number) => {
      emit('noticeCurrentPageIndex', [pageIndex, komaID])
      showLoadingIcon.value = false
      // 表示モードによって画像ビューアが持つコマ番号とアイテムが持つコマ番号が異なるため
      if (currentViewMode.value === ViewMode.TOW_IN_ONE) {
        const paramsPageIndex = Number(paramsContentIndex.value) - 1 // 0オリジン
        const isDisplayedPage = getTwoInOnePageIndex(paramsPageIndex, processingData.value.addCoverPageFlag) === pageIndex
        // 2in1で表示しているページとコンテンツタブで指定されたページが同じときはcurrentContentIndexの更新を行わない
        if (isDisplayedPage) return
        store.commit('updateCurrentContentIndex', {
          currentContentIndex: pageIndex * 2,
        })
      } else if (currentViewMode.value === ViewMode.SINGLE) {
        const paramsPageIndex = Number(paramsContentIndex.value) - 1 // 0オリジン
        const isDisplayedPage = paramsPageIndex === komaID
        // 片ページで表示しているページとコンテンツタブで指定されたページが同じときはcurrentContentIndexの更新を行わない
        if (!isDisplayedPage) {
          store.commit('updateCurrentContentIndex', {
            currentContentIndex: komaID,
          })
        }
        // 片ページ表示のときクエリパラメータで表示するページを指定させるため
        const pageSideParam = getSingleModePageSideParam(pageIndex, komaID, store.getters.bindingDirection, pages)
        // アイテム内の遷移のためヒストリーに残さずに遷移する
        if (route.query.keyword) {
          router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (store.getters.bundleNumber + 1) + '/' + (komaID + 1) + pageSideParam + '&keyword=' + route.query.keyword)
        } else {
          router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (store.getters.bundleNumber + 1) + '/' + (komaID + 1) + pageSideParam)
        }
      } else {
        store.commit('updateCurrentContentIndex', {
          currentContentIndex: pageIndex,
        })
      }

      // コンテンツ番号を持つURLかどうか判定
      const hasContentNumberInPath = !!route.path.split('pid/')[1].split('/')[2]
      // URLにコンテンツ番号が含まれている場合のみ、コマ移動でURLを更新する必要があるため遷移させる。
      if (!hasContentNumberInPath && pageIndex === 0) return
      if (currentViewMode.value === ViewMode.SINGLE) return // 片ページ表示の時はパラメータに"?page=left or right"を付与するため
      // アイテム内の遷移のためヒストリーに残さずに遷移する
      if (route.query.keyword) {
        router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (store.getters.bundleNumber + 1) + '/' + (store.getters.contentNumber + 1) + '?keyword=' + route.query.keyword)
      } else {
        router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (store.getters.bundleNumber + 1) + '/' + (store.getters.contentNumber + 1))
      }
    }
    iv.addNoticeCurrentPageIndexListener(noticeCurrentPageIndex)

    /**
     * 描画スケールが変更されたときに新しい描画スケールを通知する
     * @param scale: number 新しい描画スケール
     */
    const noticeCurrentDrawingScale = (scale: number) => {
      emit('noticeCurrentDrawingScale', scale)
      store.dispatch('setCurrentScaleValue', scale)
    }
    iv.addNoticeCurrentDrawingScaleListener(noticeCurrentDrawingScale)

    /**
     * 描画スケールステップが変更されたときに新しい描画スケールステップを通知する
     * @param steps 新しい描画スケールステップ通知オブジェクト
     */
    const noticeNewScaleSteps = (stepNotice: ScaleStepNotice) => {
      emit('noticeNewScaleSteps', stepNotice)
      store.dispatch('setCurrentScaleValue', stepNotice.currentInnerScale)
      store.dispatch('setMaxScaleValue', stepNotice.scaleSteps[0].innerScale)
      store.dispatch(
        'setMinScaleValue',
        stepNotice.scaleSteps[stepNotice?.scaleSteps.length - 1].innerScale
      )
    }
    iv.addNoticeNewScaleStepsListener(noticeNewScaleSteps)

    /**
     * ビューア中央部のシングルタップを通知する
     */
    const noticeTapTheCenter = () => {
      emit('noticeTapTheCenter')
    }
    iv.addNoticeTapTheCenterListener(noticeTapTheCenter)

    /**
     * 印刷範囲変更を通知する
     */
    const noticeChangePrintCropping = (rectMM: RectMM) => {
      emit('noticeChangePrintCropping', rectMM)
      store.commit('updateSelectionRange', rectMM)
      store.commit('updateCroppingSizeTextValue', '')
    }
    iv.addNoticeChangePrintCroppingListener(noticeChangePrintCropping)

    const currentBundleIndex = computed(() => store.getters.bundleNumber)
    // ページめくり方向：contentsBundles.viewingDirection を使用
    const bindingDirection =
      store.getters.item.contentsBundles?.[currentBundleIndex.value]?.viewingDirection
    store.commit('updateBindingDirection', bindingDirection || localStorage.getItem('direction') || 'rtl')

    let pages: PageObject
    if (contents && !hasDivideData.value) {
      const pageList = contents.map((_, i: number) => {
        return {
          PageID: i,
          Koma: i,
          Area: { Left: 0, Top: 0, Right: 1.0, Bottom: 1.0 },
        }
      })
      pages = { pagesDataLTR: pageList, pagesDataRTL: pageList }
    } else {
      if (contents) pages = getPagesData(contents, bindingDirection)
    }

    onMounted(async () => {
      if (!ivclient.value) {
      } else {
        let tokens: any[] = []
        try {
          tokens = await AccessTokenService.findByPid(props.item.pid)
        } catch (e) {
          console.error('AccessTokenService.findByPid error 2')
          console.error(e)
          throw e
        }

        if (tokens.length > 0 && Date.now() < tokens[0].timestamp) {
          // 起動オプション 生成方法はよしなに。
          const opt = new ImageViewerOption()

          // 暗号用キーペアを画像ビューアに渡す
          opt.keyPair = generateKeyPair()

          // 初期表示のオプションを設定
          if (!isMobile.value) { // デスクトップ版
            if (viewMode.value === 'single') {
              // 表示モードの初期値が'single'のアイテム
              store.commit('changeViewMode', ViewMode.TOW_IN_ONE)
              opt.initialPageIndex = getTwoInOnePageIndex(store.getters.contentNumber, processingData.value.addCoverPageFlag) // 初期表示ページの設定
              opt.initialViewMode = ViewMode.TOW_IN_ONE // 初期表示を2in1表示とする
            } else {
              // 表示モードの初期値が'spread'のアイテム
              store.commit('changeViewMode', ViewMode.KOMA)
              opt.initialPageIndex = store.getters.contentNumber // 初期表示ページの設定
              opt.initialViewMode = ViewMode.KOMA // 初期表示を1コマ表示とする
            }
          } else { // モバイル版
            if (viewMode.value === 'spread' && hasDivideData.value) {
              // 表示モードの初期値が'spread'のアイテム
              if (currentViewMode.value === ViewMode.KOMA || !hasDivideData.value) {
                // 1コマ表示が指定されているもしくは分割パラメータを持たない場合
                store.commit('changeViewMode', ViewMode.KOMA) // 初期表示を1コマ表示とする
                opt.initialViewMode = ViewMode.KOMA
                opt.initialPageIndex = store.getters.contentNumber
              } else {
                store.commit('changeViewMode', ViewMode.SINGLE)
                opt.initialViewMode = ViewMode.SINGLE // 初期表示を片ページ表示とする
                // 初期表示ページの設定
                opt.initialPageIndex = getSingleModePageIndex(store.getters.contentNumber, route, store.getters.bindingDirection, pages)
              }
            } else {
              // 表示モードの初期値が'single'のアイテム
              if (currentViewMode.value === ViewMode.TOW_IN_ONE) {
                // 2in1表示が指定されていたとき
                store.commit('changeViewMode', ViewMode.TOW_IN_ONE) // 初期表示を2in1表示とする
                opt.initialPageIndex = getTwoInOnePageIndex(store.getters.contentNumber, processingData.value.addCoverPageFlag) // 初期表示ページの設定
                opt.initialViewMode = ViewMode.TOW_IN_ONE
              } else {
                store.commit('changeViewMode', ViewMode.KOMA)
                opt.initialPageIndex = store.getters.contentNumber // 初期表示ページの設定
                opt.initialViewMode = ViewMode.KOMA // 初期表示を1コマ表示とする
              }
            }
          }

          // 初期切り抜き指定 単位はすべてミリメートル
          const ic = new InitialCropping()
          ic.left = 10
          ic.top = 20
          ic.width = 100
          ic.height = 200
          ic.label = 'Initial Crop'
          // opt.initalCropping = ic

          if (contents) {
            iv.init(
              ivclient.value,
              GenerateContentJson(
                contents,
                bindingDirection || 'rtl',
                tokens,
                viewMode.value,
                pages,
                isMobile.value
              ),
              opt
            ).then(result => {
              if (result !== IVError.NONE) {
                // 起動失敗原因を表示 適切なエラー処理があれば置き換える
                console.log(iv.lastError)
              } else {
                setProcessingData()

                iv.addNoticeCurrentPageIndexListener(noticeCurrentPageIndex)
                iv.addNoticeCurrentDrawingScaleListener(noticeCurrentDrawingScale)
                iv.addNoticeNewScaleStepsListener(noticeNewScaleSteps)
                iv.addNoticeTapTheCenterListener(noticeTapTheCenter)
                iv.addNoticeChangePrintCroppingListener(noticeChangePrintCropping)

                document.addEventListener('keydown', getPressedKey)
              }
            })
          } else {
            // 起動失敗原因を表示 適切なエラー処理があれば置き換える
            console.log(iv.lastError)
          }
        }
      }
    })

    const contentType = ref('')
    watchEffect(() => {
      contentType.value = store.getters.contentType
    })

    // コマ移動
    const paramsContentIndex = computed(() => route.params.contentIndex)
    const paramsContentPage = computed(() => route.query.page)
    const pageIndex = computed(() => Number(paramsContentIndex.value) - 1) // 0オリジン
    watch([paramsContentIndex, paramsContentPage], () => {
      if (!paramsContentIndex.value) return
      // 表示モードによって画像ビューアが持つコマ番号とアイテムが持つコマ番号が異なるため
      if (currentViewMode.value === ViewMode.KOMA) {
        iv.changePage(pageIndex.value)
      } else if (currentViewMode.value === ViewMode.TOW_IN_ONE) {
        iv.changePage(getTwoInOnePageIndex(pageIndex.value, processingData.value.addCoverPageFlag))
      } else if (currentViewMode.value === ViewMode.SINGLE) {
        iv.changePage(getSingleModePageIndex(store.getters.contentNumber, route, store.getters.bindingDirection, pages))
      }
    })

    // 範囲選択モードの切替
    watchEffect(() => {
      if (!croppingModeFlag.value) return iv.exitPrintCroppingMode() // 範囲選択モードOFF
      const sr = processingData.value.selectionRange // 範囲選択モードON
      const centeringFlag = sr.left === undefined || sr.top === undefined
      iv.setPrintCroppingArea(
        sr.left,
        sr.top,
        sr.width,
        sr.height,
        processingData.value.croppingSizeTextValue,
        centeringFlag
      )
    })
    // 切り抜き処理
    watchEffect(() => {
      if (!croppedRange.value || !Object.keys(croppedRange.value).length) {
        return iv.disableCropping()
      }
      if (!iv.cropWithCurrentArea()) {
        return console.error('error: 切り抜きに失敗しました')
      }
      // 範囲選択モードをOFFにする
      iv.exitPrintCroppingMode()
      store.commit('updateCroppingModeFlag', false)
    })
    // 余白自動削除の切替
    watch(autoClipFlag, () => {
      if (!autoClipFlag.value) return iv.disableAutoClipping()
      iv.enableAutoClipping()
    })
    // 調整タブで操作を行う度にFitされてしまうためwatchEffectを分岐
    watchEffect(() => {
      setProcessingData()
      iv.changeDirection(
        store.getters.bindingDirection === 'ltr' ? Binding.ltr : Binding.rtl
      )
    })

    // コマ送り方向の変更
    const changeBindingDirection = (bindingDirection: 'rtl' | 'ltr') => {
      iv.changeDirection(
        bindingDirection === 'ltr' ? Binding.ltr : Binding.rtl
      )
    }

    // 2in1表示の切替（PC版）
    const changeTwoInOneMode = (mode: ViewMode) => {
      iv.changeViewMode(mode, pageIndex.value)
      store.commit('changeViewMode', mode)
    }
    // 2in1表示の切替（SP版）
    watch(currentViewMode, () => {
      if (currentViewMode.value === ViewMode.SINGLE) {
        iv.changeViewMode(ViewMode.SINGLE, pageIndex.value) // 「片ページずつ表示」に変更
      } else if (currentViewMode.value === ViewMode.KOMA) {
        iv.changeViewMode(ViewMode.KOMA, pageIndex.value) // 「1コマずつ表示」に変更
      } else if (currentViewMode.value === ViewMode.TOW_IN_ONE) {
        iv.changeViewMode(ViewMode.TOW_IN_ONE, pageIndex.value) // 「2in1表示」に変更
      }
    })

    // 分割数の変更
    watchEffect(() => {
      const divisionHorizontal =
        store.getters.processingData.divisionHorizontal || 0
      const divisionVertical =
        store.getters.processingData.divisionVertical || 0
      iv.setDivision(divisionHorizontal, divisionVertical)
    })

    const shortcutOfPrevPage = () => {
      if (store.getters.bindingDirection === 'ltr') {
        // 「左から右に進む」の場合
        goToPrevPage()
      } else {
        // 「右から左に進む」の場合
        goToNextPage()
      }
    }

    const shortcutOfNextPage = () => {
      if (store.getters.bindingDirection === 'ltr') {
        // 「左から右に進む」の場合
        goToNextPage()
      } else {
        // 「右から左に進む」の場合
        goToPrevPage()
      }
    }

    const shortcutOfChangePage = (pageIndex: number) => () => {
      // 表示モードによって画像ビューアが持つコマ番号とアイテムが持つコマ番号が異なるため
      if (currentViewMode.value === ViewMode.KOMA) {
        iv.changePage(pageIndex)
      } else if (currentViewMode.value === ViewMode.TOW_IN_ONE) {
        iv.changePage(getTwoInOnePageIndex(pageIndex, processingData.value.addCoverPageFlag))
      } else if (currentViewMode.value === ViewMode.SINGLE) {
        iv.changePage(getSingleModePageIndex(store.getters.contentNumber, route, store.getters.bindingDirection, pages))
      }
    }

    const shortcutOfScaleUp = () => {
      iv.scaleUp()
    }

    const shortcutOfScaleDown = () => {
      iv.scaleDown()
    }

    const shortcutOfScaleSet = () => {
      changeScale(showFit())
    }

    const shortcutOfDegreeSet = (val: number) => () => {
      const currentProcessingData = store.getters.processingData
      const currentDegree = currentProcessingData.degree
      const settingDegree = (currentDegree + val)
      store.commit('updateCurrentDegree', settingDegree % 360)
    }

    const shortcutOfKssPrint = () => {
      const kssPrint = document.getElementById('open-kss-printing-modal') as HTMLButtonElement
      if (kssPrint) {
        kssPrint.click()
      }
    }

    const shortcutOfPrint = () => {
      const print = document.getElementById('open-printing-modal') as HTMLButtonElement
      if (print) {
        print.click()
      }
    }

    const toggleFullScreen = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        const itemViewerRef = document.getElementById('full-screen-div')
        if (itemViewerRef) {
          itemViewerRef.requestFullscreen()
        }
      }
    }

    const toggleGrayScale = () => {
      const beforeFlag = store.getters.processingData.grayscaleFlag
      store.commit('updateGrayscaleFlag', !beforeFlag)
    }

    const toggleFulltextPin = () => {
      store.commit('toggleFulltextPin')
    }

    /**
     * ショートカットキー
     */
    const preventStandardHotKeyActions = (event: any) => {
      event.stopPropagation()
      event.preventDefault()
    }

    const getPressedKey = (event: any) => {
      if (isServer) return
      const activeElement: any = document.activeElement
      // 共通ヘッダーの検索フォーム入力時はショートカットを無効化
      if (activeElement?.classList?.contains('keyword-search-input-form')) return
      // テキストフィールドの入力時はショートカットを無効化
      if (activeElement?.classList?.contains('app-input')) return
      // ラジオボタンの入力時はショートカットを無効化
      if (activeElement?.type === 'radio') return
      // レンジスライダーの入力時はショートカットを無効化
      if (activeElement?.classList?.contains('app-input-range')) return
      // モーダル表示時はショートカットを無効化
      if (document.getElementsByClassName('modal-window-container')?.length > 0) return

      // ブラウザのショートカットと競合するため
      if (event.ctrlKey) return
      const pressedKey = event.key
      let action: any
      switch (pressedKey) {
        case 'ArrowLeft':
          if (event.shiftKey) return
          if (event.altKey) return
          action = shortcutOfPrevPage
          break
        case 'ArrowRight':
          if (event.shiftKey) return
          if (event.altKey) return
          action = shortcutOfNextPage
          break
        case 'Home':
          if (event.shiftKey) return
          if (event.altKey) return
          action = shortcutOfChangePage(0)
          break
        case '0':
          action = shortcutOfChangePage(0)
          break
        case 'End': {
          if (event.shiftKey) return
          if (event.altKey) return
          const lastContentIndex = store.getters.item.contentsBundles[currentBundleIndex.value].contents.length - 1
          action = shortcutOfChangePage(lastContentIndex)
          break
        }
        case '+':
          action = shortcutOfScaleUp
          break
        case '-':
          action = shortcutOfScaleDown
          break
        case 'g':
          action = shortcutOfScaleSet
          break
        case 'j':
          action = shortcutOfDegreeSet(-90)
          break
        case 'k':
          action = shortcutOfDegreeSet(90)
          break
        case 'p':
          if (!event.altKey) return
          action = shortcutOfKssPrint
          break
        case 'P':
          action = shortcutOfPrint
          break
        case 'f':
          action = toggleFullScreen
          break
        case 'C':
          action = toggleGrayScale
          break
        case 'h':
          action = toggleFulltextPin
          break
      }
      if (
        action &&
        pressedKey !== 'Control' &&
        pressedKey !== 'Alt' &&
        pressedKey !== 'Shift'
      ) {
        action()
        preventStandardHotKeyActions(event)
      }
    }

    const showFulltextPin = computed(() => store.getters.showFulltextPin)

    watch(() => showFulltextPin.value, () => {
      if (showFulltextPin.value) {
        iv.setSearchHitInfo(store.getters.getItemViewFulltextSnippetPins)
        if (store.getters.getSelectedSnippetId > 0) {
          iv.changeCurrentSearchHitPin(store.getters.getSelectedSnippetId)
        }
      } else {
        iv.changeCurrentSearchHitPin(-1)
        iv.deleteSearchHitInfo()
        store.commit('setSelectedSnippetId', 0)
      }
    })

    onBeforeUnmount(() => {
      // 画像ビューアを破棄する
      ImageViewer.disposeInstance()
      i18nFunction.removeFunction()

      document.removeEventListener('keydown', getPressedKey)
    })

    i18nFunction.setFunction(updateStyleMode)

    const {
      onClick,
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onWheel,
      onMouseLeave,
      onDblClick,
    } = iv.getEventHanlders()

    const res = {
      ivclient,
      ivclientCover,
      // getViewerInstance,
      showLoadingIcon,

      onClick,
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onWheel,
      onMouseLeave,
      onDblClick,

      changePage,
      toNextPage,
      toPrevPage,
      goToNextPage,
      goToPrevPage,
      changeDirection,
      changeRotation,
      changeViewMode,
      showFit,

      scaleUp,
      scaleDown,

      getScaleStep,
      getMaxScale,
      getMinScale,
      changeScale,
      changeScaleByDisplayScale,

      setDivision,
      enableAutoClipping,
      disableAutoClipping,

      execPrintCroppingMode,
      exitPrintCroppingMode,
      setPrintCroppingArea,
      cropArea,
      cropWithCurrentArea,
      getCroppingAreaBitmap,
      disableCropping,
      // showRuler,
      // hideRuler,
      getImageData,
      updateToken,
      updateStyleMode,
      viewerSizeChanged,
      changeCoverPageMode,

      noticeCurrentPageIndex,
      noticeCurrentDrawingScale,
      noticeNewScaleSteps,
      noticeTapTheCenter,
      noticeChangePrintCropping,
      changeBindingDirection,
      currentContentId,
      changeTwoInOneMode,
      isMobile,
    }

    return res
  },
})
