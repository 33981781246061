const darkDepthRatio = [0.75, 0.5]
const lightDepthRatio = [0.9]
let pixels: number

interface DeltaAutoAdjust {
  contrast: number
  gamma: number
}

const getRatio = (array: number[], begin: number, end: number) => {
  const beginIndex = ~~(array.length * begin)
  const endIndex = array.length * end
  let count = 0
  for (let i = beginIndex; i < endIndex; i++) {
    count += array[i]
  }
  return count
}

/**
 * 輝度の出現頻度から、自動調整で使用する補正値を計算する
 * @param ys 輝度出現頻度集計配列
 * @returns `{contrast: number, gamma: number}` 補正値は、contrastは加算、gammaは乗算する
 */
const getDelta = (ys: number[]): DeltaAutoAdjust => {
  const darkPixels0 = getRatio(ys, 0, darkDepthRatio[0])
  const darkPixels1 = getRatio(ys, 0, darkDepthRatio[1])
  const lightPixels = getRatio(ys, lightDepthRatio[0], 1)
  console.log('auto adjust parameter ', { pixels }, { darkPixels0 }, { darkPixels1 }, { lightPixels }) // eslint-disable-line no-console
  // 1. 暗部率0.5以上のピクセル数が60%より多い
  if (darkPixels1 > pixels * 0.6) return { contrast: 2, gamma: 2 }
  // 2. 暗部率0.75以上のピクセル数が60%より多い
  if (darkPixels0 > pixels * 0.6) return { contrast: 2, gamma: 1.5 }
  // 3. 明部率0.9以上のピクセル数が10%より少ない
  if (lightPixels < pixels * 0.1) return { contrast: 0, gamma: 0.5 }
  // 4. 明部率0.9以上のピクセル数が40%より少ない
  if (lightPixels < pixels * 0.4) return { contrast: 2, gamma: 0.8 }
  // 5. 上記に合致しない
  return { contrast: 1, gamma: 0.8 }
}

/**
 * 輝度の出現頻度の集計をした配列を返す
 * @param data 各ピクセルの値をRGBAの順で保持する配列
 */
const getYArray = (data: Uint8ClampedArray) => {
  const ys = new Array(256).fill(0)
  pixels = data.length / 4
  for (let i = 0; i < data.length; i = i + 4) {
    // 輝度(YUVのY)の計算を行う
    // ITU-R Rec BT.601
    const y = ~~(0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2])

    // Y(輝度)の計算にはいくつかパターンが有るため、他のパターンをコメントで残す
    // YCgCo の Y
    // const y = ~~(0.25 * data[i] + 0.5 * data[i + 1] + 0.25 * data[i + 2])

    // 輝度の値ごとのカウント
    ys[y]++
  }
  return ys
}

const getAutoAdjustParameter = (imagedata: ImageData): DeltaAutoAdjust => {
  const yarray = getYArray(imagedata.data)
  return getDelta(yarray)
}

export default getAutoAdjustParameter
