import { find } from 'lodash'
import { getData, postData } from '@/helpers/util/webApiUtil'
import { Collection } from '@/data/@types/Collection'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { Bucket } from '@/data/@types/Aggregations'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

let order = 1

/**
 * ステート
 */

const state = {
  // コレクションマスタ
  collections: {},
  collectionImage: {
    A00001: 'book-close',
    A00002: 'book-close',
    A00003: 'book-close',
    A00014: 'book-close',
    A00022: 'document',
    A00015: 'gp',
    A00017: 'book-close',
    A00016: 'book-close',
    A00019: 'book-close',
    A00121: 'music-and-video',
    A00024: 'music',
    A00152: 'map',
    A00150: 'file',
    A00122: 'file',
    A00114: 'document',
    A00084: 'braille',
    A00030: 'document',
    A00023: 'document',
    A00109: 'document',
    A00119: 'document',
    A00162: 'document',
    A00079: 'document',
    A00025: 'document',
    A00173: 'book-close',
    B00001: 'document',
    B00002: 'document',
    B00003: 'document',
    B00004: 'document',
    B00005: 'document',
    B00006: 'document',
  },
  checkBoxTargetCollection: [
    'A00001',
    'A00002',
    'A00022',
    'A00003',
    'A00014',
    'A00015',
    'A00017',
    'A00016',
    'A00019',
    'A00121',
    'A00024',
    'A00152',
    'A00150',
    'A00173',
    'A00122',
    'A00162',
    'B00000',
  ],
  collectionTreeList: [],
  collectionTreeTypes: [],
}

/**
 * ゲッター
 */

const getters = {
  CollectionMaster (State: any) {
    return state.collections
  },
  CollectionList (State: any) {
    return Object.values(state.collections).map((e: any) => {
      return { collection: e.content.collectionId, label: e.content.name }
    })
  },
  CollectionValueList (State: any) {
    return state.checkBoxTargetCollection
  },
  CollectionCheckboxItem (State: any) {
    const checkboxTargetCollectionList = state.checkBoxTargetCollection.map((e: string) => (state.collections as any)[e])
    return {
      ja: checkboxTargetCollectionList.map((e: any) => { return { value: e.content.collectionId, label: e.content.name.ja } }),
      en: checkboxTargetCollectionList.map((e: any) => { return { value: e.content.collectionId, label: e.content.name.en } }),
    }
  },
  PlaceholderImageType (State: any) {
    return state.collectionImage
  },
  CollectionListForDisplay (State: any) {
    return state.collectionTreeList
  },
  /**
   * @return 親コレクションから自分自身までのコレクションのリスト(1階層目はdigitizedContents or onlinePublications)
   */
  CollectionFamily () {
    type Collection = {
      collectionId: string;
      collectionName: any;
      children?: Array<Collection>;
      categoryCode?: string;
      docCount?: number;
      title?: any;
    }

    const digitizedIndexId = 'A00000'
    const onlinePubIndexId = 'B00000'

    const collectionIndex = (idxId: string) => {
      const index: any = find(state.collections, (v, k) => k === idxId)
      if (!index) return false
      return index.content.name
    }

    const pickCollectionList = (collection: Collection, targetCollectionId: string): Array<Collection|unknown> => {
      if (collection.collectionId === targetCollectionId) return [collection]
      if (!collection.children || !collection.children.length) return []
      for (const child of collection.children) {
        const descendants = pickCollectionList(child, targetCollectionId)
        if (descendants.length) return [collection, ...descendants]
      }
      return []
    }

    return (collectionId: string): Array<Collection|unknown> => {
      if (!state.collectionTreeList.length) return []

      const digitizedContents = pickCollectionList({ collectionId: digitizedIndexId, collectionName: collectionIndex(digitizedIndexId), children: (state.collectionTreeList[0] as any).digitizedContents }, collectionId)
      const onlinePublications = pickCollectionList({ collectionId: onlinePubIndexId, collectionName: collectionIndex(onlinePubIndexId), children: (state.collectionTreeList[1] as any).onlinePublications }, collectionId)

      return digitizedContents.length ? digitizedContents : onlinePublications
    }
  },
}

/**
 * ミューテーション
 */

const mutations = {
  SET_COLLECTIONS (state: any, collections: any) {
    state.collections = collections
  },
  SET_COLLECTION_TREE_LIST (state: any, data: any) {
    state.collectionTreeList = data
  },
  SET_COLLECTION_TREE_TYPES (state: any, data: any) {
    state.collectionTreeTypes = data
  },
}

/**
 * アクション
 */

const actions = {
  async fetchCollectionMasters (context: any) {
    const url = `${BASE_URL}/collection/all`
    const getAggUrl = `${BASE_URL}/item/searchCollectionAggregations`
    try {
      let response: any
      let itemResponse: any
      const fetchCollection = async () => { response = await getData(url) }
      // コレクション別集計結果を取得
      const fetchCollectionAggregation = async () => { itemResponse = await getData(getAggUrl) }
      await Promise.all([fetchCollection(), fetchCollectionAggregation()])

      const collectionTreeTypes = Object.keys(response.collectionTreeSearchHits.content)
      context.commit('SET_COLLECTION_TREE_TYPES', collectionTreeTypes)
      const collectionsMaster = getCollectionMaster(response.collectionSearchHits.searchHits, response.collectionTreeSearchHits)
      context.commit('SET_COLLECTIONS', collectionsMaster)

      const aggregations = itemResponse.aggregations.asMap

      const convertedCollectionDataList = []
      for (const type of collectionTreeTypes) {
        const collectionListData = []
        const collectionMapData: any = {}
        const collectionsTree = response.collectionTreeSearchHits.content[type]
        // { key: 'A00001', docCount: 5, ... } => A00001: { key: 'A00001', docCount: 5, ... }
        const collectionDocCountObj = getCollectionCountObj(aggregations[type].buckets)

        for (const e of collectionsTree) {
          const collectionLevelData = convertCollectionMasterData(e, collectionsMaster, collectionDocCountObj)
          if (!Object.keys(collectionLevelData).length) continue
          collectionListData.push(collectionLevelData)
        }
        collectionMapData[type] = collectionListData
        convertedCollectionDataList.push(collectionMapData)
      }
      context.commit('SET_COLLECTION_TREE_LIST', convertedCollectionDataList)
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    }
  },
}

/**
 * @param collectionsTreeData コレクションマスタのツリー構造データ { A00001: [{ A00002: [...] }] }
 * @param collectionsDetailData 変換されたコレクションマスタの詳細データ
 * @param aggregationsObjByCollection コレクション別の集計結果
 * @returns 表示用に変換した、上位コレクションとそれに紐づくコレクションの階層データ { collectionId: 'A00001', docCount: 5, children: [{...}, ...] ... }
 */
const convertCollectionMasterData = (collectionsTreeData: any, collectionsDetailData: any, aggregationsObjByCollection: any) => {
  const collectionLevelData: any = {}
  for (const id in collectionsTreeData) {
    // 一覧として表示するコレクション情報をつめこむ
    if (!collectionsDetailData[id]) continue
    collectionLevelData.collectionId = collectionsDetailData[id].id
    // TODO: どの値を詰め込めばいいのか確認する
    collectionLevelData.categoryCode = collectionsDetailData[id].content.code
    collectionLevelData.title = { ja: collectionsDetailData[id].content.name.ja, en: collectionsDetailData[id].content.name.en }
    collectionLevelData.docCount = aggregationsObjByCollection?.[id]?.docCount | 0
    collectionLevelData.searchDisplay = collectionsDetailData[id].content.searchDisplay

    // 該当コレクションが下に階層を持っていいれば処理する
    if (collectionsTreeData[id]?.length) {
      collectionLevelData.children = []
      for (const child of collectionsTreeData[id]) {
        // 階層を持っている限り再帰的に処理する
        collectionLevelData.children.push(convertCollectionMasterData(child, collectionsDetailData, aggregationsObjByCollection))
      }
    }
  }
  return collectionLevelData
}

/**
 * コレクションマスタを作成する
 * @param collectionSearchHits 各コレクションの詳細情報を持つマスタデータ
 * @param collectionTreeSearchHits 階層構造のみを表現するマスタデータ
 * @returns collectionSearchHitsに階層情報と表示順を付与したデータ
 */
const getCollectionMaster = (collectionSearchHits: Array<Collection>, collectionTreeSearchHits: StringKeyObject) => {
  const initialObj: StringKeyObject = {}
  const collectionList = collectionSearchHits.reduce(
    (obj, value) => {
      obj[value.id] = value
      return obj
    }, initialObj)
  for (const type of state.collectionTreeTypes) {
    calculateCollectionLayerAndOrder(collectionList, collectionTreeSearchHits.content[type])
  }
  return collectionList
}

// { id: 'A00001', content: ... } => A00001: { id: 'A00001', content: ... }
const getCollectionCountObj = (buckets: Array<Bucket>) => {
  const initialObj: StringKeyObject = {}
  return buckets.reduce(
    (obj, value) => {
      obj[value.key] = value
      return obj
    }, initialObj)
}

/**
 * collectionListにlayerとorderを計算して追加する
 */
const calculateCollectionLayerAndOrder = (collectionList: StringKeyObject, collectionTreeList: Array<StringKeyObject>) => {
  addLayerAndOrder(collectionTreeList, 1, collectionList)
}

/**
 * collectionTreeListに対してlayerと深さ優先通し番号のorderを振る
 */
const addLayerAndOrder = (collectionTreeList: Array<StringKeyObject>, layer: number, baseCollections: StringKeyObject) => {
  collectionTreeList.forEach(collections => {
    Object.keys(collections).forEach(collectionId => {
      if (baseCollections[collectionId]) {
        baseCollections[collectionId].layer = layer
        baseCollections[collectionId].order = order++
      }
      addLayerAndOrder(collections[collectionId], layer + 1, baseCollections)
    })
  })
}

export default {
  state,
  getters,
  mutations,
  actions,
}
