import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6171a6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-kn-nav-inner" }
const _hoisted_2 = { class: "the-kn-navi-main has-animation" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "the-kn-navi-sub" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          class: "has-text-contents-normal top",
          href: _ctx.$t('link.knApplication')
        }, _toDisplayString(_ctx.$t('knNav.top')), 9, _hoisted_3)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          class: "has-text-contents-normal about",
          href: `${_ctx.KN_APPLICATION_LINK}/static/about?language=${_ctx.lang}`
        }, _toDisplayString(_ctx.$t('knNav.about')), 9, _hoisted_4)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          class: "has-text-contents-normal help",
          href: `${_ctx.KN_APPLICATION_LINK}/static/help?language=${_ctx.lang}`
        }, _toDisplayString(_ctx.$t('knNav.help')), 9, _hoisted_5)
      ])
    ]),
    _createElementVNode("ul", _hoisted_6, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeLocale && _ctx.changeLocale(...args))),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.changeLocale && _ctx.changeLocale(...args)), ["prevent"]), ["space","enter"]))
        }, _toDisplayString(_ctx.$t("header.language")), 33)
      ])
    ])
  ]))
}