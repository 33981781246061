
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'separator',
    },
    ariaLabel: {
      type: String,
      default: ' ', //  NOTE: aria-labelのデフォルトとして、スクリーンリーダーの読上仕様に合わせるため、明示的に「 」を設定。
    },
    width: {
      type: [String, Number],
      default: 1,
    },
    height: {
      type: [String, Number],
      default: 20,
    },
  },
})
