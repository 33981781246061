
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tag: {
      default: 'div',
    },
    padding: {
      default: 12,
    },
    to: {
      default: null,
    },
    size: {
      default: 'medium',
    },
  },
  setup () {
    return {}
  },
})
