
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'is-medium',
    },
  },
  setup (props) {
    const px = computed(() => {
      if (props.size.match(/^\d+$/) || props.size.match(/^\d+px$/)) {
        return props.size.replace('px', '')
      }
      if (props.size === 'is-small') {
        return 16
      }
      if (props.size === 'is-large') {
        return 32
      }
      return 24
    })

    return {
      px,
    }
  },
})
