
import { defineComponent } from 'vue'
import default_ from '@/components/organisms/metadataList/detailList/default.vue'

export default defineComponent({
  components: {
    default: default_,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const primaryCollection = props.item.collections && props.item.collections[0]
    const component = () => {
      switch (primaryCollection) {
        default:
          return 'default'
      }
    }
    // eslint-disable-next-line no-console
    console.log(`detail records type: ${component()} [recieve collection[0] is ${primaryCollection}]`)
    return {
      component: component(),
      collection: primaryCollection,
    }
  },
})
