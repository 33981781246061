
import { defineComponent, defineAsyncComponent, nextTick } from 'vue'

const AsyncAppImage = defineAsyncComponent({
  loader: () => {
    return new Promise<any>(resolve => {
      nextTick(() => {
        resolve(import('./AppImgWrapper.vue' /* webpackChunkName: "app-img" */))
      })
    })
  },
})

export default defineComponent({
  name: 'AppImg',
  components: {
    AsyncAppImage,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    type: {
      type: String,
    },
  },
})
