
import { defineComponent } from 'vue'
import { StyleName, styleNames } from '../../data/def'

export default defineComponent({
  name: 'AppButton',
  props: {
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    /** CypressでID指定するため */
    buttonId: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
})
