
import { ContentMeta } from '@/data/@types/ContentMeta'
import { computed, defineComponent, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale.value as 'ja' | 'en'

    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join() : ''
    }

    const currentContentMetadata = computed<ContentMeta>(
      () => store.getters.currentContent?.meta
    )

    const existAbstract = computed(() => props.item.meta['0041Dtct'] && props.item.meta['0041Dtct']?.length)
    const existIntroduction = computed(() => currentContentMetadata.value?.['0703Dtct'] && currentContentMetadata.value?.['0703Dtct']?.length)
    const existDetail = computed(() => currentContentMetadata.value?.['0702Dtct'] && currentContentMetadata.value?.['0702Dtct']?.length)
    const existReprint = computed(() => currentContentMetadata.value?.['0704Dtct'] && currentContentMetadata.value?.['0704Dtct']?.length)
    const isDisplayMetadata = computed(() => existAbstract.value || existIntroduction.value || existDetail.value || existReprint.value)
    const meta = computed(() => {
      return props.item.meta
    })

    const getMetadataLabels = inject<(arg0: number) => {[key: string]: string}>('getMetadataLabels')!

    return {
      meta,
      metaArrayProcessing,
      currentContentMetadata,
      existAbstract,
      existIntroduction,
      existDetail,
      existReprint,
      isDisplayMetadata,
      lang,
      getMetadataLabels,
    }
  },
})
