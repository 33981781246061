import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-008e7413"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "color", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "base-separator",
    width: _ctx.width,
    height: _ctx.height,
    color: _ctx.color,
    "aria-label": _ctx.ariaLabel
  }, null, 8, _hoisted_1))
}