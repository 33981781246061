import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-669da55a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-label"]
const _hoisted_2 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
    class: _normalizeClass([_ctx.styled, "app-select"]),
    id: _ctx.componentId,
    "aria-label": _ctx.$t('label.selection')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option.value,
        value: option.value,
        disabled: option.disabled,
        class: _normalizeClass(`app-select-value-${option.value}`)
      }, _toDisplayString(option.name), 11, _hoisted_2))
    }), 128))
  ], 10, _hoisted_1)), [
    [_vModelSelect, _ctx.selectedItem]
  ])
}