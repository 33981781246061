
import { defineComponent, PropType, ref } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import DssIcon from '../atoms/DssIcon.vue'

export default defineComponent({
  components: {
    DssIcon,
  },
  props: {
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
    target: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'arrow-right',
    },
  },
  setup (props) {
    const tag = ref('span')
    if (props.to) {
      tag.value = 'router-link'
    } else if (props.href) {
      tag.value = 'a'
    }

    return {
      tag,
    }
  },
})
