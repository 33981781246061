
class DeviceInfo {
  private static isIPad_ = false
  /**
   * iPad上か否か
   */
  public static get isIPad (): boolean {
    return DeviceInfo.isIPad_
  }

  private static isIPhone_ = false
  /**
   * iPhone上か否か
   */
  public static get isIPhone (): boolean {
    return DeviceInfo.isIPhone_
  }

  private static isIPod_ = false
  /**
   * iPod上か否か
   */
  public static get isIPod (): boolean {
    return DeviceInfo.isIPod_
  }

  private static isAndroid_ = false
  /**
   * Android上か否か
   */
  public static get isAndroid (): boolean {
    return DeviceInfo.isAndroid_
  }

  private static isPC_ = false
  /**
   * PC上か否か
   */
  public static get isPC (): boolean {
    return DeviceInfo.isPC_
  }

  /**
   * デバイスピクセルレシオを返す
   */
  public static get DPR (): number {
    if (DeviceInfo.isIPad) {
      return 1.0
    }
    return window.devicePixelRatio
  }

  public static initDeviceInfo (): void{
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('ipad') >= 0 || (ua.indexOf('macintosh') >= 0 && document.ontouchstart)) {
      DeviceInfo.isIPad_ = true
    }
    if (ua.indexOf('iphone') >= 0) {
      DeviceInfo.isIPhone_ = true
    }
    if (ua.indexOf('ipod') >= 0) {
      DeviceInfo.isIPod_ = true
    }
    if (ua.indexOf('android') >= 0 && ua.indexOf('mobile') >= 0) {
      DeviceInfo.isAndroid_ = true
    }

    if (!DeviceInfo.isIPad && !DeviceInfo.isIPod && !DeviceInfo.isIPhone && !DeviceInfo.isAndroid) {
      DeviceInfo.isPC_ = true
    }

    /*
    if(DeviceInfo.isIPad){
      alert("iPad")
    }
    if(DeviceInfo.isIPhone){
      alert("iPhone")
    }
    if(DeviceInfo.isAndroid){
      alert("Android")
    }
    if(DeviceInfo.isPC){
      alert("PC")
    }
    */
  }
}

DeviceInfo.initDeviceInfo()

/**
 * CSSピクセルからデバイスピクセルに変換する
 * @param csspix CSS ピクセルサイズ数
 * @returns
 */
function csspx2dpx (csspix: number): number {
  return csspix * DeviceInfo.DPR
}

/**
 * デバイスピクセルからCSSピクセルに変換する
 * @param dpx デバイスピクセル数
 * @returns
 */
function dpx2csspx (dpx: number): number {
  return dpx / DeviceInfo.DPR
}

/**
 * 度からラジアンへ変換する
 * @param deg
 * @returns
 */
function deg2rad (deg: number): number {
  return (deg * Math.PI) / 180
}

/**
 * 度からラジアンへ変換する
 * @param deg
 * @returns
 */
function rad2deg (rad: number): number {
  return rad / Math.PI / 180
}

/**
 * ミリメートルをピクセルに変換する
 * @param cm px変換する長さ ミリメートル
 * @param dpi 変換ソースのdpi
 * @returns 変換された実ピクセル数
 */
function mm2px (mm: number, dpi: number): number {
  return mm / 2.54 * dpi * 0.1
}

/**
 * ピクセルをミリメートルに変換する
 * @param px px変換するピクセル数
 * @param dpi 変換ソースのdpi
 * @returns 変換されたミリメートル
 */
function px2mm (px: number, dpi: number): number {
  return px / dpi * 2.54 * 10
}

function isAlmostZero (value: number): boolean {
  if ((value - 0.00001) >= -0.00002 && (value + 0.00001) <= 0.00002) {
    return true
  }

  return false
}

/**
 * 物理座標 整数値
 * 画像のピクセルや、画面解像度（デバイスピクセル）などを基準とした座標を扱う
 */
class PhysicalPosition {
  public x = 0;
  public y = 0;

  public constructor (x?: number, y?: number) {
    if (x) {
      this.x = x// ~~x
    }
    if (y) {
      this.y = y// ~~y
    }
  }

  /**
   * ２点間の距離を返す
   * @param {PhysicalPosition} a
   * @param {PhysicalPosition} b
   * @returns {number} ２点間の距離
   * @public
   */
  public static distance (a: PhysicalPosition, b: PhysicalPosition): number {
    return Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2))
  }

  public copy (src: PhysicalPosition): void {
    this.x = src.x
    this.y = src.y
  }
}

/**
 * 物理サイズ 整数値
 * 画像のピクセル数や、画面解像度（デバイスピクセル）などを扱う
 */
class PhysicalSize {
  public width = 0;
  public height = 0;

  public constructor (width?: number, height?: number) {
    if (width) {
      this.width = ~~width
    }
    if (height) {
      this.height = ~~height
    }
  }
}

/**
 * 二次元ベクトル
 */
class Vec2 {
  public x = 0;
  public y = 0;

  public static distance (a: Vec2, b: Vec2): number {
    return Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2))
  }

  public static intersection (a0: Vec2, a1: Vec2, b0: Vec2, b1: Vec2): Vec2 | undefined {
    const x0 = a0.x
    const y0 = a0.y
    const x1 = a1.x
    const y1 = a1.y
    const x2 = b0.x
    const y2 = b0.y
    const x3 = b1.x
    const y3 = b1.y

    const w = (y1 - y0) / (x1 - x0)
    const z = (y3 - y2) / (x3 - x2)

    const x = (w * x0 - y0 - z * x2 + y2) / (w - z)
    const y = (y1 - y0) / (x1 - x0) * (x - x0) + y0

    if (Math.abs(w) === Math.abs(z)) return undefined

    if (x > Math.max(x0, x1) || x > Math.max(x2, x3) ||
        y > Math.max(y0, y1) || y > Math.max(y2, y3) ||
        x < Math.min(x0, x1) || x < Math.min(x2, x3) ||
        x < Math.min(x0, x1) || y < Math.min(y2, y3)) return undefined

    return new Vec2(x, y)
  }

  public constructor (x?: number, y?: number) {
    if (x) {
      this.x = x
    }
    if (y) {
      this.y = y
    }
  }

  public clone (): Vec2 {
    return new Vec2(this.x, this.y)
  }
}

/**
 * 矩形整数
 */
class RectI {
  public left = 0
  public top = 0
  public right = 0
  public bottom = 0

  public constructor (
    left?: number,
    top?: number,
    right?: number,
    bottom?: number
  ) {
    if (left) {
      this.left = ~~left
    }
    if (top) {
      this.top = ~~top
    }
    if (right) {
      this.right = ~~right
    }
    if (bottom) {
      this.bottom = ~~bottom
    }
  }

  /**
   * 幅を返す
   * @returns {number} 幅
   */
  public get width (): number {
    return this.right - this.left
  }

  public set width (value: number) {
    this.right = this.left + value
  }

  /**
   * 高さを返す
   * @returns {number} 高さ
   */
  public get height (): number {
    return this.bottom - this.top
  }

  public set height (value: number) {
    this.bottom = this.top + value
  }

  /**
   * 中央座標を返す
   * @returns {Vec2} 中央座標
   */
  public get center (): Vec2 {
    return new Vec2(this.width / 2 + this.left, this.height / 2 + this.top)
  }

  public mul (value: number): void {
    this.left *= value
    this.top *= value
    this.right *= value
    this.bottom *= value
  }

  public copy (src: RectI): void {
    this.left = src.left
    this.top = src.top
    this.right = src.right
    this.bottom = src.bottom
  }
}

/**
 * 物理矩形
 */
class PhysicalRect {
  public left = 0
  public top = 0
  public right = 0
  public bottom = 0

  public constructor (
    left?: number,
    top?: number,
    right?: number,
    bottom?: number
  ) {
    if (left) {
      this.left = ~~left
    }
    if (top) {
      this.top = ~~top
    }
    if (right) {
      this.right = ~~right
    }
    if (bottom) {
      this.bottom = ~~bottom
    }
  }

  /**
   * 幅を返す
   * @returns {number} 幅
   */
  public get width (): number {
    return this.right - this.left
  }

  public set width (value: number) {
    this.right = this.left + value
  }

  /**
   * 高さを返す
   * @returns {number} 高さ
   */
  public get height (): number {
    return this.bottom - this.top
  }

  public set height (value: number) {
    this.bottom = this.top + value
  }

  /**
   * 中央座標を返す
   * @returns {Vec2} 中央座標
   */
  public get center (): Vec2 {
    return new Vec2(this.width / 2 + this.left, this.height / 2 + this.top)
  }

  public mul (value: number): void {
    this.left *= value
    this.top *= value
    this.right *= value
    this.bottom *= value
  }

  public copy (src: RectI): void {
    this.left = src.left
    this.top = src.top
    this.right = src.right
    this.bottom = src.bottom
  }
}

class BoundingBox extends PhysicalRect {
  public offset = new PhysicalRect();
  public matrix?: DOMMatrix;
  /**
   * 画像の中心からバウンディングボックスの各辺までの距離から、画像の中心から各辺までの距離を引いた距離
   * 回転角度が0°または90の倍数の場合 0 になる
   * コマが正方形の場合 45の倍数の場合 0 になる
   */
  public distanceOfEdgeToBBCorner = new PhysicalRect()
  public constructor () {
    super()
    this.offset.left = 0
  }

  /**
   * オフセットを考慮した、中核幅を返す
   */
  public get coreWidth (): number {
    let res = this.width
    res += this.offset.left
    res -= this.offset.right

    return res
  }

  /**
   * オフセットを考慮した、中核高さを返す
   */
  public get coreHeight (): number {
    let res = this.height
    res += this.offset.top
    res -= this.offset.bottom

    return res
  }

  public copy (src: BoundingBox): void {
    this.left = src.left
    this.top = src.top
    this.right = src.right
    this.bottom = src.bottom
    this.offset.copy(src.offset)
    this.matrix = src.matrix
    this.distanceOfEdgeToBBCorner.copy(src.distanceOfEdgeToBBCorner)
  }
}

/**
 * 矩形実数
 */
class RectF {
  public left = 0.0
  public top = 0.0
  public right = 0.0
  public bottom = 0.0

  public constructor (
    left?: number,
    top?: number,
    right?: number,
    bottom?: number
  ) {
    if (left) {
      this.left = left
    }
    if (top) {
      this.top = top
    }
    if (right) {
      this.right = right
    }
    if (bottom) {
      this.bottom = bottom
    }
  }

  /**
   * 幅を返す
   * @returns {number} 幅
   */
  public get width (): number {
    return this.right - this.left
  }

  /**
   * 高さを返す
   * @returns {number} 高さ
   */
  public get height (): number {
    return this.bottom - this.top
  }

  /**
   * 中央座標を返す
   * @returns {Vec2} 中央座標
   */
  public get center (): Vec2 {
    return new Vec2(this.width * 0.5 + this.left, this.height * 0.5 + this.top)
  }

  public copy (src: RectF): void {
    this.left = src.left
    this.top = src.top
    this.right = src.right
    this.bottom = src.bottom
  }

  public clone (): RectF {
    const res = new RectF()
    res.copy(this)
    return res
  }
}

/**
 * 矩形ミリメートル単位
 */
class RectMM {
  public left = 0.0
  public top = 0.0
  public right = 0.0
  public bottom = 0.0

  public constructor (
    left?: number,
    top?: number,
    right?: number,
    bottom?: number
  ) {
    if (left) {
      this.left = left
    }
    if (top) {
      this.top = top
    }
    if (right) {
      this.right = right
    }
    if (bottom) {
      this.bottom = bottom
    }
  }

  public copy (src: RectMM): void {
    this.left = src.left
    this.top = src.top
    this.right = src.right
    this.bottom = src.bottom
  }

  /**
   * 幅を返す
   * @returns {number} 幅
   */
  public get width (): number {
    return this.right - this.left
  }

  /**
   * 高さを返す
   * @returns {number} 高さ
   */
  public get height (): number {
    return this.bottom - this.top
  }

  /**
   * 中央座標を返す
   * @returns {Vec2} 中央座標
   */
  public get center (): Vec2 {
    return new Vec2(this.width * 0.5 + this.left, this.height * 0.5 + this.top)
  }

  public clone (): RectMM {
    const res = new RectMM()
    res.copy(this)
    return res
  }
}

/**
 * 回転情報クラス
 */
class Rotation {
  /**
   * 回転角度 0-359
   */
  public angle = 0;

  /**
   * 回転軸
   */
  public pivot = new Vec2(0.5, 0.5);

  public copy (src: Rotation): void {
    this.angle = src.angle
    this.pivot.x = src.pivot.x
    this.pivot.y = src.pivot.y
  }
}

/**
 * 指定した二次元座標を指定した行列で変換した値を返す
 * @param pos 二次元座標
 * @param matrix 適用する行列 context.getTransform() で得られる DOMMatrix
 * @returns 変換済み二次元座標
 */
function transform2D (pos: Vec2, matrix: DOMMatrix): Vec2 {
  const res = new Vec2()

  res.x = pos.x * matrix.a + pos.y * matrix.c + 1.0 * matrix.e
  res.y = pos.x * matrix.b + pos.y * matrix.d + 1.0 * matrix.f

  return res
}

/**
 * 指定した二次元座標を指定した行列で変換した値を返す
 * @param pos 二次元座標
 * @param matrix 適用する行列 context.getTransform() で得られる DOMMatrix
 * @returns 変換済み二次元座標
 */
function transform2DArray (pos: Array<number>, matrix: DOMMatrix): Array<number> {
  const res = new Array<number>()

  res[0] = pos[0] * matrix.a + pos[1] * matrix.c + 1.0 * matrix.e
  res[1] = pos[0] * matrix.b + pos[1] * matrix.d + 1.0 * matrix.f

  return res
}

class Circle {
  /**
   * 中心座標
   */
  public center = new Vec2()

  /**
   * 半径
   */
  public radius = 0.0
}

class PolygonRect2D {
  public static fromArray (srcArray: Array<Array<number>>): PolygonRect2D {
    const res = new PolygonRect2D()

    for (let i = 0; i < Math.min(srcArray.length, 4); ++i) {
      res.vertices[i].x = srcArray[i][0]
      res.vertices[i].y = srcArray[i][1]
    }

    return res
  }

  public vertices = new Array<Vec2>(4)

  public constructor () {
    for (let i = 0; i < this.vertices.length; ++i) {
      this.vertices[i] = new Vec2()
    }
  }

  public get width (): number {
    return this.vertices[1].x - this.vertices[0].x
  }

  public get height (): number {
    return this.vertices[2].y - this.vertices[0].y
  }

  public copy (src: PolygonRect2D): void {
    this.vertices[0].x = src.vertices[0].x
    this.vertices[0].y = src.vertices[0].y
    this.vertices[1].x = src.vertices[1].x
    this.vertices[1].y = src.vertices[1].y
    this.vertices[2].x = src.vertices[2].x
    this.vertices[2].y = src.vertices[2].y
    this.vertices[3].x = src.vertices[3].x
    this.vertices[3].y = src.vertices[3].y
  }

  public copyWithOffset (src: PolygonRect2D, offset: PhysicalPosition): void {
    this.vertices[0].x = src.vertices[0].x + offset.x
    this.vertices[0].y = src.vertices[0].y + offset.y
    this.vertices[1].x = src.vertices[1].x + offset.x
    this.vertices[1].y = src.vertices[1].y + offset.y
    this.vertices[2].x = src.vertices[2].x + offset.x
    this.vertices[2].y = src.vertices[2].y + offset.y
    this.vertices[3].x = src.vertices[3].x + offset.x
    this.vertices[3].y = src.vertices[3].y + offset.y
  }

  /**
   *
   * @param offset 座標のオフセットを使用する場合に指定する
   * @returns
   */
  public clone (offset?: PhysicalPosition): PolygonRect2D {
    const res = new PolygonRect2D()
    if (offset) {
      res.copyWithOffset(this, offset)
    } else {
      res.copy(this)
    }
    return res
  }
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const NodeRSA = require('node-rsa')

type KeyPair = {
  privateKey: string,
  publicKey: string
}

const generateKeyPair = (): KeyPair => {
  const key = new NodeRSA({ b: 1024 })
  const privKey = key.exportKey('pkcs1-pem')
  const pubKey = key.exportKey('pkcs8-public-pem')
  return { privateKey: privKey, publicKey: pubKey }
}

const decryptText = (privKey: string, text: string): string => {
  try {
    const key = new NodeRSA(privKey, 'pkcs1-pem')
    key.setOptions({ encryptionScheme: 'pkcs1' })
    return key.decrypt(text, 'utf8')
  } catch (e) {
    console.error('error:', e)
  }
  return ''
}

/**
 * 非同期版暗号化解除
 * @param privKey
 * @param text
 * @returns
 */
const decryptTextAsync = async function (privKey: string, text: string): Promise<string> {
  try {
    const key = new NodeRSA(privKey, 'pkcs1-pem')
    key.setOptions({ encryptionScheme: 'pkcs1' })
    return key.decrypt(text, 'utf8')
  } catch (e) {
    console.error('error:', e)
  }
  return ''
}

export {
  DeviceInfo,
  PhysicalPosition,
  PhysicalSize,
  PhysicalRect,
  BoundingBox,
  Vec2,
  RectI,
  RectF,
  RectMM,
  csspx2dpx,
  dpx2csspx,
  Rotation,
  deg2rad,
  rad2deg,
  transform2D,
  transform2DArray,
  Circle,
  PolygonRect2D,
  mm2px,
  px2mm,
  isAlmostZero,
  KeyPair,
  generateKeyPair,
  decryptText,
  decryptTextAsync,
}
