import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    to: _ctx.to,
    href: _ctx.href,
    target: _ctx.target,
    rel: _ctx.target === '_blank' ? 'noopener' : null,
    class: _normalizeClass(["text-link-with-arrow", { 'text-link': !_ctx.icon }])
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_DssIcon, {
            key: 0,
            icon: _ctx.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, [
        _createElementVNode("span", null, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.target === '_blank')
            ? (_openBlock(), _createBlock(_component_DssIcon, {
                key: 0,
                icon: "action-share-mini",
                size: "is-small"
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["to", "href", "target", "rel", "class"]))
}