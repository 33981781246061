import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c02cfc08"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-hidden", "size", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["dss-icon", _ctx.dssIconClass]),
    role: "img",
    "aria-hidden": !!!_ctx.label,
    size: _ctx.size,
    "aria-label": _ctx.label
  }, null, 10, _hoisted_1))
}