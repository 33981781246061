
import { computed, defineComponent } from 'vue'
import AppButton from '@/components/atoms/AppButton.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    AppButton,
    ButtonIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    abstract: {
      type: String,
      required: false,
    },
  },
  setup () {
    const store = useStore()
    const pinIcon = computed(() => {
      const pins = store.getters.getItemViewFulltextSnippetPins
      if (pins.length === 0) return 'nbsp'
      if (store.getters.showFulltextPin) return 'viewer-pin-disabled'
      return 'viewer-pin'
    })
    const toggleShowPin = () => {
      store.commit('toggleFulltextPin')
    }
    return {
      pinIcon,
      toggleShowPin,
    }
  },
})
