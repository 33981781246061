
import { postData } from '@/helpers/util/webApiUtil'
import { FullTextSearchResult, ItemViewFulltextSnippet } from '@/data/@types/FulltextSearchResult'
import { FullTextSearchRequest } from '@/data/@types/FulltextSearchRequest'
import { AddonViewFulltextSearchRequest } from '@/data/@types/AddonViewFulltextSearchRequest'
import { ActionContext } from 'vuex'
import { sanitizeString } from '@/helpers/util/escapeCharacterUtils'
import { store } from '..'
import { ImageViewer } from '@/helpers/imageviewer/ImageViewer'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const PIN_ICON_PATH = 'marker-icon.png'

const iv = ImageViewer.getInstance()

interface State {
  /** 全文スニペット用(全文タブ) */
  itemViewFulltextSnippet: { [id: string]: FullTextSearchResult };
  addonViewFulltextProcessingFlag: boolean;
  itemViewFulltextTotalHit: number;
  itemViewFulltextSnippetList: ItemViewFulltextSnippet[];
  itemViewFulltextSnippetPin: any[];
}

type Context = ActionContext<State, any>

const state: State = {
  /** 全文スニペット用(全文タブ) */
  itemViewFulltextSnippet: {},
  addonViewFulltextProcessingFlag: false,
  itemViewFulltextTotalHit: 0,
  itemViewFulltextSnippetList: [],
  itemViewFulltextSnippetPin: [],
}

const getters = {
  getItemViewSnippetSearchHit (state: State): number {
    return state.itemViewFulltextTotalHit
  },
  getItemViewFulltextSnippetList (state: State): any[] {
    return state.itemViewFulltextSnippetList.filter(s => s.isVisible)
  },
  getItemViewFulltextSnippetPins (state: State): any {
    return state.itemViewFulltextSnippetPin
  },
  addonViewFulltextProcessingFlag (state: State): boolean {
    return state.addonViewFulltextProcessingFlag
  },
}
const mutations = {
  ITEM_VIEW_SNIPPET (state: State, payload: { [id: string]: FullTextSearchResult }): void {
    state.itemViewFulltextSnippet = payload
  },
  ITEM_VIEW_SNIPPET_RESET (state: State): void {
    state.itemViewFulltextSnippet = {}
  },
  ITEM_VIEW_PROCESSING (state: State): void {
    state.addonViewFulltextProcessingFlag = true
  },
  ITEM_VIEW_PROCESSED (state: State): void {
    state.addonViewFulltextProcessingFlag = false
  },
  SET_ITEM_VIEW_FULLTEXT_TOTAL_HIT (state: State, payload: any): void {
    state.itemViewFulltextTotalHit = payload
  },
  SET_ITEM_VIEW_FULLTEXT_SNIPPET_LIST (state: State, payload: any): void {
    state.itemViewFulltextSnippetList = payload
  },
  SET_ITEM_VIEW_FULLTEXT_SNIPPET_PIN (state: State, payload: any): void {
    state.itemViewFulltextSnippetPin = payload
  },
}
const actions = {
  async addonViewSnippetSearch (context: Context, request: FullTextSearchRequest): Promise<void> {
    context.commit('ITEM_VIEW_PROCESSING')
    context.commit('ITEM_VIEW_SNIPPET_RESET')
    const snippetUrl = `${BASE_URL}/fulltext/search`

    try {
      const snippetSearchHits = await postData(snippetUrl, request)
      context.commit('ITEM_VIEW_SNIPPET', snippetSearchHits)
      const firstOne: any = Object.values(snippetSearchHits)[0] || {}

      // ヒット数の設定
      context.commit('SET_ITEM_VIEW_FULLTEXT_TOTAL_HIT', firstOne.totalHit || 0)

      // スニペットリストの作成
      const list = getList(firstOne, store.getters.contents)
      context.commit('SET_ITEM_VIEW_FULLTEXT_SNIPPET_LIST', list)

      // ビューアハイライトピンの作成
      const pins = getPin(list)
      context.commit('SET_ITEM_VIEW_FULLTEXT_SNIPPET_PIN', pins)
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
      context.commit('ITEM_VIEW_SNIPPET_RESET')
    }
    context.commit('ITEM_VIEW_PROCESSED')
  },
  resetSnippetSearchList (context: Context): void {
    context.commit('SET_ITEM_VIEW_FULLTEXT_TOTAL_HIT', 0)
    context.commit('SET_ITEM_VIEW_FULLTEXT_SNIPPET_LIST', [])
    context.commit('SET_ITEM_VIEW_FULLTEXT_SNIPPET_PIN', [])
  },
  async addonViewFulltextSearch (context: Context, { value, router, route }: AddonViewFulltextSearchRequest): Promise<void> {
    if (context.getters.addonViewFulltextProcessingFlag) return
    context.commit('setSelectedSnippetId', 0)
    iv.changeCurrentSearchHitPin(-1)
    const request: FullTextSearchRequest = {
      targets: [{
        pid: extractPidNum(context.getters.item.pid),
        bids: [context.getters.currentContentsBundle.id],
      }],
      mode: 'CONTENT',
      sort: 'CONTENT',
      size: 10000,
    }
    const sp = ' '
    const FullWidthSpaceRegExp = /\u3000/g
    const keywords = value.replace(FullWidthSpaceRegExp, sp).split(sp)
    if (value !== blank && keywords.length !== 0) {
      request.keywords = keywords
    }
    await context.dispatch('addonViewSnippetSearch', request)
    if (value === blank) {
      // 空文字での全文検索時はクエリパラメータを削除
      router.replace({
        query: {
          page: route.query.page,
        },
      })
    } else {
      router.replace({
        // 全文検索時にキーワードをクエリパラメータとしてURLに付与
        query: {
          keyword: value,
          page: route.query.page,
        },
      })
    }
    iv.setSearchHitInfo(context.getters.getItemViewFulltextSnippetPins)
    context.commit('enableFulltextPin')
  },
}

const getList = (data: any, contents: any[]) => {
  const resultsArray: ItemViewFulltextSnippet[] = []
  if (!data?.contents) return resultsArray
  for (let i = 0; i < data.contents.length || 0; i++) {
    // どのコマか
    const c = data.contents[i]
    const index = contents.findIndex((content: any) => c.cid === content.id)
    const cmeta = contents[index]
    const highlightFullText = (m: any): string => {
      // XSS対策のため、'<'と'>'をエスケープする
      const head = sanitizeString(m.head)
      const word = sanitizeString(m.word)
      const tail = sanitizeString(m.tail)
      return word ? head + '<em>' + word + '</em>' + tail : ''
    }
    if (!c.matches) continue

    // 権限がない場合は全てのwordが空になる
    const hasSnippetPermission = c.matches.some((match: any) => match.word)
    c.matches.forEach((match: any, i: number) => {
      // コマ内で複数マッチする
      resultsArray.push({
        id: index + 1, // コンテンツ連番。1始まり。
        cid: c.cid, // コンテンツID。UUID。
        snippetId: resultsArray.length + 1, // スニペットごとに設定するID。1始まり。
        contents: cmeta, // タイトルなど
        text: highlightFullText(match),
        searchWord: match.word,
        x: match.x === null ? null : match.x / 100,
        y: match.y === null ? null : match.y / 100,
        isVisible: hasSnippetPermission || !i, // スニペット権限が権限がない場合、最初のスニペットのみ表示する
      })
    })
  }
  return resultsArray
}

const getPin = (results: any) => {
  if (!results) return []
  return results.reduce((acc: any, cur: any) => {
    if (cur.x === null || cur.y === null) return acc
    const sameContent = acc.find((a: any) => a.content_id === cur.id - 1)
    if (sameContent) {
      const sameSearchWord = sameContent.hit_words.find((hw: { text: any }) => hw.text === cur.searchWord)
      // すでに同じコンテンツ同じ検索語でヒットしていれば、同一pins配列に座標を追加する
      if (sameSearchWord) {
        sameSearchWord.pins.push({
          pin_id: cur.snippetId,
          x: cur.x,
          y: cur.y,
        })
        return acc
      }

      // 同じコンテンツにはあるが同じ検索語でヒットしていない場合、新しい検索語としてhit_wordsに追加する
      // iconが別のものになるかどうか検討中。
      sameContent.hit_words.push({
        text: cur.searchWord,
        icon: PIN_ICON_PATH,
        pins: [{
          pin_id: cur.snippetId,
          x: cur.x,
          y: cur.y,
        }],
      })
      return acc
    }

    // 同じコンテンツがヒットしていない場合、新しいコンテンツとして追加する
    acc.push({
      content_id: cur.id - 1,
      hit_words: [{
        text: cur.searchWord,
        icon: PIN_ICON_PATH,
        pins: [{
          pin_id: cur.snippetId,
          x: cur.x,
          y: cur.y,
        }],
      }],
    })
    return acc
  }, [] as any[])
}

const blank = ''
const extractPidNum = (pid: string) => {
  const prefix = 'info:ndljp/pid/'
  return pid.startsWith(prefix) ? pid.replace(prefix, blank) : pid
}

export default {
  state,
  getters,
  mutations,
  actions,
}
