
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import ButtonCopyClipboard from '@/components/molecules/ButtonCopyClipboard.vue'

export default defineComponent({
  name: 'MetadataRecord',
  components: {
    ButtonCopyClipboard,
  },
  props: {
    // 表示ラベル。{ja: "タイトル", en: "title"}形式。
    labels: {
      type: Object,
      required: true,
    },
    // 値
    // 基本的には配列のなかに配列が含まれている状態となる。
    // 区切り文字が含まれることもある。
    // [['芥川', '夏目'], ['直木']]
    value: {
      type: Array,
      required: true,
    },
    // 配列要素のうち2つ目以降があれば省略し"[他]"と表示する
    omitOthers: {
      type: Boolean,
      required: false,
    },
    // クリップボードへコピーするアイコンを表示する
    canCopyClipboard: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const i18n = useI18n()
    const lang = i18n.locale

    /**
     * メタデータ等の配列を文字列に加工する
     * @param "メタデータ配列または区切り文字"を要素に持つ配列 または 文字列
     */
    const metaArrayAndStringJoin = (value_: Array<Array<string>|string> | string): string => {
      if (typeof value_ === 'string') return value_
      return value_.map((element, index) => {
        if (!element) return ''
        // 区切り文字はstringで記述されるため、そのまま表示する。ただし、後続のデータが存在しない場合は表示しない。
        if (typeof element === 'string') {
          if (![' ', '. '].includes(element)) return element
          if (value_[index + 1]) return element
          return ''
        }
        // メタデータは配列で渡される。omit-othersが設定されている かつ 値が複数ある場合は1件目 [他]とする。
        if (Array.isArray(element)) {
          if (!props.omitOthers) return element.join('')
          if (element.length < 2) return element[0]
          return element[0] + ' ' + i18n.t('label.bracketsOther')
        }
      }).join('')
    }

    const isExist = (value_: Array<Array<string>|string> | string): boolean => {
      return !isMultiLine(value_)
        ? !!metaArrayAndStringJoin(value_).length
        : isArrayValueLength(value_)
    }

    const isArrayValueLength = (value_: Array<Array<string>|string> | string): boolean => {
      if (!value_[0].length) return false // フィールドはあって長さ0の配列である場合は表示しない
      return (value_[0] as Array<string>)?.every((v) => v.length)
    }

    const isMultiLine = (value_: Array<Array<string>|string> | string): boolean => {
      return Array.isArray(value_) && value_.length === 1 && Array.isArray(value_[0]) && !props.omitOthers
    }

    const isValueOfLink = (value_: string): boolean => {
      const pattern = /^(https?)(:\/\/[\w/:%#$&?()~.=+-]+)/
      return pattern.test(value_)
    }

    return {
      lang,
      isExist,
      isMultiLine,
      metaArrayAndStringJoin,
      isValueOfLink,
    }
  },
  methods: {
  },
})
