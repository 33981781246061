
import { defineComponent, reactive, watchEffect, ref, computed } from 'vue'
import IconNdlOnline from '@/components/atoms/IconNdlOnline.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import PrintingModal from '@/components/static/PrintingModal.vue'
import IconLoading from '@/components/atoms/IconLoading.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import BookMarkButton from '@/components/molecules/BookMarkButton.vue'
import { ItemDownloadRequest } from '@/data/@types/ItemDownloadRequest'
import { createItemTitleLabel } from '@/domain/item/itemViewer/itemTitleLabel'
import { getMetadataLabel } from '@/domain/snippet/metadataLabel'
import { scrollToElementTop } from '@/helpers/util/scrollUtil'
import { downloadContents } from '@/domain/item/itemViewer/contentsDownload'
import { DownloadType } from '@/data/@types/DownloadType'

export default defineComponent({
  components: {
    IconNdlOnline,
    ButtonIcon,
    BookMarkButton,
    ModalWindow,
    PrintingModal,
    IconLoading,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
    showFullScreenHeader: {
      type: Function,
      required: false,
    },
    hideFullScreenHeader: {
      type: Function,
      required: false,
    },
  },
  setup (props) {
    const KN_APPLICATION_LINK = process.env.VUE_APP_KN_APPLICATION_LINK
    const i18n = useI18n()
    const lang = i18n.locale
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const location = computed(() => store.getters.location)
    const state = reactive({
      modal1: false,
      showPrintGuide: false,
      printModalType: '',
    })
    const store = useStore()
    const stateModule = ref()
    watchEffect(() => {
      stateModule.value = store.getters
    })
    const downloadingFlag = computed(() => store.getters.processingStatus === 'downloading')
    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join(' ') : ''
    }
    const creatorArrayProcessing = (creators: Array<string>): string => {
      if (creators.length === 1) return creators[0]
      // 著者が4人以上の場合は [他] をつける
      else if (creators.length > 3) return [creators[0], creators[1], creators[2]].join(', ') + i18n.t('label.bracketsOther')
      else return creators.join(', ')
    }
    const publisherArrayProcessing = (publishers: Array<string>): string => {
      // 出版者は最初の1つだけ
      // れきおん, 科学映像, 愛・地球博の動画は全部出す
      if (props.item.collections[0] === 'A00024' || props.item.collections[0] === 'A00126' || props.item.collections[0] === 'A00145') return publishers.join(' ')
      return publishers[0]
    }
    const canSimView = computed(() => store.getters.canSimView)

    const showPdf = async (itemTitle: string, printingParam: ItemDownloadRequest) => {
      state.showPrintGuide = false
      store.commit('SET_REQUEST_ITEM_TITLE', itemTitle)
      await store.dispatch('getItemDownloadUrl', {
        request: printingParam,
        errorMessagePrefix: i18n.t('header.toaster.download_error_prefix'),
        errorMessageSuffix: i18n.t('header.toaster.download_error_suffix'),
      })
      store.commit('SET_SHOW_PRINT_MODAL', false)
      store.commit('SET_SHOW_KSS_PRINT_MODAL', false)
    }

    const download = async (downloadParam: ItemDownloadRequest, itemTitle: string) => {
      state.showPrintGuide = false
      downloadContents(store, downloadParam, itemTitle, i18n, DownloadType.IMAGE)
      store.commit('SET_SHOW_INTERNET_PRINT_MODAL', false)
    }

    // モーダルウィンドウの出し分け条件
    const isAllowedPrint = computed(() => !isRekion && store.getters.isAllowedPrint)
    const isAllowedPrintKss = computed(() => !isRekion && (location.value === 'ndl' || location.value === 'kss') && store.getters.isAllowedPrintKss)
    const isAllowedPrintInternet = computed(() => !isRekion && location.value !== 'ndl' && location.value !== 'kss' && store.getters.isAllowedPrintKss)

    const openPrintModal = () => {
      state.showPrintGuide = true
      state.printModalType = 'ooc'
      store.commit('SET_SHOW_PRINT_MODAL', true)
    }

    const openPrintKssModal = () => {
      state.showPrintGuide = true
      state.printModalType = 'inlibrary'
      store.commit('SET_SHOW_KSS_PRINT_MODAL', true)
    }

    const openPrintInternetModal = () => {
      state.showPrintGuide = true
      state.printModalType = 'internet'
      store.commit('SET_SHOW_INTERNET_PRINT_MODAL', true)
    }

    const closeModal = () => {
      state.showPrintGuide = false
      store.commit('SET_SHOW_PRINT_MODAL', false)
      store.commit('SET_SHOW_KSS_PRINT_MODAL', false)
      store.commit('SET_SHOW_INTERNET_PRINT_MODAL', false)
    }

    const itemTitle = computed(() => createItemTitleLabel(props.item))

    const getMetadataLabelName = (metadataFieldName: string) => {
      return getMetadataLabel(store.getters, props.item.collections[0], metadataFieldName)[lang.value as 'en' | 'ja']
    }

    const pid = computed(() => props.item.pid.split('/').pop())

    return {
      KN_APPLICATION_LINK,
      isRekion,
      isKn,
      state,
      PrintingModal,
      openPrintModal,
      openPrintKssModal,
      openPrintInternetModal,
      closeModal,
      showPdf,
      download,
      lang,
      exitFullScreen: () => {
        document.exitFullscreen()
      },
      metaArrayProcessing,
      creatorArrayProcessing,
      publisherArrayProcessing,
      stateModule,
      itemTitle,
      getMetadataLabelName,
      pid,
      isAllowedPrint,
      isAllowedPrintKss,
      isAllowedPrintInternet,
      scrollToElementTop,
      downloadingFlag,
      canSimView,
    }
  },
})
