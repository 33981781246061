type State = {
  imageViewer: {
    fullScreen: boolean
    showFulltextPin: boolean | null
    selectedSnippetId: number
  },
}

const state: State = {
  imageViewer: {
    fullScreen: false,
    showFulltextPin: null,
    selectedSnippetId: 0,
  },
}
const mutations = {
  toggleFullScreen (state: State): void {
    state.imageViewer.fullScreen = !state.imageViewer.fullScreen
  },
  toggleFulltextPin (state: State): void {
    state.imageViewer.showFulltextPin = !state.imageViewer.showFulltextPin
  },
  enableFulltextPin (state: State): void {
    state.imageViewer.showFulltextPin = true
  },
  setSelectedSnippetId (state: State, snippetId: number): void {
    state.imageViewer.selectedSnippetId = snippetId
  },
}
const getters = {
  fullScreen: (state: State): boolean => {
    return state.imageViewer.fullScreen
  },
  showFulltextPin: (state: State): boolean => {
    return state.imageViewer.showFulltextPin || false
  },
  getSelectedSnippetId: (state: State): number => {
    return state.imageViewer.selectedSnippetId
  },
}

export default {
  state,
  mutations,
  getters,
}
