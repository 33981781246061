
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AppButton from '@/components/atoms/AppButton.vue'
import RadioButtonList from '@/components/molecules/RadioButtonList.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import IconLoading from '@/components/atoms/IconLoading.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import { getSelector } from '@/data/selector/getSelector'
import { packageStreamingEnvironmentList } from '@/data/selector/packageStreamingEnvironmentList'

export default defineComponent({
  components: {
    AppButton,
    RadioButtonList,
    DssIcon,
    IconLoading,
    TextAlert,
  },
  props: {
    pid: {
      type: String,
      required: true,
    },
    packageTitle: {
      type: String,
      required: true,
    },
    currentBundle: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const i18n = useI18n()
    const store = useStore()
    const packageStreamingEnvList = computed(() => getSelector('packageStreamingEnvironmentList', packageStreamingEnvironmentList, i18n.t))

    const defaultPackageEnv = computed(() => {
      if (props.currentBundle?.packageEnv) {
        const defaultEnv = packageStreamingEnvList.value.find((env) => env.value === props.currentBundle?.packageEnv)
        return defaultEnv ? defaultEnv.value : packageStreamingEnvList.value[0].value
      } else {
        return packageStreamingEnvList.value[0].value
      }
    })

    const selectedPackageEnv = ref(defaultPackageEnv.value)

    const publishingErrorMessage = computed(() => {
      const publishingUrlStatus = store.getters.publishingUrlStatus
      return ['failed', 'session-limit-exceeded', 'os-limit-exceeded', 'system-error', 'sim-execution-limit-exceeded', 'interval-period', 'not-available-instance'].includes(publishingUrlStatus) ? i18n.t(`packageModal.errorMessage.${publishingUrlStatus}`) : ''
    })

    const requiredContentsMeta = () => {
      return props.currentBundle.contents.map((content: any) => (
        {
          publicPath: content.publicPath,
          id: content.id,
          fileName: content.fileName,
          originalFileName: content.originalFileName,
          parentPath: content.parentPath,
        }
      ))
    }

    const openPackageStreamingUrl = async () => {
      const data = {
        pid: props.pid,
        bundleId: props.currentBundle.id,
        contents: requiredContentsMeta(),
        packageEnv: selectedPackageEnv.value,
      }
      await store.dispatch('publishPackageStreamingUrl', data)
      if (store.getters.publishingUrlStatus === 'successful') {
        const packageStreamingUrl = store.getters.packageStreamingUrl
        window.open(packageStreamingUrl, '_blank', 'noreferrer')
        store.dispatch('closePackageGuideModal')
      }
    }

    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join('\r\n') : ''
    }

    const packageBundleMessage = computed(() => metaArrayProcessing(props.currentBundle.meta?.['0602Dt']))

    const isPublishingStreamingUrl = computed(() => store.getters.publishingUrlStatus === 'publishing')

    return {
      selectedPackageEnv,
      publishingErrorMessage,
      openPackageStreamingUrl,
      packageStreamingEnvList,
      packageBundleMessage,
      isPublishingStreamingUrl,
    }
  },
})
