import { Store } from 'vuex'
/**
 * 検索結果一覧のアイテムのコレクション部分を返却します。
 * @param hitDocument SearchHits<item>のhits配列のオブジェクト
 * @param store
 * @param lang
 * @returns
 */
export const getItemListViewCollection = (hitDocument: any, store: Store<any>, lang: string): string => {
  // コレクションが未登録の場合のガード節
  if (!hitDocument.content.collections?.length) return ''

  // collectionListの1つ目のコレクション名を表示
  const mainCollection = hitDocument.content.collections[0]

  const CollectionFamily = store.getters.CollectionFamily(mainCollection)

  const collectionList: Array<string> = []
  // 電子書籍・電子雑誌以下のコレクションについては「電子書籍・電子雑誌」を表示させる
  if (CollectionFamily[0]?.collectionId === 'B00000') {
    collectionList.push(CollectionFamily[0].collectionName[lang as 'en' || 'ja'])
  } else {
    // CollectionFamily[0]は最上位（デジタル化資料）のため省略
    for (let i = 1; i < CollectionFamily.length; i++) {
      collectionList.push(CollectionFamily[i].title[lang])
    }
  }

  const collectionListForDisplay = collectionList ? collectionList.join(' > ') : ''
  // DD2.0 ではサムネイルの有無とコレクションによって、コレクション表示の後ろに改行を出し分けていたが、
  // DD3.0 では一律改行を入れる
  return collectionListForDisplay + '\r\n'
}

/**
 * 検索結果一覧画面のコレクション部分を返却します。
 * @param item アイテム
 * @param store
 * @param lang
 * @returns
 */
export const getItemListViewAllCollectionsForSearch = (item: any, store: Store<any>, lang: string) => {
  return createItemListViewAllCollectionsForSearch(item.content.collections, store, lang)
}

/**
 * マイコレクション画面のコレクション部分を返却します。
 * @param item アイテム
 * @param store
 * @param lang
 * @returns
 */
export const getItemListViewOfMyCollectionAllCollectionsForSearch = (item: any, store: Store<any>, lang: string) => {
  return createItemListViewAllCollectionsForSearch(item.collections, store, lang)
}

/**
 * 検索結果一覧画面のコレクション部分を返却します。
 * @param item アイテム
 * @param store
 * @param lang
 * @returns
 */
export const createItemListViewAllCollectionsForSearch = (collections: any, store: Store<any>, lang: string) => {
  type Collection = {
    collectionId: string;
    collectionName: { ja: string, en: string }
    children?: Array<Collection>;
    categoryCode?: string;
    docCount?: number;
    title?: any;
  }

  // コレクションが未登録の場合のガード節
  if (!collections?.length) return ''
  const CollectionFamily = collections.map((col: string) => {
    return store.getters.CollectionFamily(col)
  })
  if (!CollectionFamily) return ''

  const collectionList: Array<string> = []
  CollectionFamily.forEach((collection: Array<Collection>) => {
    const collections: Array<string> = []
    // 電子書籍・電子雑誌以下のコレクションについては「電子書籍・電子雑誌」を表示させる
    if (collection[0]?.collectionId === 'B00000') {
      collections.push(collection[0].collectionName[lang as 'en' || 'ja'])
    } else {
      // CollectionFamily[0]は最上位（デジタル化資料）のため省略
      for (let i = 1; i < collection.length; i++) {
        collections.push(collection[i].title[lang])
      }
    }
    collectionList.push(collections ? collections.join(' > ') : '')
  })
  return collectionList
}

/**
 * アイテム詳細画面の書誌情報のコレクション部分を返却します。
 * @param item アイテム
 * @param store
 * @param lang
 * @returns
 */
export const getItemListViewAllCollectionsForDetail = (item: any, store: Store<any>, lang: string) => {
  type Collection = {
    collectionId: string;
    collectionName: { ja: string, en: string }
    children?: Array<Collection>;
    categoryCode?: string;
    docCount?: number;
    title?: any;
  }

  // コレクションが未登録の場合のガード節
  if (!item.content.collections?.length) return ''

  const CollectionFamily = item.content.collections.map((col: string) => {
    return store.getters.CollectionFamily(col)
  })
  if (!CollectionFamily) return ''

  const collectionList: Array<string> = []
  CollectionFamily.forEach((collection: Array<Collection>) => {
    const collections: Array<string> = []
    // CollectionFamily[0]は最上位（デジタル化資料）のため省略
    // 電子書籍・電子雑誌の場合は省略しない
    if (collection[0]?.collectionId === 'B00000') {
      collections.push(collection[0].collectionName[lang as 'en' || 'ja'])
    }
    for (let i = 1; i < collection.length; i++) {
      collections.push(collection[i].title[lang])
    }
    collectionList.push(collections ? collections.join(' > ') : '')
  })
  return collectionList
}
