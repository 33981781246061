
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    type: {
      type: String as PropType<'internet' | 'ooc' | 'inlibrary' | 'rekion' | 'forbidden'>,
      default: 'forbidden',
      validator: (val: string): boolean => {
        return ['internet', 'ooc', 'inlibrary', 'rekion', 'forbidden'].includes(val)
      },
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const getColorType = (type: string) => (type === 'inlibrary' && isRekion) ? 'rekion' : type
    return {
      getColorType,
    }
  },
})
