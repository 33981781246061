
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import IconLoading from '@/components/atoms/IconLoading.vue'

export default defineComponent({
  name: 'DmsLoading',
  components: {
    IconLoading,
  },
  setup () {
    const store = useStore()
    const loading = computed(() => store.getters.loading)
    return {
      loading,
    }
  },
})
