
import { defineComponent, ref, onMounted, PropType } from 'vue'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    root: {
      type: HTMLElement,
      default: null,
    },
    rootMargin: {
      type: String,
      default: '0px',
    },
    threshold: {
      type: Array as PropType<number[]>,
      default: () => [0],
    },
  },
  setup (props, context) {
    const target = ref<Element>()

    onMounted(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          context.emit('intersect', entry.isIntersecting)
        })
      }, props)

      if (target.value) {
        observer.observe(target.value)
      }
    })

    return {
      target,
    }
  },
})
