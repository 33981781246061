import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f961ffa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "contents-list-tree-index-title level-left is-mobile"
}
const _hoisted_2 = { class: "contents-list-tree-index-title-name" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_DirectoryIndexTree = _resolveComponent("DirectoryIndexTree")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["contents-list-tree-index", `children-${_ctx.n}`])
  }, [
    (_ctx.index.type === 'directory')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_CloserController, {
            modelValue: _ctx.state.show,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.show) = $event)),
            icons: ['accordion-open', 'arrow-right']
          }, null, 8, ["modelValue"]),
          _createElementVNode("a", {
            href: " ",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.changeDirectory()), ["prevent"])),
            onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.changeDirectory()), ["prevent"]), ["space","enter"])),
            class: _normalizeClass(["level is-mobile flex-grow", { current:  _ctx.current && _ctx.currentFileDirectoryId === _ctx.index.id }])
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.index.title), 1)
          ], 34)
        ]))
      : _createCommentVNode("", true),
    (_ctx.index.type === 'directory')
      ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.index.children, (child) => {
            return (_openBlock(), _createBlock(_component_DirectoryIndexTree, {
              key: child.id,
              index: child,
              hasDirectory: true,
              pid: _ctx.pid,
              contentsBundle: _ctx.contentsBundle,
              contentsBundleId: _ctx.contentsBundleId,
              currentFileDirectoryId: _ctx.currentFileDirectoryId,
              onUpdateViewer: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateViewer())),
              current: _ctx.current
            }, null, 8, ["index", "pid", "contentsBundle", "contentsBundleId", "currentFileDirectoryId", "current"]))
          }), 128))
        ], 512)), [
          [_vShow, _ctx.state.show]
        ])
      : _createCommentVNode("", true),
    (_ctx.index.originalFileName)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["contents-list-tree-file-name", {'no-directory': !_ctx.hasDirectory}])
        }, [
          _createElementVNode("a", {
            href: _ctx.generatePath(),
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.changeSelectContent(_ctx.generatePath())), ["prevent"])),
            onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.changeSelectContent(_ctx.generatePath())), ["prevent"]), ["space","enter"]))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.index.originalFileName), 1)
          ], 40, _hoisted_4)
        ], 2)), [
          [_vShow, _ctx.state.show]
        ])
      : _createCommentVNode("", true)
  ], 2))
}