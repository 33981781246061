import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadstoneItem = _resolveComponent("HeadstoneItem")!
  const _component_ItemDetail = _resolveComponent("ItemDetail")!

  return (_ctx.hasContentError)
    ? (_openBlock(), _createBlock(_component_HeadstoneItem, {
        key: 0,
        item: _ctx.item,
        hasContentError: _ctx.hasContentError
      }, null, 8, ["item", "hasContentError"]))
    : (_ctx.localState.mounted && _ctx.item && Object.keys(_ctx.item).length > 0)
      ? (_openBlock(), _createBlock(_component_ItemDetail, {
          key: 1,
          item: _ctx.item,
          "show-viewer": _ctx.showViewer
        }, null, 8, ["item", "show-viewer"]))
      : _createCommentVNode("", true)
}