
import { defineComponent, defineAsyncComponent, ref, computed, watch, watchEffect, nextTick } from 'vue'
import AddonVIewContentsList from './AddonVIewContentsList.vue'
import SegmentedController from '@/components/molecules/SegmentedController.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import md from '@/helpers/util/MobileDetect'
import { useRoute } from 'vue-router'
import { ItemViewFulltextSnippet } from '@/data/@types/FulltextSearchResult'

const AsyncAddonViewIndex = defineAsyncComponent({
  loader: () => {
    return new Promise<any>(resolve => {
      setTimeout(() => {
        resolve(import('./AddonViewIndex.vue' /* webpackChunkName: "addon-view-index" */))
      }, 200)
    })
  },
})

const AsyncAddonViewProcessing = defineAsyncComponent({
  loader: () => {
    return new Promise<any>(resolve => {
      setTimeout(() => {
        resolve(import('./AddonViewProcessing.vue' /* webpackChunkName: "addon-view-processing" */))
      }, 200)
    })
  },
})

const AsyncAddonViewFullText = defineAsyncComponent({
  loader: () => {
    return new Promise<any>(resolve => {
      setTimeout(() => {
        resolve(import('./AddonViewFullText.vue' /* webpackChunkName: "addon-view-fulltext" */))
      }, 200)
    })
  },
})

export default defineComponent({
  components: {
    AddonVIewContentsList,
    AsyncAddonViewIndex,
    AsyncAddonViewProcessing,
    AsyncAddonViewFullText,
    SegmentedController,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
    tabViewFlag: {
      type: Boolean,
      required: true,
    },
    showNotPublished: {
      type: Boolean,
      required: true,
    },
    resetToken: {
      type: Number,
      required: false,
    },
  },
  setup (props) {
    const i18n = useI18n()
    const store = useStore()
    const route = useRoute()
    const isMobile = md.phone()
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const canSimView = computed(() => store.getters.canSimView)

    const segments = computed(() => {
      const contentType = store.getters.contentType
      const menu = [
        {
          id: 'contents',
          name: (() => i18n.t('rightPane.contents'))(),
          disabled: (props.viewerType === 'BranchViewer' || !contentType || props.showNotPublished || !canSimView.value),
        },
        {
          id: 'index',
          name: (() => i18n.t('rightPane.index'))(),
          disabled: false,
        },
        {
          id: 'processing',
          name: (() => i18n.t('rightPane.processing'))(),
          disabled: (props.viewerType !== 'TheImageViewer' || props.showNotPublished || !canSimView.value),
        },
        {
          id: 'fulltext',
          name: (() => i18n.t('rightPane.fullText'))(),
          disabled: ((props.viewerType !== 'TheImageViewer' && props.viewerType !== 'TheFileViewer') || isKn || props.showNotPublished || !canSimView.value),
        },
      ]
      return isMobile.value ? [menu[0], menu[3]] : menu
    })

    /* 言語 */
    const lang = useI18n().locale.value
    /* チェックするアイテム */
    const item = store.getters.item
    /* 現在表示しているバンドル */
    const contentsBundle = store.getters.currentContentsBundle
    /* コンテンツバンドルが１つだけ存在するか否か */
    const contentsBundlesOneExistFlag = ref(item.contentsBundles?.length === 1)
    /* コンテンツバンドルにある目次の存在有無 */
    const contentsBundlesIndicesExistFlag = ref(contentsBundle?.indices?.length > 0)
    /* コンテンツバンドルのコンテンツそれぞれのコンテンツ名の存在有無 */
    const contentNameExistFlag = ref(contentsBundle?.contents?.some(
      (content: any) => content?.name?.[lang]
    ))

    const updateCurrentId = () => {
      // 全文検索スニペットからのアイテム詳細への遷移の場合は全文検索タブで表示
      if (route.query.keyword) {
        const indexToCheck = isMobile.value ? 1 : 3
        if (!segments.value[indexToCheck].disabled) {
          return 'fulltext'
        }
      }
      /*
      閲覧可能なアイテムである かつ 同時閲覧チェックで閲覧可能状態 かつ 親アイテムがない かつ 葉アイテムである かつ バンドルが1つである かつ そのバンドルに目次を持たず、名前を持つコンテンツも無い場合は、コンテンツタブで表示
      デスクトップ版で目次タブを表示するアイテムもモバイルでは目次タブが無いのでコンテンツタブを表示
      */
      if ((!props.showNotPublished && canSimView.value && item.parent === null && item.type === 'leaf' && contentsBundlesOneExistFlag.value && !contentsBundlesIndicesExistFlag.value && !contentNameExistFlag.value) || isMobile.value) {
        return 'contents'
      }
      /* 上記以外である場合は、目次タブで表示 */
      return 'index'
    }

    const currentId = ref(updateCurrentId())
    const segmentedController = ref()

    watch(() => props.resetToken,
      () => {
        // eslint-disable-next-line no-unused-expressions
        segmentedController.value?.update(updateCurrentId())
      }
    )

    /**
     * 全文スニペットの自動スクロール処理
     */

    // スクロール先の要素
    const getCurrentElement = () => document.querySelector('.addon-view-full-text-list.current') as HTMLElement | null

    // スクロールさせる範囲の要素
    const getSidePanelBody = (currentElement: HTMLElement | null) =>
      currentElement?.closest('.side-panel-body.has-scrollbar') as HTMLElement | null

    // スクロール位置の計算
    const calculateScrollTopValue = (currentElement: HTMLElement, sidePanelBody: HTMLElement) =>
      currentElement.offsetTop - sidePanelBody.offsetTop + sidePanelBody.scrollTop

    // スクロール再試行処理
    const retryScroll = (retryCount: number, maxRetries: number, callback: () => void) => {
      if (retryCount < maxRetries) {
        setTimeout(callback, 100)
      }
    }

    // 指定の要素に自動スクロールする処理
    const scrollToCurrentElement = (retryCount = 0) => {
      const maxRetries = 10 // 再試行回数の上限（タイミングがシビアなため、再試行を行う）
      const currentElement = getCurrentElement()
      const sidePanelBody = getSidePanelBody(currentElement)

      if (currentElement && sidePanelBody) {
        const scrollTopValue = calculateScrollTopValue(currentElement, sidePanelBody)
        if (scrollTopValue !== 0) {
          // スクロール位置が0でない場合はスクロール
          sidePanelBody.scrollTo({ top: scrollTopValue, behavior: 'smooth' })
        } else {
          // スクロール位置が0の場合は再試行
          retryScroll(retryCount, maxRetries, () => scrollToCurrentElement(retryCount + 1))
        }
      } else {
        // 対象の要素が見つからない場合は再試行
        retryScroll(retryCount, maxRetries, () => scrollToCurrentElement(retryCount + 1))
      }
    }

    // Vuexのデータが変更されたときの処理
    const onSearchResultsUpdated = async (newList: ItemViewFulltextSnippet[]) => {
      if (newList.length !== 0) {
        await nextTick()
        scrollToCurrentElement() // DOM更新後にスクロール処理を実行
      }
    }

    watchEffect(() => {
      onSearchResultsUpdated(store.getters.getItemViewFulltextSnippetList)
    })

    return {
      currentId,
      segments,
      props,
      segmentedController,
    }
  },
})
