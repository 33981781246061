<template>
  <svg
    class="icon-loading"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="icon-loading-background"
      cx="14"
      cy="14"
      r="12"
      fill="none"
      stroke-width="4"
    />
    <path
      class="icon-loading-foreground"
      d="M 2,14 A 12 12 0 1 1 14,26"
      fill="none"
      stroke-width="4"
    />
  </svg>
</template>

<style lang="scss" scoped>
@use "../../styles/variables" as v;
@use "../../styles/functions" as f;
@use "../../styles/mixins" as m;

.icon-loading {
  animation: rotate 1.2s linear infinite;
}
@keyframes rotate {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.icon-loading-foreground {
  stroke: f.map-deep-get(v.$form-selected, DEFAULT, light);
  @include m.is-dark(&) {
    stroke: f.map-deep-get(v.$form-selected, DEFAULT, dark);
  }
}

.icon-loading-background {
  stroke: f.map-deep-get(v.$border-table, DEFAULT, light);
  @include m.is-dark(&) {
    stroke: f.map-deep-get(v.$border-table, DEFAULT, dark);
  }
}
</style>
