
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import AppToggleSwitch from '@/components/atoms/AppToggleSwitch.vue'

export default defineComponent({
  components: {
    AppToggleSwitch,
  },
  setup () {
    const store = useStore()
    const currentProcessingData = computed(() => store.getters.processingData)

    // 自動余白削除
    const autoClipFlag = computed({
      get: () => currentProcessingData.value.autoClipFlag,
      set: (val: boolean) => store.commit('updateAutoClipFlag', val),
    })
    const hasClippingData = computed(() => {
      const currentClippingData = store.getters.crippingData
      if (currentClippingData) return true
      return false
    })

    return {
      autoClipFlag,
      hasClippingData,
    }
  },
})
