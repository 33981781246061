import { getData, postData } from '@/helpers/util/webApiUtil'
import { ActionContext } from 'vuex'

type CookieAllowLevel = 'none' | 'core' | 'all'

type State = {
  cookieAllowLevel: CookieAllowLevel;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Context = ActionContext<State, any>

const state: State = {
  cookieAllowLevel: 'none',
}

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const privacySettingUrl = `${BASE_URL}/personalize/privacy/setting`

const initValue = localStorage.getItem('cookieAllowLevel') || ''
state.cookieAllowLevel = ['none', 'core', 'all'].includes(initValue) ? initValue as CookieAllowLevel : 'none'

const getters = {
  isCookieAllowed (state: State): boolean {
    return state.cookieAllowLevel !== 'none'
  },
  cookieAllowLevel (state: State): string {
    return state.cookieAllowLevel
  },
}

const mutations = {
  SET_COOKIE_ALLOW_LEVEL (state: State, level: CookieAllowLevel = 'none'): void {
    state.cookieAllowLevel = level
  },
}

const actions = {
  resetCookieAllowLevelOnLogout (context: Context): void {
    const initValue = localStorage.getItem('cookieAllowLevel') || ''
    const level = ['none', 'core', 'all'].includes(initValue) ? initValue as CookieAllowLevel : 'none'
    context.commit('SET_COOKIE_ALLOW_LEVEL', level)
  },
  setCookieAllowLevel (context: Context, level: CookieAllowLevel): void {
    context.commit('SET_COOKIE_ALLOW_LEVEL', level)
    if (context.getters.isLoggedIn && context.getters.loginType === 'sso') {
      postData(privacySettingUrl, { level })
    } else {
      localStorage.setItem('cookieAllowLevel', level)
    }
  },
  async fetchPersonalizeData (context: Context): Promise<void> {
    const response = await getData(privacySettingUrl)
    if (response?.level) {
      context.commit('SET_COOKIE_ALLOW_LEVEL', response.level)
    }
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
