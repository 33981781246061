
import { defineComponent, ref, computed } from '@vue/runtime-core'
import AppSelect from '@/components/atoms/AppSelect.vue'
import AppInputRange from '@/components/atoms/AppInputRange.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import { speedAdjustmentList, continuousModeList } from '@/data/selector/videoToolbarList'
import { useI18n } from 'vue-i18n'
import { getSelector } from '@/data/selector/getSelector'
import { useStore } from 'vuex'
import BookMarkButton from '../../molecules/BookMarkButton.vue'
import TextLinkWithArrow from '@/components/molecules/TextLinkWithArrow.vue'

export default defineComponent({
  components: {
    AppSelect,
    AppInputRange,
    ButtonIcon,
    BookMarkButton,
    TextLinkWithArrow,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    media: {
      type: String,
      default: 'video',
    },
    state: {
      type: Object,
      required: true,
    },
  },
  setup (props, { emit }) {
    const meta: {[metaname: string]: string} = {}
    Object.keys(props.item.meta).forEach((key: string) => {
      meta[key] = props.item.meta[key]
    })

    const i18n = useI18n()
    const lang = i18n.locale
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const KN_APPLICATION_LINK = process.env.VUE_APP_KN_APPLICATION_LINK
    const isIframePage = computed(() => store.getters.isIframe)
    const store = useStore()

    const speedList = computed(() => getSelector('speedAdjustmentList', speedAdjustmentList, i18n.t))
    const continuousList = computed(() => getSelector('continuousModeList', continuousModeList, i18n.t))

    const play = () => emit('play')

    const pause = () => emit('pause')

    const backward = () => emit('backward')

    const forward = () => emit('forward')

    const speed = (val: number) => emit('changeSpeed', val)

    const prev = () => emit('prev')

    const next = () => emit('next')

    const seekbar = (val: any) => emit('seekbar', val)

    const toggleContinuous = (flag: string) => emit('toggleContinuous', flag)

    const unmute = () => emit('unmute')

    const mute = () => emit('mute')

    const bookmarked = ref(false)

    const bookmarkItem = () => {
      bookmarked.value = !bookmarked.value
    }

    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join('') : ''
    }

    const volumeIcon = computed(() => {
      if (props.state.volume <= 0) {
        return 'speaker-disabled'
      } else if (props.state.volume <= 50) {
        return 'speaker-wave-quiet'
      } else {
        return 'speaker-wave-loud'
      }
    })

    const toggleFullScreen = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        const itemViewerRef = document.getElementById('full-screen-div')
        if (itemViewerRef) {
          itemViewerRef.requestFullscreen()
        }
      }
    }
    const fullscreenEnabled = document.fullscreenEnabled

    const fullScreenIcon = computed(() => {
      if (store.getters.fullScreen) {
        return 'arrow-to-inside'
      } else {
        return 'arrow-to-outside'
      }
    })

    const fullScreenAriaLabel = computed(() => {
      if (store.getters.fullScreen) {
        return i18n.t('informationHeader.fullScreenAriaLabel')
      } else {
        return i18n.t('label.fullScreen')
      }
    })

    const itemPid = computed(() => props.item.pid.split('/').slice(-1).toString())
    const currentBid = computed(() => store.getters.currentContentsBundle.id)
    const currentCid = computed(() => store.getters.currentContent.id)

    return {
      isKn,
      isRekion,
      isIframePage,
      KN_APPLICATION_LINK,
      lang,
      metaArrayProcessing,
      meta,
      stateModule: props.state,
      play,
      pause,
      backward,
      forward,
      prev,
      next,
      seekbar,
      bookmarked,
      bookmarkItem,
      speed,
      toggleContinuous,
      unmute,
      mute,
      volumeIcon,
      speedList,
      continuousList,
      toggleFullScreen,
      fullscreenEnabled,
      fullScreenIcon,
      fullScreenAriaLabel,
      itemPid,
      currentBid,
      currentCid,
    }
  },
})
