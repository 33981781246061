import { AccessToken } from '@/store/modules/AccessToken'
import { AnalysisError } from 'aws-sdk/clients/quicksight'
import { resolve } from 'cypress/types/bluebird'
import { ImageViewer } from './ImageViewer'

class DrawingCoordinate {
  public srcPosX = 0;
  public srcPosY = 0;
  public srcWidth = 0;
  public srcHeight = 0;
  public destPosX = 0;
  public destPosY = 0;
  public destWidth = 0;
  public destHeight = 0;

  public round (): void {
    this.srcPosX = Math.floor(this.srcPosX)
    this.srcPosY = Math.floor(this.srcPosY)
    this.srcWidth = Math.ceil(this.srcWidth)
    this.srcHeight = Math.ceil(this.srcHeight)

    this.destPosX = Math.floor(this.destPosX)
    this.destPosY = Math.floor(this.destPosY)
    this.destWidth = Math.ceil(this.destWidth)
    this.destHeight = Math.ceil(this.destHeight)
  }
}

class BlockInfo {
  /**
   * ブロックID配列 LT->RB
   */
  public blockIDs?: Array<number> | undefined;

  /**
   * ブロック分割数 4x4 なら 4
   */
  public divisions = 0;

  public constructor (infoStr: string) {
    const tableArrayStr = infoStr.split(',')
    if (tableArrayStr.length <= 0) {
      this.blockIDs = undefined
      this.divisions = 0
      return
    }

    this.blockIDs = new Array<number>()
    tableArrayStr.forEach((value) => {
      const v = ~~value
      if (!Number.isFinite(v)) {
        this.blockIDs = undefined
        return
      }
      if (v < 0) {
        this.blockIDs = undefined
        return
      }

      if (this.blockIDs) this.blockIDs.push(v)
    })

    this.divisions = this.detectDivisionFromTable()
  }

  private detectDivisionFromTable (): number {
    if (!this.blockIDs) return -1

    // 誤差の考慮が面倒
    let div = 2
    let res = -1
    let loop = true
    while (loop) {
      res = this.blockIDs.length / div
      if (res === div) {
        loop = false
        break
      }

      div *= 2
      if (div >= res) {
        res = -1
        loop = false
        break
      }
    }

    return res
  }
}

/**
 * ダウンロード状況
 * 0=開始前
 * 1=DL中
 * 2=DL完了
 * 3=失敗
 */
const DLProgress = {
  NotStarted: 0,
  ReadyToStart: 1,
  InProgress: 2,
  Done: 3,
  Failed: 4,
} as const
type DLProgress = typeof DLProgress[keyof typeof DLProgress];

const TileInfoExt = '.info'
/**
 * タイル描画検証クラス
 */
class TileDrawer {
  /**
   * 同時にDLできるタイルの枚数
   */
  private static LIMIT_OF_TILE_DOWNLOADING = 8

  private tileImageURL_ = '';
  private token_: AccessToken;
  public set token (value: AccessToken) {
    this.token_ = value
  }

  /**
   * 平文の難読化解除情報
   */
  private blockInfo_?: BlockInfo | undefined = undefined;

  /**
   * 難読化画像を展開させておくCanvas
   */
  private imgTag_?: HTMLImageElement | undefined = undefined;

  /**
   * タイル画像内でタイル余白を除いた有効な領域の幅比率
   * 右端のタイルのみ1.0未満になる
   */
  private availableAreaWidth_ = 1.0;
  /**
   * タイル画像内でタイル余白を除いた有効な領域の高さ比率
   * 下端のタイルのみ1.0未満になる
   */
  private availableAreaHeight_ = 1.0;

  /**
   * エッジ拡張ピクセル数
  */
  private expandEdgeSize_ = 0

  /**
   * タイル画像のDL状況
   */
  private dlProgress_: DLProgress = DLProgress.NotStarted;

  /**
   * タイルサイズ
   */
  private tileSize_ = 1024;

  /**
   * このタイルが属するコマのID
   */
  private komaID_ = -1
  public set komaID (value: number) {
    this.komaID_ = value
  }

  public get komaID (): number {
    return this.komaID_
  }

  public setTileSize (size: number): void {
    this.tileSize_ = size
  }

  private static imgLoadQueueLimit_ = 10
  private static imgLoadQueue_ = new Array<any>()
  private static currentLoadingImgs_ = new Array<any>()

  /**
   *
   * @param imageURL タイル画像取得先URL
   */
  public constructor (imageURL: string, token: AccessToken) {
    this.tileImageURL_ = imageURL
    this.token_ = token
  }

  /**
   * タイル画像がDL済みか否か
   */
  public get hasLoaded (): boolean {
    return this.dlProgress_ === DLProgress.NotStarted
  }

  /**
   * ダウンロード状況を返す
   */
  public get dlProgress (): DLProgress {
    return this.dlProgress_
  }

  /**
   * 画像ロードキューをチェックし、指定されたキューIDがロード可能になるまで待機する
   * @param queueIdentifyObject キュー内で特定の画像ロード要求を特定するためのオブジェクト
   * @returns
   */
  private async waitForImgLoadQueue (queueIdentifyObject: any): Promise<void> {
    TileDrawer.imgLoadQueue_.push(queueIdentifyObject)

    while (true) {
      await new Promise((resolve: any) => setTimeout(() => {
        return resolve()
      }, 10))

      // 並列ダウンロードではなく8枚ずつDLさせてみる
      let limit = TileDrawer.LIMIT_OF_TILE_DOWNLOADING
      if (TileDrawer.imgLoadQueue_.length < 8) {
        limit = TileDrawer.imgLoadQueue_.length
      }
      let found = false
      for (let i = 0; i < limit && i < TileDrawer.imgLoadQueue_.length; ++i) {
        if (TileDrawer.imgLoadQueue_[TileDrawer.imgLoadQueue_.length - 1 - i] === queueIdentifyObject) {
          found = true
          break
        }
      }

      if (found) {
        break
      }

      if (TileDrawer.imgLoadQueue_.length <= 0) {
        break
      }
    }
  }

  private popImgLoadQueue (queueIdentifyObject: AnalysisError): void {
    for (let i = TileDrawer.imgLoadQueue_.length - 1; i >= 0; --i) {
      if (TileDrawer.imgLoadQueue_[i] === queueIdentifyObject) {
        TileDrawer.imgLoadQueue_.splice(i, 1)
      }
    }
  }

  /**
   * タイル画像をDLする
   * @param url 画像のURL
   * @returns
   */
  public async loadTile (defer: boolean, blockInfo?: BlockInfo): Promise<TileDrawer> {
    // const ops: RequestInit = {
    //   mode: 'cors',
    //   credentials: 'same-origin'
    // }

    if (blockInfo) {
      this.blockInfo_ = blockInfo
    }

    const queueIdentifyObject = {}

    if (this.dlProgress_ === DLProgress.NotStarted) {
      this.dlProgress_ = DLProgress.ReadyToStart
      if (defer) {
        await this.waitForImgLoadQueue(queueIdentifyObject)
      }
    }

    return new Promise<TileDrawer>((resolve, reject) => {
      if (this.dlProgress_ === DLProgress.ReadyToStart) {
        this.dlProgress_ = DLProgress.InProgress

        const img = new Image()

        if (process.env.VUE_APP_ENV !== 'production') {
          img.crossOrigin = 'anonymous'// 'use-credentials'
        } else {
          img.crossOrigin = 'use-credentials'
        }

        img.onload = () => {
          if (defer) {
            this.popImgLoadQueue(queueIdentifyObject)
          }

          this.imgTag_ = img

          const keyPair = ImageViewer.getKeyPair()
          if (!keyPair) {
            this.dlProgress_ = DLProgress.Failed
            throw new Error('Invalid Key Pair')
          }

          if (!this.blockInfo_) {
            this.dlProgress_ = DLProgress.Failed
            throw new Error('There is no Available Information')
          }
          if (this.imgTag_) {
            this.expandEdgeSize_ = (this.imgTag_.naturalWidth - this.tileSize_) / (this.blockInfo_.divisions * 2)
            this.dlProgress_ = DLProgress.Done
            resolve(this)
          }
        }

        img.onerror = () => {
          this.dlProgress_ = DLProgress.Failed
          reject(new Error('Image Download Error'))
        }
        img.src = `${this.tileImageURL_}?token=${encodeURIComponent(JSON.stringify(this.token_))}`
      } else {
        if (this.dlProgress_ !== DLProgress.Failed) {
          resolve(this)
        } else {
          reject(new Error('Image Download Error'))
        }
      }
    })
  }

  /**
   * タイル余白を除いた有効範囲を指定する
   * @param width 0.0-1.0
   * @param height 0.0-1.0
   */
  public setAvailableSize (width: number, height: number): void {
    if (width < 0.0 || width > 1.0) return
    if (height < 0.0 || height > 1.0) return

    this.availableAreaWidth_ = width
    this.availableAreaHeight_ = height
  }

  /**
   * 指定座標に難読化を解除したタイルの一部を描画する
   * @param context 描画先コンテキスト
   * @param srcLeft
   * @param srcTop
   * @param srcWidth
   * @param srcHeight
   * @param destLeft
   * @param destTop
   * @param destWidth
   * @param destHeight
   */
  public drawPortion (
    context: CanvasRenderingContext2D,
    srcLeft: number,
    srcTop: number,
    srcWidth: number,
    srcHeight: number,
    destLeft: number,
    destTop: number,
    destWidth: number,
    drawBG: boolean
  ): void {
    if (this.dlProgress_ !== DLProgress.Done) {
      return
    }

    const blockInfo = this.blockInfo_
    if (blockInfo && blockInfo.blockIDs && this.imgTag_) {
      const tileDrawingCoordinates = new Array<DrawingCoordinate>(
        blockInfo.blockIDs.length
      )

      context.imageSmoothingEnabled = true

      const scale = destWidth / srcWidth
      const blockSize = this.tileSize_ / blockInfo.divisions

      const limitB = blockInfo.blockIDs.length
      for (let i = 0; i < limitB; ++i) {
        const id = blockInfo.blockIDs[i]
        const destX = (id % blockInfo.divisions)
        const destY = ~~(id / blockInfo.divisions)

        const srcX = (i % blockInfo.divisions)
        const srcY = ~~(i / blockInfo.divisions)

        const drawOffsetX = destLeft + (blockSize * destX - srcLeft) * scale
        const drawOffsetY = destTop + (blockSize * destY - srcTop) * scale
        const drawWidth = blockSize * scale
        const drawHeight = blockSize * scale

        let clipX = 1.0
        let clipY = 1.0

        if (this.tileSize_ * this.availableAreaWidth_ <= destX * blockSize) {
          continue
        }

        if (this.tileSize_ * this.availableAreaHeight_ <= destY * blockSize) {
          continue
        }

        if (
          this.tileSize_ * this.availableAreaWidth_ <
          destX * blockSize + blockSize
        ) {
          clipX =
            1.0 -
            (destX * blockSize +
              blockSize -
              this.tileSize_ * this.availableAreaWidth_) /
            blockSize
        }
        if (
          this.tileSize_ * this.availableAreaHeight_ <
          destY * blockSize + blockSize
        ) {
          clipY =
            1.0 -
            (destY * blockSize +
              blockSize -
              this.tileSize_ * this.availableAreaHeight_) /
            blockSize
        }

        let offsetLeft = 0
        let offsetTop = 0
        let offsetRight = 0
        let offsetBottom = 0
        if (destX * blockSize > srcLeft + srcWidth) {
          continue
        } else if (destX * blockSize + blockSize < srcLeft) {
          continue
        }
        if (destX * blockSize < srcLeft) {
          offsetLeft = srcLeft - destX * blockSize
        }

        if (clipX >= 1.0 && destX * blockSize + blockSize > srcLeft + srcWidth) {
          offsetRight = destX * blockSize + blockSize - (srcLeft + srcWidth)
        }

        if (destY * blockSize > srcTop + srcHeight) {
          continue
        } else if (destY * blockSize + blockSize < srcTop) {
          continue
        }
        if (destY * blockSize < srcTop) {
          offsetTop = srcTop - destY * blockSize
        }

        if (clipY >= 1.0 && destY * blockSize + blockSize > srcTop + srcHeight) {
          offsetBottom = destY * blockSize + blockSize - (srcTop + srcHeight)
        }

        const dc = new DrawingCoordinate()
        dc.srcPosX = srcX * blockSize + offsetLeft + 0.5
        dc.srcPosY = srcY * blockSize + offsetTop + 0.5
        dc.srcWidth = blockSize * clipX - offsetLeft - offsetRight
        dc.srcHeight = blockSize * clipY - offsetTop - offsetBottom

        dc.destPosX = (drawOffsetX + offsetLeft * scale)
        dc.destPosY = (drawOffsetY + offsetTop * scale)

        dc.destWidth = (
          drawWidth * clipX -
          (offsetLeft + offsetRight) * scale
        )
        dc.destHeight = (
          drawHeight * clipY -
          (offsetTop + offsetBottom) * scale
        )

        dc.srcPosX +=
          this.expandEdgeSize_ * 2 * srcX +
          this.expandEdgeSize_
        dc.srcPosY +=
          this.expandEdgeSize_ * 2 * srcY +
          this.expandEdgeSize_

        tileDrawingCoordinates[i] = dc

        // Firefoxのためだけに必要
        if (drawBG) {
          context.drawImage(
            this.imgTag_,
            dc.srcPosX,
            dc.srcPosY,
            dc.srcWidth,
            dc.srcHeight,

            dc.destPosX - 1,
            dc.destPosY - 1,
            dc.destWidth + 1,
            dc.destHeight + 1
          )
        }
      }

      const limit = blockInfo.blockIDs.length
      for (let i = 0; i < limit; ++i) {
        const dc = tileDrawingCoordinates[i]

        if (dc) {
          context.drawImage(
            this.imgTag_,
            dc.srcPosX,
            dc.srcPosY,
            dc.srcWidth,
            dc.srcHeight,

            dc.destPosX,
            dc.destPosY,
            dc.destWidth,
            dc.destHeight
          )
        }
      }
    }
  }
}

export { BlockInfo, TileDrawer, DLProgress }
