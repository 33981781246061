
import { defineComponent } from 'vue'
import ModalSheet from '@/components/organisms/ModalSheet.vue'
import TheFileViewer from '@/components/TheFileViewer.vue'

export default defineComponent({
  components: {
    ModalSheet,
    TheFileViewer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props, context) {
    // 全画面表示のときブラウザバックでポスター表示に変えるため
    window.addEventListener('popstate', () => {
      context.emit('close')
    })
  },
})
