
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup () {
    const KN_APPLICATION_LINK = process.env.VUE_APP_KN_APPLICATION_LINK
    const route = useRoute()

    const isServer = typeof window === 'undefined'
    const i18n = useI18n()
    const lang = i18n.locale

    const changeLocale = () => {
      const beforeLang = i18n.locale.value
      const afterLang = beforeLang === 'ja' ? 'en' : 'ja'
      if (isServer) return
      // 多言語用のパスはaliasで実現しているため、router.replaceは同一パスに遷移できない
      // そのため、history.replaceStateで実装し、パスを書き換えるためにリロードさせる
      history.replaceState(null, '',
        route.fullPath.startsWith(`/${beforeLang}/`) || route.fullPath === `/${beforeLang}`
          ? route.fullPath.replace(`/${beforeLang}`, `/${afterLang}`)
          : `/${afterLang}` + route.fullPath
      )
      location.reload()
    }

    return {
      KN_APPLICATION_LINK,
      lang,
      changeLocale,
    }
  },
})
