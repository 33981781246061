const emptyFunction = () => { /** */ }
let functionOnChangeLanguage: any = emptyFunction

export default () => {
  return {
    setFunction: (val: any): void => {
      functionOnChangeLanguage = val
    },

    execFunction: (): void => {
      functionOnChangeLanguage && functionOnChangeLanguage()
    },

    removeFunction: (): void => {
      functionOnChangeLanguage = emptyFunction
    },
  }
}
