import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_AppInput = _resolveComponent("AppInput")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: "input-spinner input-spinner-firefox",
    styled: _ctx.styled,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ButtonIcon, {
        id: "scale-decrement-button",
        icon: _ctx.iconDecrement,
        onClick: _ctx.decrement,
        onKeydown: _withKeys(_withModifiers(_ctx.decrement, ["prevent"]), ["space","enter"]),
        disabled: _ctx.scaleValue === _ctx.minScaleValue,
        "aria-label": _ctx.labelLeft,
        styled: "is-none"
      }, null, 8, ["icon", "onClick", "onKeydown", "disabled", "aria-label"]),
      _createVNode(_component_AppInput, {
        id: "input-scale-value",
        modelValue: _ctx.scaleValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scaleValue) = $event)),
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        min: _ctx.minScaleValue,
        max: _ctx.maxScaleValue,
        label: _ctx.label,
        type: "number",
        styled: "is-none",
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.debounceSetScale($event.target.value)))
      }, null, 8, ["modelValue", "placeholder", "disabled", "min", "max", "label"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createVNode(_component_ButtonIcon, {
        id: "scale-increment-button",
        icon: _ctx.iconIncrement,
        onClick: _ctx.increment,
        onKeydown: _withKeys(_withModifiers(_ctx.increment, ["prevent"]), ["space","enter"]),
        disabled: _ctx.scaleValue === _ctx.maxScaleValue,
        "aria-label": _ctx.labelRight,
        styled: "is-none"
      }, null, 8, ["icon", "onClick", "onKeydown", "disabled", "aria-label"])
    ]),
    _: 3
  }, 8, ["styled", "disabled"]))
}