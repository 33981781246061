
import { defineComponent, onMounted, onUnmounted } from 'vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import fixBackground from '@/helpers/util/fixBackground'
import { focusInsideDialog } from '@/helpers/util/focusInsideDialog'

export default defineComponent({
  components: {
    ButtonIcon,
  },
  emits: ['close'],
  setup (props, context) {
    const isServer = typeof window === 'undefined'

    const escape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        context.emit('close')
      }
    }

    if (!isServer) {
      window.addEventListener('keydown', escape)
      onUnmounted(() => {
        window.removeEventListener('keydown', escape)
      })
    }

    onMounted(() => {
      fixBackground.fix('unsafety', '.modal-sheet-body')
    })
    onUnmounted(() => {
      fixBackground.cancel()
    })
  },
})
