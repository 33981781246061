/**
 * アイテムのタイトル部分を返却します。
 * @param item アイテム
 * @returns 検索結果一覧画面のアイテムのタイトル部分と同じ文字列
 */

import { createTitleMetaLabel } from '../ItemListView/titleLabel'

export const createItemTitleLabel = (item: any) => {
  if (!item) return ''

  const meta = item.meta
  const mainCollection = item.collections ? item.collections[0] : 'default'

  return createTitleMetaLabel(meta, mainCollection, item.parent, item.type)
}
