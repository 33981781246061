import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createBlock(_component_AppButton, {
    styled: _ctx.styled,
    class: _normalizeClass([_ctx.classes, "button-icon"]),
    disabled: _ctx.disabled,
    type: _ctx.type
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_DssIcon, {
            key: 0,
            icon: _ctx.icon,
            size: _ctx.iconSize,
            class: "button-icon-icon"
          }, null, 8, ["icon", "size"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1),
      (_ctx.iconAfter)
        ? (_openBlock(), _createBlock(_component_DssIcon, {
            key: 1,
            icon: _ctx.iconAfter,
            size: _ctx.iconAfterSize,
            class: "button-icon-icon-after"
          }, null, 8, ["icon", "size"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["styled", "class", "disabled", "type"]))
}