import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cdaac8d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["icon", "tabindex", "role", "aria-expanded", "aria-hidden"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["closer-controller", { opened: _ctx.show, closed: !_ctx.show }]),
    icon: _ctx.icon,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show)),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.show = !_ctx.show), ["prevent"]), ["space","enter"])),
    tabindex: _ctx.disabled ? '-1' : '0',
    role: _ctx.disabled ? 'none' : 'button',
    "aria-expanded": _ctx.show,
    "aria-hidden": _ctx.disabled
  }, [
    _createVNode(_component_ButtonIcon, {
      class: "closer-button",
      icon: _ctx.icon,
      styled: "is-none",
      role: "img",
      label: _ctx.ariaLabel,
      tabindex: "-1"
    }, null, 8, ["icon", "label"]),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
  ], 42, _hoisted_1))
}