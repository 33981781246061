
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import AppInputRange from '@/components/atoms/AppInputRange.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import ContentsBundleThumbnails from './ContentsBundleThumbnails.vue'

export default defineComponent({
  components: {
    AppInputRange,
    ButtonIcon,
    ContentsBundleThumbnails,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const store = useStore()
    const currentContentsBundleIndex = computed(() => store.getters.bundleNumber)
    const playingBundleIndex = computed(() => store.getters.videoViewerInformation.playingBundleIndex)

    const thumbnailSize = ref<'is-large' | 'is-medium' | 'is-small'>('is-small')

    const thumbnailSizeIndex = computed({
      get: () => {
        return {
          'is-large': 3,
          'is-medium': 2,
          'is-small': 1,
        }[thumbnailSize.value]
      },
      set: (value) => {
        if (value >= 3) {
          thumbnailSize.value = 'is-large'
        } else if (value === 2) {
          thumbnailSize.value = 'is-medium'
        } else {
          thumbnailSize.value = 'is-small'
        }
      },
    })

    const updateViewer = (val: number) => {
      store.commit('updateCurrentBundleIndex', {
        currentBundleIndex: val,
      })
    }

    return {
      currentContentsBundleIndex,
      playingBundleIndex,
      thumbnailSize,
      thumbnailSizeIndex,
      updateViewer,
    }
  },
})
