
import { defineComponent, ref } from 'vue'
import DssIcon from '@/components/atoms/DssIcon.vue'

export default defineComponent({
  components: {
    DssIcon,
  },
  props: {
    breadcrumbs: {
      type: Object,
      required: true,
    },
  },
  setup () {
    return {
      extended: ref(false),
    }
  },
})
