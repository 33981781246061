import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-366c8cdb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-viewer-breadcrumbs" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "level"
}
const _hoisted_4 = ["onClick", "onKeydown", "tabindex", "aria-hidden"]
const _hoisted_5 = {
  key: 2,
  class: "level"
}
const _hoisted_6 = {
  key: 3,
  class: "is-hidden-desktop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(["level-left is-mobile", { extended: _ctx.extended }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (crumb, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: crumb.id
        }, [
          (crumb.type ==='leaf')
            ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                _createElementVNode("span", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeDirectory', 'root'))),
                  onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('changeDirectory', 'root')), ["prevent"]), ["space","enter"])),
                  tabindex: "0",
                  role: "button"
                }, _toDisplayString(crumb.title), 33)
              ]))
            : (crumb.type ==='directory')
              ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                  _createVNode(_component_DssIcon, {
                    icon: "arrow-right",
                    size: "is-small"
                  }),
                  _createElementVNode("span", {
                    onClick: ($event: any) => (_ctx.$emit('changeDirectory', crumb.id)),
                    onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.$emit('changeDirectory', crumb.id)), ["prevent"]), ["space","enter"]),
                    tabindex: crumb.title ? 0 : -1,
                    role: "button",
                    "aria-hidden": crumb.title ? false : true
                  }, _toDisplayString(crumb.title), 41, _hoisted_4)
                ]))
              : (_openBlock(), _createElementBlock("li", _hoisted_5, [
                  _createVNode(_component_DssIcon, {
                    icon: "arrow-right",
                    size: "is-small"
                  }),
                  _createElementVNode("span", null, _toDisplayString(crumb.title), 1)
                ])),
          (!_ctx.extended && _ctx.breadcrumbs.length >= 3 && i === 0)
            ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                _createVNode(_component_DssIcon, {
                  icon: "arrow-right",
                  size: "is-small"
                }),
                _createVNode(_component_DssIcon, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.extended = true)),
                  onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.extended = true), ["prevent"]), ["space","enter"])),
                  tabindex: "0",
                  icon: "ellipsis-horizonal",
                  size: "is-small"
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ], 2)
  ]))
}