/**
 * 永続的識別子の文字列から"info:ndljp"を除いた文字列を返す
 * @param pid 永続的識別子 例）"info:ndljp/pid/11111111"
 * @returns 永続的識別子からinfo:ndljpを除いたもの 例）"pid/11111111"
 */

const makeUpdatedPidString = (pid: string | undefined): any => {
  if (pid) {
    const splitedPid = pid.split('/')
    return splitedPid.slice(1).join('/')
  }
  return pid
}

export default makeUpdatedPidString
