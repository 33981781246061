
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ButtonIcon from '../molecules/ButtonIcon.vue'

export default defineComponent({
  name: 'CloserController',
  components: {
    ButtonIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    text: {
      type: [Array, String],
      required: false,
    },
    icons: {
      type: Array,
      default: () => ['accordion-close', 'accordion-open'],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const i18n = useI18n()
    const show = computed({
      get: () => props.modelValue,
      set: (val) => {
        context.emit('update:modelValue', val)
      },
    })

    const ariaLabel = computed(() => {
      if (props.disabled) return ''
      return show.value ? i18n.t('label.closerClose') : i18n.t('label.closerOpen')
    })

    const icon = computed(() => {
      if (props.disabled) return 'nbsp'
      return show.value ? props.icons[0] : props.icons[1]
    })

    const label = computed(() => {
      if (!props.text) return ''
      if (typeof props.text === 'string') {
        return props.text
      }
      return props.text[show.value ? 1 : 0]
    })

    return {
      show,
      icon,
      label,
      ariaLabel,
    }
  },
})
