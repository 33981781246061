
import { computed, defineComponent, ref } from 'vue'
import BasePanel from './BasePanel.vue'
import BaseHeading from './BaseHeading.vue'
import BaseParagraph from './BaseParagraph.vue'
import BaseLayoutColumn from './BaseLayoutColumn.vue'
import BaseListItem from './BaseListItem.vue'
import BaseList from './BaseList.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    BasePanel,
    BaseHeading,
    BaseParagraph,
    BaseLayoutColumn,
    BaseListItem,
    BaseList,
    AppButton,
    ButtonIcon,
  },
  setup () {
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'

    const store = useStore()
    const accept = (level: 'all' | 'core') => {
      store.dispatch('setCookieAllowLevel', level)
    }

    const isPolicySelected = computed(() => {
      return store.getters.isCookieAllowed
    })

    const showDetail = ref(false)
    return {
      isKn,
      accept,
      isPolicySelected,
      showDetail,
    }
  },
})
