/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { sum, head, flattenDeep } from 'lodash'
import { getData, postData, postDataCredential } from '@/helpers/util/webApiUtil'
import { ItemDownloadRequest } from '@/data/@types/ItemDownloadRequest'
import { DownloadOthersRequest } from '@/data/@types/DownloadOthersRequest'
import { ItemTree } from '@/data/@types/ItemTree'
import { store } from '..'
import { ActionContext } from 'vuex'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const IMAGE_URL = process.env.VUE_APP_DOWNLOAD_API_URL
const OTHER_URL = process.env.VUE_APP_FILE_DOWNLOAD_API_URL
const BRANCH_DL_URL = process.env.VUE_APP_BRANCH_DOWNLOAD_API_URL
const isKn = process.env.VUE_APP_IS_KN === 'TRUE'

const getSize = (node: any): number => {
  if (node?.contents?.length) {
    return node?.contents?.length
  }
  return sum(node?.children?.map((child: any) => getSize(child)))
}

interface updateDownloadStatusParams {
  status: string
  fixedText?: string
}
interface getItemDownloadUrlParams {
  request: ItemDownloadRequest
  errorMessagePrefix: string
  errorMessageSuffix: string
}

type State = any

/**
 * ステート
 */

const state: State = {
  itemDownloadUrl: {},
  downloadOthersUrl: {},
  itemDownloadQuery: {},
  hasError: false,
  errorText: '',
  processingStatus: '',
  othersDownloadProcessingStatus: '',
  branchDownloadProcessingStatus: '',
  downloadNotificationToasts: [],
  previousRequest: {},
  requestItemTitle: '',
  itemTree: {},
  isProcessing: false,
  downloadBranchUrl: {},
  downloadSequence: 0, // キャンセル後に前回ダウンロード分がダウンロードされるのを防ぐ
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Context = ActionContext<State, any>

/**
 * ミューテーション
 */

const mutations = {
  ITEM_DOWNLOAD_QUERY (state: State, query: ItemDownloadRequest) {
    state.ItemDownloadRequest = query
    console.log('ITEM_DOWNLOAD_QUERY', state.ItemDownloadRequest)
  },
  // 統計ログのため
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ITEM_DOWNLOAD_URL (state: State, { url, contentMeta, errorFlg }: any) {
    // 統計ログを出力するため引数に無効なパラメータを追加
    state.itemDownloadUrl = url
    console.log('ITEM_DOWNLOAD_URL', state.itemDownloadUrl)
  },
  // 統計ログのため
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PICTURE_DOWNLOAD_URL (state: State, { url, pictureMeta, errorFlg }: any) {
    // 統計ログを出力するため引数に無効なパラメータを追加
    state.itemDownloadUrl = url
    console.log('PICTURE_DOWNLOAD_URL', state.itemDownloadUrl)
  },
  // 統計ログのため
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DOWNLOAD_OTHERS_URL (state: State, { url, contentMeta, errorFlg }: any) {
    // 統計ログを出力するため引数に無効なパラメータを追加
    state.downloadOthersUrl = url
    console.log('DOWNLOAD_OTHERS_URL', state.downloadOthersUrl)
  },
  UPDATE_DOWNLOAD_STATUS (state: State, { status, fixedText }: updateDownloadStatusParams) {
    // ダウンロード処理フラグを更新
    state.processingStatus = status
    // トースト表示を更新
    if (status === 'downloading') {
      state.downloadNotificationToasts = [{
        id: 'download_0001',
        type: 'downloading',
        text: 'downloading',
        style: 'download-toast download-toast-downloading',
      }]
    } else if (status === 'download_success') {
      state.downloadNotificationToasts = [{
        id: 'download_0002',
        type: 'download_success',
        text: 'download_success',
        style: 'download-toast',
      }]
    } else if (status === 'printing_success') {
      state.downloadNotificationToasts = [{
        id: 'download_0003',
        type: 'printing_success',
        text: 'printing_success',
        style: 'download-toast',
      }]
    } else if (status === 'image_download_error' || status === 'picture_download_error' || status === 'session_check_download_error') {
      state.downloadNotificationToasts = [{
        id: 'download_0004',
        type: status,
        fixedText,
        style: 'download-toast',
      }]
    } else {
      state.downloadNotificationToasts = []
    }
  },
  UPDATE_OTHERS_DOWNLOAD_STATUS (state: State, { status, fixedText }: updateDownloadStatusParams) {
    state.othersDownloadProcessingStatus = status
    // TODO:トースト表示を更新
    if (status === 'error') {
      state.downloadNotificationToasts = [{
        type: 'others_download_error',
        text: 'download_error',
        fixedText,
        style: 'download-toast',
      }]
    } else {
      state.downloadNotificationToasts = []
    }
  },
  SET_PREVIOUS_REQUEST (state: State, request: ItemDownloadRequest) {
    state.previousRequest = request
  },
  SET_REQUEST_ITEM_TITLE (state: State, title: string) {
    state.requestItemTitle = title
  },
  // 統計ログのため
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DOWNLOAD_OTHER_FILE (state: State, { contentMeta }: any) {
    // 統計ログを出力するため
  },
  ITEM_TREE (state: State, tree: ItemTree) {
    state.itemTree = tree
    console.log('ITEM_TREES', state.itemTree)
  },
  GET_ITEMTREES_PROCESSING (state: State) {
    state.isProcessing = true
    console.log('GET_ITEMTREES_PROCESSING : ', state.isProcessing)
  },
  GET_ITEMTREES_PROCESSED (state: State) {
    state.isProcessing = false
    console.log('GET_ITEMTREES_PROCESSING : ', state.isProcessing)
  },
  DOWNLOAD_BRANCH_URL (state: State, url: any) {
    state.downloadBranchUrl = url
    console.log('DOWNLOAD_BRANCH_URL', state.downloadBranchUrl)
  },
  UPDATE_BRANCH_DOWNLOAD_STATUS (state: State, { status, fixedText }: updateDownloadStatusParams) {
    state.branchDownloadProcessingStatus = status
    console.log('UPDATE_BRANCH_DOWNLOAD_STATUS', state.branchDownloadProcessingStatus)
    if (status === 'error') {
      state.downloadNotificationToasts = [{
        type: 'branch_download_error',
        text: 'download_error',
        fixedText,
        style: 'download-toast',
      }]
    } else {
      state.downloadNotificationToasts = []
    }
  },
  INCREMENT_DOWNLOAD_SEQUENCE (state: State) {
    ++state.downloadSequence
  },
}

/**
 * ゲッター
 */

const getters = {
  itemDownloadQuery (state: State) {
    return state.itemDownloadQuery
  },
  itemDownloadUrl (state: State) {
    return state.itemDownloadUrl
  },
  downloadOthersUrl (state: State) {
    return state.downloadOthersUrl
  },
  processingStatus (state: State) {
    return state.processingStatus
  },
  othersDownloadProcessingStatus (state: State) {
    return state.othersDownloadProcessingStatus
  },
  downloadNotificationToasts (state: State) {
    return state.downloadNotificationToasts
  },
  previousRequest (state: State) {
    return state.previousRequest
  },
  requestItemTitle (state: State) {
    return state.requestItemTitle
  },
  getItemTree (state: State) {
    return state.itemTree
  },
  getTreeProcessing (state: State) {
    return state.isProcessing
  },
  downloadBranchUrl (state: State) {
    return state.downloadBranchUrl
  },
  branchDownloadProcessingStatus (state: State) {
    return state.branchDownloadProcessingStatus
  },
  downloadSequence (state: State) {
    return state.downloadSequence
  },
}

const addSizeToCids = (cids: string[], item: any): { id: string, size?: number }[] => {
  const contents = flattenDeep([item?.contentsBundles?.map((bundle: any) => bundle?.contents)])
  return cids.map((cid) => {
    const contentIndex = contents.findIndex((c: any) => c?.id === cid)
    const content = contents[contentIndex]
    return {
      id: cid,
      koma_number: contentIndex + 1, // 1から始める
      width: head(content?.meta['0714Dod']),
      height: head(content?.meta['0715Dod']),
    }
  })
}

/**
 * アクション
 */
const actions = {
  async getItemDownloadUrl (context: Context, { request, errorMessagePrefix, errorMessageSuffix }: getItemDownloadUrlParams) {
    const currentSequence = state.downloadSequence
    try {
      context.commit('SET_PREVIOUS_REQUEST', request)
      context.commit('UPDATE_DOWNLOAD_STATUS', { status: 'downloading' })
      const addSizeOptionRequest = {
        item: { ...request.item, cid: addSizeToCids(request.item.cid, store.getters.item) },
        option: { ...request.option },
      }
      const response = await postDataCredential(`${IMAGE_URL}`, addSizeOptionRequest, false)
      // キャンセルされた実行の場合、後続処理をすべてスキップする
      if (currentSequence !== state.downloadSequence) return
      if (!response || response.status !== 200) throw Error
      console.log('response: ', response)
      context.commit('ITEM_DOWNLOAD_URL', { url: response.data.presigned_url, contentMeta: store.getters.item, errorFlg: 0 })
      if (request.option.function === 'print' || request.option.function === 'printKss') {
        context.commit('UPDATE_DOWNLOAD_STATUS', { status: 'printing_success' })
      } else {
        context.commit('UPDATE_DOWNLOAD_STATUS', { status: 'download_success' })
      }
    } catch (error: any) {
      context.commit('ITEM_DOWNLOAD_URL', { url: null, contentMeta: store.getters.item, errorFlg: 1 })
      // ダウンロード処理をキャンセルしたときはエラーを表示させない
      if (state.processingStatus !== '') {
        console.error(error.message)
        context.commit('UPDATE_DOWNLOAD_STATUS', {
          status: 'image_download_error',
          fixedText: errorMessagePrefix + state.requestItemTitle + errorMessageSuffix,
        })
      }
    }
  },
  async getDownloadOthersUrl (context: Context, request: DownloadOthersRequest) {
    try {
      context.commit('UPDATE_OTHERS_DOWNLOAD_STATUS', { status: 'downloading' })
      const response = await postDataCredential(`${OTHER_URL}`, request, false)
      if (!response || response.status !== 200) throw Error
      context.commit('DOWNLOAD_OTHERS_URL', { url: response.data.presigned_url, contentMeta: store.getters.item, errorFlg: 0 })
      context.commit('UPDATE_OTHERS_DOWNLOAD_STATUS', { status: 'download_success' })
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
      context.commit('DOWNLOAD_OTHERS_URL', { url: null, contentMeta: store.getters.item, errorFlg: 1 })
      context.commit('UPDATE_OTHERS_DOWNLOAD_STATUS', { status: 'error' })
    }
  },
  async getPictureDownloadUrl (context: Context, { request, errorMessagePrefix, errorMessageSuffix }: getItemDownloadUrlParams) {
    const currentSequence = state.downloadSequence
    try {
      context.commit('UPDATE_DOWNLOAD_STATUS', { status: 'downloading' })
      const response = await postData(`${IMAGE_URL}`, request)
      // キャンセルされた実行の場合、後続処理をすべてスキップする
      if (currentSequence !== state.downloadSequence) return
      if (!response) throw Error
      context.commit('PICTURE_DOWNLOAD_URL', { url: response.presigned_url, pictureMeta: store.getters.pictureMeta, errorFlg: 0 })
      if (request.option.function === 'print') {
        context.commit('UPDATE_DOWNLOAD_STATUS', { status: 'printing_success' })
      } else {
        context.commit('UPDATE_DOWNLOAD_STATUS', { status: 'download_success' })
      }
    } catch (error: any) {
      context.commit('SET_PREVIOUS_REQUEST', request)
      context.commit('PICTURE_DOWNLOAD_URL', { url: null, pictureMeta: store.getters.pictureMeta, errorFlg: 1 })
      // ダウンロード処理をキャンセルしたときはエラーを表示させない
      if (state.processingStatus !== '') {
        console.error(error.message)
        context.commit('UPDATE_DOWNLOAD_STATUS', {
          status: 'picture_download_error',
          fixedText: errorMessagePrefix + state.requestItemTitle + errorMessageSuffix,
        })
      }
    }
  },
  async getTree (context: Context, pid: string) {
    const url = isKn ? `${BASE_URL}/item/kn/tree?pid=${pid}` : `${BASE_URL}/item/tree?pid=${pid}`
    context.commit('GET_ITEMTREES_PROCESSING')
    getData(url).then((response: any) => {
      context.commit('ITEM_TREE', response)
      context.commit('GET_ITEMTREES_PROCESSED')
      context.commit('GET_ITEMTREES_PROCESSING')
    }).catch((error: Error) => {
      console.log(error)
      context.commit('GET_ITEMTREES_PROCESSED')
    })
  },
  async getDownloadBranchUrl (context: Context, { request, fixedText }: any) {
    try {
      const contentSize = sum(request?.item?.children?.map((child: any) => getSize(child)))
      if (contentSize === 0 || contentSize > 1000) {
        context.commit('UPDATE_BRANCH_DOWNLOAD_STATUS', {
          status: 'error',
          fixedText,
        })
        return
      }
      context.commit('UPDATE_BRANCH_DOWNLOAD_STATUS', { status: 'downloading' })
      const response = await postDataCredential(`${BRANCH_DL_URL}`, request, false)
      if (response && response.status === 413) {
        context.commit('UPDATE_BRANCH_DOWNLOAD_STATUS', {
          status: 'error',
          fixedText,
        })
        return
      }
      if (!response || response.status !== 200) throw Error
      context.commit('DOWNLOAD_BRANCH_URL', response.data.presigned_url)
      context.commit('UPDATE_BRANCH_DOWNLOAD_STATUS', { status: 'success' })
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
      context.commit('DOWNLOAD_BRANCH_URL', null)
      context.commit('UPDATE_BRANCH_DOWNLOAD_STATUS', { status: 'error' })
    }
  },
  async cancelDownload (context: Context): Promise<void> {
    context.commit('UPDATE_DOWNLOAD_STATUS', { status: '' })
    context.commit('INCREMENT_DOWNLOAD_SEQUENCE')
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
