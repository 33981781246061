import { RouteLocationNormalizedLoaded, Router } from 'vue-router'

interface RouterExtention {
  replaceOrPush: (val: string) => void,
  isModifyPid: (val: string) => boolean,
}

export const useRouterExtention = (route: RouteLocationNormalizedLoaded, router: Router | undefined = undefined): RouterExtention => {
  const isModifyPid = (toPath: string): boolean => {
    return route.params.pid.toString() !== toPath.split('/')[2]
  }

  return {
  /**
   * 遷移によってpidが変動する場合はヒストリーに残し、変動しない場合はヒストリーに残さずに遷移する
   * @param toPath 遷移先のパス
   */
    replaceOrPush: (toPath: string): void => {
      if (!router) {
        console.error('router is not initialized in useRouterExtention')
        return
      }
      if (isModifyPid(toPath)) {
        console.log('extention push')
        router.push(toPath)
      } else {
        console.log('extention replace')
        router.replace(toPath)
      }
    },

    /**
     * 遷移によってpidが変動するかチェックする
     * @param toPath 遷移先のパス
     * @returns pidが変動する場合true、しない場合false
     */
    isModifyPid,
  }
}
