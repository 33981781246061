import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-971be782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-login-form" }
const _hoisted_2 = { class: "the-login-form-id" }
const _hoisted_3 = { class: "the-login-form-password" }
const _hoisted_4 = { class: "the-login-form-submit" }
const _hoisted_5 = { class: "the-login-form-footer level is-mobile" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_TextAlert = _resolveComponent("TextAlert")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('parts.userId')), 1),
      _createVNode(_component_AppInput, {
        id: "the-login-form-id",
        modelValue: _ctx.form.cardId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.cardId) = $event)),
        label: _ctx.$t('parts.userId'),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.blurCheck('cardId')))
      }, null, 8, ["modelValue", "label"]),
      (_ctx.localState.userIdErrFlag)
        ? (_openBlock(), _createBlock(_component_TextAlert, {
            key: 0,
            id: "the-login-form-id-error"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('parts.idErrorMsg')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('parts.password')), 1),
      _createVNode(_component_AppInput, {
        modelValue: _ctx.form.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.password) = $event)),
        id: "the-login-form-password",
        type: "password",
        label: _ctx.$t('parts.password'),
        onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.blurCheck('password')))
      }, null, 8, ["modelValue", "label"]),
      (_ctx.localState.passwordErrFlag)
        ? (_openBlock(), _createBlock(_component_TextAlert, {
            key: 0,
            id: "the-login-form-password-error"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('parts.passwordErrorMsg')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AppCheckbox, {
      modelValue: _ctx.form.enableRm,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.enableRm) = $event)),
      id: "rememberMe",
      name: "rememberMe",
      value: _ctx.form.enableRm,
      "data-cy": "rememberMe"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('parts.rememberMe')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "value"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AppButton, {
        onClick: _withModifiers(_ctx.login, ["prevent"]),
        onKeydown: _withKeys(_withModifiers(_ctx.login, ["prevent"]), ["space","enter"]),
        id: "the-login-form-submit",
        type: "submit",
        styled: "is-accent",
        disabled: _ctx.submitDisable
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("parts.login")), 1)
        ]),
        _: 1
      }, 8, ["onClick", "onKeydown", "disabled"]),
      (!!_ctx.errorMessage)
        ? (_openBlock(), _createBlock(_component_TextAlert, {
            key: 0,
            id: "the-login-form-submit-error"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: _ctx.$t(`link.signUp`),
        rel: "noopener"
      }, _toDisplayString(_ctx.$t("parts.signUp")), 9, _hoisted_6),
      _createElementVNode("a", {
        href: _ctx.$t(`link.forgotPassword`),
        rel: "noopener"
      }, _toDisplayString(_ctx.$t("parts.forgotPassword")), 9, _hoisted_7)
    ])
  ]))
}