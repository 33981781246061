import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetadataRecord = _resolveComponent("MetadataRecord")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["A00123-simple-record-component", `collection-${_ctx.collection}`])
  }, [
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-10001",
      labels: _ctx.getMetadataLabels(10001),
      value: [_ctx.getMetadataValue(10001)],
      canCopyClipboard: "true"
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-1",
      labels: _ctx.getMetadataLabels(1),
      value: [_ctx.createItemTitleLabel(_ctx.item)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-10",
      labels: _ctx.label_10,
      value: [_ctx.getMetadataValue(10)],
      "omit-others": ""
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-20",
      labels: _ctx.label_20,
      value: [_ctx.getMetadataValue(20)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-58",
      labels: _ctx.label_58,
      value: [_ctx.getMetadataValue(58) || _ctx.getMetadataValue(59)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-18",
      labels: _ctx.getMetadataLabels(18),
      value: [_ctx.getMetadataValue(18)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-40",
      labels: _ctx.label_40,
      value: [_ctx.getMetadataValue(40)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-410",
      labels: _ctx.getMetadataLabels(410),
      value: [_ctx.getMetadataValue(410)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-371",
      labels: _ctx.label_371,
      value: [_ctx.getMetadataValue(371)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-114",
      labels: _ctx.getMetadataLabels(114),
      value: [_ctx.getMetadataValue(114) || _ctx.getMetadataValue(438)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-281",
      labels: _ctx.getMetadataLabels(281),
      value: [_ctx.getMetadataValue(281)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-372",
      labels: _ctx.getMetadataLabels(372),
      value: [_ctx.getMetadataValue(372)]
    }, null, 8, ["labels", "value"]),
    _createVNode(_component_MetadataRecord, {
      id: "metadata-id-491",
      labels: _ctx.getMetadataLabels(491),
      value: [_ctx.getMetadataValue(491)]
    }, null, 8, ["labels", "value"])
  ], 2))
}