import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37805f34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "addon-view-contents-list" }
const _hoisted_2 = { class: "is-sr-only" }
const _hoisted_3 = { class: "level-right is-small is-hidden-mobile" }
const _hoisted_4 = { class: "addon-view-contents-bundles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_AppInputRange = _resolveComponent("AppInputRange")!
  const _component_ContentsBundleThumbnails = _resolveComponent("ContentsBundleThumbnails")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('rightPane.contents')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ButtonIcon, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.thumbnailSizeIndex--)),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.thumbnailSizeIndex--), ["prevent"]), ["space","enter"])),
        icon: "minus",
        styled: "is-none",
        class: "has-form-label",
        "aria-label": _ctx.$t('label.thumbnailSmaller')
      }, null, 8, ["aria-label"]),
      _createVNode(_component_AppInputRange, {
        modelValue: _ctx.thumbnailSizeIndex,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.thumbnailSizeIndex) = $event)),
        min: 1,
        max: 3,
        size: "is-small",
        label: _ctx.$t('rightPane.thumbnailSize')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_ButtonIcon, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.thumbnailSizeIndex++)),
        onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.thumbnailSizeIndex++), ["prevent"]), ["space","enter"])),
        icon: "plus",
        styled: "is-none",
        class: "has-form-label",
        "aria-label": _ctx.$t('label.thumbnailBigger')
      }, null, 8, ["aria-label"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.contentsBundles, (contentsBundle, i) => {
        return (_openBlock(), _createBlock(_component_ContentsBundleThumbnails, {
          key: contentsBundle.id,
          item: _ctx.item,
          contentsBundleId: i,
          "contents-bundle": contentsBundle,
          current: _ctx.currentContentsBundleIndex === i,
          isPlayingBundle: _ctx.playingBundleIndex === i,
          size: _ctx.thumbnailSize,
          onUpdateViewer: ($event: any) => (_ctx.updateViewer(i))
        }, null, 8, ["item", "contentsBundleId", "contents-bundle", "current", "isPlayingBundle", "size", "onUpdateViewer"]))
      }), 128))
    ])
  ]))
}