
import { defineComponent } from 'vue'
import NotificationMessage from '../molecules/NotificationMessage.vue'

export default defineComponent({
  components: {
    NotificationMessage,
  },
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
})
