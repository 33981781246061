import {
  SsoLoginRequest,
  Patoron,
  RegistrationStatusCode,
  Attr,
  SsoLoginResponse,
  Restriction,
  BuiltinLoginResponse,
  AuthState,
  CertificateStatusCode,
  PatronTypeCode,
  AuthorityType,
} from '@/data/@types/Authentication'
import { SearchMode, initialSearchMode } from '@/data/@types/SearchMode'
import { getData, postData, deleteData } from '@/helpers/util/webApiUtil'
import { store } from '..'
import { MUTATIONS } from '../mutations-types'

import { ActionContext } from 'vuex'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

enum LoginType {
  SSO = 'sso',
  BUILTIN = 'builtin'
}

enum Location {
  NDL = 'ndl',
  KSS = 'kss',
  REKION = 'rekion',
  DAISY = 'daisy',
  SHINSAI = 'sinsai',
  SENDLIBRARIES = 'sendlibraries',
  UNKNOWN = 'unknown'
}

type State = {
  LoginType: string,
  patron?: Patoron,
  loggedInUser?: BuiltinLoginResponse,
  location: string,
  isLoggedIn: boolean,
  hasError: boolean,
  errorText: string,
  isSessionTimeout: boolean,
  routeHistory?: string,
}

const state: State = {
  LoginType: '',
  patron: undefined,
  loggedInUser: undefined,
  location: 'unknown',
  isLoggedIn: false,
  hasError: false,
  errorText: '',
  isSessionTimeout: false,
  routeHistory: undefined,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Context = ActionContext<State, any>

const deleteLoggedinData = async () => {
  store.commit('SET_AUTH_STATE', false)
  store.commit('SET_PATRON', {})
  store.commit('MY_COLLECTION_CLEAR')
  store.commit('CLEAR_LOGGEDIN_USER')
  store.commit('CLEAR_LOGIN_TYPE')
  store.dispatch('resetCookieAllowLevelOnLogout')
  store.commit('SET_LOGIN_INITIALIZED', false)
}

const _isLoggedIn = (state: State): boolean => {
  return state.isLoggedIn
}

const _isRegistered = (state: State): boolean => {
  const patron = state.patron
  const type = patron?.type
  return type === PatronTypeCode.REGISTERED
}

const _isSimple = (state: State): boolean => {
  const patron = state.patron
  const registrationStatus = patron?.registrationStatus
  return registrationStatus === RegistrationStatusCode.SIMPLE
}

const _isOfficial = (state: State): boolean => {
  const patron = state.patron
  const registrationStatus = patron?.registrationStatus
  return registrationStatus === RegistrationStatusCode.OFFICIAL
}

const _isApplying = (state: State): boolean => {
  //  利用者登録状態が簡易登録で、本人確認書類確認状態コード[CertificateStatusCode]が確認中[INPROCESS]、保留[PENDING]のいずれか
  const patron = state.patron
  const registrationStatus = patron?.registrationStatus
  const certificateStatusCode = patron?.certificate?.status
  return (
    registrationStatus === RegistrationStatusCode.SIMPLE &&
    (certificateStatusCode === CertificateStatusCode.INPROCESS ||
      certificateStatusCode === CertificateStatusCode.PENDING)
  )
}

const _isPersendUserType = (state: State): boolean => {
  //  登録利用者、国会議員、国会職員、NDL職員か確認
  const allowType = ['REGISTERED', 'DIET_MEMBER', 'DIET_STAFF', 'NDL_STAFF']
  const patron = state.patron
  return !!patron?.type && allowType.includes(patron?.type)
}

const _isOrganizationUserType = (state: State): boolean => {
  //  国会議員、国会職員、NDL職員か確認
  const allowType = ['DIET_MEMBER', 'DIET_STAFF', 'NDL_STAFF']
  const patron = state.patron
  return !!patron?.type && allowType.includes(patron?.type)
}

const _isLiveInJapan = (state: State): boolean => {
  // 居住国が日本か確認
  const patron = state.patron
  const country = patron?.country
  return country === 'JP'
}

const _isPersendAgreed = (state: State): boolean => {
  const patron = state.patron
  return patron?.attrs?.find((attr: Attr) => attr.key === 'PERSEND' && attr.value === 'ACCEPTED') !== undefined
}

const _isPersendForbidden = (state: State): boolean => {
  const patron = state.patron
  return patron?.restrictions?.find((restriction: Restriction) => restriction.type === 'PERSEND' || restriction.type === 'ALL') !== undefined
}

const _isPersendPerfect = (state: State) => {
  //  利用者がデジタルコレクションにログインしていること。
  if (!_isLoggedIn(state)) return false
  //  利用者の「利用者種別」が＜登録利用者＞＜国会議員＞＜国会職員＞＜NDL職員＞のいずれかであること。
  if (!_isPersendUserType(state)) return false
  // 「利用者種別」が＜登録利用者＞の場合は「登録状態」が＜本登録＞かつ「居住国」が＜日本＞であること。
  if (!_isOrganizationUserType(state) && !(_isRegistered(state) && _isOfficial(state) && _isLiveInJapan(state))) return false
  //  「個人送信サービス同意状態」が＜同意＞であること。
  if (!_isPersendAgreed(state)) return false
  //  「利用制限」で、＜個人送信利用禁止＞または＜全サービス利用禁止＞がかかっていないこと。
  if (_isPersendForbidden(state)) return false
  return true
}

const getters = {
  isLoggedIn (state: State): boolean {
    return _isLoggedIn(state)
  },
  // 個人送信or図書館送信アカウント判定
  isPersendOrLibsend (state: State): boolean {
    const patron = state.patron
    if (patron?.attrs?.find((attr: Attr) => attr.key === 'LIBSEND' && attr.value !== 'LIBSEND_B_PERSEND')
    ) {
      // 図書館送信
      return true
    } else if (_isPersendPerfect(state)) {
      // 個人送信-手続き済み
      return true
    } else {
      // 個人送信-未手続き
      return false
    }
  },
  //  登録利用者の場合
  isRegistered (state: State): boolean {
    return _isRegistered(state)
  },
  //  簡易登録ユーザーの場合
  isSimple (state: State): boolean {
    return _isSimple(state)
  },
  //  本登録ユーザーの場合
  isOfficial (state: State): boolean {
    return _isOfficial(state)
  },
  //  本登録申請中の場合
  isApplying (state: State): boolean {
    return _isApplying(state)
  },
  //  居住国が日本か判定
  isLiveInJapan (state: State): boolean {
    return _isLiveInJapan(state)
  },
  //  個人送信の条件をすべて満たしているか判定
  isPersendPerfect (state: State): boolean {
    return _isPersendPerfect(state)
  },
  //  個人送信対象のユーザー種別か判定
  isPersendUserType (state: State): boolean {
    return _isPersendUserType(state)
  },
  //  国会議員、国会職員、NDL職員か判定
  isOrganizationUserType (state: State): boolean {
    return _isOrganizationUserType(state)
  },
  //  「個人送信サービス同意状態」が＜同意＞であること。
  isPersendAgreed (state: State): boolean {
    return _isPersendAgreed(state)
  },
  //  「利用制限」で、＜個人送信利用禁止＞または＜全サービス利用禁止＞がかかっていないこと。
  isPersendForbidden (state: State): boolean {
    return _isPersendForbidden(state)
  },
  // 個人送信アカウント判定
  isPersend (state: State): boolean {
    const patron = state.patron
    if (patron?.attrs?.find((attr: Attr) => attr.key === 'PERSEND')) {
      return true
    } else {
      return false
    }
  },
  // 個人送信アカウント判定（手続き途中であるかの判断が必要な場合）
  isMiddlePersend (state: State): boolean {
    const patron = state.patron
    if (patron?.attrs?.find((attr: Attr) => attr.key === 'PERSEND' && attr.value !== 'ACCEPTED')) {
      // 個人送信-手続き途中
      return false
    } else {
      // 個人送信-手続き済み、個人送信-未手続き
      return true
    }
  },
  // 図書館送信アカウント判定
  isSendlibraries (state: State): boolean {
    const patron = state.patron
    return !!patron?.attrs?.find((attr: Attr) => attr.key === 'LIBSEND' && attr.value !== 'LIBSEND_B_PERSEND')
  },
  // 図書館送信参加館アカウント判定
  isLibsend (state: State): string {
    const patron = state.patron
    if (!patron?.attrs) return ''
    const libsend = patron.attrs.filter((attr: Attr) => attr.key === 'LIBSEND' && attr.value !== 'LIBSEND_B_PERSEND')
    return libsend[0]?.value || ''
  },
  // 統計ダウンロード可能判定
  canDownloadStatistics (state: State): boolean {
    const patron = state.patron
    if (!patron?.attrs) return false
    const libsend = patron.attrs.find((attr: Attr) => ['LIBSEND_B_STAFF', 'LIBSEND_BP_STAFF'].includes(attr.value))
    return libsend !== undefined
  },
  // れきおん参加館アカウント判定
  isRekionBuilding (state: State): boolean {
    const loginUserRole = state?.loggedInUser?.role || []
    return loginUserRole.includes(AuthorityType.REKION)
  },
  // れきおん参加館管理者アカウント判定
  isRekionAdmin (state: State): boolean {
    const loginUserRole = state?.loggedInUser?.role || []
    return loginUserRole.includes(AuthorityType.REKION_ADMIN)
  },
  isPackageUser (state: State): boolean {
    const loginUserRole = state?.loggedInUser?.role || []
    return loginUserRole.includes(AuthorityType.PACKAGE)
  },
  libraryName (state: State): string {
    const patron = state.patron
    return patron?.name ?? ''
  },
  loginType (state: State): string {
    return state.LoginType
  },
  loggedInUser (state: State): BuiltinLoginResponse | undefined {
    return state.loggedInUser
  },
  location (state: State): string {
    return state.location
  },
  isFromKss (state: State): boolean {
    return state.location === Location.KSS
  },
  isFromNdl (state: State): boolean {
    return state.location === Location.NDL
  },
  hasErrorInLoginPage (state: State): boolean {
    return state.hasError
  },
  errorTextInLoginPage (state: State): string {
    return state.errorText
  },
  searchMode (): SearchMode {
    const cookieSearchMode: SearchMode[] = []
    const isServer = typeof document === 'undefined'
    if (!isServer) {
      document.cookie.split(';').map(cookie => {
        if (cookie.split('=')[0].includes('searchMode')) {
          cookieSearchMode.push(JSON.parse(cookie.split('=')[1]))
        }
      })
      return cookieSearchMode[0] || initialSearchMode
    }
    return initialSearchMode
  },
  patron (state: State): Patoron | undefined {
    return state.patron
  },
  isSessionTimeout (state: State): boolean {
    return state.isSessionTimeout
  },
  routeHistory (state: State): string | undefined {
    return state.routeHistory
  },
}

const mutations = {
  LOGIN (state: State): void {
    state.isLoggedIn = true
    state.LoginType = LoginType.SSO
    store.commit('SET_LOGIN_INITIALIZED', true)
  },
  LOGOUT (state: State): void {
    state.isLoggedIn = false
    store.commit('SET_LOGIN_INITIALIZED', false)
  },
  BUILTIN_LOGIN (state: State, user: BuiltinLoginResponse): void {
    state.isLoggedIn = true
    state.LoginType = LoginType.BUILTIN
    state.loggedInUser = user
  },
  CLEAR_LOGGEDIN_USER (state: State): void {
    state.loggedInUser = undefined
  },
  CLEAR_LOGIN_TYPE (state: State): void {
    state.LoginType = ''
  },
  HAS_ERROR (state: State, hasError: boolean): void {
    state.hasError = hasError
  },
  SET_PATRON (state: State, patron: Patoron): void {
    state.patron = patron
  },
  SET_ERROR_TEXT (state: State, errorText: string): void {
    state.errorText = errorText
  },
  SET_AUTH_STATE (state: State, isLoggedIn: boolean): void {
    state.isLoggedIn = isLoggedIn
  },
  SET_LOCATON (state: State, location: Location): void {
    state.location = location
  },
  SET_SETTION_TIMEOUT (state: State, isSessionTimeout: boolean): void {
    state.isSessionTimeout = isSessionTimeout
  },
  SET_ROUTE_HISTORY (state: State, routeHistory?: string): void {
    state.routeHistory = routeHistory
  },
}

const actions = {
  async login (context: Context, request: SsoLoginRequest): Promise<void> {
    await postData(`${BASE_URL}/auth/sso/login`, request).then((response: SsoLoginResponse) => {
      context.commit('SET_PATRON', response.patron)
      context.commit('HAS_ERROR', false)
      context.commit('SET_ERROR_TEXT', '')
      context.commit('LOGIN')
    }).catch((error: Error) => {
      context.commit('HAS_ERROR', true)
      context.commit('SET_ERROR_TEXT', error.message)
    })
  },
  async logout (context: Context): Promise<void> {
    try {
      await context.commit('LOGOUT')
      const url = `${BASE_URL}/auth/sso/logout`
      await deleteData(url)
      // 画面が再描画されないので明示的にログアウト状態を設定
      deleteLoggedinData()
    } catch (e) {
      console.error(e)
      await context.commit('LOGOUT')
      // セッションタイムアウト（404）のとき、フロントログアウトを手動で実行
      deleteLoggedinData()
      // エラーの場合はクライアント側でCookieを削除する
      document.cookie = `cookieId=; domain=${process.env.VUE_APP_COOKIE_DOMAIN}; path =/; max-age=0`
      document.cookie = `NDLS_SESSION=; domain=${process.env.VUE_APP_COOKIE_DOMAIN}; path =/; max-age=0`
    }
  },
  async builtinLogin (context: Context, request: FormData): Promise<void> {
    await postData(`${BASE_URL}/auth/builtin/login`, request).then((response: any) => {
      if (response.status === 401) {
        console.error('response.ok:', response.ok)
        console.error('response.statusText:', response.statusText)
        // const json = await response.json()
        throw new Error('入力された認証情報が不正です。入力内容を見直してください。')
      }
      const successResponse: BuiltinLoginResponse = response
      context.commit('HAS_ERROR', false)
      context.commit('SET_ERROR_TEXT', '')
      context.commit('BUILTIN_LOGIN', successResponse)
    }).catch((error: Error) => {
      // ネットワークエラーでも !response.ok でもここで処理できる
      context.commit('HAS_ERROR', true)
      context.commit('SET_ERROR_TEXT', error.message)
    })
  },
  async builtinLogout (): Promise<void> {
    const url = `${BASE_URL}/auth/builtin/logout`
    await postData(url)

    // 画面が再描画されないので明示的にログアウト状態を設定
    deleteLoggedinData()
  },
  async getAuthState (context: Context): Promise<AuthState | void> {
    const url = `${BASE_URL}/auth/state`
    const authState = await getData(url).then((response: AuthState) => {
      const isLoggedIn = store.getters.isLoggedIn
      // 別タブでログアウトしている場合、ログイン情報を削除
      if (isLoggedIn && response.cardId === 'anonymousUser') deleteLoggedinData()

      // ログインしていない場合、ログアウト直後の場合、アカウント情報をセットせずリターン
      if (response.cardId === 'anonymousUser' || !response.authenticated) return response

      if (response.patron) {
        // 通常ログインしている場合、アカウントの情報を再セット
        context.commit('SET_PATRON', response.patron)
        context.commit('LOGIN')
      } else {
        // 組み込みログインしている場合、組み込みアカウントの情報を再セット
        context.commit('BUILTIN_LOGIN', {
          cardId: response.cardId,
          role: response.authorities,
        })
      }
      return response
    }).catch((error: Error) => {
      // エラーの場合はクライアント側でCookieを削除する
      console.error(error.message)
      document.cookie = `cookieId=; domain=${process.env.VUE_APP_COOKIE_DOMAIN}; path =/; max-age=0`
      document.cookie = `NDLS_SESSION=; domain=${process.env.VUE_APP_COOKIE_DOMAIN}; path =/; max-age=0`
    })
    return authState
  },
  async setLocation (context: Context): Promise<void> {
    await getData(`${BASE_URL}/geography`).then((response: any) => {
      context.commit('SET_LOCATON', response.value)
    })
  },
  async setSearchMode (context: Context, searchMode: SearchMode): Promise<void> {
    const isServer = typeof document === 'undefined'
    if (!isServer) {
      const now = new Date()
      now.setFullYear(now.getFullYear() + 1)
      document.cookie = `searchMode=${JSON.stringify(searchMode)};expires=${now.toUTCString()};`
    }
  },
  async sessionCheck (context: Context): Promise<boolean> {
    const isPersend = store.getters.isPersend
    const isSendlibraries = store.getters.isSendlibraries
    const isRekionBuilding = store.getters.isRekionBuilding
    const authState: AuthState = await store.dispatch('getAuthState')

    // 個人送信、図書館送信、れきおん参加館のアカウントはログアウト
    if (!authState.authenticated && (isPersend || isSendlibraries)) {
      await store.dispatch('logout')
      context.commit('SET_SETTION_TIMEOUT', true)
      return false
    } else if (authState.cardId === 'anonymousUser' && isRekionBuilding) {
      await store.dispatch('builtinLogout')
      context.commit(MUTATIONS.SESSION_TIMEOUT_NOTIFICATION)
      return false
    }
    return true
  },
  setRouteHistory (context: Context, routeHistory: string): void {
    context.commit('SET_ROUTE_HISTORY', routeHistory)
  },
  clearRouteHistory (context: Context): void {
    context.commit('SET_ROUTE_HISTORY', undefined)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
