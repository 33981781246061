/**
 * @param {Array<string>} metaArray 加工対象となる配列
 * @param {string} separator 配列要素を区切るセパレータ
 * @return {Array<string>} 入力配列をセパレータで区切った配列。ただし、先頭要素はセパレータとする。
 */
export const joinArrayWith = (metaArray: Array<string>, separator: string): Array<string> => {
  return metaArray?.reduce((p, c) => {
    p.push(separator)
    p.push(c)
    return p
  }, [] as Array<string>)
}
