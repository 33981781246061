export const sortList = [
  { value: 'SCORE', sortKey: 'SCORE', order: 'DESC' },
  { value: 'TITLE_ASC', sortKey: 'TITLE', order: 'ASC' },
  { value: 'TITLE_DESC', sortKey: 'TITLE', order: 'DESC' },
  { value: 'CREATOR_ASC', sortKey: 'CREATOR', order: 'ASC' },
  { value: 'CREATOR_DESC', sortKey: 'CREATOR', order: 'DESC' },
  { value: 'ISSUED_DESC', sortKey: 'ISSUED', order: 'DESC' },
  { value: 'ISSUED_ASC', sortKey: 'ISSUED', order: 'ASC' },
  { value: 'CALL_NUMBER_ASC', sortKey: 'CALL_NUMBER', order: 'ASC' },
  { value: 'CALL_NUMBER_DESC', sortKey: 'CALL_NUMBER', order: 'DESC' },
  { value: 'AVAILABLE_PERSEND_DESC', sortKey: 'AVAILABLE_PERSEND', order: 'DESC' },
  { value: 'AVAILABLE_PERSEND_ASC', sortKey: 'AVAILABLE_PERSEND', order: 'ASC' },
  { value: 'AVAILABLE_INTERNET_DESC', sortKey: 'AVAILABLE_INTERNET', order: 'DESC' },
  { value: 'AVAILABLE_INTERNET_ASC', sortKey: 'AVAILABLE_INTERNET', order: 'ASC' },
  { value: 'AVAILABLE_DESC', sortKey: 'AVAILABLE', order: 'DESC' },
  { value: 'AVAILABLE_ASC', sortKey: 'AVAILABLE', order: 'ASC' },
]
