import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7edd590e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-viewer-deny-item item-viewer-deny-padding is-mobile" }
const _hoisted_2 = { class: "item-viewer-deny-item-body has-background-contents shadow" }
const _hoisted_3 = { class: "message deny-item-message transfer-pid-link" }
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_DssIcon, {
            icon: "book-forbiddan",
            style: {"font-size":"4rem"}
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('restrictionMessage.simView.cardTitle')), 1),
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('restrictionMessage.simView.cardMessage')), 1)
          ])
        ])
      ])
    ])
  ]))
}