/**
 * コンテナ要素（containerSelectorで指定）からフォーカスが出ないようにする。
 * コンテナ要素が異なれば重複して実行可能。
 * マウント時にフォーカスの位置を記憶しておいて、アンマウント時にフォーカスを元に戻す。
 * マウント時にコンテナ内にinputがあればフォーカスする。
 */

import { onMounted, onUnmounted } from 'vue'

export const focusInsideDialog = (containerSelector: string): void => {
  const returnTarget = () => {
    const selector = `${containerSelector} input, ${containerSelector} button, ${containerSelector} a`
    return document.querySelector(selector) as HTMLElement
  }

  const focusModal = (event: FocusEvent) => {
    const target = event.target as Element
    if (!target.closest(containerSelector)) {
      return returnTarget()?.focus()
    }
  }

  onMounted(() => {
    document.addEventListener('focus', focusModal, { capture: true })
    const selector = `${containerSelector} input`
    const target = document.querySelector(selector) as HTMLElement
    return target?.focus()
  })

  const current = document.activeElement as HTMLElement
  onUnmounted(() => {
    document.removeEventListener('focus', focusModal, { capture: true })
    return current?.focus()
  })
}
