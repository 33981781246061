import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95edf474"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "printing-modal-message-internet" }
const _hoisted_2 = { class: "printing-modal-section" }
const _hoisted_3 = { class: "title has-text-weight-normal" }
const _hoisted_4 = { class: "title has-text-weight-normal" }
const _hoisted_5 = { class: "title has-text-weight-normal" }
const _hoisted_6 = {
  key: 0,
  class: "printing-modal-range-input",
  "aria-live": "polite"
}
const _hoisted_7 = { class: "printing-modal-input-range-guide" }
const _hoisted_8 = { class: "printing-modal-footer level-right is-mobile" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "printing-modal-message-inlibrary" }
const _hoisted_11 = {
  key: 1,
  class: "printing-modal-message-ooc"
}
const _hoisted_12 = { class: "title has-text-weight-normal" }
const _hoisted_13 = { class: "printing-modal-section" }
const _hoisted_14 = { class: "title has-text-weight-normal" }
const _hoisted_15 = { class: "printing-modal-plint-limit-guide" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 0,
  class: "printing-modal-range-input",
  "aria-live": "polite"
}
const _hoisted_19 = {
  key: 2,
  class: "printing-modal-input-range-guide"
}
const _hoisted_20 = { class: "printing-modal-footer level-right is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButtonList = _resolveComponent("RadioButtonList")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_TextAlert = _resolveComponent("TextAlert")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_TextLinkWithArrow = _resolveComponent("TextLinkWithArrow")!

  return (_ctx.type === 'internet')
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(`printing-modal printing-modal-${_ctx.type}`)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('printModal.internetMessage')), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('downloadPanel.fileType')), 1),
          _createVNode(_component_RadioButtonList, {
            modelValue: _ctx.state.fileType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.fileType) = $event)),
            items: _ctx.fileTypes,
            id: "printing-modal-file-type",
            name: "printing-modal-file-type",
            class: "printing-modal-file-type"
          }, null, 8, ["modelValue", "items"]),
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('downloadPanel.size')), 1),
          _createVNode(_component_RadioButtonList, {
            modelValue: _ctx.state.imageSize,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.imageSize) = $event)),
            items: _ctx.imageSizes,
            id: "printing-modal-image-size",
            name: "printing-modal-image-size",
            styled: "line-up",
            class: "printing-modal-image-size"
          }, null, 8, ["modelValue", "items"]),
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('downloadPanel.range')), 1),
          _createVNode(_component_RadioButtonList, {
            modelValue: _ctx.rangeState.val,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rangeState.val) = $event)),
            items: _ctx.rangesLocal,
            id: "printing-modal-range",
            name: "printing-modal-range",
            class: "printing-modal-range"
          }, null, 8, ["modelValue", "items"]),
          (_ctx.rangeState.val === 'specific' || _ctx.rangeState.val === 'mycollection')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_AppInput, {
                  modelValue: _ctx.state.specificRange,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.specificRange) = $event)),
                  type: "string",
                  placeholder: "1,3,6",
                  id: "range-specific-input",
                  "aria-label": _ctx.$t('printModal.inputRangeAriaLabel')
                }, null, 8, ["modelValue", "aria-label"]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('printModal.inputRangeGuideMessage')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.warning)
            ? (_openBlock(), _createBlock(_component_TextAlert, {
                key: 1,
                class: "printing-modal-range-caution",
                "data-cy": _ctx.warningId
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.warning), 1)
                ]),
                _: 1
              }, 8, ["data-cy"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_AppButton, {
            id: "printing-modal-cancel-button",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
            onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]), ["space","enter"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('label.cancel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_AppButton, {
            id: "printing-modal-printing-button",
            onClick: _ctx.download,
            onKeydown: _withKeys(_withModifiers(_ctx.download, ["prevent"]), ["space","enter"]),
            styled: "is-accent"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('label.acceptanceAndInternetPrinting')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "onKeydown"])
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("section", {
        key: 1,
        class: _normalizeClass(`printing-modal printing-modal-${_ctx.type}`)
      }, [
        (_ctx.type === 'inlibrary')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("p", _hoisted_10, [
                _createTextVNode(_toDisplayString(_ctx.$t('printModal.inlibraryMessagePrefix')) + " ", 1),
                _createVNode(_component_TextLinkWithArrow, {
                  icon: "",
                  href: _ctx.$t('printModal.inlibraryMessageLink.tokyo'),
                  target: "_blank",
                  class: "printing-modal-message-inlibrary-link-tokyo"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('printModal.inlibraryMessageLinkText.tokyo')), 1)
                  ]),
                  _: 1
                }, 8, ["href"]),
                _createVNode(_component_TextLinkWithArrow, {
                  icon: "",
                  href: _ctx.$t('printModal.inlibraryMessageLink.kansai'),
                  target: "_blank",
                  class: "printing-modal-message-inlibrary-link-kansai"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('printModal.inlibraryMessageLinkText.kansai')), 1)
                  ]),
                  _: 1
                }, 8, ["href"]),
                _createVNode(_component_TextLinkWithArrow, {
                  icon: "",
                  href: _ctx.$t('printModal.inlibraryMessageLink.kodomo'),
                  target: "_blank",
                  class: "printing-modal-message-inlibrary-link-kodomo"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('printModal.inlibraryMessageLinkText.kodomo')), 1)
                  ]),
                  _: 1
                }, 8, ["href"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('printModal.inlibraryMessageSuffix')), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t('printModal.notes')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('printModal.oocMessage1')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('printModal.oocMessage2')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('printModal.oocMessage3')), 1)
            ])),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t('printModal.range')), 1),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('printModal.imageProcessingMessage')), 1),
            (_ctx.type === 'inlibrary')
              ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t('printModal.printKssLimitMessage')), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t('printModal.printLimitMessage')), 1))
          ]),
          _createVNode(_component_RadioButtonList, {
            modelValue: _ctx.rangeState.val,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.rangeState.val) = $event)),
            items: _ctx.rangesLocal,
            id: "printing-modal-range",
            name: "printing-modal-range",
            class: "printing-modal-range"
          }, null, 8, ["modelValue", "items"]),
          (_ctx.rangeState.val !== 'all')
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_AppInput, {
                  modelValue: _ctx.state.specificRange,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.specificRange) = $event)),
                  placeholder: "1,3,6",
                  id: "range-specific-input",
                  "aria-label": _ctx.$t('printModal.inputRangeAriaLabel')
                }, null, 8, ["modelValue", "aria-label"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.warning)
            ? (_openBlock(), _createBlock(_component_TextAlert, {
                key: 1,
                class: "printing-modal-range-caution",
                "data-cy": _ctx.warningId
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.warning), 1)
                ]),
                _: 1
              }, 8, ["data-cy"]))
            : _createCommentVNode("", true),
          (_ctx.rangeState.val !== 'all')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('printModal.inputRangeGuideMessage')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_AppButton, {
            id: "printing-modal-cancel-button",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('close'))),
            onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]), ["space","enter"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('label.cancel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_AppButton, {
            id: "printing-modal-printing-button",
            onClick: _ctx.printing,
            onKeydown: _withKeys(_withModifiers(_ctx.printing, ["prevent"]), ["space","enter"]),
            styled: "is-accent"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('label.acceptanceAndPrinting')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "onKeydown"])
        ])
      ], 2))
}