import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c8e5653"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-viewer-header has-background-contents shadow level is-mobile" }
const _hoisted_2 = { class: "image-viewer-header-left level is-mobile" }
const _hoisted_3 = { class: "image-viewer-header-right level is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ButtonIcon, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', 'help'))),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('update:modelValue', 'help')), ["prevent"]), ["space","enter"])),
        icon: "circle-questionmark",
        styled: "is-none",
        id: "image-viewer-header-help-button"
      }),
      _createVNode(_component_AppButton, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', 'settings'))),
        onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('update:modelValue', 'settings')), ["prevent"]), ["space","enter"])),
        styled: "is-none",
        id: "image-viewer-header-setting-button"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.settings')), 1)
        ]),
        _: 1
      }),
      (_ctx.abstract && _ctx.abstract.length > 0)
        ? (_openBlock(), _createBlock(_component_AppButton, {
            key: 0,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:modelValue', 'abstract'))),
            onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('update:modelValue', 'abstract')), ["prevent"]), ["space","enter"])),
            styled: "is-none"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('label.abstractShort')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ButtonIcon, {
        onClick: _ctx.toggleShowPin,
        onKeydown: _withKeys(_withModifiers(_ctx.toggleShowPin, ["prevent"]), ["space","enter"]),
        icon: _ctx.pinIcon,
        styled: "is-none",
        id: "image-viewer-header-viewer-pin-button"
      }, null, 8, ["onClick", "onKeydown", "icon"]),
      _createVNode(_component_ButtonIcon, {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:modelValue', 'addonView'))),
        onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('update:modelValue', 'addonView')), ["prevent"]), ["space","enter"])),
        icon: "list-bullet",
        styled: "is-none",
        id: "image-viewer-header-addonview-button"
      }),
      _createVNode(_component_ButtonIcon, {
        styled: "is-none",
        icon: "xmark-circle",
        onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"])),
        onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent","stop"]), ["space","enter"])),
        id: "image-viewer-header-close-button"
      })
    ])
  ]))
}