import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-610d6ee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "notification-message-text" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notification-message", _ctx.style || _ctx.type])
  }, [
    (_ctx.type === 'notice' || _ctx.type === 'information')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_DssIcon, { icon: "balloon-exclamationmark" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
    (_ctx.type === 'notice' || _ctx.type === 'success')
      ? (_openBlock(), _createBlock(_component_ButtonIcon, {
          key: 1,
          class: "notification-message-close-button",
          icon: "xmark-circle",
          styled: "is-none",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove', _ctx.id))),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('remove', _ctx.id)), ["prevent"]), ["space","enter"])),
          "aria-label": _ctx.$t('header.toaster.close')
        }, null, 8, ["aria-label"]))
      : _createCommentVNode("", true),
    (_ctx.type === 'downloading')
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          class: "notification-message-download-cansel-text",
          href: " ",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('cancelDownload')), ["prevent"])),
          onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('cancelDownload')), ["prevent"]), ["space","enter"])),
          "aria-label": _ctx.$t('header.toaster.download.cancel')
        }, _toDisplayString(_ctx.$t('printModal.cancel')), 41, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.type === 'printing_success')
      ? (_openBlock(), _createElementBlock("a", {
          key: 3,
          class: "notification-message-show-printing-text",
          href: _ctx.itemDownloadUrl,
          target: "_blank",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('resetDownload'))),
          onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('resetDownload')), ["prevent"]), ["space","enter"]))
        }, _toDisplayString(_ctx.$t('printModal.openPdf')), 41, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.type === 'image_download_error' || _ctx.type === 'picture_download_error')
      ? (_openBlock(), _createElementBlock("a", {
          key: 4,
          class: "notification-message-download-retry-text",
          href: " ",
          onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.$emit('retryDownload', _ctx.type)), ["prevent"])),
          onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('retryDownload', _ctx.type)), ["prevent"]), ["space","enter"])),
          "aria-label": _ctx.$t('header.toaster.download.retry')
        }, _toDisplayString(_ctx.$t('printModal.retry')), 41, _hoisted_5))
      : _createCommentVNode("", true),
    (['download_success', 'others_download_error', 'branch_download_error', 'image_download_error', 'picture_download_error', 'session_check_download_error'].includes(_ctx.type))
      ? (_openBlock(), _createBlock(_component_ButtonIcon, {
          key: 5,
          class: "notification-message-download-reset-button",
          icon: "xmark-circle",
          styled: "is-none",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('resetDownload'))),
          onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('resetDownload')), ["prevent"]), ["space","enter"])),
          "aria-label": _ctx.$t('header.toaster.close')
        }, null, 8, ["aria-label"]))
      : _createCommentVNode("", true)
  ], 2))
}