
import { defineComponent, ref } from 'vue'
import { StyleName, styleNames } from '../../data/def'
import ButtonIcon from './ButtonIcon.vue'

export default defineComponent({
  components: {
    ButtonIcon,
  },
  props: {
    targetText: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: 'copy',
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    iconSize: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  setup (props) {
    const isServer = typeof window === 'undefined'
    const copied = ref(false)

    const copy = () => {
      const isIOS = (() => !isServer && (
        window.navigator.userAgent.indexOf('iPhone') > -1 ||
        window.navigator.userAgent.indexOf('iPad') > -1
      ))()
      if (isIOS) {
        const textarea = document.createElement('textarea')
        textarea.style.position = 'absolute'
        textarea.style.opacity = '0'
        textarea.style.pointerEvents = 'none'
        textarea.value = props.targetText
        document.body.appendChild(textarea)
        textarea.focus()
        textarea.setSelectionRange(0, 999999)
        document.execCommand('copy')
        if (textarea.parentNode) {
          textarea.parentNode.removeChild(textarea)
        }
      } else {
        navigator.clipboard.writeText(props.targetText)
      }
      copied.value = true
      setTimeout(() => {
        copied.value = false
      }, 1500)
    }

    return {
      copy,
      copied,
    }
  },
})
