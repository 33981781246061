import { getData } from '@/helpers/util/webApiUtil'
import { CheckSimViewRequest } from '@/data/@types/CheckSimViewRequest'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

/**
 * ステート
 */
const state = {
  checkSimViewQuery: {},
  canSimView: false,
}

/**
 * ミューテーション
 */

const mutations = {
  CAN_SIMVIEW_QUERY (state: any, query: CheckSimViewRequest) {
    state.checkSimViewQuery = query
    console.log('CAN_SIMVIEW_QUERY', state.checkSimViewQuery)
  },
  CAN_SIMVIEW (state: any, simViewResult: any) {
    state.canSimView = simViewResult
    console.log('CAN_SIMVIEW', state.canSimView)
  },
}

/**
 * ゲッター
 */

const getters = {
  checkSimViewQuery (state: any) {
    return state.checkSimViewQuery
  },
  canSimView (state: any) {
    return state.canSimView
  },
}

/**
 * アクション
 */
const actions = {
  async canSimView (context: any, request: CheckSimViewRequest) {
    const url = `${BASE_URL}/simView/check/?pid=${request.pid}&bid=${request.bid}&cid=${request.cid}`
    try {
      context.commit('CAN_SIMVIEW_QUERY', request)
      const response = await getData(url)
      console.log('call action : canSimView')
      context.commit('CAN_SIMVIEW', response)
    } catch (error: any) {
      // TODO エラーハンドリング
      context.commit('CAN_SIMVIEW', false)
      console.error(error.message)
    }
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
