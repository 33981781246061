
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { MUTATIONS } from '@/store/mutations-types'
import AppButton from '../atoms/AppButton.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TheMyCollectionHeader',
  components: {
    AppButton,
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()
    const fromLocation = computed(() => store.getters.location)
    const isKss = computed(() => fromLocation.value === 'kss')

    const onLogout = async () => {
      await store.dispatch('logout')
      store.commit(MUTATIONS.LOGOUT_NOTIFICATION, i18n)
      router.push('/')
    }

    return {
      onLogout,
      isKss,
    }
  },
})
