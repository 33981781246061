import StringKeyObject from '@/data/@types/StringKeyObject'

export const useRequestBuilder = (): StringKeyObject => {
  return {
    makeDateRangeQuery,
  }
}

// TODO 別ファイル管理

/**
 * 日付クエリから検索リクエストボディを作成する
 * TODO RM#5187 の恒久対応 dss-serverと通したインターフェース設計の見直し。
 * @param route
 * @returns
 */
const makeDateRangeQuery = (route: any, fromKey = 'publicationFrom', toKey = 'publicationTo'): StringKeyObject => {
  const dateFrom = route.query[fromKey] ? route.query[fromKey].toString() : ''
  const dateTo = route.query[toKey] ? route.query[toKey].toString() : ''

  const dateRangeObject: StringKeyObject = {}

  if (dateFrom !== '' && dateFrom.length === 'YYYY-MM-DD'.length) {
    const splitDateFrom = dateFrom.split('-')
    dateRangeObject.fromYear = splitDateFrom[0]
    dateRangeObject.fromMonth = splitDateFrom[1]
    dateRangeObject.fromDay = splitDateFrom[2]
  }

  if (dateTo !== '' && dateTo.length === 'YYYY-MM-DD'.length) {
    const splitDateTo = dateTo.split('-')
    dateRangeObject.toYear = splitDateTo[0]
    dateRangeObject.toMonth = splitDateTo[1]
    dateRangeObject.toDay = splitDateTo[2]
  }

  if (dateRangeObject.fromYear === '0000') {
    dateRangeObject.fromYear = ''
    dateRangeObject.fromMonth = ''
    dateRangeObject.fromDay = ''
  }
  if (dateRangeObject.fromMonth === '00') {
    dateRangeObject.fromMonth = ''
    dateRangeObject.fromDay = ''
  }
  if (dateRangeObject.fromDay === '00') {
    dateRangeObject.fromDay = ''
  }

  if (dateRangeObject.toYear === '0000') {
    dateRangeObject.toYear = ''
    dateRangeObject.toMonth = ''
    dateRangeObject.toDay = ''
  }
  if (dateRangeObject.toMonth === '00') {
    dateRangeObject.toMonth = ''
    dateRangeObject.toDay = ''
  }
  if (dateRangeObject.toDay === '00') {
    dateRangeObject.toDay = ''
  }

  return dateRangeObject
}

export const defaultQuery = {
  pageNum: '0',
  pageSize: '20',
  sortKey: 'SCORE',
  fullText: true,
  includeVolumeNum: true,
}
