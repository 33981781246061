/* eslint-disable no-unused-expressions */
import { createStore } from 'vuex'
import common from '@/store/common'
import createPersistedState from 'vuex-persistedstate'
import metadataMaster from '@/store/modules/master/metadata'
import moduleItem from '@/store/modules/Item'
import moduleCollection from '@/store/modules/Collection'
import moduleMyCollection from '@/store/modules/MyCollection'
import moduleContentData from '@/store/contentData'
import moduleFacet from '@/store/modules/Facet'
import modulePicture from '@/store/modules/pictuire'
import moduleContentViewer from '@/store/contentViewer'
import moduleOgp from '@/store/modules/ogp'
import moduleStaticFileLoad from '@/store/modules/StaticFileLoad'
import searchResultState from '@/store/modules/searchResultState'
import notificationMessage from '@/store/modules/notificationMessage'
import loginState from '@/store/modules/Login'
import dms from '@/store/modules/dms'
import itemDownload from '@/store/modules/itemDownload'
import simView from '@/store/modules/SimView'
import checked from '@/store/modules/Checked'
import privacy from '@/store/modules/privacy'
import { getPid, getContentMeta, getPermissionRule, getCardId, createMetaParameter } from '@/store/modules/StatisticsLog'
import packageStreaming from '@/store/modules/PackageStreaming'
import tracker from '@/helpers/tracking'

const trackPlugin = (store: any) => {
  // called when the store is initialized
  store.subscribe((mutation: any, _: any) => {
    const targetMutationArray = ['ITEM_DOWNLOAD_URL', 'DOWNLOAD_OTHERS_URL', 'DOWNLOAD_OTHER_FILE', 'GET_META_CONTENTS', 'PICTURE_DOWNLOAD_URL']
    if (targetMutationArray.includes(mutation.type)) {
      const pid = getPid(mutation)
      const contentMeta = getContentMeta(mutation)
      const permissionRule = getPermissionRule(contentMeta)
      const cardId = getCardId(store)
      const data = createMetaParameter(pid, contentMeta, permissionRule, mutation, store, cardId)
      // 統計用(長期保存)
      if (!isError(mutation)) {
        tracker.push({ key: 'action', mutation: { ...mutation, payload: {} }, data })
        // インシデント時のトレース用（90日間保存）
        if (cardId != null) {
          tracker.push({ key: 'action', mutation: { ...mutation, payload: {} }, data, cardId })
        }
      }
    }
  })
}

const isError = (mutation: any): boolean => {
  if (mutation.type === 'GET_META_CONTENTS' || mutation.type === 'DOWNLOAD_OTHER_FILE') return false
  return mutation.payload.errorFlg === 1
}

export const store = createStore({
  modules: {
    common,
    metadataMaster,
    itemInfo: moduleItem,
    collectionInfo: moduleCollection,
    myCollectionInfo: moduleMyCollection,
    contentDataInfo: moduleContentData,
    facetInfo: moduleFacet,
    pictureInfo: modulePicture,
    contentViewerInfo: moduleContentViewer,
    searchResultState,
    loginState,
    notificationMessage,
    dms,
    itemDownload,
    simView,
    ogp: moduleOgp,
    staticFileLoad: moduleStaticFileLoad,
    checked,
    packageStreaming,
    privacy,
  },
  plugins: [
    trackPlugin,
    // NOTE: リロードしてもStateの中身を維持するための設定
    // 参照: https://qiita.com/_masa_u/items/b58b92c283f4e770e094
    createPersistedState({
      key: 'vuex',
      // 管理対象のStateを指定
      paths: [
        'loginState',
        'dms',
      ],
      // ストレージの種類を指定する。デフォルトではlocalStorage
      storage: window.sessionStorage,
    }),
  ],
})
