import { DownloadType } from '@/data/@types/DownloadType'
import { ItemDownloadRequest } from '@/data/@types/ItemDownloadRequest'

/**
 * 画像ダウンロードAPIにリクエストを送り、レスポンスのダウンロードURLをダウンロードさせる
 * @param store ストア情報
 * @param request 画像ダウンロードに渡すリクエスト
 * @param title トーストに表示するタイトル名
 */

export const downloadContents = async (store: any, request: ItemDownloadRequest, title: string, i18n: any, type: DownloadType) => {
  // トーストに表示するアイテムのタイトル名を設定
  await store.commit('SET_REQUEST_ITEM_TITLE', title)
  // 画像ダウンロードか挿絵ダウンロードか判定
  const action = type === DownloadType.IMAGE ? 'getItemDownloadUrl' : 'getPictureDownloadUrl'
  // 画像ダウンロードAPIからダウンロードURLを取得
  await store.dispatch(action, {
    request,
    errorMessagePrefix: i18n.t('header.toaster.download_error_prefix'),
    errorMessageSuffix: i18n.t('header.toaster.download_error_suffix'),
  })
  const downloadUrl = store.getters.itemDownloadUrl
  const processingStatus = store.getters.processingStatus
  if (processingStatus !== 'download_success') return
  if (downloadUrl && Object.keys(downloadUrl).length) {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = downloadUrl
    a.click()
    a.remove()
  }
}
