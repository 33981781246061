import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b72d7cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-pressed"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["app-toggle-switch", {
      'toggle-on': _ctx.modelValue,
      'toggle-off': !_ctx.modelValue,
      'is-large': _ctx.isLarge
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)), ["prevent"]), ["space","enter"])),
    "aria-pressed": _ctx.modelValue
  }, [
    _createElementVNode("span", {
      class: _normalizeClass({'is-large': _ctx.isLarge})
    }, null, 2)
  ], 42, _hoisted_1))
}