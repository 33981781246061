import StringKeyObject from '@/data/@types/StringKeyObject'
import convertToArray from '@/helpers/util/convertToArray'

const createCollectionIdArray = (id: string, collectionMaster: StringKeyObject, routeQuery: StringKeyObject): string[] => {
  let collections = routeQuery.subCollection
  // 古典籍資料、憲政資料、日本占領関係資料の場合のみ、クエリが異なるため分岐
  if (id === 'A00003') {
    return collections ? convertToArray(collections) : (isFirstScreenTransition(routeQuery) ? ['A00004', 'A00005', 'A00006', 'A00007', 'A00008'] : [])
  } else if (id === 'A00017') {
    return collections ? convertToArray(collections) : (isFirstScreenTransition(routeQuery) ? ['A00051', 'A00052', 'A00053', 'A00054', 'A00055'] : [])
  } else if (id === 'A00016') {
    return collections ? convertToArray(collections) : (isFirstScreenTransition(routeQuery) ? ['A00105', 'A00020', 'A00161', 'A00018', 'A00177', 'A00076'] : [])
  } else {
    const collectionArray = []
    collections = collections ? collections.toString() : ''
    for (const id in collectionMaster) {
      const collectionName = collectionMaster[id].content.name
      if (collectionName.ja === collections || collectionName.en === collections || id === collections) {
        collectionArray.push(id)
      }
    }
    return collectionArray.length > 0 ? collectionArray : (collections.length > 0 ? [collections] : [])
  }
}

const convertSingleArrayToString = (query: StringKeyObject): StringKeyObject => {
  for (const param in query) {
    if (Array.isArray(query[param])) {
      if (query[param].length === 0) {
        // 配列の中身が空の場合、パラメータを削除する
        delete query[param]
      } else if (query[param].length === 1) {
        // 配列の中身が1つの場合、配列ではなくString型に変換する
        query[param] = query[param].toString()
      }
    }
  }
  return query
}

const resetFacet = (query: StringKeyObject): StringKeyObject => {
  for (const param in query) {
    if (param.includes('facet')) {
      delete query[param]
    }
  }
  return query
}

const isIncludeFasetQuery = (query: StringKeyObject): boolean => {
  for (const param in query) {
    if (param.includes('facet')) {
      return true
    }
  }
  return false
}

const isEmpty = (obj: StringKeyObject): boolean => {
  return Object.keys(obj).length === 0
}

const isFirstScreenTransition = (routeQuery: StringKeyObject): boolean => {
  return JSON.stringify(routeQuery) === JSON.stringify({ pageNum: '0' }) || isEmpty(routeQuery)
}

export {
  createCollectionIdArray,
  convertSingleArrayToString,
  resetFacet,
  isIncludeFasetQuery,
  isEmpty,
  isFirstScreenTransition,
}
