import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-061eb152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-panel has-background-contents" }
const _hoisted_2 = { class: "side-panel-nav" }
const _hoisted_3 = { class: "side-panel-body has-scrollbar" }
const _hoisted_4 = { class: "side-panel-index-body has-scrollbar" }
const _hoisted_5 = {
  key: 0,
  class: "side-panel-body has-scrollbar"
}
const _hoisted_6 = { class: "side-panel-body has-scrollbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SegmentedController = _resolveComponent("SegmentedController")!
  const _component_AddonVIewContentsList = _resolveComponent("AddonVIewContentsList")!
  const _component_AsyncAddonViewIndex = _resolveComponent("AsyncAddonViewIndex")!
  const _component_AsyncAddonViewProcessing = _resolveComponent("AsyncAddonViewProcessing")!
  const _component_AsyncAddonViewFullText = _resolveComponent("AsyncAddonViewFullText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SegmentedController, {
        ref: "segmentedController",
        segments: _ctx.segments,
        modelValue: _ctx.currentId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentId) = $event))
      }, null, 8, ["segments", "modelValue"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      (_ctx.tabViewFlag)
        ? (_openBlock(), _createBlock(_component_AddonVIewContentsList, {
            key: 0,
            item: _ctx.item,
            id: "tabpanel-contents",
            role: "tabpanel"
          }, null, 8, ["item"]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.currentId === 'contents']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AsyncAddonViewIndex, {
        item: _ctx.item,
        viewerType: _ctx.viewerType,
        id: "tabpanel-index",
        role: "tabpanel"
      }, null, 8, ["item", "viewerType"])
    ], 512), [
      [_vShow, _ctx.currentId === 'index']
    ]),
    (_ctx.props.viewerType === 'TheImageViewer')
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_AsyncAddonViewProcessing, {
            item: _ctx.item,
            id: "tabpanel-processing",
            role: "tabpanel",
            onOnClickAutoAdjust: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClickAutoAdjust')))
          }, null, 8, ["item"])
        ], 512)), [
          [_vShow, _ctx.currentId === 'processing']
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      (_ctx.tabViewFlag)
        ? (_openBlock(), _createBlock(_component_AsyncAddonViewFullText, {
            key: 0,
            item: _ctx.item,
            id: "tabpanel-fulltext",
            role: "tabpanel"
          }, null, 8, ["item"]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.currentId === 'fulltext']
    ])
  ]))
}