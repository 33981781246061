import { computed } from 'vue'
import { store } from '@/store'

const location = computed(() => store.getters.location)
const isPersendOrLibsend = computed(() => store.getters.isPersendOrLibsend)
const isLoggedIn = computed(() => store.getters.isLoggedIn)

export const ruleToType = (rule: string) => {
  if (rule.startsWith('internet')) {
    return 'internet'
  } else if (rule.startsWith('ooc')) {
    return 'ooc'
  } else if (rule.startsWith('ndl')) {
    return 'inlibrary'
  } else if (rule.startsWith('rekion')) {
    return 'rekion'
  } else if (rule.startsWith('shinsai')) {
    return rule
  } else {
    return 'forbidden'
  }
}
export const getPermission = (permissionType: string) => {
  if (permissionType === 'internet') {
    return true
  } else if (permissionType === 'ooc' && (isPersendOrLibsend.value || (location.value === 'ndl' || location.value === 'kss'))) {
    return true
  } else if (permissionType === 'inlibrary' && (location.value === 'ndl' || location.value === 'kss')) {
    return true
  } else if (permissionType === 'rekion' && (location.value === 'rekion' || location.value === 'ndl' || location.value === 'kss')) {
    return true
  } else if (permissionType === 'shinsai_internet') {
    return true
  } else if (permissionType === 'shinsai_ndl_only' && (location.value === 'ndl' || location.value === 'kss')) {
    return true
  } else {
    return false
  }
}
export const getPermissionColor = (permissionRule: string, isRekion: any) => {
  if (isRekion) {
    if (location.value === 'ndl' || location.value === 'kss') {
      // NDL館内・KSS
      if (permissionRule === 'internet') {
        // 資料がインターネット公開
        return permissionRule
      } else if (permissionRule === 'rekion') {
        // れきおん限定公開
        return permissionRule
      } else {
        return 'forbidden'
      }
    } else if (location.value === 'rekion') {
      // れきおん館内
      if (permissionRule === 'internet') {
        // 資料がインターネット公開
        return permissionRule
      } else if (permissionRule === 'rekion' && isLoggedIn.value) {
        // れきおん限定公開
        return permissionRule
      } else {
        return 'forbidden'
      }
    } else if (location.value === 'unknown') {
      // 個人PC
      if (permissionRule === 'internet') {
        // 資料がインターネット公開
        return permissionRule
      } else {
        return 'forbidden'
      }
    }
  } else {
    // デジデポ画面
    if (location.value === 'ndl' || location.value === 'kss') {
      // 館内PC
      return permissionRule
    } else {
      // 個人PC、れきおんPC、その他PC
      if (isLoggedIn.value) {
        // ログイン済み
        if (permissionRule === 'internet') {
          // 資料がインターネット公開
          return permissionRule
        } else if (permissionRule === 'ooc') {
          // 資料が個人送信で閲覧可能
          if (isPersendOrLibsend.value) {
            // 個人送信手続き済みのアカウント
            return permissionRule
          } else {
            // 非個人送信対象アカウント、個人送信手続き途中アカウント
            return 'forbidden'
          }
        } else if (permissionRule === 'inlibrary') {
          // 資料が図書館内限定
          return 'forbidden'
        } else if (permissionRule === 'rekion') {
          // 資料がれきおん限定
          if (location.value === 'rekion') {
            // れきおんPC
            return permissionRule
          } else {
            return 'forbidden'
          }
        }
      } else {
        // 未ログイン
        if (permissionRule === 'internet') {
          // 資料がインターネット公開
          return permissionRule
        } else {
          return 'forbidden'
        }
      }
    }
  }
  return 'forbidden'
}
export const getPermissionText = (type: string) => {
  if (type === 'internet') {
    return ''
  } else if (type === 'ooc') {
    return '個人送信限定'
  } else if (type === 'inlibrary') {
    return '館内限定'
  } else if (type === 'rekion') {
    return 'れきおん参加館限定'
  } else {
    return ''
  }
}
