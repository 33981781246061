import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38958b70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "has-background-footer the-kn-footer" }
const _hoisted_2 = { class: "the-kn-footer-inner" }
const _hoisted_3 = { class: "level level-left the-kn-footer-navi" }
const _hoisted_4 = {
  class: "has-text-contents-normal national",
  href: "https://www.ndl.go.jp/"
}
const _hoisted_5 = {
  class: "has-text-contents-normal ministryOfInternal",
  href: "http://www.soumu.go.jp/"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "level level-center the-kn-footer-copy copy-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('knFooter.national')), 1)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('knFooter.ministryOfInternal')), 1)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            class: "has-text-contents-normal ndlSearch",
            href: _ctx.$t('serviceList.ndlSearch.link')
          }, _toDisplayString(_ctx.$t('knFooter.ndlSearch')), 9, _hoisted_6)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            class: "has-text-contents-normal about",
            href: `${_ctx.KN_APPLICATION_LINK}/static/about?language=${_ctx.lang}`
          }, _toDisplayString(_ctx.$t('knFooter.about')), 9, _hoisted_7)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            class: "has-text-contents-normal contact",
            href: `${_ctx.KN_APPLICATION_LINK}/static/contact?language=${_ctx.lang}`
          }, _toDisplayString(_ctx.$t('knFooter.contact')), 9, _hoisted_8)
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_9, " Copyright © 2013- National Diet Library. All Rights Reserved. " + _toDisplayString(_ctx.$t('knFooter.contactInfo')), 1)
    ])
  ]))
}