/**
 * ステート
 */
const state = {
  isNarrowed: false,
  facetKeyList: [
    'colorType',
    'itemToSearch',
    'accessRestrictions',
    'digitizedContents',
    'onlinePublications',
    'ndcDivisions',
    'rekionGenre',
    'collectionBasis',
    'bibliographicLevel',
    'officialBulletinType',
    'articleType',
    'institutionLabel',
    'subjectLabel',
    'languageLabel',
    'classicLabel',
    'ghqLabel',
  ],
  facetNameQueryMap: {
    accessRestrictions: 'permission_facet',
    digitizedContents: 'collection_facet',
    onlinePublications: 'collection_facet',
    ndcDivisions: 'ndc_facet',
    rekionGenre: 'rekion_facet',
    collectionBasis: 'basis_facet',
    bibliographicLevel: 'levelCode_facet',
    officialBulletinType: 'official_facet',
    articleType: 'article_facet',
    institutionLabel: 'institution_facet',
    subjectLabel: 'subject_facet',
    languageLabel: 'language_facet',
    classicLabel: 'classic_facet',
    ghqLabel: 'ghq_facet',
    itemToSearch: 'itemToSearch_facet',
    colorType: 'color_facet',
  },
  collectionFacetList: {
    // 図書
    A00001: [ // 共通項目としても扱う
      'accessRestrictions',
      'digitizedContents',
      'onlinePublications',
      'ndcDivisions',
      'bibliographicLevel',
      'itemToSearch',
      'colorType',
    ],
    // 雑誌
    A00002: [
      'accessRestrictions',
      'digitizedContents',
      'ndcDivisions',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 古典籍
    A00003: [
      'accessRestrictions',
      'digitizedContents',
      'ndcDivisions',
      'bibliographicLevel',
      'classicLabel',
      'itemToSearch',
    ],
    // 博士論文
    A00014: [
      'accessRestrictions',
      'digitizedContents',
      'ndcDivisions',
      'collectionBasis',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 官報
    A00015: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'officialBulletinType',
      'articleType',
      'institutionLabel',
      'itemToSearch',
    ],
    // 日本占領関係資料
    A00016: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'ghqLabel',
      'itemToSearch',
    ],
    // 憲政資料
    A00017: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // プランゲ文庫
    A00019: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 歴史的音源
    A00024: [
      'accessRestrictions',
      'digitizedContents',
      'rekionGenre',
      'collectionBasis',
      'itemToSearch',
    ],
    // 録音・映像関係資料
    A00121: [
      'accessRestrictions',
      'digitizedContents',
      'ndcDivisions',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 他機関デジタル化資料
    A00122: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 内務省検閲発禁図書
    A00148: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 特殊デジタルコレクション
    A00150: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 地図
    A00152: [
      'accessRestrictions',
      'digitizedContents',
      'ndcDivisions',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // パッケージ系電子出版物
    A00162: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 新聞
    A00022: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 日系移民関係資料
    A00173: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
    // 電子書籍・電子雑誌
    B00000: [
      'accessRestrictions',
      'onlinePublications',
      'collectionBasis',
      'bibliographicLevel',
      'subjectLabel',
      'languageLabel',
      'itemToSearch',
    ],
    // その他
    others: [
      'accessRestrictions',
      'digitizedContents',
      'bibliographicLevel',
      'itemToSearch',
    ],
  },
}

/**
 * ゲッター
 */

const getters = {
  FacetKeyList (state: any) {
    return state.facetKeyList
  },
  FacetTitleMap (state: any) {
    return state.facetTitleMap
  },
  FacetNameQueryMap (state: any) {
    return state.facetNameQueryMap
  },
  CollectionFacetList (state: any) {
    return state.collectionFacetList
  },
  BibliographicLevelLabel (state: any) {
    return state.bibliographicLevelLabel
  },
  AccessRestrictionLabel (state: any) {
    return state.accessRestrictionLabel
  },
  RekionAccessRestrictionLabel (state: any) {
    return state.rekionAccessRestrictionLabel
  },
  ItemToSearchLabel (state: any) {
    return state.itemToSearchLabel
  },
}

/**
 * ミューテーション
 */

const mutations = {
}

/**
 * アクション
 */

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions,
}
