/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Item } from '@/data/@types/Item'
import { SearchHits } from '@/data/@types/SearchHits'
import { getData, cacheGet } from '@/helpers/util/webApiUtil'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import { ProcessingData } from '@/data/@types/ProcessingData'
import { RectMM } from '@/helpers/imageviewer/ImageViewerCommon'
import { DirectoryIndex } from '@/data/@types/DirectoryIndex'
import { ActionContext } from 'vuex'
import { ViewMode } from '@/helpers/imageviewer/ImageViewer'
import addonViewFulltext from './modules/addonViewFulltext'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const isKn = process.env.VUE_APP_IS_KN === 'TRUE'

interface CurrentBundleIndexObject {
  currentBundleIndex: number
}
interface CurrentContentIndexObject {
  currentContentIndex: number
}

const initialSizeProcessingData = {
  degree: 0,
  croppingSizeTextValue: '',
  selectionRange: {
    left: undefined,
    top: undefined,
    width: 0,
    height: 0,
  },
  croppedRange: null,
  autoClipFlag: false,
  divisionHorizontal: 1,
  divisionVertical: 1,
  addCoverPageFlag: true,
}

const initialColorProcessingData = {
  brightness: 0,
  contrast: 0,
  sharpness: 0,
  gamma: 1,
  grayscaleFlag: false,
}

const getDefaultState = () => {
  return {
    contentMetaData: {},
    contentIndexData: {},
    rootData: {},
    itemViewer: {
      currentBundleIndex: 0,
      currentContentIndex: 0,
      currentScale: 0,
      maxScale: 0,
      minScale: 0,
      bindingDirection: 'rtl',
      croppingModeFlag: false,
      currentFileViewerDirectory: 'root',
      changeFileViewerDirectory: '',
      imageviewerViewMode: ViewMode.KOMA,
    },
    BranchChildrenSearchHits: {
      searchHits: [],
    },
    hasContentSearchError: false,
    imageViewerProcessingData: {
      degree: 0,
      brightness: 0,
      contrast: 0,
      sharpness: 0,
      gamma: 1,
      croppingSizeTextValue: '',
      selectionRange: {
        left: undefined,
        top: undefined,
        width: 0,
        height: 0,
      },
      croppedRange: {},
      autoClipFlag: false,
      divisionHorizontal: 1,
      divisionVertical: 1,
      addCoverPageFlag: true,
    },
    // 詳細画面で表示する目次データを格納
    indexData: {},
    videoViewerInformation: {
      playingBundleIndex: 0,
      playingContentIndex: 0,
      playingFlag: false,
    },
    tocProcessing: false,
    relatedItemList: [],
  }
}

interface State {
  contentMetaData: any;
  contentIndexData: any;
  rootData: any;
  itemViewer: any;
  BranchChildrenSearchHits: any;
  hasContentSearchError: any;
  imageViewerProcessingData: any;
  indexData: any;
  videoViewerInformation: any;
  tocProcessing: any;
  relatedItemList: Array<any>;
}

type Context = ActionContext<State, any>

export default {
  state: getDefaultState(),
  mutations: {
    setContentMetaData (state: State, metaData: Item): void {
      state.contentMetaData = metaData
    },
    setInitialContentBundle (state: State): void {
      if (!state.contentMetaData.contentsBundles) return
      if (!state.contentMetaData.initialContentBundle) return
      const initialContentBundle = state.contentMetaData.contentsBundles
        .findIndex((v: ContentsBundle) => v.id === state.contentMetaData.initialContentBundle)
      if (initialContentBundle === -1) {
        state.itemViewer.currentBundleIndex = 0
        return
      }
      state.itemViewer.currentBundleIndex = initialContentBundle
    },
    updateCurrentBundleIndex (state: State, currentBundleIndex: CurrentBundleIndexObject): void {
      state.itemViewer = {
        ...state.itemViewer,
        ...currentBundleIndex,
      }
    },
    updateCurrentContentIndex (state: State, currentContentIndex: CurrentContentIndexObject): void {
      state.itemViewer = {
        ...state.itemViewer,
        ...currentContentIndex,
      }
    },
    setPlayingBundleIndex (state: State, playingBundleIndex: number): void {
      state.videoViewerInformation.playingBundleIndex = playingBundleIndex
    },
    setPlayingContentIndex (state: State, playingContentIndex: number): void {
      state.videoViewerInformation.playingContentIndex = playingContentIndex
    },
    updatePlayingFlag (state: State, playingFlag: boolean): void {
      state.videoViewerInformation.playingFlag = playingFlag
    },
    /**
     * その他ビューアで表示する階層構造のデータを作成
     */
    setDirectoryIndex (state: State, contentsBundle: ContentsBundle): void {
      // コンテンツ取得に失敗したコンテンツはpublicPathを持たないので、画面に表示しない
      const contents = contentsBundle.contents?.filter(content => content.publicPath)
      if (contentsBundle.directoryIndex && contentsBundle.directoryIndexTree) return
      // ファイルビューアで使用
      contentsBundle.directoryIndex = []
      const directoryIndex = contentsBundle.directoryIndex
      // コンテンツタブで使用
      let directoryIndexTree = contentsBundle.directoryIndexTree
      const toIndexData = (id: string, title: string, children: any, type: string) => {
        const indexData = {
          id,
          title,
          children,
          type,
        }
        return indexData
      }
      // directoryIndexTreeへの追加
      const addDirectoryIndexTree = (tree: DirectoryIndex, content: DirectoryIndex, parentId: string) => {
        tree.children.forEach(child => {
          if (child.id === parentId) {
            child.children.push(content)
          } else if (child.children) {
            addDirectoryIndexTree(child, content, parentId)
          }
        })
      }
      // 初期画面に表示する'root'の作成(childrenにファイル名orディレクトリが投入される)
      directoryIndex[0] = toIndexData('root', contentsBundle.name?.ja, [], 'directory')
      directoryIndexTree = toIndexData('root', contentsBundle.name?.ja, [], 'directory')
      contents && contents.forEach((content: any) => {
        // コンテンツがディレクトリ構造（parentPath='/'でないもの）を持っているとき
        if (content.parentPath !== '/' && content.parentPath) {
          // parentPath('/hoge/hoge/')をsplitしたときの先頭と末尾の空配列を取り除くため
          const splitPath = content.parentPath.split('/').slice(1, -1)
          const hasRootChildData = directoryIndex.some(v => v.id === ('d0' + splitPath[0]))
          // 最上位ディレクトリ名のdirectoryIndexが作成されているか
          if (!hasRootChildData) {
            directoryIndex[0].children.push(toIndexData(('d0' + splitPath[0]), splitPath[0], [], 'directory'))
            directoryIndexTree.children.push(toIndexData(('d0' + splitPath[0]), splitPath[0], [], 'directory'))
          }
          // 2階層目以降のディレクトリ名をチェック
          for (let depth = 1; depth < splitPath.length; depth++) {
            const selfId = 'd' + depth + splitPath[depth]
            const parentId = 'd' + (depth - 1) + splitPath[depth - 1]
            const parentDirectoryIndex = directoryIndex.findIndex(v => v.id === parentId)
            // 親のディレクトリ名のdirectoryIndexが作成されているか
            if (parentDirectoryIndex !== -1) {
              const hasChildData = directoryIndex.some(v => {
                return v.children.some((y: any) => y.id === selfId)
              })
              // 親のディレクトリ名のdirectoryIndex.childrenに投入されているか
              if (!hasChildData) {
                directoryIndex[parentDirectoryIndex].children.push(
                  toIndexData(selfId, splitPath[depth], [], 'directory')
                )
                addDirectoryIndexTree(directoryIndexTree, toIndexData(selfId, splitPath[depth], [], 'directory'), parentId)
              }
            } else {
              // 親のディレクトリが作成されていない場合
              directoryIndex.push(toIndexData(parentId, splitPath[(depth - 1)], [], 'directory'))
              const rootDataIndex = directoryIndex.findIndex(v => v.id === parentId)
              directoryIndex[rootDataIndex].children.push(
                toIndexData(selfId, splitPath[depth], [], 'directory')
              )
              addDirectoryIndexTree(directoryIndexTree, toIndexData(selfId, splitPath[depth], [], 'directory'), parentId)
            }
          }
          // leafアイテムのコンテンツデータを投入
          const lastSplitPathId = ('d' + (splitPath.length - 1) + splitPath[(splitPath.length - 1)])
          const leafDirectoryIndex = directoryIndex.findIndex(v => v.id === lastSplitPathId)
          if (leafDirectoryIndex === -1) {
            directoryIndex.push(
              toIndexData(lastSplitPathId, splitPath[(splitPath.length - 1)], [content], 'leaf')
            )
          } else {
            directoryIndex[leafDirectoryIndex].children.push(content)
          }
          addDirectoryIndexTree(directoryIndexTree, content, lastSplitPathId)
        } else {
          directoryIndex[0].children.push(content)
          directoryIndexTree.children.push(content)
        }
      })
      contentsBundle.directoryIndexTree = directoryIndexTree
    },
    updateFileViewerDirectory (state: State, directoryId: string): void {
      state.itemViewer.currentFileViewerDirectory = directoryId
    },
    changeFileViewerDirectory (state: State, directoryId: string): void {
      state.itemViewer.changeFileViewerDirectory = directoryId
    },
    setImageViewerProcessingData (state: State, processingData: ProcessingData): void {
      state.imageViewerProcessingData = { ...processingData }
    },
    resetImageViewerSizeProcessingData (state: State): void {
      state.imageViewerProcessingData = {
        ...state.imageViewerProcessingData,
        ...initialSizeProcessingData,
        selectionRange: { ...initialSizeProcessingData.selectionRange },
      }
    },
    resetImageViewerColorProcessingData (state: State): void {
      state.imageViewerProcessingData = {
        ...state.imageViewerProcessingData,
        ...initialColorProcessingData,
      }
    },
    updateCurrentDegree (state: State, val: number): void {
      state.imageViewerProcessingData.degree = val
    },
    updateCurrentBrightness (state: State, val: number | string): void {
      state.imageViewerProcessingData.brightness = Number(val)
    },
    updateCurrentContrast (state: State, val: number | string): void {
      state.imageViewerProcessingData.contrast = Number(val)
    },
    updateCurrentSharpness (state: State, val: number | string): void {
      state.imageViewerProcessingData.sharpness = Number(val)
    },
    updateCurrentGamma (state: State, val: number | string): void {
      state.imageViewerProcessingData.gamma = Number(val)
    },
    updateGrayscaleFlag (state: State, flag: boolean): void {
      state.imageViewerProcessingData.grayscaleFlag = flag
    },
    updateAutoClipFlag (state: State, flag: boolean): void {
      state.imageViewerProcessingData.autoClipFlag = flag
    },
    updateAddCoverPageFlag (state: State, flag: boolean): void {
      state.imageViewerProcessingData.addCoverPageFlag = flag
    },
    updateCurrentScale (state: State, val: number): void {
      state.itemViewer.currentScale = val
    },
    updateMaxScale (state: State, val: number): void {
      state.itemViewer.maxScale = val
    },
    updateMinScale (state: State, val: number): void {
      state.itemViewer.minScale = val
    },
    updateBindingDirection (state: State, val: string): void {
      state.itemViewer.bindingDirection = val
    },
    updateCroppingModeFlag (state: State, flag: boolean): void {
      state.itemViewer.croppingModeFlag = flag
    },
    updateDivisionHorizontal (state: State, val: number): void {
      state.imageViewerProcessingData.divisionHorizontal = val
    },
    updateDivisionVertical (state: State, val: number): void {
      state.imageViewerProcessingData.divisionVertical = val
    },
    updateCroppingSizeTextValue (state: State, text: string): void {
      state.imageViewerProcessingData.croppingSizeTextValue = text
    },
    updateSelectionRange (state: State, range: RectMM): void {
      // 切り抜き範囲にフリーサイズを指定した場合
      // height: 33.940... のようになったとき、画面には「33」が表示されるため四捨五入する
      state.imageViewerProcessingData.selectionRange.left = range.left === undefined ? undefined : Math.round(range.left)
      state.imageViewerProcessingData.selectionRange.top = range.top === undefined ? undefined : Math.round(range.top)
      state.imageViewerProcessingData.selectionRange.width = range.width >= 0 ? Math.round(range.width) : Math.round(range.right)
      state.imageViewerProcessingData.selectionRange.height = range.height >= 0 ? Math.round(range.height) : Math.round(range.bottom)
    },
    setCroppedRange (state: State, { range, content }: any): void {
      if (!range) {
        state.imageViewerProcessingData.croppedRange = null
      } else {
        // 切り抜き範囲の値（mm単位）を割合（選択範囲の値 / 全体幅or高さ * 100）に変更
        // TODO metaからdpiの値を取得
        const dpi = 400
        // 全体幅・高さのpixelをmmに変換(1インチ =)
        const overallWidth = (content.meta['0714Dod'] / dpi) * 25.4
        const overallHeight = (content.meta['0715Dod'] / dpi) * 25.4
        state.imageViewerProcessingData.croppedRange = {
          // 小数第一位で切り上げ
          left: Math.round(range.left / overallWidth * 100 * 10) / 10,
          top: Math.round(range.top / overallHeight * 100 * 10) / 10,
          width: Math.round(range.width / overallWidth * 100 * 10) / 10,
          height: Math.round(range.height / overallHeight * 100 * 10) / 10,
        }
      }
    },
    BRANCH_CHILDREN_SEARCH (state: State, payload: SearchHits<Item>): void {
      state.BranchChildrenSearchHits = payload
    },
    hasContentSearchError (state: State, hasError: boolean): void {
      state.hasContentSearchError = hasError
    },
    clearContentData (state: State): void {
      Object.assign(state, getDefaultState())
    },
    /**
     * @param state
     * @param indexResponse: APIレスポンスの取得目次データ
     */
    setConvertedIndexData (state: State, indexResponse: any): void {
      state.indexData = indexResponse
    },
    setTocProcessing (state: State, tocProcessing: boolean): void {
      state.tocProcessing = tocProcessing
    },
    setRelatedItems (state: State, items: Array<any>): void {
      state.relatedItemList = items
    },
    changeViewMode (state: State, mode: number): void {
      state.itemViewer.imageviewerViewMode = mode
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    GET_META_CONTENTS (state: State, { contentMeta, isRekion }: any): void {
      // 統計ログを出力するため
    },
  },
  actions: {
    async fetchContentData (context: Context, routeParams: {pid: string}): Promise<void> {
      const url = isKn ? `${BASE_URL}/item/kn/search/info:ndljp/pid/${routeParams.pid}` : `${BASE_URL}/item/search/info:ndljp/pid/${routeParams.pid}`
      try {
        const response = await getData(url)
        context.commit('setContentMetaData', {
          ...response.item,
        })
        //  関連書誌の取得
        if (!isKn) {
          await context.dispatch('fetchRelatedItemsData', response.item)
          context.dispatch('addWarpItemIntoRelatedItemsData')
        }
        context.commit('hasContentSearchError', false)

        // ファイルビューア用構造化処理
        // eslint-disable-next-line no-unused-expressions
        response.item.contentsBundles?.forEach((cb: ContentsBundle) => {
          context.dispatch('setDirectoryIndex', cb)
        })
      } catch (error: any) {
        // TODO エラーハンドリング
        console.error('error: ', error.message)
        context.commit('hasContentSearchError', true)
      }
    },
    async setInitialContentBundle (context: Context): Promise<void> {
      // 初期表示バンドルの設定
      context.commit('setInitialContentBundle')
    },
    async setDirectoryIndex (context: Context, contents: any): Promise<void> {
      context.commit('setDirectoryIndex', contents)
    },
    async branchItemChildrenSearch (context: Context, request: any): Promise<void> {
      // 必要であれば用意する
      // context.commit('PROCESSING')
      const url = isKn ? `${BASE_URL}/item/kn/branch?pid=${request.pid}&pageNum=${request.pageNum}&pageSize=${request.pageSize}` : `${BASE_URL}/item/branch?pid=${request.pid}&pageNum=${request.pageNum}&pageSize=${request.pageSize}`
      // TODO エラーハンドリング
      try {
        const response = await getData(url)
        context.commit('BRANCH_CHILDREN_SEARCH', response)
      } catch (error: any) {
        // context.commit('ERROR', error.message)
        console.error(error)
      } finally {
        // context.commit('PROCESSED')
      }
    },
    async setCurrentScaleValue (context: Context, val: number): Promise<void> {
      context.commit('updateCurrentScale', val)
    },
    async setMaxScaleValue (context: Context, val: number): Promise<void> {
      context.commit('updateMaxScale', val)
    },
    async setMinScaleValue (context: Context, val: number): Promise<void> {
      context.commit('updateMinScale', val)
    },
    // 目次APIをよぶaction
    async indexSearch (context: Context, pid: string): Promise<void> {
      const url = isKn ? `${BASE_URL}/meta/kn/search/toc/facet/${pid}` : `${BASE_URL}/meta/search/toc/facet/${pid}`
      context.commit('setTocProcessing', true)
      try {
        const response = await cacheGet(url)
        context.commit('setConvertedIndexData', response)
      } catch (error: any) {
        console.error(error)
      } finally {
        context.commit('setTocProcessing', false)
      }
    },
    async fetchRelatedItemsData (context: Context, item: any): Promise<void> {
      const url = `${BASE_URL}/item/related/${item.pid}`
      // TODO const meta = item.meta['0237Dk'] || false
      try {
        const response = await cacheGet(url)
        context.commit('setRelatedItems', response || [])
      } catch (error: any) {
        console.error(error)
      } finally {
        context.commit('setTocProcessing', false)
      }
    },
    addWarpItemIntoRelatedItemsData (context: Context): void {
      const hasWarpUri = context.getters.item.meta?.['0181Dk']?.[0]?.startsWith('https://warp.da.ndl.go.jp')
      const warpItemList = hasWarpUri ? [{
        warpUri: context.getters.item.meta['0181Dk'][0],
      }] : []
      const warpRelatedItems = {
        id: '085',
        list: warpItemList,
      }
      const relatedItemList = context.getters.relatedItemList
      const insertIndex = relatedItemList?.findIndex((e: { id: string }) => e.id > warpRelatedItems.id)
      const duplicatedIndex = relatedItemList?.findIndex((e: { id: string }) => e.id === warpRelatedItems.id)
      duplicatedIndex === -1 && insertIndex >= 0 && relatedItemList.splice(insertIndex, 0, warpRelatedItems)
      context.commit('setRelatedItems', relatedItemList)
    },
    autoAdjust (context: Context, delta: {contrast: number, gamma: number}): void {
      const fixNumber = (value: number, max: number, min: number, unit = 1): number => Number((Math.round(
        Math.max(Math.min(value, max), min) / unit
      ) * unit).toFixed(2)) // 入力単位で丸める(微小な誤差が出る場合があるためtoFixする)

      context.commit('updateCurrentBrightness', initialColorProcessingData.brightness)
      context.commit('updateCurrentContrast', fixNumber(initialColorProcessingData.contrast + delta.contrast, 100, -50))
      context.commit('updateCurrentSharpness', initialColorProcessingData.sharpness)
      context.commit('updateCurrentGamma', fixNumber(initialColorProcessingData.gamma * delta.gamma, 5, 0.05, 0.05))
      context.commit('updateGrayscaleFlag', initialColorProcessingData.grayscaleFlag)
    },
  },
  getters: {
    item: (state: State) => {
      if (!state.contentMetaData) return
      return state.contentMetaData
    },
    currentContentsBundle: (state: State, getters: any) => {
      if (!state.contentMetaData || !state.contentMetaData.contentsBundles) return
      return state.contentMetaData.contentsBundles?.[getters.bundleNumber]
    },
    contents: (state: State, getters: any) => {
      if (!state.contentMetaData?.contentsBundles?.[getters.bundleNumber]) return []
      return state.contentMetaData.contentsBundles[getters.bundleNumber]?.contents
    },
    currentContent: (state: State, getters: any) => {
      if (!state.contentMetaData || !state.contentMetaData.contentsBundles?.[getters.bundleNumber]) return
      return state.contentMetaData.contentsBundles[getters.bundleNumber]?.contents?.[getters.contentNumber]
    },
    contentType: (state: State, getters: any) => {
      if (!state.contentMetaData?.contentsBundles?.[getters.bundleNumber]) return
      return state.contentMetaData.contentsBundles[getters.bundleNumber].type
    },
    publicPath: (state: State, getters: any): string | undefined => {
      if (!state.contentMetaData?.contentsBundles?.[getters.bundleNumber]?.contents?.[getters.contentNumber]) return
      return state.contentMetaData.contentsBundles[getters.bundleNumber].contents[getters.contentNumber].publicPath
    },
    bundleNumber: (state: State): number | undefined => {
      if (state.itemViewer.currentBundleIndex === null) return
      return state.itemViewer.currentBundleIndex
    },
    contentNumber: (state: State): number | undefined => {
      if (state.itemViewer.currentContentIndex === null) return
      return state.itemViewer.currentContentIndex
    },
    contentId: (state: State, getters: any): string | undefined => {
      if (!state.contentMetaData?.contentsBundles?.[getters.bundleNumber]?.contents?.[getters.contentNumber]) return
      return state.contentMetaData.contentsBundles[getters.bundleNumber].contents[getters.contentNumber].id
    },
    contentTreeIndex: (state: State) => {
      if (!state.contentIndexData) return
      return state.contentIndexData
    },
    rootPid: (state: State) => {
      if (!state.rootData) return
      return state.rootData.pid
    },
    imageViewerProcessingData: (state: State, getters: any) => {
      if (!state.contentMetaData?.contentsBundles?.[getters.bundleNumber]?.contents?.[getters.contentNumber]) return
      return state.contentMetaData.contentsBundles[getters.bundleNumber].contents[getters.contentNumber].imageViewerProcessingData
    },
    BranchChildrenList: (state: State) => {
      return state.BranchChildrenSearchHits.searchHits ? state.BranchChildrenSearchHits.searchHits : []
    },
    BranchChildrenTotalHits: (state: State) => {
      return state.BranchChildrenSearchHits.totalHits ? state.BranchChildrenSearchHits.totalHits : 0
    },
    hasContentSearchError: (state: State) => {
      return state.hasContentSearchError
    },
    currentScale: (state: State) => {
      if (state.itemViewer.currentScale === null) return
      return state.itemViewer.currentScale
    },
    maxScale: (state: State) => {
      if (state.itemViewer.maxScale === null) return
      return state.itemViewer.maxScale
    },
    minScale: (state: State) => {
      if (state.itemViewer.minScale === null) return
      return state.itemViewer.minScale
    },
    bindingDirection: (state: State): string => {
      if (state.itemViewer.bindingDirection === null) return ''
      return state.itemViewer.bindingDirection
    },
    croppingModeFlag: (state: State) => {
      if (state.itemViewer === null) return
      return state.itemViewer.croppingModeFlag
    },
    crippingData: (state: State, getters: any) => {
      if (!state.contentMetaData?.contentsBundles?.[getters.bundleNumber]?.contents?.[getters.contentNumber]?.extra?.layout) return
      return state.contentMetaData.contentsBundles[getters.bundleNumber].contents[getters.contentNumber].extra.layout.main
    },
    processingData: (state: State) => {
      if (state.imageViewerProcessingData === null) return
      return state.imageViewerProcessingData
    },
    // 詳細画面に出す変換後の目次データ呼び出し
    indexResponse: (state: State) => {
      return state.indexData
    },
    videoViewerInformation: (state: State) => {
      if (state.videoViewerInformation === null) return
      return state.videoViewerInformation
    },
    tocProcessing: (state: State) => {
      return state.tocProcessing
    },
    croppedRange: (state: State) => {
      return state.imageViewerProcessingData.croppedRange
    },
    currentFileViewerDirectory: (state: State) => {
      return state.itemViewer.currentFileViewerDirectory
    },
    changeFileViewerDirectory: (state: State) => {
      return state.itemViewer.changeFileViewerDirectory
    },
    relatedItemList: (state: State) => {
      return state.relatedItemList
    },
    imageviewerViewMode: (state: State) => {
      return state.itemViewer.imageviewerViewMode
    },
  },
  modules: {
    addonViewFulltext,
  },
}
