/**
 * ゲッター
 */

const getters = {
  getAccessRestrictionsCheckList (state: any) {
    return JSON.parse(localStorage.getItem('permissionCheckList')!) || ['internet', 'ooc', 'inlibrary']
  },
  getRekionAccessRestrictionsCheckList (state: any) {
    return JSON.parse(localStorage.getItem('rekionPermissionCheckList')!) || ['internet', 'inlibrary']
  },
}

/**
 * ミューテーション
 */

const mutations = {
  SET_ACCESS_RESTRICTIONS (state: any, accessRestrictions: Array<string>) {
    console.log('SET_ACCESS_RESTRICTIONS: ', accessRestrictions)
    localStorage.setItem('permissionCheckList', JSON.stringify(accessRestrictions))
  },
  SET_REKION_ACCESS_RESTRICTIONS (state: any, accessRestrictions: Array<string>) {
    console.log('SET_REKION_ACCESS_RESTRICTIONS: ', accessRestrictions)
    localStorage.setItem('rekionPermissionCheckList', JSON.stringify(accessRestrictions))
  },
}

export default {
  mutations,
  getters,
}
