import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0603e46a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-viewer-headstone-item level-center is-mobile" }
const _hoisted_2 = { class: "item-viewer-headstone-item-body has-background-contents shadow" }
const _hoisted_3 = {
  key: 0,
  class: "title headstone-item-title"
}
const _hoisted_4 = { class: "title headstone-item-title" }
const _hoisted_5 = { class: "message headstone-item-message transfer-pid-link" }
const _hoisted_6 = { class: "message headstone-item-message" }
const _hoisted_7 = { class: "title headstone-item-title" }
const _hoisted_8 = { class: "message headstone-item-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasContentError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('label.documentNotFound')), 1))
        : (_ctx.isHeadstone)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.isTransferedItem)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('label.changedDocument')), 1),
                    _createElementVNode("div", _hoisted_5, [
                      _createTextVNode(_toDisplayString(_ctx.$t('label.newPid')) + ": ", 1),
                      _createVNode(_component_router_link, {
                        to: `/${_ctx.transformedItem}`
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.transformedItem), 1)
                        ]),
                        _: 1
                      }, 8, ["to"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('label.useMaterial')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(`（${_ctx.headstoneMessage}）`), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('label.deletedDocument')), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(`${_ctx.$t('label.delete')}（${_ctx.headstoneMessage}）`), 1)
                  ], 64))
            ], 64))
          : _createCommentVNode("", true)
    ])
  ]))
}