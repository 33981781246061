export const speedAdjustmentList = [
  { value: 0.25, id: 25 },
  { value: 0.5, id: 50 },
  { value: 0.75, id: 75 },
  { value: 1, id: 100 },
  { value: 1.25, id: 125 },
  { value: 1.5, id: 150 },
  { value: 1.75, id: 175 },
  { value: 2, id: 200 },
  { value: 2.5, id: 250 },
  { value: 3, id: 300 },
]

export const continuousModeList = [
  { value: 'continuous-on', id: 'on' },
  { value: 'continuous-off', id: 'off' },
]
