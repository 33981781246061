
import { defineComponent, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import AppInputRange from '@/components/atoms/AppInputRange.vue'
import { useRoute, useRouter } from 'vue-router'
import BookMarkButton from '../../../molecules/BookMarkButton.vue'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'

export default defineComponent({
  components: {
    AppInputRange,
    BookMarkButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const stateModule = store.getters
    const router = useRouter()
    const route = useRoute()
    const currentBundleIndex = stateModule.bundleNumber
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'

    const state = reactive({
      displayedFrames: '1',
      bookmarked: false,
    })

    // 画像ビューアで表示するコマの切替
    const currentFrameIndex = computed({
      get: () => stateModule.contentNumber + 1,
      set: (val) => {
        store.commit('updateCurrentContentIndex', {
          currentContentIndex: val - 1,
        })
        // アイテム内の遷移のためヒストリーに残さずに遷移する
        if (route.query.keyword) {
          router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (currentBundleIndex + 1) + '/' + val + '?keyword=' + route.query.keyword)
        } else {
          router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (currentBundleIndex + 1) + '/' + val)
        }
      },
    })

    const currentBundleContents = computed(() => stateModule.contents)

    const isRtlDirection = computed(() => stateModule.bindingDirection === 'rtl')

    const itemPid = computed(() => props.item.pid.split('/').slice(-1).toString())
    const currentBid = computed(() => stateModule.currentContentsBundle.id)
    const currentCid = computed(() => stateModule.currentContent.id)

    return {
      state,
      stateModule,
      currentBundleContents,
      currentFrameIndex,
      isRtlDirection,
      isKn,
      isRekion,
      itemPid,
      currentBid,
      currentCid,
    }
  },
})
