
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { MUTATIONS } from '@/store/mutations-types'
import AppButton from '../atoms/AppButton.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TheMyCollectionHeader',
  components: {
    AppButton,
  },
  props: {},
  setup () {
    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()

    const onLogout = async () => {
      await store.dispatch('builtinLogout')
      store.commit(MUTATIONS.LOGOUT_NOTIFICATION, i18n)
      router.push('/')
    }

    return {
      onLogout,
    }
  },
})
