import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head'
import { store } from './store'
import i18n from './data/i18n/'
import useConsole from './helpers/hooks/useConsole'

// productionではコンソール出力を無効にする
// eslint-disable-next-line no-global-assign
console = useConsole()

const head = createHead();
/**
 * パフォーマンス改善のため、必要な情報を動的importするように修正
 */
(async () => {
  if (process.env.VUE_APP_IS_REKION === 'TRUE') {
    // npm run rekionで起動した場合
    const rekionRouter = await import('./router/rekion')
    createApp(App).use(store).use(head).use(rekionRouter.default).use(i18n).mount('#app')
  } else if (process.env.VUE_APP_IS_KN === 'TRUE') {
    // npm run knで起動した場合
    const knRouter = await import('./router/kn')
    createApp(App).use(store).use(head).use(knRouter.default).use(i18n).mount('#app')
  } else {
    createApp(App).use(store).use(head).use(router).use(i18n).mount('#app')
  }
})()
