
import { defineComponent, computed, PropType } from 'vue'
import AppRadioButton from '../atoms/AppRadioButton.vue'

interface Item {
  value: string
  label: string
  disabled: boolean
}

export default defineComponent({
  components: {
    AppRadioButton,
  },
  props: {
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    styled: {
      type: String,
      required: false,
    },
    mobileFlag: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const pcPicked = computed({
      get: () => props.modelValue,
      set: (val) => {
        context.emit('update:modelValue', val)
      },
    })
    const mobilePicked = computed({
      get: () => props.modelValue,
      set: (val) => {
        // space や Enter でのみ選択とする
      },
    })
    const selected = (val: string) => {
      context.emit('update:modelValue', val)
    }
    return {
      pcPicked,
      mobilePicked,
      selected,
    }
  },
})
