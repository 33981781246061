import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24d9e509"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-copy-clipboard" }
const _hoisted_2 = {
  key: 0,
  class: "button-copy-clipboard-balloon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ButtonIcon, {
      onClick: _ctx.copy,
      onKeydown: _withKeys(_withModifiers(_ctx.copy, ["prevent"]), ["space","enter"]),
      text: _ctx.text,
      icon: _ctx.icon,
      styled: _ctx.styled,
      iconSize: _ctx.iconSize,
      disabled: _ctx.disabled,
      "aria-label": _ctx.label
    }, null, 8, ["onClick", "onKeydown", "text", "icon", "styled", "iconSize", "disabled", "aria-label"]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.copied)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('label.copied')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}