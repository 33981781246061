
import { defineComponent, reactive } from 'vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import ModalSheet from '@/components/organisms/ModalSheet.vue'
import TheVideoViewer from '@/components/TheVideoViewer.vue'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import HelpImageViewerMobile from '@/components/static/HelpImageViewerMobile.vue'
import AddonView from '../AddonView.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ButtonIcon,
    ModalSheet,
    TheVideoViewer,
    ModalWindow,
    HelpImageViewerMobile,
    AddonView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
    tabViewFlag: {
      type: Boolean,
      required: true,
    },
  },
  setup (props, context) {
    const i18n = useI18n()
    const lang = i18n.locale

    const state = reactive({
      modal: '',
    })

    // 全画面表示のときブラウザバックでポスター表示に変えるため
    window.addEventListener('popstate', () => {
      context.emit('close')
    })

    return {
      state,
      HelpImageViewerMobile,
      lang,
    }
  },
})
