
import { defineComponent, computed } from 'vue'
import { StyleName, styleNames } from '../../data/def'

export default defineComponent({
  name: 'AppTextarea',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    cols: {
      type: String,
      required: false,
    },
    rows: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup (props, context) {
    const value = computed({
      get: () => props.modelValue,
      set: (val) => {
        context.emit('update:modelValue', val)
      },
    })
    return {
      value,
    }
  },
})
