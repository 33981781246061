/**
 * mobile-detectにwidthによる判定を追加
 * md.mobile()またはmd.phone()でnullの場合でもbreakpoint未満の場合はtrue
 * イベントが重複しないようにシングルトンパターン
 */
import { computed, Ref, ref } from 'vue'
import MobileDetect from 'mobile-detect'

class DssMobileDetect {
  md: MobileDetect
  breakpoint: number
  clientWidth: Ref

  constructor () {
    this.breakpoint = 768
    this.clientWidth = ref(document.body.clientWidth)
    if (window) {
      window.onresize = () => {
        this.clientWidth.value = document.body.clientWidth
        /**
         * iOS用遅延処理
         * iOSではリサイズイベントが発火してからclientWidthに反映されるまで時間がかかるため
         */
        setTimeout(() => {
          this.clientWidth.value = document.body.clientWidth
        }, 500)
      }
      this.md = new MobileDetect(window.navigator.userAgent)
    } else {
      this.md = new MobileDetect('')
    }
  }

  mobile (breakpoint: number | null = null) {
    this.breakpoint = breakpoint || this.breakpoint
    return computed(() => {
      const value = this.md.mobile()
      if (value) {
        return value
      } else if (this.clientWidth.value >= this.breakpoint) {
        return null
      } else {
        return 'true'
      }
    })
  }

  phone (breakpoint: number | null = null) {
    this.breakpoint = breakpoint || this.breakpoint
    return computed(() => {
      const value = this.md.phone()
      if (value) {
        return value
      } else if (this.clientWidth.value >= this.breakpoint) {
        return null
      } else {
        return 'true'
      }
    })
  }

  // breakpointだけで判定
  width (breakpoint: number | null = null) {
    this.breakpoint = breakpoint || this.breakpoint
    return computed(() => {
      if (this.clientWidth.value >= this.breakpoint) {
        return null
      } else {
        return 'true'
      }
    })
  }
}

export default new DssMobileDetect()
