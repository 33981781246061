import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!

  return (_ctx.icon)
    ? (_openBlock(), _createBlock(_component_DssIcon, {
        key: 0,
        icon: _ctx.icon,
        size: _ctx.size,
        class: _normalizeClass(`app-icon-places ${_ctx.color}`)
      }, null, 8, ["icon", "size", "class"]))
    : _createCommentVNode("", true)
}