
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'ul',
    },
    gap: {
      type: [String, Number],
      default: 4,
    },
    deleteIcon: {
      type: Boolean,
      default: false,
    },
  },
})
