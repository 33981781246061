import {
  csspx2dpx,
  PhysicalPosition,
} from './ImageViewerCommon'

/**
 * マウス、タッチ情報クラス
 */
class Pointer {
    public identifier = 0;
    private startPosition_ = new PhysicalPosition();
    private startPositionBuf_ = new PhysicalPosition();
    public setStartPosition (x: number, y: number): void{
      this.startPosition_.x = x
      this.startPosition_.y = y
    }

    public get startPosition (): PhysicalPosition {
      this.startPositionBuf_.x = this.startPosition_.x
      this.startPositionBuf_.y = this.startPosition_.y
      return this.startPositionBuf_
    }

    private prevPosition_ = new PhysicalPosition();
    private prevPositionBuf_ = new PhysicalPosition();
    public setPrevPosition (x: number, y: number): void{
      this.prevPosition_.x = x
      this.prevPosition_.y = y
    }

    public get prevPosition (): PhysicalPosition {
      this.prevPositionBuf_.x = this.prevPosition_.x
      this.prevPositionBuf_.y = this.prevPosition_.y
      return this.prevPositionBuf_
    }

    private position_ = new PhysicalPosition();
    private positionBuf_ = new PhysicalPosition();
    public setPosition (x: number, y: number): void{
      this.position_.x = x
      this.position_.y = y
    }

    public get position (): PhysicalPosition {
      this.positionBuf_.x = this.position_.x
      this.positionBuf_.y = this.position_.y
      return this.positionBuf_
    }

    private differenceOfPosition_ = new PhysicalPosition();
    private differenceOfPositionBuf_ = new PhysicalPosition();
    public setDifferenceOfposition (x: number, y: number): void{
      this.differenceOfPosition_.x = x
      this.differenceOfPosition_.y = y
    }

    public get differenceOfPosition (): PhysicalPosition {
      this.differenceOfPositionBuf_.x = this.differenceOfPosition_.x
      this.differenceOfPositionBuf_.y = this.differenceOfPosition_.y
      return this.differenceOfPositionBuf_
    }

    /**
     * 有効なポインターか否か
     */
    public enable = false;

    public remain = true
}

/**
 * マウス、タッチ管理クラス
 */
class PointerManager {
  /**
     * マウスの為のダミーID
     */
  public static get MOUSE_IDENTIFIER (): number {
    return 0
  }

  public getPointer (identifier: number): Pointer | undefined {
    return this.pointers_.get(identifier)
  }

  private pointers_ = new Map<number, Pointer>();

  /**
   * 直前のポインター座標
   * マウス、指無関係に最後のポインターの座標を記録する
   */
  private prevPosition_ = new PhysicalPosition();
  private prevPositionBuf_ = new PhysicalPosition();
  private prevDistance_ = 0;
  private currentDistance_ = 0;
  /**
   * ２ポイント（タップ位置）の中心座標
   */
  private currentCenter_ = new PhysicalPosition();
  private currentCenterBuf_ = new PhysicalPosition();

  private prevCenter_ = new PhysicalPosition()
  private prevCenterBuf_ = new PhysicalPosition()

  private diffOfCenterPosBuf_ = new PhysicalPosition()

  /**
   * 最後に更新されたポインターのID
   */
  private lastPointerId_ = -1

  /**
   * イベント所得HTML要素を全画面化した際の、MainCanvasの左上の位置差分補正値
   */
  private clientAreaOffset_ = new PhysicalPosition()

  /**
   * 最後に更新されたポインターを返す
   */
  public getLastPointer (): Pointer | undefined {
    return this.getPointer(this.lastPointerId_)
  }

  public get prevPosisionX (): number {
    return this.prevPosition_.x
  }

  public get prevPosisionY (): number {
    return this.prevPosition_.y
  }

  public get prevPosition (): PhysicalPosition {
    this.prevPositionBuf_.x = this.prevPosition_.x
    this.prevPositionBuf_.y = this.prevPosition_.y
    return this.prevPositionBuf_
  }

  public get distance (): number {
    return this.currentDistance_
  }

  public get differenceOfDistance (): number {
    if (this.prevDistance_ === 0 || this.currentDistance_ === 0) return 0

    return this.currentDistance_ - this.prevDistance_
  }

  /**
   * ２ポイント（タップ位置）の中心座標を返す
   */
  public get centerPosition (): PhysicalPosition {
    this.currentCenterBuf_.x = this.currentCenter_.x
    this.currentCenterBuf_.y = this.currentCenter_.y
    return this.currentCenterBuf_
  }

  /**
   * 前回の２ポイント（タップ位置）の中心座標を返す
   */
  public get prevCenterPosition (): PhysicalPosition {
    this.prevCenterBuf_.x = this.prevCenter_.x
    this.prevCenterBuf_.y = this.prevCenter_.y
    return this.prevCenterBuf_
  }

  /**
   * 前回と今回の２ポイント（タップ位置）間の差分を返す
   */
  public get differenceOfCenterPosition (): PhysicalPosition {
    this.diffOfCenterPosBuf_.x = this.currentCenter_.x - this.prevCenter_.x
    this.diffOfCenterPosBuf_.y = this.currentCenter_.y - this.prevCenter_.y
    return this.diffOfCenterPosBuf_
  }

    /**
   * 入力イベント処理専用HTML要素
   */
    private toCoverMainCanvas_?: HTMLDivElement;
    public setToCoverViewer (cover: HTMLDivElement): void{
      this.toCoverMainCanvas_ = cover
      this.toCoverMainCanvas_.oncontextmenu = function () { return false }
    }

    public get toCoverMainCanvas (): HTMLDivElement | undefined {
      return this.toCoverMainCanvas_
    }

    private toCoverFullscreend_ = false

    public getPressingPointerCount (): number {
      let res = 0
      this.pointers_.forEach((v) => {
        if (v.enable) ++res
      })
      return res
    }

    /**
   * 入力イベント処理専用HTML要素のマウスカーソルを変更する
   */
    public setToCoverViewerCursor (cursor: string): void {
      if (this.toCoverMainCanvas_) {
        this.toCoverMainCanvas_.style.cursor = cursor
      }
    }

    /**
   * いずれかのポインターが有効（マウスダウン・タッチダウン中）か否か
   */
    public isPressing (): boolean {
      let res = false
      this.pointers_.forEach((v) => {
        if (v.enable) res = true
      })

      return res
    }

    /**
   * 優先されるポインター（マウスの場合はそれ自身、タッチの場合は最もidentifierが若く且つenebledなもの）を返す
   */
    public getPrimaryPointer (): Pointer | undefined {
      let res: Pointer | undefined

      let id = -1
      this.pointers_.forEach((v) => {
        if (v.enable) {
          if (id === -1 || id > v.identifier) {
            id = v.identifier
            res = v
          }
        }
      })

      return res
    }

    /**
   * 有効な（マウス左ボタン押下中、タッチ中）のポインターの数
   */
    public get eneblPointCount (): number {
      let res = 0

      this.pointers_.forEach((v) => {
        if (v.enable) {
          res++
        }
      })

      return res
    }

    /**
  * ポインターが画像ビューア範囲外に出た場合にドラッグ解除し、入力イベント処理要素を縮小する
  */
    public disabledPoint (): void {
      this.pointers_.forEach((v) => {
        if (v.enable) {
          v.enable = false
        }
      })
      this.toUncoverViewer()
    }

    private pointerUnmark (): void {
      this.pointers_.forEach((value: Pointer, key: number) => {
        value.remain = false
      })
    }

    private pointerCG (): void{
      const unmarked = new Array<number>()
      this.pointers_.forEach((value: Pointer, key: number) => {
        if (!value.remain) {
          unmarked.push(key)
        }
      })

      unmarked.forEach((id: number) => {
        this.pointers_.delete(id)
      })
    }

    /**
   * ２ポイント間の距離を返す
   */
    public updateDistance (): number {
      if (this.pointers_.size < 2) {
        this.prevDistance_ = 0
        return 0
      }

      // 有効なポイントを先頭から２つ選択する
      const ps = new Array<PhysicalPosition>()
      this.pointers_.forEach((v) => {
        if (v.enable) {
          ps.push(v.position)
        }
      })
      if (ps.length < 2) {
        this.prevDistance_ = 0
        return 0
      }

      this.prevDistance_ = this.currentDistance_
      this.currentDistance_ = PhysicalPosition.distance(ps[0], ps[1])

      // 中心座標を更新する
      this.prevCenter_.x = this.currentCenter_.x
      this.prevCenter_.y = this.currentCenter_.y

      if (ps[0].x > ps[1].x) {
        this.currentCenter_.x = (ps[0].x - ps[1].x) / 2 + ps[1].x
      } else {
        this.currentCenter_.x = (ps[1].x - ps[0].x) / 2 + ps[0].x
      }
      if (ps[0].y > ps[1].y) {
        this.currentCenter_.y = (ps[0].y - ps[1].y) / 2 + ps[1].y
      } else {
        this.currentCenter_.y = (ps[1].y - ps[0].y) / 2 + ps[0].y
      }

      return this.currentDistance_
    }

    public onDown (event: MouseEvent | TouchEvent): void {
      if (event instanceof MouseEvent) {
        let p = this.getPointer(PointerManager.MOUSE_IDENTIFIER)
        if (!p) {
          p = new Pointer()
          p.identifier = PointerManager.MOUSE_IDENTIFIER
        }
        this.toCoverViewer()
        p.enable = true
        const x = csspx2dpx(event.offsetX) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.x : 0)
        const y = csspx2dpx(event.offsetY) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.y : 0)
        p.setStartPosition(x, y)
        p.setPosition(x, y)
        p.setPrevPosition(x, y)

        p.setDifferenceOfposition(0, 0)

        this.pointers_.set(p.identifier, p)

        this.prevPosition_.x = x
        this.prevPosition_.y = y

        this.lastPointerId_ = p.identifier
      } else if (event instanceof TouchEvent) {
        let elementRect
        if (event.target) {
          elementRect = (event.target as HTMLElement).getBoundingClientRect()
        }
        for (let t = 0; t < event.changedTouches.length; ++t) {
          // たまっている Pointerを削除する
          this.pointerUnmark()

          for (let t = 0; t < event.touches.length; ++t) {
            const id = event.touches[t].identifier
            const po = this.pointers_.get(id)
            if (po) {
              po.remain = true
            }
          }

          this.pointerCG()

          const touch = event.changedTouches[t]
          let p = this.getPointer(touch.identifier)
          if (!p) {
            p = new Pointer()
            p.identifier = touch.identifier
          }

          let offsetX = touch.clientX
          let offsetY = touch.clientY

          if (elementRect) {
            offsetX = (touch.clientX - elementRect.left)
            offsetY = (touch.clientY - elementRect.top)
          }

          p.enable = true
          const x = csspx2dpx(offsetX) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.x : 0)
          const y = csspx2dpx(offsetY) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.y : 0)
          p.setStartPosition(x, y)
          p.setPosition(x, y)
          p.setPrevPosition(x, y)

          p.setDifferenceOfposition(0, 0)

          this.pointers_.set(p.identifier, p)

          this.prevPosition_.x = x
          this.prevPosition_.y = y

          this.lastPointerId_ = p.identifier
        }
      }
      this.updateDistance()
    }

    public onMove (event: MouseEvent | TouchEvent): void {
      if (event instanceof MouseEvent) {
        let p = this.getPointer(PointerManager.MOUSE_IDENTIFIER)
        if (!p) {
          p = new Pointer()
          p.identifier = PointerManager.MOUSE_IDENTIFIER
        }

        p.setPrevPosition(p.position.x, p.position.y)
        p.setPosition(csspx2dpx(event.offsetX) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.x : 0), csspx2dpx(event.offsetY) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.y : 0))
        p.setDifferenceOfposition(p.position.x - p.prevPosition.x, p.position.y - p.prevPosition.y)

        this.pointers_.set(p.identifier, p)

        this.prevPosition_.x = p.position.x
        this.prevPosition_.y = p.position.y

        this.lastPointerId_ = p.identifier
      } else if (event instanceof TouchEvent) {
        let elementRect
        if (event.target) {
          elementRect = (event.target as HTMLElement).getBoundingClientRect()
        }
        for (let t = 0; t < event.changedTouches.length; ++t) {
          const touch = event.changedTouches[t]
          let p = this.getPointer(touch.identifier)
          if (!p) {
            p = new Pointer()
            p.identifier = touch.identifier
          }

          p.setPrevPosition(p.position.x, p.position.y)

          let offsetX = touch.clientX
          let offsetY = touch.clientY
          if (elementRect) {
            offsetX = (touch.clientX - elementRect.left)
            offsetY = (touch.clientY - elementRect.top)
          }
          p.setPosition(csspx2dpx(offsetX) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.x : 0), csspx2dpx(offsetY) + (this.toCoverFullscreend_ ? this.clientAreaOffset_.y : 0))

          p.setDifferenceOfposition(p.position.x - p.prevPosition.x, p.position.y - p.prevPosition.y)

          this.pointers_.set(p.identifier, p)

          this.prevPosition_.x = p.position.x
          this.prevPosition_.y = p.position.y

          this.lastPointerId_ = p.identifier
        }

        this.updateDistance()
      }
    }

    public onUp (event: MouseEvent | TouchEvent): void {
      if (event instanceof MouseEvent) {
        let p = this.getPointer(PointerManager.MOUSE_IDENTIFIER)
        if (!p) {
          p = new Pointer()
          p.identifier = PointerManager.MOUSE_IDENTIFIER
        }

        p.enable = false
        this.toUncoverViewer()
        p.setDifferenceOfposition(0, 0)

        this.pointers_.set(p.identifier, p)

        this.prevPosition_.x = p.position.x
        this.prevPosition_.y = p.position.y

        this.lastPointerId_ = p.identifier
      } else if (event instanceof TouchEvent) {
        for (let t = 0; t < event.changedTouches.length; ++t) {
          const touch = event.changedTouches[t]
          let p = this.getPointer(touch.identifier)
          if (!p) {
            p = new Pointer()
            p.identifier = touch.identifier
          }

          p.enable = false

          p.setDifferenceOfposition(0, 0)

          this.pointers_.set(p.identifier, p)

          this.prevPosition_.x = p.position.x
          this.prevPosition_.y = p.position.y

          this.lastPointerId_ = p.identifier
        }
      }
      this.updateDistance()
    }

    /**
   * マウス・タッチイベントから Pointer を生成する
   * @param event
   * @returns Pointer配列
   */
    public convertEventToPointer (event: MouseEvent | TouchEvent): Array<Pointer> {
      const res = new Array<Pointer>()

      if (event instanceof MouseEvent) {
        const p = new Pointer()
        p.identifier = PointerManager.MOUSE_IDENTIFIER

        const x = csspx2dpx(event.offsetX + this.clientAreaOffset_.x)
        const y = csspx2dpx(event.offsetY + this.clientAreaOffset_.y)
        p.setStartPosition(x, y)
        p.setPosition(x, y)
        p.setPrevPosition(x, y)

        p.setDifferenceOfposition(0, 0)

        res.push(p)
      } else if (event instanceof TouchEvent) {
        let elementRect
        if (event.target) {
          elementRect = (event.target as HTMLElement).getBoundingClientRect()
        }
        for (let t = 0; t < event.changedTouches.length; ++t) {
          const touch = event.changedTouches[t]
          const p = new Pointer()
          p.identifier = touch.identifier

          let offsetX = touch.clientX
          let offsetY = touch.clientY

          if (elementRect) {
            offsetX = (touch.clientX - elementRect.left)
            offsetY = (touch.clientY - elementRect.top)
          }

          const x = csspx2dpx(offsetX + (this.toCoverFullscreend_ ? this.clientAreaOffset_.x : 0))
          const y = csspx2dpx(offsetY + (this.toCoverFullscreend_ ? this.clientAreaOffset_.y : 0))
          p.setStartPosition(x, y)
          p.setPosition(x, y)
          p.setPrevPosition(x, y)

          p.setDifferenceOfposition(0, 0)

          res.push(p)
        }
      }

      return res
    }

    /**
   * 入力イベント処理要素を拡大し全体を覆う
   */
    private toCoverViewer (): void{
      if (this.toCoverMainCanvas_) {
        const coverStyle = this.toCoverMainCanvas_.style
        coverStyle.position = 'fixed'
        coverStyle.zIndex = '999'

        this.toCoverFullscreend_ = true
      }
    }

    /**
   * 入力イベント処理要素を縮小する
   */
    private toUncoverViewer (): void{
      if (this.toCoverMainCanvas_) {
        const coverStyle = this.toCoverMainCanvas_.style
        coverStyle.position = 'absolute'
        coverStyle.zIndex = '2'
      }

      this.toCoverFullscreend_ = false
    }

    /**
     * MainCanvas位置のオフセット値を設定する
     * @param x
     * @param y
     */
    public setPositionOffset (x: number, y: number): void {
      this.clientAreaOffset_.x = x
      this.clientAreaOffset_.y = y
    }

    public removePointer (pointer: Pointer): void {
      this.pointers_.delete(pointer.identifier)
    }

    public removePointerByID (id: number): void {
      this.pointers_.delete(id)
    }
}

export { PointerManager, Pointer }
