
const isServer = typeof window === 'undefined'

const currentContentScrollTo = () => {
  if (isServer) return
  const referencePositionContent = document.querySelector(
    '.item-viewer-contents-bundle-thumbnail'
  )
  const selectedContent = document.querySelector('.current')
  if (!selectedContent) return
  const element = document.querySelector('.side-panel-body')
  const referencePosition =
    referencePositionContent?.getBoundingClientRect()
  const contentPosition = selectedContent?.getBoundingClientRect()
  if (!!element && !!contentPosition && !!referencePosition) {
    element.scrollTo(0, contentPosition.top - referencePosition.top)
  }
}

const scrollToSearchResultTop = () => {
  if (isServer) return
  const trgetPositionElement = document.querySelector(
    '.the-search-result-main-total'
  )
  const trgetPosition =
    trgetPositionElement?.getBoundingClientRect()
  if (trgetPosition) {
    scrollTo(0, trgetPosition.top)
  }
}

const scrollToElementTop = (elementId: string) => {
  if (isServer) return
  const trgetPositionElement = document.querySelector(elementId)
  const trgetPosition = trgetPositionElement?.getBoundingClientRect()
  if (trgetPosition) {
    scrollTo(0, trgetPosition.top)
  }
}

export {
  currentContentScrollTo,
  scrollToSearchResultTop,
  scrollToElementTop,
}
