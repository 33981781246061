import { isEmpty } from 'lodash'
import { getData } from '@/helpers/util/webApiUtil'
import { spotlightCategoryTag, spotlightItem } from '@/data/@types/Spotlight'
import { ExampleQueries } from '@/data/@types/ExampleQueries'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const CONTENTS_BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

/**
 * ステート
 */
type State = {
  spotlightCategoryTags: spotlightCategoryTag[],
  spotlightItems: spotlightItem[],
  exampleQueries: ExampleQueries,
}

const state: State = {
  spotlightCategoryTags: [],
  spotlightItems: [],
  exampleQueries: { ja: [], en: [], length: 5 },
}

/**
 * ゲッター
 */

const getters = {
  getSpotlightItems (state: any) {
    return state.spotlightItems
  },
  getSpotlightCategoryTags (state: any) {
    if (!state.spotlightCategoryTags) return
    return state.spotlightCategoryTags.slice(0, 9)
  },
  getExampleQueries (state: any) {
    return state.exampleQueries
  },
  getStaticHtml () {
    return async (pathParam: any) => {
      const isRekion = pathParam.isRekion
      const htmlName = pathParam.htmlName
      const lang = pathParam.lang

      const staticPath = isRekion ? `static/rekion/${lang}/${htmlName}` : `static/${lang}/${htmlName}`
      const url = `${CONTENTS_BASE_URL}${staticPath}`

      try {
        const response = await getData(url)
        return response
      } catch (error: any) {
        // TODO エラーハンドリング
        console.error(error.message)
        return ''
      }
    }
  },
}

/**
 * ミューテーション
 */

const mutations = {
  LOAD_SPOTLIGHT_ITEMS (state: any, items: any[]) {
    const sortedSpotlightItems: any = []
    const itemsWithIndex: any = []
    items.forEach((item: any) => {
      if (isEmpty(item)) return
      if (item.index) {
        // item.indexを持つアイテム
        itemsWithIndex.push(item)
      } else {
        // item.indexを持たないアイテム
        sortedSpotlightItems.push(item)
      }
    })
    // item.indexに従ってitemを正しい位置に格納する
    // item.indexが小さい値から格納しないと順番がずれるのため昇順にソート
    for (const item of itemsWithIndex.sort((a: any, b: any) => a.index - b.index)) {
      sortedSpotlightItems.splice(item.index - 1, 0, item)
    }
    state.spotlightItems = sortedSpotlightItems
  },
  LOAD_SPOTLIGHT_CATEGORYTAGS (state: any, categoryTags: any) {
    state.spotlightCategoryTags = categoryTags
  },
  SET_EXAMPLE_QUERIES (state: any, exampleQueries: any) {
    state.exampleQueries = exampleQueries
  },
}

/**
 * アクション
 */

const actions = {
  async fetchSpotlightItems (context: any, items: string[]) {
    const resultItems = await Promise.all(items.map(async (item: any) => {
      if (item.pid === 'about') {
        return {
          item: { pid: item.pid },
          context: item.context,
          index: item.index,
          label: item.label ?? '',
          title: item.title ?? '',
          thumbnail: item.thumbnail ?? '',
          link: item.link ?? '',
          tagId: item.tagId,
        }
      }
      try {
        const res = await getData(`${BASE_URL}/item/search/${item.pid}`)
        return {
          item: res.item,
          context: item.context,
          index: item.index,
          label: item.label ?? '',
          title: item.title ?? '',
          thumbnail: item.thumbnail ?? '',
          tagId: item.tagId,
        }
      } catch (error: any) {
        console.error('error: ', error.message)
        context.commit('hasContentSearchError', true)
      }
    }))
    context.commit('LOAD_SPOTLIGHT_ITEMS', resultItems)
  },
  async loadSpotlight (context: any, isRekion: boolean) {
    const url = `${CONTENTS_BASE_URL}static/spotlight.json`

    try {
      const response = await getData(url)
      context.commit('LOAD_SPOTLIGHT_CATEGORYTAGS', response.categoryTags)
      context.dispatch('fetchSpotlightItems', response.items)
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    }
  },
  async loadExampleQueries (context: any, isRekion: boolean) {
    const url = `${CONTENTS_BASE_URL}static/exampleQuery.json`
    try {
      const response = await getData(url)
      context.commit('SET_EXAMPLE_QUERIES', response)
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    }
  },
  async loadStaticHtml (context: any, params: any) {
    const htmlName = params.htmlName
    const lang = params.lang

    const url = `${CONTENTS_BASE_URL}static/${lang}/${htmlName}`

    try {
      const response = await getData(url)
      return response
    } catch (error: any) {
      // TODO エラーハンドリング
      console.error(error.message)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
