import { csspx2dpx } from './ImageViewerCommon'
import { PointerManager } from './PointerManager'

/**
 * イベントに登録できるコールバック関数シグネチャ
 * trueを返した場合後続の仮想HTML要素にイベントの伝播を行わない(=stopPropagation)。その他の場合は伝播を行う
 */
type UIMEventCallback = (event: MouseEvent | TouchEvent | WheelEvent, pointerManager: PointerManager) => boolean | undefined

type EventRootHandler = (event: MouseEvent | TouchEvent | WheelEvent, targetClientElement: HTMLElement | undefined) => void

/**
 * 仮想HTML要素レイヤークラス
 */
class VirtualElementLayer {
    public layterName_= ''
    /**
     * k = event Name
     * v = callback func
     */
    public eventMap = new Map<string, UIMEventCallback>()
}

class UserInputManager {
    private static instance?: UserInputManager
    public static getInstance (): UserInputManager {
      if (!UserInputManager.instance) {
        UserInputManager.instance = new UserInputManager()
      }

      return UserInputManager.instance
    }

    /**
     * 管理下にある仮想HTML要素レイヤー配列
     * elementLayers_[0]が最下層レイヤー
     * elementLayers_[elementLayers_length-1]が最上層レイヤー
     */
    private elementLayers_ = new Array<VirtualElementLayer>()

    private eventDispatcher_?: EventRootHandler

    private pointerManager_ = new PointerManager()
    public get pointerManager (): PointerManager {
      return this.pointerManager_
    }

    private constructor () {
      this.init()
    }

    private init (): void {
      this.eventDispatcher_ = (event: MouseEvent | TouchEvent | WheelEvent, targetClientElement: HTMLElement | undefined) => {
        let stopPropagation = false

        if (targetClientElement) {
          const crect = targetClientElement.getBoundingClientRect()
          this.pointerManager.setPositionOffset(csspx2dpx(-crect.left), csspx2dpx(-crect.top))
        } else {
          this.pointerManager.setPositionOffset(0, 0)
        }

        switch (event.type) {
          case 'mousedown':
          case 'touchstart':
            this.pointerManager_.onDown(event)
            break
          case 'mousemove':
          case 'touchmove':
            this.pointerManager_.onMove(event)
            break
          case 'mouseup':
          case 'touchend':
            this.pointerManager_.onUp(event)
            break
        }

        for (let i = this.elementLayers_.length - 1; i >= 0; --i) {
          const el = this.elementLayers_[i]
          if (!el) {
            continue
          }

          const f = el.eventMap.get(event.type)
          if (f) {
            if (f(event, this.pointerManager_)) {
              stopPropagation = true
              break
            }
          }
        }

        if (stopPropagation) {
          event.stopPropagation()
        }
      }
    }

    public resetPointerManager (): void {
      this.pointerManager_ = new PointerManager()
    }

    public getRootEventHandler (): EventRootHandler | undefined {
      return this.eventDispatcher_
    }

    public dispatch (event: MouseEvent | TouchEvent | WheelEvent, targetClientElement?: HTMLElement): void {
      if (this.eventDispatcher_) {
        this.eventDispatcher_(event, targetClientElement)
      }
    }

    /**
     * イベントハンドラを登録する
     * 一つのレイヤーの一つのイベントに登録できるハンドラは１つのみ。
     * @param layerIndex 登録先仮想HTML要素レイヤーインデックス
     * @param eventName イベント名
     * @param callback イベントハンドラー 戻り値は boolean型で trueを返した場合後続の仮想HTML要素にイベントの伝播を行わない。その他の場合は伝播を行う
     */
    public attachEventListener (layerIndex: number, eventName: string, callback: UIMEventCallback): void {
      if (!this.elementLayers_[layerIndex]) {
        this.elementLayers_[layerIndex] = new VirtualElementLayer()
      }

      this.elementLayers_[layerIndex].eventMap.set(eventName, callback)
    }

    /**
     * 指定したイベントに登録されているハンドラを削除する
     * @param layerIndex 登録先仮想HTML要素レイヤーインデックス
     * @param eventName イベント名
     */
    public removeEventListener (layerIndex: number, eventName: string): void {
      if (this.elementLayers_[layerIndex]) {
        this.elementLayers_[layerIndex].eventMap.delete(eventName)
      }
    }
}

export { UserInputManager, UIMEventCallback }
