import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-89d0600c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mycollection-sub-header" }
const _hoisted_2 = { class: "the-global-header-nav" }
const _hoisted_3 = { class: "the-global-header-nav--list has-animation" }
const _hoisted_4 = { class: "the-global-header-nav--item item__userName" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "the-global-header-nav--item item__logout",
  id: "mycollection-header-logout-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("a", {
            class: "item-input button-userName",
            href: _ctx.$t(`link.userInfo`)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('label.userInfo')), 1)
          ], 8, _hoisted_5)
        ]),
        (!_ctx.isKss)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createVNode(_component_AppButton, {
                class: "item-input__button",
                styled: "is-none",
                onClick: _ctx.onLogout,
                onKeydown: _withKeys(_withModifiers(_ctx.onLogout, ["prevent"]), ["space","enter"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('label.logout')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "onKeydown"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}