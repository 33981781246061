
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'DssIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup (props) {
    return {
      dssIconClass: computed(() => `dss-${props.icon}`),
    }
  },
})
