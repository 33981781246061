/**
 * 検索結果一覧のアイテムのタイトル部分を返却します。
 * @param hitDocument SearchHits<item>のhits配列のオブジェクト
 * @returns 0001Dtct. 0007Dtct 0270Dt 0269Dod 【全号まとめ】
 */

import { getParentCollectionId } from './contentLabel'

export const createTitleLabel = (hitDocument: any): string => {
  if (!hitDocument) return ''
  if (!hitDocument.content) return ''
  if (!hitDocument.content.meta) return ''

  const meta = hitDocument.content.meta
  const mainCollection = hitDocument.content.collections ? hitDocument.content.collections[0] : 'default'

  return createTitleMetaLabel(meta, mainCollection, hitDocument.content.parent, hitDocument.content.type)
}

export const createTitleLabelOfMyCollection = (item: any): string => {
  if (!item.meta) return ''
  const mainCollection = item.collections ? item.collections[0] : 'default'

  return createTitleMetaLabel(item.meta, mainCollection, item.parent, item.type)
}

export const createTitleMetaLabel = (meta: any, mainCollection: string, isParent: boolean, contentType: string): string => {
  let label = ''
  label += titleProcessing(meta, mainCollection)

  // 全冊/全号まとめ の付与
  switch (getParentCollectionId(mainCollection)) {
    case 'A00004':
      // 古典籍資料 > 錦絵
      if (!isParent && contentType === 'branch') label += '【全冊まとめ】'
      break
    // 【全号まとめ】を表示しないコレクション
    // 歴史的音源/科学映像/脚本/手稿譜/日本占領関係資料
    case 'A00024':
    case 'A00126':
    case 'A00123':
    case 'A00124':
    case 'A00016':
      break
    default:
      if (!isParent && contentType === 'branch') label += '【全号まとめ】'
  }
  // 2.0 title. volume number issue 【全号まとめ】
  // 3.0 0001Dtct. 0007Dtct 0270Dt 0269Dod 【全号まとめ】
  return label
}

/**
 * タイトル部分をコレクションごとに出し分ける
 * @param meta メタデータ
 * @param mainCollection 第一コレクション
 * @returns タイトル部分の中央に表示する部分
 */
export const titleProcessing = (meta: any, mainCollection: string): string => {
  let label = ''

  // 共通で表示している箇所（前）
  if (hasMeta(meta['0001Dtct'])) label += metaArrayProcessing(meta['0001Dtct']) // タイトル
  if (hasMeta(meta['0007Dtct'])) label += ' ' + metaArrayProcessing(meta['0007Dtct']) // 巻次又は部編番号
  if (hasMeta(meta['0006Dtct'])) label += ' ' + metaArrayProcessing(meta['0006Dtct']) // 巻タイトル又は部編名

  // コレクションごとの出し分け箇所
  // 図書、古典籍資料（貴重書等）、地図、日本占領関係資料、プランゲ文庫
  // 古典籍資料（貴重書等） > 錦絵、 他機関デジタル化資料 > 内務省検閲発禁図書
  // 電子書籍・電子雑誌の場合コレクション
  // には、版表示とシリーズタイトルを出す。
  if (['A00001', 'A00003', 'A00152', 'A00016', 'A00019'].includes(getParentCollectionId(mainCollection)) || // 最上位コレクションの指定
      ['A00004', 'A00148'].includes(mainCollection) || // 最上位コレクションでない場合の出し分け処理
      mainCollection?.[0] === 'B' // 電子書籍・電子雑誌の場合コレクションの1文字目がB
  ) {
    if (hasMeta(meta['0018Dtct'])) label += ' ' + metaArrayProcessing(meta['0018Dtct']) // 版表示
    if (hasMeta(meta['0003Dtct'])) label += ' (' + metaArrayProcessingBySeparator(meta['0003Dtct'], ') (') + ') ' // シリーズタイトル
  }

  // 共通で表示している箇所（後）
  if (hasMeta(meta['0270Dt'])) label += ' ' + metaArrayProcessing(meta['0270Dt']) // 刊行物号
  if (hasMeta(meta['0269Dod'])) label += ' ' + metaArrayProcessing(meta['0269Dod']) // 刊行物通号

  return label
}

const hasMeta = (meta: Array<string>): boolean => {
  if (!meta || metaArrayProcessing(meta) === '') return false // null or undefind or '' ならばfalse
  return true
}

const metaArrayProcessing = (meta: Array<string>) => {
  return meta ? meta.join(' ') : ''
}

const metaArrayProcessingBySeparator = (meta: Array<string>, sep = ',') => {
  return meta ? meta.join(sep) : ''
}
