
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'AppSelect',
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    styled: {
      type: String,
      default: 'is-normal',
    },
    /** CypressでID指定するため */
    componentId: {
      type: String,
      required: false,
    },
  },
  setup (props, context) {
    const selectedItem = computed({
      get: () => props.modelValue,
      set: (val) => {
        context.emit('update:modelValue', val)
      },
    })
    return {
      selectedItem,
    }
  },
})
