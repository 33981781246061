
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AppInput from '@/components/atoms/AppInput.vue'
import AppTextarea from '@/components/atoms/AppTextarea.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import ButtonCopyClipboard from '@/components/molecules/ButtonCopyClipboard.vue'
import IconTwitter from '@/components/atoms/IconTwitter.vue'
import IconFacebook from '@/components/atoms/IconFacebook.vue'
import IconLine from '@/components/atoms/IconLine.vue'
import IconEmail from '@/components/atoms/IconEmail.vue'
import IconIIIF from '@/components/atoms/IconIIIF.vue'
import TextWithLeadingIcon from '@/components/molecules/TextWithLeadingIcon.vue'
import { getItemCopyright } from '@/domain/item/itemViewer/itemCopyright'
import AppIconPlaces from '../../atoms/AppIconPlaces.vue'
import AppLabelPlaces from '../../atoms/AppLabelPlaces.vue'
import { useRoute } from 'vue-router'
import { getAvailableStateText } from '@/data/AccessRestrictions'
import { getPermissionColor, ruleToType } from '@/data/Permission'

const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
const DOI_BASE_URL = process.env.VUE_APP_DOI_BASE_URL

export default defineComponent({
  components: {
    AppInput,
    AppTextarea,
    DssIcon,
    AppButton,
    ButtonCopyClipboard,
    IconTwitter,
    IconFacebook,
    IconLine,
    IconEmail,
    IconIIIF,
    TextWithLeadingIcon,
    AppIconPlaces,
    AppLabelPlaces,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const i18n = useI18n()
    const lang = i18n.locale
    const route = useRoute()
    const store = useStore()
    const databaseLinkerRoot = 'http://resolver.ndl.go.jp/ndl02?'
    const urlVer = 'Z39.88-2004'
    const rftValFmt = 'info:ofi/fmt:kev:mtx:book'
    const urlCtxFmt = 'info:ofi/fmt:kev:mtx:ctx'
    const ctxEnc = 'info:ofi/enc:UTF-8'
    const rfrId = 'info:sid/dl.ndl.go.jp:NDL-DL'
    const isServer = typeof window === 'undefined'

    const onTwitter = () => {
      if (isServer) return
      window.open(`
        https://twitter.com/share?url=${encodeURIComponent(
          location.href
        )}&hashtags=${encodeURIComponent(
          i18n.t('label.siteName')
        )}
      `)
    }
    const onFacebook = () => {
      if (isServer) return
      window.open(`
        https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          location.href
        )}
      `)
    }
    const onLine = () => {
      if (isServer) return
      window.open(`
        http://line.me/R/msg/text/?${encodeURIComponent(location.href)}
      `)
    }
    const onMail = () => {
      const hostName = location.href
      location.href = `mailto:?body=${hostName}`
    }
    const metaItem = (meta: any) => {
      if (!meta) return ''
      return meta.join('')
    }

    const isRekion = route.meta.isRekion
    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const connectionSourceLocation = computed(() => store.getters.location)
    const isMiddlePersend = computed(() => store.getters.isMiddlePersend)
    const permissionRule = computed(() => {
      const rule = props.item.permission.rule
      return ruleToType(rule)
    })
    const permissionColor = computed(() => {
      return getPermissionColor(permissionRule.value, isRekion)
    })
    const newLineCode = permissionRule.value === 'rekion' ? '/' : lang.value === 'en' ? ' ' : ''
    const permissionText = computed(() => getAvailableStateText(permissionRule.value, connectionSourceLocation.value, isLoggedIn.value, isMiddlePersend.value, newLineCode, i18n))

    const isDispDatabaseLink = computed(() => {
      if (!store.getters.isLoggedIn) return false
      const loginType = store.getters.loginType
      return (loginType === 'sso' && props.item.permission.type === 'ooc')
    })

    const itemUrl = computed(() => BASE_URL.replace(/\/$/, '') + route.fullPath) // 双方にスラッシュがあるため、片方を取り除く

    const accessRights = computed(() => getItemCopyright(props.item))

    const doi = computed(() => metaItem(props.item.meta['0114Dkck']))

    const manifestUri = computed(() => props.item.iiifManifestUrl)

    const rightsDescription = computed(() => {
      if (props.item.rights?.description) return props.item.rights.description
      if (props.item.rights?.code === 'pdm') return i18n.t('rights.message.pdm')
      if (permissionRule.value === 'ooc') return i18n.t('rights.message.permission')
      return ''
    })

    // コレクション「憲政資料」「日本占領期資料」については、転載時の表記例を非表示にする暫定対応
    const shouldShowPanelShareRightsCopy = computed(() => {
      const collections = props.item.collections
      for (const collection of collections) {
        const parentCollectionId = store.getters.CollectionFamily(collection)[1]?.collectionId
        if (parentCollectionId === 'A00016' || parentCollectionId === 'A00017') {
          return false
        }
      }
      return true
    })

    const displayInternetRightsCodeMessage = computed(() => props.item.permission.type === 'internet' && !props.item.rights?.code && !props.item.rights?.description)

    return {
      itemUrl,
      rightsDescription,
      accessRights,
      doi,
      manifestUri,
      onTwitter,
      onFacebook,
      onLine,
      onMail,
      databaseLinkerRoot,
      urlVer,
      rftValFmt,
      urlCtxFmt,
      ctxEnc,
      rfrId,
      metaItem,
      DOI_BASE_URL,
      permissionText,
      permissionColor,
      permissionRule,
      isDispDatabaseLink,
      shouldShowPanelShareRightsCopy,
      displayInternetRightsCodeMessage,
    }
  },
})
