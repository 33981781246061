
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'underlayer',
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  setup (props) {
    const i18n = useI18n()
    const lang = i18n.locale

    const classNames = computed(() => {
      return {
        [props.type]: true,
        [lang.value]: true,
      }
    })

    return {
      classNames,
    }
  },
})
