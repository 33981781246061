
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import default_ from '@/components/organisms/metadataList/simpleList/default.vue'
import A00003 from '@/components/organisms/metadataList/simpleList/A00003.vue'
import A00016 from '@/components/organisms/metadataList/simpleList/A00016.vue'
import A00017 from '@/components/organisms/metadataList/simpleList/A00017.vue'
import A00019 from '@/components/organisms/metadataList/simpleList/A00019.vue'
import A00024 from '@/components/organisms/metadataList/simpleList/A00024.vue'
import A00084 from '@/components/organisms/metadataList/simpleList/A00084.vue'
import A00123 from '@/components/organisms/metadataList/simpleList/A00123.vue'
import A00124 from '@/components/organisms/metadataList/simpleList/A00124.vue'
import A00126 from '@/components/organisms/metadataList/simpleList/A00126.vue'
import A00145 from '@/components/organisms/metadataList/simpleList/A00145.vue'
import A00151 from '@/components/organisms/metadataList/simpleList/A00151.vue'
import A00152 from '@/components/organisms/metadataList/simpleList/A00152.vue'
import B00000 from '@/components/organisms/metadataList/simpleList/B00000.vue'

export default defineComponent({
  components: {
    default: default_,
    A00003,
    A00016,
    A00017,
    A00019,
    A00024,
    A00084,
    A00123,
    A00124,
    A00126,
    A00145,
    A00151,
    A00152,
    B00000,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const primaryCollection = props.item.collections && props.item.collections[0]
    const collectionFamily = computed(() => store.getters.CollectionFamily(primaryCollection))
    const componentPatternMapping = {
      A00003: 'A00003',
      A00016: 'A00016',
      A00017: 'A00017',
      A00019: 'A00019',
      A00024: 'A00024',
      A00084: 'A00084',
      A00123: 'A00123',
      A00124: 'A00124',
      A00126: 'A00126',
      A00145: 'A00145',
      A00151: 'A00151',
      A00152: 'A00152',
      B00000: 'B00000',
      '': 'default',
    } as {[collectionId: string]: string}

    collectionFamily.value.reverse() // 下位コレクションの設定のほうが先にhitするようにする
    const component: string = componentPatternMapping[
      collectionFamily.value.find(
        (collection: any) => Object.keys(componentPatternMapping).includes(collection.collectionId)
      )?.collectionId || '']

    // eslint-disable-next-line no-console
    console.log(`simple records type: ${component} [recieve collection[0] is ${primaryCollection}]`)
    return {
      component: component,
      collection: primaryCollection,
    }
  },
})
