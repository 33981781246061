import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23aa1d5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-item" }
const _hoisted_2 = { class: "notification-message-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["notification-message", _ctx.type])
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
      (_ctx.type === 'error' || _ctx.type === 'notice' || _ctx.type === 'success')
        ? (_openBlock(), _createBlock(_component_ButtonIcon, {
            key: 0,
            icon: "xmark-circle",
            styled: "is-none",
            onClick: _ctx.onRemove,
            onKeydown: _withKeys(_withModifiers(_ctx.onRemove, ["prevent"]), ["space","enter"]),
            "aria-label": _ctx.$t('header.toaster.close')
          }, null, 8, ["onClick", "onKeydown", "aria-label"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}