
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    fontSize: {
      type: String,
      default: 'medium',
    },
    fontWeight: {
      type: String,
      default: 'normal',
    },
  },
  setup () {
    return {}
  },
})
