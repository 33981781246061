
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { MUTATIONS } from '@/store/mutations-types'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import TheLoginForm from '@/components/organisms/TheLoginForm.vue'

export default defineComponent({
  name: 'SessionExpiredModalWindow',
  components: {
    ModalWindow,
    AppButton,
    TheLoginForm,
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const isSessionTimeoutModalShow = computed(() => store.getters.isSessionTimeout)
    const isLoginModalShow = ref<boolean>(false)

    const onClose = () => {
      // クローズに伴い、アイテム詳細画面でのダウンロードエラーに関するトースト通知を非表示
      // （印刷、ダウンロード、枝アイテムの一括ダウンロード、その他アイテムの一括ダウンロード）
      store.commit('UPDATE_DOWNLOAD_STATUS', { status: '' })
      store.commit('UPDATE_OTHERS_DOWNLOAD_STATUS', { status: '' })
      store.commit('UPDATE_BRANCH_DOWNLOAD_STATUS', { status: '' })
      // クローズに伴い、リセット
      store.dispatch('clearRouteHistory')
      store.commit('SET_SETTION_TIMEOUT', false)
      store.commit(MUTATIONS.SESSION_TIMEOUT_NOTIFICATION)
      isLoginModalShow.value = false
      // TOP画面に遷移
      router.push('/')
    }
    const onOpenOfLoginForm = () => {
      store.commit('SET_SETTION_TIMEOUT', false)
      isLoginModalShow.value = true
    }
    const onLogin = () => {
      isLoginModalShow.value = false
      const couteHistory = store.getters.routeHistory
      couteHistory && router.push(couteHistory)
      store.dispatch('clearRouteHistory')
    }
    return {
      isSessionTimeoutModalShow,
      isLoginModalShow,
      onClose,
      onOpenOfLoginForm,
      onLogin,
    }
  },
})
