/**
 * selectorとして定義した配列に対してi18nで設定したname, labelを付与する(基底コンポーネントでnameを使用するものとlabelを使用するものと両方存在する)
 * @param selectorName i18nファイルで定義したselector名
 * @param baseSelector name, label要素を付与する対象のoption配列
 * @param i18nFunction i18n.t を渡す
 * @returns i18n対応したname, labelを付与したoption配列
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const getSelector = (selectorName: string, baseSelector: Array<any>, i18nFunction: any): Array<any> => {
  return baseSelector.map(option => {
    return {
      ...option,
      name: i18nFunction(`selector.${selectorName}.${option.id || option.value}`),
      label: i18nFunction(`selector.${selectorName}.${option.id || option.value}`),
    }
  })
}
