
import { computed, defineComponent, inject } from 'vue'
import { useStore } from 'vuex'
import MetadataRecord from '@/components/molecules/MetadataRecord.vue'
import { useI18n } from 'vue-i18n'
import { detailRecords } from '@/data/master/CustomMetadataLabel'
import { getItemListViewAllCollectionsForDetail } from '@/domain/item/ItemListView/collectionLabel'

export default defineComponent({
  components: {
    MetadataRecord,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    collection: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup (props) {
    const store = useStore()
    const collectionFamily = computed(() => store.getters.CollectionFamily(props.collection))
    const i18n = useI18n()
    const lang = i18n.locale
    const itemListViewCollection = computed(() => getItemListViewAllCollectionsForDetail({ content: props.item }, store, lang.value))

    const labelPatternList = ['A00024', 'A00017', 'A00151', 'A00126', 'A00145', 'A00124', 'A00003', 'A00016', 'A00019', 'A00123']

    collectionFamily.value.reverse() // 下位コレクションの設定のほうが先にhitするようにする
    const labelPattern: string = collectionFamily.value.find(
      (collection: any) => labelPatternList.includes(collection.collectionId)
    )?.collectionId || 'default'

    const customLabelMapping: {[id: number]: {[pattern: string]: {[lang: string]: string}}} = detailRecords

    const getMetadataLabels = (id: number) => {
      const metadata = inject<(arg0: number) => {[key: string]: string}>('getMetadataLabels')?.(id)
      return customLabelMapping[id]?.[labelPattern] || customLabelMapping[id]?.default || metadata
    }

    const provider = computed(() => props.item.sindex?.sortProvider)

    return {
      itemListViewCollection,
      getMetadataValue: inject('getMetadataValue'),
      getMetadataLabels,
      provider,
    }
  },
})
