
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SegmentedController',
  props: {
    segments: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  setup (props, context) {
    const currentId = ref(props.modelValue)
    const update = (value: string) => {
      currentId.value = value
      context.emit('update:modelValue', value)
    }
    return {
      currentId,
      update,
    }
  },
})
