
import { defineComponent, computed, ref, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import DssIcon from '../atoms/DssIcon.vue'
import TheGlobalNav from './TheGlobalNav.vue'
import TheMyCollectionHeader from './TheMyCollectionHeader.vue'
import TheRekionAdminMenuHeader from './TheRekionAdminMenuHeader.vue'
import LogoRekion from '@/components/organisms/rekion/LogoRekion.vue'
import LogoKn from '@/components/organisms/kn/LogoKn.vue'
import LogoNdldc from '@/components/organisms/LogoNdldc.vue'
import md from '@/helpers/util/MobileDetect'
import ModalWindow from '@/components/organisms/ModalWindow.vue'
import TheLoginForm from '@/components/organisms/TheLoginForm.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { scrollToSearchResultTop } from '@/helpers/util/scrollUtil'

export default defineComponent({
  name: 'TheGlobalHeader',
  components: {
    TheGlobalNav,
    TheMyCollectionHeader,
    TheRekionAdminMenuHeader,
    DssIcon,
    LogoRekion,
    LogoKn,
    LogoNdldc,
    ModalWindow,
    TheLoginForm,
    AppButton,
  },
  props: {
    isRekion: {
      type: Boolean,
      default: false,
    },
    isKn: {
      type: Boolean,
      default: false,
    },
    headerType: {
      type: String,
      default: 'underlayer',
    },
  },
  setup (props) {
    const KN_APPLICATION_LINK = process.env.VUE_APP_KN_APPLICATION_LINK

    const breakpoint = md.width()
    const route = useRoute()
    const store = useStore()

    const i18n = useI18n()
    const lang = i18n.locale

    const path = computed(() => route.path)
    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const loggedInUser = computed(() => store.getters.loggedInUser)

    // myCollectionページ = true
    const isMyCollectionPage = computed(() => {
      return route.name === 'MyCollection' && isLoggedIn.value
    })

    // れきおん管理者メニューページ = true
    const isRekionAdminMenu = computed(() => {
      return route.name === 'AdminMenu' && loggedInUser?.value.role?.includes('REKION_ADMIN')
    })

    const show = ref(false)
    const logoType = computed(() => {
      if (breakpoint.value && (props.headerType !== 'top-page')) {
        return 'underlayer'
      }
      return props.headerType
    })

    const headerStyle = computed(() => {
      return {
        'header-top-page': logoType.value === 'top-page',
        'header-underlayer': logoType.value === 'underlayer',
        'header-mycollection-page': isMyCollectionPage.value,
        'has-background-contents': !props.isRekion && !props.isKn,
        'has-background-rekion-main': props.isRekion,
        'has-background-kn-main': props.isKn,
      }
    })

    const nav = ref<HTMLDivElement>()
    const header = ref<HTMLDivElement>()
    const heightDuringAnimation = reactive<{ parent: null | number, nav: null | number }>({
      parent: null,
      nav: null,
    })
    const duringAnimation = ref(false)
    const toggleHeight = (isEnter: boolean) => {
      if (
        header.value &&
        nav.value &&
        breakpoint.value
      ) {
        if (isEnter) {
          const navHeight = (nav.value.children[0] as HTMLElement).offsetHeight
          heightDuringAnimation.parent = header.value.clientHeight + navHeight
          heightDuringAnimation.nav = navHeight
        } else {
          heightDuringAnimation.parent = null
          heightDuringAnimation.nav = null
        }
      }
    }

    /**
     * アニメーションに待機時間を設定する
     * @param element 対象のHTML要素の配列
     * @param standby 最初のアニメーションが発火するまでの待機時間 (秒)
     * @param delay 次のアニメーションが発火するまでの待機時間 (秒)
     * @param isEnter アニメーションの状態
     */
    const setAnimationDelaySec = (params: {
      element: HTMLElement[],
      standby: number,
      delay: number
      isEnter: boolean
    }) => {
      const delay = params.delay
      params.element && params.element.forEach((h, i) => {
        if (!params.isEnter) return ((h as HTMLElement).style.transitionDelay = '')
        const sec_ = (params.standby || 0) + delay * i
        ;(h as HTMLElement).style.transitionDelay = `${sec_}s`
      })
    }

    const toggle = async () => {
      if (!breakpoint.value) return
      show.value = !show.value
      duringAnimation.value = true
      setTimeout(() => (duringAnimation.value = false), 500)
    }

    watch(() => show.value, (newValue_) => {
      setTimeout(() => toggleHeight(newValue_), 10) // v-show の display: none が切り替わってから開閉する
    })
    watch(() => duringAnimation.value, (newValue_) => {
      setAnimationDelaySec({
        element: Array.from(window.document.querySelectorAll<HTMLElement>('.has-animation')),
        standby: 0,
        delay: 0.05,
        isEnter: newValue_,
      })
    })

    const headerStyleDuringAnimation = computed(() => ({
      height: heightDuringAnimation.parent && `${heightDuringAnimation.parent}px`,
      ...duringAnimation.value && {
        transitionDuration: '0.2s',
        transitionProperty: 'height',
        transitionTimingFunction: 'ease-out',
      },
    }))

    /**
     * グローバルナビの開閉ステータス、高さをリセットする処理
     */
    const resetNavStyle = () => {
      show.value = false
      heightDuringAnimation.parent = null
      heightDuringAnimation.nav = null
    }
    // ページ遷移時、グローバルナビをリセット
    watch(() => path.value, resetNavStyle)

    const focusRouteNameOfDetailSearchList = [
      'DetailedSearch', 'DetailedSearchResult', // 検索結果一覧画面
    ]
    const focusRouteNameOfCollectionSearchList = [
      'RekionSearchResult', 'CollectionDetailedSearch', 'CollectionDetailedSearchResult', // コレクション検索結果一覧画面
    ]
    const focusRouteNameOfImageResultList = [
      'SearchImage', 'SearchImageResult', // 画像検索画面
    ]
    const focusRouteNameOfAllList = focusRouteNameOfDetailSearchList.concat(focusRouteNameOfCollectionSearchList, focusRouteNameOfImageResultList)
    const isShowFocusMoveButton = computed(() => {
      return focusRouteNameOfAllList.includes(String(route.name))
    })

    const isServer = typeof window === 'undefined'
    const focusMove = (id: string) => {
      if (isServer) return
      const stringRootName = String(route.name)
      if (id === 'focus-move-button-of-search-form') {
        // 検索フォームへ移動
        let searchFormElement
        if (focusRouteNameOfImageResultList.includes(stringRootName)) {
          // 画像検索画面
          if (document.getElementsByClassName('input-image-file-preview').length !== 0) {
            searchFormElement = document.querySelector('.input-image-file-preview button') as HTMLElement
          } else {
            searchFormElement = document.querySelector('.input-image-file-input button') as HTMLElement
          }
        } else if (focusRouteNameOfDetailSearchList.includes(stringRootName)) {
          // 検索結果一覧画面
          searchFormElement = document.querySelector('.the-detailed-search-keyword input') as HTMLElement
        } else {
          // コレクション検索結果一覧画面
          searchFormElement = document.querySelector('.collection-detailed-search-keyword input') as HTMLElement
        }
        if (searchFormElement) searchFormElement.focus()
      } else if (id === 'focus-move-button-of-search-result') {
        // 検索実施前や検索結果0件の場合はフォーカス移動は実施しない
        if (store.getters.totalHits === 0) return
        // 検索結果一覧へ移動
        let searchResultElement
        if (focusRouteNameOfImageResultList.includes(stringRootName)) {
          // 画像検索画面
          searchResultElement = document.querySelector('.the-search-result-main-item-list a') as HTMLElement
        } else {
          // 検索結果一覧画面、コレクション検索結果一覧画面
          searchResultElement = document.querySelector('#the-selectbox-display-mode') as HTMLElement
        }
        if (searchResultElement) {
          searchResultElement.focus()
          scrollToSearchResultTop()
        }
      } else {
        // 検索実施前や検索結果0件の場合はフォーカス移動は実施しない
        if (store.getters.totalHits === 0) return
        // 検索結果の絞り込み欄へ移動
        const facetElement = document.querySelector('#facet-focus-move-button-of-search-result') as HTMLElement
        if (facetElement) facetElement.focus()
      }
    }

    return {
      KN_APPLICATION_LINK,
      headerStyle,
      show,
      logoType,
      isMyCollectionPage,
      isRekionAdminMenu,
      path,
      toggle,
      header,
      nav,
      heightDuringAnimation,
      headerStyleDuringAnimation,
      breakpoint,
      lang,
      focusMove,
      isShowFocusMoveButton,
    }
  },
})
