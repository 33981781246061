
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import AppToggleSwitch from '@/components/atoms/AppToggleSwitch.vue'
import { ImageViewer, ViewMode } from '@/helpers/imageviewer/ImageViewer'
import { useRoute } from 'vue-router'

const iv = ImageViewer.getInstance()

export default defineComponent({
  components: {
    AppToggleSwitch,
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const currentProcessingData = computed(() => store.getters.processingData)

    const pageIndex = computed(() => Number(route.params.contentIndex) - 1)
    // ローカルストレージに保持している値を設定
    iv.changeCoverPageMode(currentProcessingData.value.addCoverPageFlag ? 0 : 1, pageIndex.value)
    // 2in1時の表紙ページの表示切替
    const addCoverPageFlag = computed({
      get: () => currentProcessingData.value.addCoverPageFlag,
      set: (val: boolean) => {
        store.commit('updateAddCoverPageFlag', val)
        iv.changeCoverPageMode(Number(!val), pageIndex.value)
      },
    })

    // 2in1表示しているかの判定
    const isTwoInOneMode = computed(() => {
      return store.getters.imageviewerViewMode === ViewMode.TOW_IN_ONE
    })

    return {
      addCoverPageFlag,
      isTwoInOneMode,
    }
  },
})
