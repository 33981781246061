
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import DssIcon from '../atoms/DssIcon.vue'
import ButtonIcon from './ButtonIcon.vue'

export default defineComponent({
  components: {
    DssIcon,
    ButtonIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    style: {
      type: String,
      required: false,
    },
  },
  setup (props, context) {
    const store = useStore()
    const itemDownloadUrl = computed(() => store.getters.itemDownloadUrl)
    let timeObj: any = null
    const onRemove = () => {
      context.emit('resetDownload')
      clearTimeout(timeObj)
    }
    if (props.type === 'download_success') {
      timeObj = setTimeout(onRemove, 10000)
    }
    return {
      itemDownloadUrl,
    }
  },
})
