import { getDataWithRetry } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

type Meta = {
  id: number,
  field: string,
  group: string,
  label: string,
  label_en: string, // eslint-disable-line camelcase
  order?: number,
  type: string,
  value?: string|Array<string>, // マスタのため不要。API改修後に削除する
}

type State = {
  metadata: Array<Meta>,
}

const state = {
  metadata: [],
}

const getters = {
  MetedataMaster (state: State): Array<Meta> {
    return state.metadata
  },
  MetadataObjectById (state: State) {
    return (id: number): Meta => state.metadata.find(meta => meta.id === id) || {} as Meta
  },
  MetadataObjectByField (state: State) {
    return (field: string): Meta => state.metadata.find(meta => meta.field === field) || {} as Meta
  },
}

const mutations = {
  METADATA_MASTER (state: State, payload: Array<Meta>) {
    state.metadata = payload
  },
}

const actions = {
  async fetchMetadataMaster (context: any) {
    const url = `${BASE_URL}/master/metadata`
    const response = await getDataWithRetry(url)
    context.commit('METADATA_MASTER', response)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
