import router from '@/router'
import { store } from '@/store'

export const sessionCheck = async (fullPath: string, sessionExpiredAction = () => { /** nop */ }): Promise<boolean> => {
  if (await store.dispatch('sessionCheck')) {
    return true
  }
  store.dispatch('setRouteHistory', fullPath)
  sessionExpiredAction()
  router.push('/')
  return false
}
