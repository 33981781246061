import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b144ba14"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "disabled", "min", "max", "step", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', Number($event.target.value)))),
    disabled: _ctx.disabled,
    min: _ctx.min,
    max: _ctx.max,
    step: _ctx.step,
    "aria-label": _ctx.label,
    style: _normalizeStyle(_ctx.style),
    class: _normalizeClass([_ctx.size, "app-input-range"]),
    type: "range"
  }, null, 46, _hoisted_1))
}