export interface MyCollectionSearchMode {
  myColletionDisplayMode: string,
  myColletionPageSize: string,
  myColletionSortKey: string,
  myColletionDisplayItemViewPattern: string,
}

export const initialMyCollectionSearchMode = {
  myColletionDisplayMode: 'list',
  myColletionPageSize: '20',
  myColletionSortKey: 'SCORE',
  myColletionDisplayItemViewPattern: 'true',
}
