import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: "base-paragraph",
    "font-size": _ctx.fontSize,
    style: _normalizeStyle(`font-weight: ${_ctx.fontWeight};`)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["font-size", "style"]))
}