
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'AppInputRange',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      required: true,
    },
    fillProgress: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'is-medium',
    },
    basePoint: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  setup (props) {
    const style = computed(() => {
      if (props.fillProgress) {
        const total = props.max - props.min
        const base = props.basePoint - props.min
        const value = props.modelValue - props.min
        const left = Math.min(base, value) / total * 100
        const right = Math.max(base, value) / total * 100
        return {
          background: `linear-gradient(90deg, var(--background-color) 0 ${left}%, var(--progress-color) ${left}% ${right}%, var(--background-color) ${right}% 100%)`,
        }
      } else {
        return {}
      }
    })

    return {
      style,
    }
  },
})
