import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fbbf4e62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "panel-metadata-field" }
const _hoisted_4 = { class: "panel-metadata-field-name has-text-modest" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "panel-metadata-field-value"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "panel-metadata-field" }
const _hoisted_9 = { class: "panel-metadata-field-name has-text-modest" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 1,
  class: "panel-metadata-field-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonCopyClipboard = _resolveComponent("ButtonCopyClipboard")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_ctx.isExist(_ctx.value))
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
          (!_ctx.isMultiLine(_ctx.value))
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                _createElementVNode("li", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.labels[_ctx.lang]), 1),
                  (_ctx.isValueOfLink(_ctx.metaArrayAndStringJoin(_ctx.value)))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: _ctx.metaArrayAndStringJoin(_ctx.value),
                        class: "panel-metadata-field-value"
                      }, _toDisplayString(_ctx.metaArrayAndStringJoin(_ctx.value)), 9, _hoisted_5))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                        _createTextVNode(_toDisplayString(_ctx.metaArrayAndStringJoin(_ctx.value)), 1),
                        (_ctx.canCopyClipboard)
                          ? (_openBlock(), _createBlock(_component_ButtonCopyClipboard, {
                              key: 0,
                              icon: "copy",
                              styled: "is-none",
                              "target-text": _ctx.metaArrayAndStringJoin(_ctx.value),
                              label: _ctx.$t('metadata.pidCopyClipboard'),
                              class: "has-form-label"
                            }, null, 8, ["target-text", "label"]))
                          : _createCommentVNode("", true)
                      ]))
                ])
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                _createElementVNode("li", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.labels[_ctx.lang]), 1),
                  _createElementVNode("span", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value[0], (v, i) => {
                      return (_openBlock(), _createElementBlock("ul", { key: i }, [
                        _createElementVNode("li", null, [
                          (_ctx.isValueOfLink(v))
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: v,
                                class: "panel-metadata-field-value"
                              }, _toDisplayString(v), 9, _hoisted_10))
                            : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(v), 1))
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}