
import { defineComponent, ref } from 'vue'
import CloserController from '@/components/molecules/CloserController.vue'
import MetadataSimpleRecords from '@/components/organisms/metadataList/MetadataSimpleRecords.vue'
import MetadataDetailRecords from '@/components/organisms/metadataList/MetadataDetailRecords.vue'

export default defineComponent({
  components: {
    CloserController,
    MetadataSimpleRecords,
    MetadataDetailRecords,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup () {
    return {
      isDetail: ref(false),
    }
  },
})
