
import { defineComponent, computed, ref } from 'vue'
import AppButton from '@/components/atoms/AppButton.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppInputRange from '@/components/atoms/AppInputRange.vue'
import AppToggleSwitch from '@/components/atoms/AppToggleSwitch.vue'
import { useStore } from 'vuex'
import md from '@/helpers/util/MobileDetect'

export default defineComponent({
  components: {
    AppButton,
    AppInput,
    AppInputRange,
    AppToggleSwitch,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const store = useStore()
    const currentProcessingData = computed(() => store.getters.processingData)
    const isMobile = md.phone()

    const currentBrightness = computed({
      get: () => {
        return currentProcessingData.value.brightness
      },
      set: (val) => {
        store.commit('updateCurrentBrightness', val)
      },
    })
    const currentContrast = computed({
      get: () => {
        return currentProcessingData.value.contrast
      },
      set: (val) => {
        store.commit('updateCurrentContrast', val)
      },
    })
    const currentSharpness = computed({
      get: () => {
        return currentProcessingData.value.sharpness
      },
      set: (val) => {
        store.commit('updateCurrentSharpness', val)
      },
    })
    const currentGamma = computed({
      get: () => {
        return currentProcessingData.value.gamma
      },
      set: (val) => {
        store.commit('updateCurrentGamma', val)
      },
    })
    const grayscaleFlag = computed({
      get: () => currentProcessingData.value.grayscaleFlag,
      set: (flag: boolean) => store.commit('updateGrayscaleFlag', flag),
    })

    const resetColorProcessing = () => store.commit('resetImageViewerColorProcessingData')

    return {
      currentBrightness,
      currentContrast,
      currentSharpness,
      currentGamma,
      grayscaleFlag,
      resetColorProcessing,
      isMobile,
    }
  },
})
