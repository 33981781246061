
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    return {
      isRekion,
    }
  },
})
