import { MutationTree, ActionTree } from 'vuex'

export interface State {
  notificationMessages: { id: string, type: string, text: string }[]
}

const state: State = {
  notificationMessages: [],
}

const getters = {
  notificationMessages (state: any) {
    return state.notificationMessages
  },
}

const mutations: MutationTree<State> = {
  DELETE_NOTIFICATION (state: State, id) {
    const ids = state.notificationMessages.map(a => a.id)
    const index = ids.indexOf(id)
    state.notificationMessages.splice(index, 1)
  },
  LOGIN_NOTIFICATION (state: State) {
    state.notificationMessages = [{
      id: '001',
      type: 'success',
      text: 'loginMessage',
    }]
  },
  LOGOUT_NOTIFICATION (state: State) {
    state.notificationMessages = [{
      id: '001',
      type: 'success',
      text: 'logoutMessage',
    }]
  },
  // TODO セッションタイムアウト時のメッセージ確定
  SESSION_TIMEOUT_NOTIFICATION (state: State) {
    state.notificationMessages = [{
      id: '001',
      type: 'success',
      text: 'sessionTimeoutMessage',
    }]
  },
}

const actions: ActionTree<State, any> = {
}

export default {
  state,
  getters,
  mutations,
  actions,
}
