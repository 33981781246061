import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27ae00be"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "segmented-controller",
  role: "tablist"
}
const _hoisted_2 = ["onClick", "onKeydown", "disabled", "aria-selected", "aria-controls", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segments, (segment) => {
      return (_openBlock(), _createElementBlock("button", {
        key: segment.id,
        class: _normalizeClass({'is-current': segment.id === _ctx.currentId}),
        onClick: ($event: any) => (_ctx.update(segment.id)),
        onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.update(segment.id)), ["prevent"]), ["space","enter"]),
        disabled: segment.disabled,
        "aria-selected": segment.id === _ctx.currentId,
        "aria-controls": `tabpanel-${segment.id}`,
        role: "tab",
        id: `addon-view-${segment.id}`
      }, _toDisplayString(segment.name), 43, _hoisted_2))
    }), 128))
  ]))
}